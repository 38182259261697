import { useCallback, useEffect, useState } from "react";
import { useAtom } from "jotai";
import { sessionAtom } from "../../features/accounts/account.state";
import { CatalogBuilder } from "../../features/catalogs/catalog.builder";
import { catalogDbAtom } from "../../features/catalogs/catalog.state";
import { parameterDbAtom } from "../../features/eval/parameter.state";
import { layoutDbAtom } from "../../features/layouts/layout.state";
import { productDbAtom } from "../../features/products/product.state";
import { templateDbAtom } from "../../features/templates/template.state";

export const useBuilder = () => {
  const [catalogs] = useAtom(catalogDbAtom);
  const [templates] = useAtom(templateDbAtom);
  const [parameters] = useAtom(parameterDbAtom);
  const [productDb] = useAtom(productDbAtom);
  const [layoutDb] = useAtom(layoutDbAtom);
  const [session] = useAtom(sessionAtom);

  const [builder, setBuilder] = useState<CatalogBuilder>(null);
  const [viewVersion, setViewVersion] = useState<number>(0);
  const [builderVersion, setBuilderVersion] = useState<number>(-1);
  const [isDirty, setIsDirty] = useState(false);

  const refreshBuilder = useCallback(() => {
    if (builder) {
      builder.setData({
        catalogs,
        templates,
        parameters,
        productDb,
        layoutDb,
        config: session.account.config,
      });
      setViewVersion((v) => v + 1);
      setBuilderVersion(builder.data.version);
    }
  }, [
    builder,
    catalogs,
    layoutDb,
    parameters,
    productDb,
    session.account.config,
    templates,
  ]);

  useEffect(() => {
    if (
      builder &&
      (!builder?.ready ||
        builder?.data?.version !== builderVersion ||
        productDb?.version !== builder?.data?.productDb?.version)
    ) {
      refreshBuilder();
    }
  }, [
    builder?.data?.productDb?.version,
    builder?.ready,
    builder?.data?.version,
    builderVersion,
    productDb?.version,
    refreshBuilder,
    builder,
  ]);

  return {
    builder,
    setBuilder,
    isDirty,
    setIsDirty,
    viewVersion,
    setViewVersion,
    refreshBuilder,
  };
};
