export const ROOT_FOLDER = "Root";
export const PRODUCTS_FOLDER = "Products";

// export type ImageConfig = {
//   imageStorageUrl: string;
// };

export type ImageInfo = {
  p: string;
  l: number;
  m?: string;
};

export type ImageDbRaw = {
  //config: ImageConfig;
  images: ImageInfo[];
};

export type BlobImage = {
  name: string;
  folder: string;
  path: string;
  thumb: string;
  size: number;
  modified: string;
  ext: string;
  url: string;
};

export type ImageFolder = {
  files: BlobImage[];
  size: number;
};

export type ImageDb = {
  //  config: ImageConfig;
  storageUrl: string;
  imageMap: Map<string, BlobImage>;
  folderMap: Map<string, ImageFolder>;
  size: number;
};

export type ImageSelectOptions = {
  path?: string;
  folder?: string;
  multi?: boolean;
  canChange?: boolean;
  canSelectFolder?: boolean;
  onSelect?: (path: string, url: string) => void;
};

export const getImageUrl = (storageUrl: string, path: string) =>
  storageUrl + "/" + path + "?" + Date.now();

export const parseImagePath = (storageUrl: string, path: string) => {
  if (!path) return null;
  const i = path.lastIndexOf("/");
  const folder = i > 0 ? path.substring(0, i) : "";
  const name = i > 0 ? path.substring(i + 1) : path;
  const e = path.lastIndexOf(".");
  const ext = e > 0 ? path.substring(e + 1) : "";
  return {
    thumb: getImageUrl(storageUrl, combinePath(folder, "_thumb/" + name)),
    url: getImageUrl(storageUrl, path),
    folder,
    name,
    ext,
  };
};

export const combinePath = (folder, name) =>
  (folder ? folder + "/" : "") + name;
