import { useCallback } from "react";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import { ReactSortable } from "react-sortablejs";
import { FixedPanel } from "../../../components/FixedPanel";
import { IconTypes } from "../../../components/icons.types";
import {
  DesignItemRef,
  getPositonAttr,
  getItemDescription,
} from "../../../features/design/design.types";
import {
  DesignerBoardApi,
  DesignerChange,
  canEditItem,
} from "../designer.board.api";
import { useEditors } from "../useEditors";

const thumbSize = 30;

type Props = {
  api: DesignerBoardApi;
  onChange: (change: DesignerChange) => void;
  pageItems: DesignItemRef[];
  readonly?: boolean;
};

export const DesignerLayers = ({
  api,
  pageItems,
  onChange,
  readonly,
}: Props) => {
  const { openProductListExtEditor, openItemProductEdit } = useEditors();

  const positionAttr = getPositonAttr(api.meta);

  const page = api?.builder.getActivePage();
  const items = pageItems
    .filter((i) => i.type !== "page")
    .sortList((e) => -(e.di[positionAttr] ?? 0));

  const renderActions = useCallback(
    (item: DesignItemRef) => {
      return (
        <>
          {canEditItem(item) && (
            <IconButton
              size="small"
              color="primary"
              onClick={() => api.editItem(item.id)}
            >
              <IconTypes.Edit fontSize="small" />
            </IconButton>
          )}
          {item.info.product && item.meta === "catalog" && (
            <IconButton
              size="small"
              color="primary"
              onClick={() => openItemProductEdit(item, onChange)}
            >
              <IconTypes.Product fontSize="small" />
            </IconButton>
          )}

          {item.info.extension && (
            <IconButton
              size="small"
              color="primary"
              onClick={() =>
                openProductListExtEditor(item, api.builder, onChange)
              }
            >
              <IconTypes.Settings fontSize="small" />
            </IconButton>
          )}
        </>
      );
    },
    [api, onChange, openItemProductEdit, openProductListExtEditor]
  );

  if (!page || !items) return null;

  return (
    <Box
      sx={{
        "& .layer-element": {
          cursor: "pointer",
          p: 1,
          mb: 1,
          display: "flex",
          gap: 2,
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: 50,

          button: {
            display: "none",
          },

          "&.selected , &:hover": {
            backgroundColor: "action.selected",
            button: {
              display: "inline-flex",
            },
          },

          "& .actions": {
            //minWidth: 80,
            display: "flex",
            justifyContent: "flex-end",
            button: {
              color: "text.secondary",
            },
          },
          "& .layer-drag": {
            width: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          "& .layer-thumb": {
            minWidth: thumbSize + 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& img": {
              maxWidth: thumbSize + 10,
              maxHeight: thumbSize,
            },
            "& .bg": {
              backgroundColor: "text.secondary",
            },
            "& svg": {
              color: "text.secondary",
            },
          },
          "& .title": {
            flexGrow: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
        },
      }}
    >
      {!items.length && (
        <Box sx={{ p: 2, mt: 2, textAlign: "center" }}>
          <Typography variant="subtitle1">No elements</Typography>
        </Box>
      )}
      {items.length > 0 && !readonly && (
        <Box sx={{ mb: 1, ml: 1, px: 2 }}>
          <Typography variant="caption">
            Reorder, edit or delete elements
          </Typography>
        </Box>
      )}

      <FixedPanel overflow="auto">
        <Box sx={{ px: 1 }}>
          <ReactSortable
            list={items}
            disabled={readonly}
            setList={(newList) => {
              if (!readonly) {
                refreshPositions(newList, positionAttr);
                onChange({ type: "refresh", action: "reorder elements" });
              }
            }}
          >
            {items.map((item) => (
              <Paper
                key={item.id}
                className={`layer-element ${
                  api.selectedItem?.id === item.id ? "selected" : ""
                }`}
                onClick={
                  readonly
                    ? null
                    : () => api.boardRef.selectBoardItem(item, "click")
                }
              >
                <div className="layer-drag">
                  <IconTypes.Drag fontSize="small" />
                </div>

                <div className="layer-thumb">{renderElement(item)}</div>

                <span
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: getItemDescription(item, false),
                  }}
                />
                {!readonly && (
                  <div className="actions">
                    {renderActions(item)}
                    <IconButton
                      size="small"
                      // sx={{ color: "text.secondary" }}
                      onClick={(event) => {
                        event.stopPropagation();
                        onChange({ type: "remove", action: "remove", item });
                      }}
                    >
                      <IconTypes.DeleteAction />
                    </IconButton>
                  </div>
                )}
              </Paper>
            ))}
          </ReactSortable>
        </Box>
      </FixedPanel>
    </Box>
  );
};

const renderElement = (item: DesignItemRef) => {
  if (item.info.extension) return <IconTypes.ProductList />;
  if (item.info.template) return <IconTypes.ProductCard />;
  switch (item.type) {
    case "image": {
      return item.info.src && !item.info.imageField ? (
        <img src={item.info.src} alt={item.info.path} title={item.info.path} />
      ) : (
        <IconTypes.Image />
      );
    }
    case "bg": {
      return (
        <Box
          className="bg"
          style={{
            ...item.style,
            width: thumbSize,
            height: thumbSize,
            borderWidth: 2,
          }}
        ></Box>
      );
    }
    case "text": {
      return <IconTypes.TextPlaceholder />;
    }
    default:
      return "";
  }
};

const refreshPositions = (newList: DesignItemRef[], positionAttr: string) => {
  newList.forEach((item, i) => {
    const pos = newList.length - i - 1;
    item.di[positionAttr] = pos;
    item.style = { ...item.style, zIndex: pos };
  });
};
