import { IconButton } from "@mui/material";
import { grey } from "@mui/material/colors";
import { IconTypes } from "../../components/icons.types";

export const CloseButton = (params: { onClose: () => void }) => (
  <IconButton
    aria-label="close"
    onClick={params.onClose}
    sx={{
      position: "absolute",
      right: 8,
      top: 8,
      color: grey[500],
    }}
  >
    <IconTypes.Close />
  </IconButton>
);
