//https://github.com/remirror/remirror/blob/main/packages/remirror__extension-node-formatting/src/node-formatting-extension.ts

import {
  IdentifierSchemaAttributes,
  SchemaAttributesObject,
  command,
  extractPixelSize,
  joinStyles,
} from "remirror";
import { NodeFormattingExtension } from "remirror/extensions";
import type { CommandFunction } from "remirror";
import { removeStyleAttribute } from "./extension-utils";

const NODE_PB_ATTRIBUTE = "data-node-pb";
const NODE_STYLE_EXPRESSION = "data-raw-style";

const paddingBottom = (): SchemaAttributesObject => {
  return {
    default: null,
    parseDOM: (element) => {
      const pb =
        element.style.paddingBottom != null && element.style.paddingBottom != ""
          ? extractPixelSize(element.style.paddingBottom)
          : null;
      return pb;
    },
    toDOM: (attrs) => {
      //return null;
      if (attrs.paddingBottom == null) {
        return;
      }
      return {
        [NODE_PB_ATTRIBUTE]: attrs.paddingBottom.toString(),
      };
    },
  };
};

//const styleExprRegex = /{{([^}]+)}}(?:[^{]*|{{[^}]*}})*{{\/\1}}|[^;:]+:\s*{{[^}]*}};/g;
// const styleExprRegex =
//   /{{([^}]+)}}(?:[^{]*|{{[^}]*}})*{{\/\1}}|[^;:{}]+:\s*[^;]*{{[^}]*}}[^;]*;/g;

// const extractAndRemoveExpressions = (style) => {
//   const matches = style.match(styleExprRegex) || [];
//   const expressions = matches.join(" ");
//   const cleanedStyle = style.replace(styleExprRegex, "").trim();
//   return {
//     expressions,
//     cleanedStyle,
//   };
// };

const rawStyle = (): SchemaAttributesObject => {
  return {
    default: null,
    parseDOM: (element) => {
      const rawStyle = element.getAttribute("style");
      element.setAttribute("style", "");
      return rawStyle;
      // if (rawStyle) {
      //   const { expressions, cleanedStyle } =
      //     extractAndRemoveExpressions(rawStyle);
      //   element.setAttribute("style", cleanedStyle);
      //   return expressions;
      // }
      //return null;
    },
    toDOM: (attrs) => {
      //return { styleExpr: attrs.style as string };
      // const originalStyle = attrs.style || '';
      // const styleWithExpressions = [originalStyle, attrs.rawStyle].filter(Boolean).join(' ').trim();
      // return styleWithExpressions ? { 'style': styleWithExpressions } : {};
      if (attrs.rawStyle == null) {
        return;
      }
      return {
        [NODE_STYLE_EXPRESSION]: attrs.rawStyle.toString(),
      };
      //return null;
    },
  };
};

export class CmNodeFormattingExtension extends NodeFormattingExtension {
  createSchemaAttributes(): IdentifierSchemaAttributes[] {
    const schema = super.createSchemaAttributes();

    const attrs = schema[0].attributes;

    attrs.paddingBottom = paddingBottom();
    attrs.rawStyle = rawStyle();

    // ["nodeIndent", "nodeTextAlignment", "nodeLineHeight"].forEach((attr) => {
    //   (attrs[attr] as SchemaAttributesObject).toDOM = () => null;
    // });

    if (typeof attrs.style === "object" && attrs.style.toDOM) {
      // const originalToDOM = attrs.style.toDOM as (
      //   attrs: ProsemirrorAttributes
      //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // ) => Record<string, any>;

      attrs.style.toDOM = (nodeAttrs) => {
        // let originalStyle = originalToDOM(nodeAttrs).style;
        // if (originalStyle && !originalStyle.endsWith(";")) originalStyle += ";";
        // if (nodeAttrs.rawStyle != null) {
        //   originalStyle += nodeAttrs.rawStyle;
        // }

        const originalStyle = (nodeAttrs.rawStyle as string) || "";

        const s1 = removeStyleAttribute(originalStyle, "text-align");
        const s2 = removeStyleAttribute(s1, "line-height");
        const s3 = removeStyleAttribute(s2, "margin-left");

        const finalStyle = removeStyleAttribute(s3, "padding-bottom");

        const marginLeft = nodeAttrs.nodeIndent
          ? this.options.indents[nodeAttrs.nodeIndent]
          : undefined;
        const textAlign =
          nodeAttrs.nodeTextAlignment && nodeAttrs.nodeTextAlignment !== "none"
            ? nodeAttrs.nodeTextAlignment
            : undefined;
        const lineHeight = nodeAttrs.nodeLineHeight
          ? nodeAttrs.nodeLineHeight
          : undefined;

        const paddingBottom =
          nodeAttrs.paddingBottom != null
            ? `${nodeAttrs.paddingBottom}px`
            : undefined;

        let style = joinStyles(
          { paddingBottom, marginLeft, textAlign, lineHeight },
          ""
        );
        if (style && !style.endsWith(";")) style += ";";

        return {
          style: (style + finalStyle).trim(),
        };
      };
    }

    return schema;
  }

  @command()
  setBottomPadding(padding: number): CommandFunction {
    return ({ tr, dispatch }) => {
      const { selection } = tr;
      if (selection.empty) return false;

      const { from, to } = selection;
      let updated = false;

      tr.doc.nodesBetween(from, to, (node, pos) => {
        if (
          node.type.name === "paragraph" &&
          node.attrs.paddingBottom !== padding
        ) {
          const newAttrs = { ...node.attrs, paddingBottom: padding };
          tr.setNodeMarkup(pos, undefined, newAttrs);
          updated = true;
        }
      });

      if (updated && dispatch) {
        dispatch(tr);
        return true;
      }

      return false;
    };
  }
}
