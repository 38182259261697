import { trackEvent } from "../../services/analytics";
import apiClient, { OperationResult } from "../../services/apiClient";
import { EMPTY_GUID } from "../data/data-types";
import {
  Layout,
  LayoutAction,
  LayoutDb,
  LayoutDbRaw,
  LayoutRef,
} from "./layouts";

export const getLayoutDb = async (): Promise<LayoutDb> => {
  const response = await apiClient.get<LayoutDbRaw>("/catalogs/layouts");
  const db = response.data;
  return {
    standard: db.standard.map((l) => ({ layout: l, readonly: true })),
    account: db.account.map((l) => ({ layout: l })),
  };
};

export const saveLayout = async (
  model: Layout
): Promise<OperationResult<Layout>> => {
  trackEvent({
    category: "layout",
    action: model.id === EMPTY_GUID ? "create" : "update",
    label: (model.id === EMPTY_GUID ? "new" : model.id) + ":" + model.name,
    feature: true,
  });
  const response = await apiClient.post<OperationResult<Layout>>(
    "/catalogs/layout",
    model
  );
  return response.data;
};

export const deleteLayout = async (
  layoutId: string
): Promise<OperationResult<string>> => {
  trackEvent({ category: "layout", action: "delete", label: layoutId });
  const response = await apiClient.post<OperationResult<string>>(
    "/catalogs/delete-layout?layoutId=" + layoutId
  );
  return response.data;
};

export const getSharedLayouts = async (): Promise<LayoutRef[]> => {
  const response = await apiClient.get<Layout[]>("/catalogs/shared-layouts");
  return response.data.map((l) => ({ layout: l, readonly: true }));
};

export const sendLayoutAction = async (
  layoutId: string,
  action: LayoutAction
) => {
  const response = await apiClient.post<boolean>("/catalogs/layout-action", {
    layoutId,
    action,
  });
  return response.data;
};
