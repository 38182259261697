import { useBlocker, useBeforeUnload } from "react-router-dom";
import { WarningDialog } from "../components/dialogs/WarningDialog";
import { useState, useCallback, useEffect, useMemo } from "react";

type ChangeTarget = {
  id: string;
  arg?;
};

type Params = {
  isDirty: boolean;
  //onSave?: () => void;
  //onExit?: (cancelled: boolean) => void;
  target: string;
  searchParam?: string;
  onProceed?: (change: ChangeTarget, cancelled: boolean) => void;
  onStay?: () => void;
  disableSave?: boolean;
  autoSave: boolean;
  isNew?: boolean;
};

export const useBlockRoute = ({
  isDirty,
  onProceed,
  onStay,
  target,
  searchParam,
  disableSave,
  autoSave,
  isNew,
}: Params) => {
  const [isExit, setIsExit] = useState(false);
  const [change, setChange] = useState<ChangeTarget>(null);

  const proceed = useCallback(
    (cancelled?: boolean) => {
      onProceed?.(change, cancelled);
      setIsExit(false);
      setChange(null);
    },
    [change, onProceed]
  );

  const blocker = useBlocker((tx) => {
    if (isDirty) {
      const changed =
        tx.currentLocation.pathname !== tx.nextLocation.pathname ||
        (searchParam &&
          new URLSearchParams(tx.currentLocation.search).get(searchParam) !==
            new URLSearchParams(tx.nextLocation.search).get(searchParam));
      if (changed) {
        if (autoSave && !isNew) {
          proceed();
          return false;
        }

        setIsExit(true);
        return true;
      }
    }
    return false;
  });

  useEffect(() => {
    if (blocker.state === "blocked" && !isDirty) {
      blocker.reset();
    }
  }, [blocker, isDirty]);

  useBeforeUnload((event) => {
    if (isDirty) {
      event.preventDefault();
      event.returnValue =
        "Are you sure you want to leave? You have unsaved changes.";
    }
  });

  const ExitWarningDialog = useMemo(() => {
    //const nextLabel = change ? "proceed" : "exit";
    const message =
      (disableSave ? "Stay" : `Save ${target}`) +
      " or discard changes before leaving?";
    return (
      <WarningDialog
        open={isExit || !!change}
        onClose={() => {
          setIsExit(false);
          setChange(null);
          blocker?.reset?.();
          onStay?.();
        }}
        onConfirm={(cancelled) => {
          proceed(cancelled);
          blocker?.proceed?.();
        }}
        title="You have unsaved changes."
        action={!disableSave && !isNew ? "Save" : null}
        cancelLabel="Stay"
        secondaryAction="Discard"
        processing={false}
      >
        {message}
      </WarningDialog>
    );
  }, [blocker, change, disableSave, isExit, isNew, onStay, proceed, target]);

  return { ExitWarningDialog, setChange, setIsExit };
};
