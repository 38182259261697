import { ThemeProvider } from "@mui/material";

interface Props {
  theme?: object;
  children: React.ReactNode;
}

const ThemeWrapper: React.FC<Props> = ({ theme, children }) => {
  if (theme) {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
  } else {
    return <>{children}</>;
  }
};

export default ThemeWrapper;
