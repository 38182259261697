import { AnyExtension, ChainedFromExtensions } from "remirror";

export interface HtmlEditorRef {
  setContent: (content: string) => void;
  replaceContent: (content: string) => void;
  focus: () => void;
  insertText: (content: string) => void;
  insertParagraph: (content: string) => void;
  chain: ChainedFromExtensions<AnyExtension>;
}

export const insertFieldToEditor = (
  content: string,
  args,
  { chain, insertText }: Partial<HtmlEditorRef>
) => {
  if (chain) {
    if (content === "$variants") {
      const options =
        args?.product?.category.options.reduce(
          (a, o) => `${a} {{${o.name}}}`,
          ""
        ) ?? "";
      chain
        .emptySelection()
        .insertParagraph(`{{/Variants}}`, { selection: "end" })
        .insertParagraph(
          `{{Image:Fit:50:50}} ${options} {{SKU}} {{Price}} {{Description}}`
        )
        .insertParagraph(`{{Variants}}`)
        .focus()
        .run();
    } else if (content === "$product-list") {
      chain
        .emptySelection()
        .insertParagraph(`{{/Products}}`)
        .insertParagraph(`{{Name}} {{Code}} {{Price}}`)
        .insertParagraph(`{{Products}}`)
        .focus()
        .run();
    } else if (content === "$category-list") {
      chain
        .emptySelection()
        .insertParagraph(`{{/Categories}}`)
        .insertParagraph(`{{Name}}`)
        .insertParagraph(`{{Categories}}`)
        .focus()
        .run();
    } else {
      insertText(content);
    }
  }
};
