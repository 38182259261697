declare global {
  interface Array<T> {
    sortList(getter?: (e: T) => string | number, direction?: number): Array<T>;
    filterList(filter?: string, getter?: (e: T) => string): Array<T>;
  }
}

export const sortBy = (a: unknown, b: unknown, direction = 1) =>
  a > b ? direction : b > a ? -direction : 0;

Array.prototype.sortList = function <T>(
  getter?: (e: T) => string | number,
  direction = 1
) {
  return [...this].sort((a, b) => {
    const v1 = getter ? getter(a) : a;
    const v2 = getter ? getter(b) : b;
    return sortBy(v1, v2, direction);
  });
};

export const escapeRegExp = (value: string): string => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

Array.prototype.filterList = function <T>(
  filter?: string,
  getter?: (e: T) => string
): T[] {
  if (!filter) return this;

  const searchRegex = new RegExp(escapeRegExp(filter), "i");

  return this.filter((e) =>
    searchRegex.test(getter ? getter(e) : (e as string))
  );
};

export const hasListIdsOrderChange = (
  list1: { id: string }[],
  list2: { id: string }[]
) => {
  if (list1.length !== list2.length) return true;
  return list1.some((e, i) => e.id !== list2[i].id);
};

export const enumIndexName = (myEnum: object, index: number) => {
  const result = Object.keys(myEnum)
    .filter((key) => isNaN(Number(key)))
    .map((key) => key);

  return result[index];
};
