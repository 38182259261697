import { Box, Button, SxProps } from "@mui/material";
import { SearchBox } from "../../../components/SearchBox";
import { IconTypes } from "../../../components/icons.types";
import { useDialogs } from "../../../contexts/useDialogs";
import {
  ProductFilter,
  useProducts,
} from "../../../features/products/product-func";
import { CategorySelect } from "../categories/CategorySelect";
import { CollectionSelect } from "../collections/CollectionSelect";

type ProductFilterProps = {
  filter: ProductFilter;
  setFilter: (filter: ProductFilter) => void;
  sx?: SxProps;
  noAdd?: boolean;
};

export const ProductFilterBox = ({
  filter,
  setFilter,
  sx,
  noAdd,
}: ProductFilterProps) => {
  const { defaultCategory } = useProducts();
  const { productModalApi } = useDialogs();

  return (
    <Box
      sx={{
        p: 1,
        width: {
          xs: "100%",
          sm: "auto",
        },
        display: "flex",
        flexWrap: "wrap",
        gap: 1,
        ...sx,
      }}
    >
      <SearchBox
        value={filter.search ?? ""}
        onChange={(search) => setFilter({ ...filter, search })}
        placeholder="Search products…"
      />
      <CategorySelect
        sx={{ width: 120 }}
        categoryId={filter.categoryId}
        onChange={(categoryId) => setFilter({ ...filter, categoryId })}
        defaultLabel="Category"
      />
      <CollectionSelect
        sx={{ mx: 1, width: 120 }}
        collectionId={filter.collectionId}
        onChange={(collectionId) => setFilter({ ...filter, collectionId })}
        defaultLabel="Collection"
      />
      {!noAdd && (
        <Button
          color="primary"
          sx={{ maxWidth: 40, minWidth: 40, height: 40 }}
          onClick={() => {
            const categoryId = filter?.categoryId ?? defaultCategory.group.id;
            productModalApi.openEditProduct({
              categoryId,
            });
          }}
          variant="outlined"
        >
          <IconTypes.Add />
        </Button>
      )}
      {/* <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
       
      </Box> */}
    </Box>
  );
};
