import { useState, useCallback } from "react";
import { TemplateDesignerOptions } from "./TemplateDesigner";
import { TemplateDesignerModal } from "./TemplateDesignerModal";

export const useTemplateDesignerModal = () => {
  const [params, setParams] = useState<TemplateDesignerOptions>();

  const openTemplateDesigner = (p: TemplateDesignerOptions) => setParams(p);
  const renderTemplateDesignerModal = useCallback(() => {
    if (!params) return null;

    return (
      <TemplateDesignerModal
        {...params}
        isOpen={!!params}
        onClose={() => setParams(null)}
      />
    );
  }, [params]);

  return { renderTemplateDesignerModal, openTemplateDesigner };
};
