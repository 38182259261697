import { Box, IconButton } from "@mui/material";
import { IconTypes } from "../../../components/icons.types";

type Props = {
  isNavOpen: boolean;
  onNavToggle: (isOpen: boolean) => void;
};

export const NavToggle = ({ isNavOpen, onNavToggle }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mr: 1,
      }}
    >
      <IconButton
        onClick={() => {
          onNavToggle(!isNavOpen);
        }}
        size="small"
        sx={{ color: "text.secondary" }}
      >
        {isNavOpen ? (
          <IconTypes.DoubleLeftArrow fontSize="small" />
        ) : (
          <IconTypes.DoubleRightArrow fontSize="small" />
        )}
      </IconButton>
    </Box>
  );
};
