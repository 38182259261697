import {
  CatalogExtension,
  DesignItemRef,
  DesignItemMeta,
  VariantPrefix,
} from "../design/design.types";
import { LayoutItem } from "../layouts/layouts";
import { CatalogBuilder } from "./catalog.builder";
import { CreateItemAttrs } from "./catalog.design";
import { CatalogItem } from "./catalogs";

export enum ProductListExtTabIndex {
  Products = 0,
  Fields = 1,
  Design = 2,
}

export type ProductListExtOptions = {
  onChange?: (ext: CatalogExtension, productsOnly?: boolean) => void;
  onClose?: () => void;
  onCancel?: () => void;
  containerId?: string;
  extension: CatalogExtension;
  builder?: CatalogBuilder;
  item?: DesignItemRef;
  isNew?: boolean;
};

const rightAlignFields = ["price", "page"];

export const assignProductListExtAttrs = (attrs: CreateItemAttrs) => {
  const change = getProductListExtChange(
    createProductListExt(),
    "layout",
    false
  ) as Partial<LayoutItem>;
  attrs.type = "text";
  attrs.ext = change.Extension;
  attrs.content = change.text;
  attrs.w = 400;
  attrs.h = 400;
};

export const createProductListExt = (): CatalogExtension => {
  return {
    type: "productlist",
    fields: ["Name", "Code", "Price"],
    grouping: "",
    groupOrder: "Name",
    HeaderColor: "#ffffff",
    HeaderBkgColor: "#5A6685",
    HeaderPadding: "4",
    ProductPadding: "4",
    GroupPadding: "4",
    Border: "Grid",
    BorderColor: "#cccccc",
  };
};

export const initProductListExt = (ext: string): CatalogExtension => {
  try {
    return ext ? JSON.parse(ext) : null;
  } catch (err) {
    console.log(err);
  }
};

export const getProductListExtChange = (
  extension: CatalogExtension,
  meta: DesignItemMeta,
  productsOnly: boolean
) => {
  const ext: CatalogExtension = {
    ...extension,
    collection: undefined,
    previewProductIds: undefined,
  };
  const extStr = JSON.stringify(ext);
  const text = createProductListExtBody(extension);
  if (meta === "catalog") {
    return productsOnly
      ? {
          Extension: extStr,
        }
      : ({
          Extension: extStr,
          Body: text,
        } as Partial<CatalogItem>);
  }
  if (meta === "layout") {
    return {
      Extension: extStr,
      text,
    } as Partial<LayoutItem>;
  }
};

export const createProductListExtBody = (ext: CatalogExtension) => {
  let borderStyle = "",
    tableBorderStyle = "";

  if (ext.Border !== "None")
    borderStyle =
      "border" +
      (ext.Border === "Bottom" ? "-bottom" : "") +
      "-width: 1px; " +
      "border" +
      (ext.Border === "Bottom" ? "-bottom" : "") +
      "-style: solid; border-color: " +
      ext.BorderColor +
      ";";

  if (ext.Border === "Grid") tableBorderStyle = borderStyle;

  const headerStyle =
    'style="' +
    (ext.HeaderBkgColor ? "background-color:" + ext.HeaderBkgColor + ";" : "") +
    (ext.HeaderColor ? " color:" + ext.HeaderColor + ";" : "") +
    '"';

  const headerCellStyle =
    "text-align:center;" +
    tableBorderStyle +
    (ext.HeaderPadding ? " padding:" + ext.HeaderPadding + "px;" : "") +
    (ext.HeaderFontSize ? " font-size:" + ext.HeaderFontSize + "px;" : "") +
    (ext.HeaderFont ? " font-family:" + ext.HeaderFont + ";" : "");

  const productStyle =
    'style="' +
    (ext.ProductBkgColor
      ? "background-color:" +
        (ext.AltRowBkgColor
          ? "{{If AltRow}}" +
            ext.AltRowBkgColor +
            "{{Else}}" +
            ext.ProductBkgColor +
            "{{/If}}"
          : ext.ProductBkgColor) +
        ";"
      : "") +
    (ext.ProductColor ? " color:" + ext.ProductColor + ";" : "") +
    '"';

  const productCellStyle =
    "vertical-align: top;" +
    borderStyle +
    (ext.ProductPadding ? " padding:" + ext.ProductPadding + "px;" : "") +
    (ext.ProductFontSize ? " font-size:" + ext.ProductFontSize + "px;" : "") +
    (ext.ProductFont ? " font-family:" + ext.ProductFont + ";" : "");

  let fieldsHeader = "";
  let fieldsCells = "";
  if (ext.fields.length === 0) ext.fields.push("Name");

  let totalWidth = 0;
  const relWidths = [];
  for (let j = 0; j < ext.fields.length; j++) {
    const fn = ext.fields[j].toLowerCase();
    let w = 2;
    if (
      fn.indexOf("image") >= 0 ||
      fn.indexOf("price") >= 0 ||
      fn === "product page link" ||
      fn === "product order link" ||
      fn === "code"
    ) {
      w = 1;
    }
    relWidths.push(w);
    totalWidth += w;
  }

  for (let i = 0; i < ext.fields.length; i++) {
    const f = ext.fields[i];
    let content = "{{" + f + "}}";
    const n = f.indexOf(":");
    let name = n > 0 ? f.substring(n + 1) : f;
    const prefix = n > 0 ? f.substring(0, n) : "";

    if (prefix === VariantPrefix) {
      const un = name.indexOf(":Unique");
      name = un > 0 ? name.substring(0, un) : name;
      if (ext.VariantRow) {
        content = "{{" + name + "}}";
      } else {
        content =
          "{{Variants" +
          (un > 0 ? ":Unique=" + name : "") +
          "}}{{" +
          name +
          "}}</br>{{/Variants}}";
      }
    }

    if (name === "Product Page Link") name = "Page";

    let fieldStyle = "",
      fieldHeaderStyle = "";
    if (
      name === "Page" ||
      rightAlignFields.some(function (f) {
        return name.toLowerCase().indexOf(f) >= 0;
      })
    ) {
      fieldStyle += "text-align:right;";
    }

    if (name === "Product Order Link") {
      name = "Order";
      fieldStyle += "text-align:right;";
    }

    if (name === "Empty") {
      content = "";
    }
    if (n > 0 && prefix !== VariantPrefix && prefix !== "Product Page Link") {
      name = prefix;
    }
    if (name.indexOf("Image") >= 0) {
      name = "";
      fieldStyle += "text-align:center;";
    }

    if (totalWidth > 0) {
      fieldHeaderStyle +=
        "width:" +
        Math.round((relWidths[i] * 100 * 10) / totalWidth) / 10 +
        "%;";
    }

    fieldsHeader +=
      '<th style="' +
      headerCellStyle +
      fieldHeaderStyle +
      '">' +
      name +
      "</th>";
    fieldsCells +=
      '<td style="' + productCellStyle + fieldStyle + '">' + content + "</td>";
  }

  let productArgs = "";
  if (ext.inStock) {
    productArgs += ": InStock";
  }
  if (ext.orderBy) {
    let sf = ext.orderBy;
    if (sf.indexOf("Product Page Link:") === 0) {
      sf = sf.substring(sf.indexOf(":") + 1);
    }
    productArgs += ": OrderBy " + (ext.orderDesc ? "> " : "< ") + sf;
  }

  let groupStart = "",
    groupEnd = "";
  if (ext.GroupBy) {
    const bkgStyle = ext.UseGroupColor
      ? "{{If Color}}{{Color}}{{Else}}" + ext.GroupBkgColor + "{{/If}}"
      : ext.GroupBkgColor;

    const grpStyle =
      'style="' +
      (bkgStyle ? "background-color:" + bkgStyle + ";" : "") +
      (ext.GroupColor ? " color:" + ext.GroupColor + ";" : "") +
      '"';

    const grpCellStyle =
      "text-align:center;" +
      (ext.GroupPadding ? " padding:" + ext.GroupPadding + "px;" : "") +
      (ext.GroupFontSize ? " font-size:" + ext.GroupFontSize + "px;" : "") +
      (ext.GroupFont ? " font-family:" + ext.GroupFont + ";" : "");

    const grpOrder = ext.GroupOrder ? ": OrderBy < " + ext.GroupOrder : "";

    groupStart =
      "<div><!--{{" +
      ext.GroupBy +
      ": ProductCount > 0 " +
      grpOrder +
      "}}-->" +
      '<table border="0" style="width: 100%;"><tbody>' +
      "<tr " +
      grpStyle +
      '><td style="' +
      grpCellStyle +
      '">';

    if (ext.GroupThumb) groupStart += "{{Thumbnail:Fit:25:25}} &nbsp;";
    groupStart += "{{Name}}";
    if (ext.GroupDesc) groupStart += " &nbsp;&nbsp;{{Description}}";

    groupStart += "<br></td></tr></tbody></table>";

    groupEnd = "<br><!--{{/" + ext.GroupBy + "}}--></div>";
  }

  const header = ext.HideHeader
    ? ""
    : "<thead>" +
      "<tr " +
      headerStyle +
      ">" +
      fieldsHeader +
      "</tr>" +
      "</thead>";

  const variantStart = ext.VariantRow ? "<!--{{Variants}}-->" : "";
  const variantEnd = ext.VariantRow ? "<!--{{/Variants}}-->" : "";

  const body =
    groupStart +
    '<table style="width: 100%;' +
    tableBorderStyle +
    '" ' +
    (!tableBorderStyle ? ' border = "0"' : "") +
    ">" +
    header +
    "<tbody>" +
    "<!--{{Products" +
    productArgs +
    "}}-->" +
    variantStart +
    "<tr " +
    productStyle +
    ">" +
    fieldsCells +
    "</tr>" +
    variantEnd +
    "<!--{{/Products}}-->" +
    "</tbody >" +
    "</table >" +
    groupEnd;

  return body;
};
