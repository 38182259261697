import { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";

import { sessionAtom } from "../../features/accounts/account.state";
import { useAtom } from "jotai";
import ProgressMetric from "../../components/cards/ProgressMetric";
import { NavLink } from "react-router-dom";
import { Page } from "../../containers/layouts/Page";
import { useAppState } from "../../features/app/app.service";
import { ImportQuickActions, QuickAction } from "../../components/QuickAction";
import { ServerInfo } from "../../features/app/app.state";

const Home = () => {
  const [session] = useAtom(sessionAtom);
  const { refreshAccountSummary, getAppState } = useAppState();
  const [info, setInfo] = useState<ServerInfo>();

  useEffect(() => {
    const load = async () => {
      await refreshAccountSummary();
      const a = await getAppState();
      setInfo(a.info);
    };
    load();
  }, [getAppState, refreshAccountSummary]);

  return (
    <Page title="Dashboard">
      <Box>
        <Grid container spacing={3}>
          <Grid
            item
            lg={3}
            sm={6}
            xs={12}
            component={NavLink}
            to="/products"
            sx={{ textDecoration: "none" }}
          >
            <ProgressMetric
              title="Products"
              value={session.account.summary.itemsCount ?? 0}
              maxValue={session.account.limits.items}
              info={`${session.account.summary.categoriesCount} categories`}
            />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xs={12}
            component={NavLink}
            to="/images"
            sx={{ textDecoration: "none" }}
          >
            <ProgressMetric
              title="Images"
              value={
                session.account.summary.imageSizeMb
                  ? Math.floor(session.account.summary.imageSizeMb)
                  : 0
              }
              maxValue={session.account.limits.space}
              unit="MB"
              info={`${session.account.summary.imagesCount ?? 0} images`}
            />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xs={12}
            component={NavLink}
            to="/catalogs"
            sx={{ textDecoration: "none" }}
          >
            <ProgressMetric
              title="Catalogs"
              value={session.account.summary.catalogsCount ?? 0}
              maxValue={session.account.limits.catalogs}
              info={`${session.account.summary.templatesCount} templates`}
            />
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ mt: 5 }}>
          Quick Actions
        </Typography>
        <Box
          sx={{
            mt: 2,
            mb: 2,
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          <QuickAction
            title="Create catalogs"
            imageSrc="/images/catalogue.png"
            href="/catalogs"
          />
          <QuickAction
            title="Add / edit products"
            imageSrc="/images/products.png"
            href="/products"
          />
          <QuickAction
            title="Upload / edit images"
            imageSrc="/images/image-gallery.png"
            href="/images"
          />
          <ImportQuickActions />
          <QuickAction
            title="Manage account"
            imageSrc="/images/user.png"
            href="/account"
          />
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 1,
          right: 5,
          fontSize: 9,
          overflow: "hidden",
        }}
      >
        v.{info?.version}{" "}
        {!info?.version.startsWith(import.meta.env.VITE_VERSION) &&
          " c." + import.meta.env.VITE_VERSION}
      </Box>
    </Page>
  );
};

export default Home;
