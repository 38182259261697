import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SxProps,
  Box,
} from "@mui/material";
import { SearchBox } from "../../components/SearchBox";
import { SelectFromList } from "../../components/SelectFromList";
import { imageSortBy, useImages } from "../../features/images/image-func";

export type ImageViewOptions = {
  filter?: string;
  sort?: string;
  folder?: string;
};

interface FolderSelectParams {
  showAll?: boolean;
  folder: string;
  label: string;
  onChange: (folder: string) => void;
  sx?: SxProps;
}

export const FolderSelect = ({
  folder,
  onChange,
  label,
  showAll,
  sx,
}: //,
FolderSelectParams) => {
  const { folders } = useImages();

  return (
    <SelectFromList
      entries={folders.map((f) => ({ value: f, name: f }))}
      value={folder}
      setValue={onChange}
      label={label}
      size="small"
      defaultLabel={showAll ? "All images" : null}
      sx={{ minWidth: 100, ...sx }}
    />
  );
};

interface ImageViewParams {
  view: ImageViewOptions;
  onChange: (view: ImageViewOptions) => void;
  align?: "right";
}

export const ImageViewControls = ({ view, onChange }: ImageViewParams) => {
  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <SearchBox
        value={view.filter}
        onChange={(t) => onChange({ filter: t })}
        placeholder="Search…"
        title="Search images"
      />
      <FormControl sx={{ ml: 1, width: 120 }} size="small">
        <InputLabel id="image-sort">Sort By</InputLabel>
        <Select
          value={view.sort}
          onChange={(event) => onChange({ sort: event.target.value })}
          label="Sort By"
          labelId="image-sort"
        >
          {imageSortBy.map((s) => (
            <MenuItem key={s.v} value={s.v}>
              {s.n}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
