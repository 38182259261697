import { Typography, Button, Box, Paper } from "@mui/material";

import { useCallback } from "react";
import { useAtom } from "jotai";
import { ImageModalType } from "../containers/images/modals/ImageModal";
import { sessionAtom } from "../features/accounts/account.state";
import { parseImagePath } from "../features/images/image";
import { useDialogs } from "../contexts/useDialogs";

interface ImagePickerProps {
  path?: string;
  label: string;
  setPath: (path?: string) => void;
}

export const ImagePicker = ({ label, path, setPath }: ImagePickerProps) => {
  const [session] = useAtom(sessionAtom);
  const { openImageModal } = useDialogs();
  const p = parseImagePath(session.account.config.imageStorageUrl, path);

  const openImageView = useCallback(
    (type: ImageModalType) => {
      openImageModal(
        {
          path,
          onSelect: setPath,
          canChange: true,
          canSelectFolder: true,
        },
        type
      );
    },
    [openImageModal, path, setPath]
  );

  return (
    <Paper
      variant="outlined"
      //   elevation={1}
      sx={{
        maxWidth: 150,
        position: "relative",
        // backgroundImage:
        //   "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        my: 1,
        "& .label": {
          transformOrigin: "top left",
          transform: "translate(10px, -9px) scale(0.75)",
          position: "absolute",
          left: 0,
          top: 0,
          maxWidth: 180,
          color: "text.secondary",
          zIndex: 1,
          px: 1,
          backgroundColor: "background.paper",
          opacity: 0.9,
          whiteSpace: "nowrap",
          overflow: "hidden",
          // textOverflow: "ellipsis",
          // backgroundImage:
          //   "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        },
      }}
    >
      <Box className="label">
        <span> {label}</span>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 50,
          maxHeight: 200,
          overflow: "hidden",
          fontSize: 10,
          cursor: "pointer",
          "& img": {
            maxHeight: "100%",
            maxWidth: "100%",
            borderRadius: 0.5,
          },
        }}
        onClick={() => openImageView(path ? "view" : "select")}
      >
        {path && <img src={p?.thumb} alt={path} />}
        {!path && <Typography>No image</Typography>}
      </Box>

      {path && (
        <>
          <Box
            sx={{
              p: 1,
              textAlign: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Typography variant="caption" title={path}>
              {path}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              variant="text"
              size="small"
              onClick={() => openImageView("select")}
            >
              Change
            </Button>
            <Button variant="text" size="small" onClick={() => setPath(null)}>
              Remove
            </Button>
          </Box>
        </>
      )}
    </Paper>
  );
};
