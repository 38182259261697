const maxRecords = 50;

export type DesignerAction = {
  title: string;
  json: string;
  pageNum: number;
};

export type DesignerHistory = {
  undo: DesignerAction[];
  redo: DesignerAction[];
};

export const addActionHistory = (
  history: DesignerHistory,
  title: string,
  pageNum: number,
  data: unknown
): DesignerHistory => {
  const action: DesignerAction = {
    title: title,
    json: JSON.stringify(data),
    pageNum,
  };
  if (history.undo.length > maxRecords) {
    history.undo.shift();
  }
  return {
    undo: [...history.undo, action],
    redo: [],
  };
};

export const undoAction = (history: DesignerHistory, i: number) => {
  const n = history.undo.length - i - 2;
  if (n >= 0 && n < history.undo.length) {
    const action = history.undo[n];
    const to = n + 1;
    const undo = history.undo.slice(0, to);
    const redo = history.undo.slice(to);
    return {
      action,
      history: {
        undo,
        redo: [...redo, ...history.redo],
      },
    };
  }
  return null;
};

export const redoAction = (history: DesignerHistory, i: number) => {
  if (i >= 0 && i < history.redo.length) {
    const action = history.redo[i];
    const undo = history.redo.slice(0, i + 1);
    const redo = history.redo.slice(i + 1);
    return {
      action,
      history: {
        undo: [...history.undo, ...undo],
        redo,
      },
    };
  }
};

export const getActionHistory = (history: DesignerHistory) => {
  return [...history.undo].slice(1).reverse();
};
