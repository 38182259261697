import { Dialog } from "@mui/material";
import { TemplateDesigner, TemplateDesignerOptions } from "./TemplateDesigner";
//import { Transition } from "../../../components/Transitions";

export type TemplateDesignerModalProps = {
  isOpen?: boolean;
} & TemplateDesignerOptions;

export type OpenTemplateDesignerModalFunc = (
  params: TemplateDesignerOptions
) => void;

export const TemplateDesignerModal = (props: TemplateDesignerModalProps) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      fullScreen
      //TransitionComponent={Transition}
    >
      <TemplateDesigner modal={true} {...props} />
    </Dialog>
  );
};
