import { useCallback, useState } from "react";
import { useAtom } from "jotai";
import {
  Collection,
  mapCollection,
  refreshCollectionMap,
} from "../../../features/products/product";
import { saveCollection } from "../../../features/products/product.service";
import { productDbAtom } from "../../../features/products/product.state";
import { useDbOperations } from "../../data-func";

export const useCollections = () =>
  //onSave?: (r: CollectionRef) => void,
  //onDelete?: (id?: string) => void
  {
    const [productDb, setProductDb] = useAtom(productDbAtom);

    const { processDb } = useDbOperations();

    const [processing, setProcessing] = useState(false);

    const processSaveCollection = useCallback(
      async (
        collection: Collection,
        withItems: boolean,
        postSave: (id: string) => void
      ) => {
        processDb(
          async () => {
            return await saveCollection(collection, withItems);
          },
          (result) => {
            const collectionMap = new Map(productDb.collectionMap);
            const r = mapCollection(result.value, productDb.storageUrl);
            collectionMap.set(r.group.id, r);

            const productMap = new Map(productDb.productMap);

            refreshCollectionMap(collectionMap, productMap);

            setProductDb({
              ...productDb,
              collectionMap,
              productMap,
              version: productDb.version + 1,
            });
            postSave?.(r.group.id);
          },
          setProcessing,
          "Saved collection " + collection.name,
          "Error saving collection."
        );
      },
      [processDb, productDb, setProductDb]
    );

    // const processDeleteCollection = useCallback(
    //   async (collectionId: string) => {
    //     const col = productDb.collectionMap.get(collectionId);
    //     if (!col) return;
    //     processDb(
    //       async () => {
    //         return await deleteCollection(collectionId);
    //       },
    //       (result) => {
    //         const collectionMap = new Map(productDb.collectionMap);
    //         collectionMap.delete(collectionId);

    //         const productMap = new Map(productDb.productMap);
    //         refreshCollectionMap(collectionMap, productMap);

    //         setProductDb({ ...productDb, collectionMap, productMap });
    //         onDelete?.(collectionId);
    //       },
    //       setProcessing,
    //       "Deleted collection " + col.group.name,
    //       "Error deleting collection."
    //     );
    //   },
    //   [onDelete, processDb, productDb, setProductDb]
    // );

    return {
      processSaveCollection,
      //processDeleteCollection,
      processing,
    };
  };
