import { onlyUnique } from "../../services/util";
import {
  CollectionRef,
  ProductDb,
  getCollectionProductIds,
} from "../products/product";
import { CatalogItemRef } from "./catalogs";

type ProductContainerState = "unset" | "products" | "collection";

export const INDIVIDUAL_PRODUCTS = "0";
export const ALL_PRODUCTS = "all";
export const CATALOG_PRODUCTS = "catalog";

export type CatalogProductSource = "all" | "catalog";

export type ProductContainerFields = {
  collectionId?: string;
  products?: string[];
  inStock?: boolean;
  productSource?: CatalogProductSource;
};

export type ProductContainer = {
  collection?: CollectionRef;
  previewProductIds?: string[];
  useSampleProducts?: boolean;
} & ProductContainerFields;

export const getProductContainerState = (
  container: ProductContainer
): ProductContainerState => {
  if (!container) return "unset";

  return (container.collectionId &&
    container.collectionId !== INDIVIDUAL_PRODUCTS) ||
    container.productSource === CATALOG_PRODUCTS ||
    container.productSource === ALL_PRODUCTS
    ? "collection"
    : container.products?.length ||
      container.collectionId === INDIVIDUAL_PRODUCTS
    ? "products"
    : "unset";
};

export const isEmptyContainer = (container: ProductContainer): boolean => {
  if (!container) return true;

  const hasProducts =
    (container.collectionId &&
      container.collectionId !== INDIVIDUAL_PRODUCTS) ||
    container.productSource === CATALOG_PRODUCTS ||
    container.productSource === ALL_PRODUCTS ||
    container.products?.length > 0;

  return !hasProducts;
};

export const initProductContainerCollection = (
  productDb: ProductDb,
  container: ProductContainer,
  items?: CatalogItemRef[]
) => {
  if (
    container.collectionId &&
    container.collectionId !== INDIVIDUAL_PRODUCTS &&
    container.collectionId !== ALL_PRODUCTS
  ) {
    container.collection = productDb.collectionMap.get(container.collectionId);
  } else {
    container.collection = null;
  }

  container.previewProductIds = container.products ?? [];

  if (
    container.collectionId === ALL_PRODUCTS ||
    container.productSource === ALL_PRODUCTS
  ) {
    container.previewProductIds = Array.from(productDb.productMap.values())
      .sortList((p) => p.product.rank)
      .sortList((p) => p.product.name)
      .map((p) => p.id);
  } else if (container.productSource === CATALOG_PRODUCTS) {
    container.previewProductIds =
      items
        ?.filter((i) => i.di.ItemId)
        .map((i) => i.di.ItemId)
        .filter(onlyUnique) ?? [];
  } else if (container.collection) {
    container.previewProductIds = getCollectionProductIds(container.collection);
  }
  return container;
};

export const getContainerFields = (
  container: ProductContainer
): ProductContainerFields => {
  const { collectionId, products, inStock, productSource } = container;
  const result: ProductContainerFields = {
    collectionId,
    products,
    inStock,
    productSource,
  };
  if (container.collectionId === INDIVIDUAL_PRODUCTS) {
    result.collectionId = null;
    result.productSource = null;
  }
  if (
    container.collectionId === ALL_PRODUCTS ||
    container.productSource === ALL_PRODUCTS
  ) {
    result.collectionId = null;
    result.productSource = ALL_PRODUCTS;
  }
  return result;
};
