import { useCallback, useMemo } from "react";
import { useAtom } from "jotai";
import { CategoryRef, CollectionRef } from "./product";
import { productDbAtom } from "./product.state";
import { getProductDb } from "./product.service";
import { sessionAtom } from "../accounts/account.state";
import { useAuth } from "../accounts/useAuth";
import { escapeRegExp } from "../data/data-arrays";
import { DataTypeName } from "../data/data-types";

export type ProductFilter = {
  search?: string;
  collectionId?: string;
  categoryId?: string;
};

export const useProducts = () => {
  const [productDb, setProductDb] = useAtom(productDbAtom);
  const [session] = useAtom(sessionAtom);

  const reloadProductDb = async () => {
    const db = await getProductDb(session.account.config.imageStorageUrl);
    db.version = productDb.version + 1;
    setProductDb(db);
  };

  const defaultCategory = useMemo(() => {
    return productDb.categoryMap.values().next().value as CategoryRef;
  }, [productDb.categoryMap]);

  const getCategories = useCallback(() => {
    return Array.from(productDb.categoryMap.values()).sort((a, b) => {
      let n1 = a.group.name;
      let n2 = b.group.name;
      if (a.parent?.group.id !== b.parent?.group.id) {
        if (a.parent) n1 = a.parent.group.name;
        if (b.parent) n2 = b.parent.group.name;
      }
      return n1 > n2 ? 1 : n2 > n1 ? -1 : 0;
    });
  }, [productDb.categoryMap]);

  const categoryNames = useMemo(() => {
    return getCategories().map((c) => ({
      value: c.group.id,
      name: c.group.name,
      level: c.parents.length,
      fullName: c.fullName,
    }));
  }, [getCategories]);

  const topCategories = useMemo(() => {
    return Array.from(productDb.categoryMap.values())
      .filter((r) => !r.parent)
      .sortList((r) => r.group.name.toLowerCase());
  }, [productDb.categoryMap]);

  const searchProducts = useCallback(
    (filter: ProductFilter, ids: string[] = null) => {
      filter = { ...filter };
      const searchRegex = filter.search
        ? new RegExp(escapeRegExp(filter.search), "i")
        : null;
      const products = ids
        ? ids
            .map((id) => {
              return productDb.productMap.get(id);
            })
            .filter((r) => !!r)
        : Array.from(productDb.productMap.values());
      return products.filter((p) => {
        if (filter.categoryId && p.product.categoryId !== filter.categoryId)
          return false;

        if (
          filter.collectionId &&
          p.collections?.every((c) => c.group.id !== filter.collectionId)
        )
          return false;

        if (filter.search) {
          let values = [
            ...Array.from(p.fieldMap.values())
              .filter(
                (f) =>
                  !f.dataType ||
                  f.dataType === DataTypeName.RichText ||
                  f.dataType === DataTypeName.Text
              )
              .map((f) => f.value),
            p.category.fullName,
            ...(p.collections?.map((c) => c.fullName) ?? []),
          ];
          if (p.product.variants) {
            const vv = p.product.variants.flatMap((v) => [
              v.description,
              v.sku,
              ...v.options.map((o) => o.value),
            ]);
            values = values.concat(vv);
          }

          const match = values
            .filter((v) => v)
            .some((v) => searchRegex.test(v.toString()));
          return match;
        }

        return true;
      });
    },
    [productDb.productMap]
  );

  const iterateCollections = useCallback(
    (collections: CollectionRef[]): CollectionRef[] => {
      return collections
        .sortList((c) => c.group.name.toLowerCase())
        .reduce((acc, c) => [...acc, c, ...iterateCollections(c.children)], []);
    },
    []
  );

  const collectionTree = useMemo(
    () =>
      iterateCollections(
        Array.from(productDb.collectionMap.values()).filter((c) => !c.parent)
      ),
    [iterateCollections, productDb.collectionMap]
  );

  const collectionNames = useMemo(() => {
    return collectionTree.map((c) => ({
      value: c.group.id,
      name: c.group.name,
      level: c.parents.length,
      count: c.group.items.length,
      fullName: c.fullName,
    }));
  }, [collectionTree]);

  const topCollections = useMemo(() => {
    return Array.from(productDb.collectionMap.values())
      .filter((r) => !r.parent)
      .sortList((c) => c.group.name.toLowerCase());
  }, [productDb.collectionMap]);

  // const allFields = useMemo(() => {
  //   return Array.from(productDb.fieldMap.values()).sortList((f) =>
  //     f.group.name.toLowerCase()
  //   );
  // }, [productDb.fieldMap]);

  const { hasPermission } = useAuth();
  const isReadOnly = !hasPermission("products");

  return {
    reloadProductDb,
    defaultCategory,
    categoryNames,
    topCategories,
    collectionTree,
    collectionNames,
    topCollections,
    searchProducts,
    isReadOnly,
  };
};
