import { atom } from "jotai";
import structuredClone from "@ungap/structured-clone";

import { DesignItemMeta, DesignItemRef } from "./design.types";
import { CatalogPageRef } from "../catalogs/catalogs";

export type Clipboard = {
  items: { name: string; item: DesignItemRef }[];
  pages: { name: string; page: CatalogPageRef }[];
};

export const clipboardAtom = atom<Clipboard>({ pages: [], items: [] });

export const getClipboardItems = (
  clipboard: Clipboard,
  meta: DesignItemMeta
) => {
  return clipboard.items.filter((i) => i.item.meta === meta);
};

export const getClipboardCount = (
  clipboard: Clipboard,
  meta: DesignItemMeta
) => {
  return (
    getClipboardItems(clipboard, meta).length +
    (meta === "catalog" ? clipboard.pages.length : 0)
  );
};

export const addClipboardPage = (
  clipboard: Clipboard,
  page: CatalogPageRef
) => {
  const name = page.name ?? "Page " + (page.pageId + 1);
  return {
    ...clipboard,
    pages: [...clipboard.pages, { name, page: structuredClone(page) }],
  };
};

export const addClipboardElement = (
  clipboard: Clipboard,
  item: DesignItemRef
) => {
  const name = item.info?.title ?? item.type;
  item = structuredClone(item);
  item.style = { ...item.style, top: 0, left: 0 };
  const items = clipboard.items; //.filter((i) => i.item.id !== item.id && i.item.version !== item.version);
  return {
    ...clipboard,
    items: [{ name, item }, ...items],
  };
};

export const removeClipboardItem = (
  clipboard: Clipboard,
  meta: DesignItemMeta,
  index: number
) => {
  const items = getClipboardItems(clipboard, meta);
  if (index < items.length) {
    return {
      ...clipboard,
      items: [
        ...items.slice(0, index),
        ...items.slice(index + 1, items.length),
      ],
    };
  }
  return clipboard;
};

export const getClipboardItem = (
  clipboard: Clipboard,
  meta: DesignItemMeta,
  index: number
) => {
  const items = getClipboardItems(clipboard, meta);
  if (index < items.length) {
    return structuredClone(items[index].item.di);
  }
  return null;
};
