import { Alert, Box, Link } from "@mui/material";
import { Link as RouterLink, Outlet } from "react-router-dom";
import { FC, useEffect } from "react";

import { useAtom } from "jotai";
import ErrorBoundary from "../../contexts/ErrorBoundary";
import { uiAtom } from "../../features/state/uiState";
import useIsMobile from "../../ui/useIsMobile";
import NavBar from "./NavBar";
import "./layout.scss";
import { sessionAtom } from "../../features/accounts/account.state";

const Layout: FC = () => {
  const [uiSettings, setUiSettings] = useAtom(uiAtom);
  const [session] = useAtom(sessionAtom);

  const isMobile = useIsMobile();
  useEffect(() => {
    if (isMobile && !uiSettings.navBarClosed) {
      setUiSettings({ ...uiSettings, navBarClosed: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="layout">
      <NavBar
        onNavBarChange={() => {
          setUiSettings({
            ...uiSettings,
            navBarClosed: !uiSettings.navBarClosed,
          });
        }}
        openNavBar={!uiSettings.navBarClosed}
      />
      <Box className="layout-container">
        <Box className="layout-content">
          {session?.account?.errorStatus && (
            <Alert severity="error">
              Your subscription status is{" "}
              <strong>{session.account.errorStatus}</strong>. Please{" "}
              <Link component={RouterLink} to={"/account/billing/subscription"}>
                update billing information
              </Link>{" "}
              or{" "}
              <Link component="button" onClick={() => window.Intercom("show")}>
                contact support
              </Link>{" "}
              to ensure your service is not interrupted.
            </Alert>
          )}
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
