import {
  forwardRef,
  Suspense,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Box, IconButton, Tabs, ThemeProvider } from "@mui/material";

import { DesignerTabIndex } from "../catalogs/designer-types";
import { darkTheme, appTheme } from "../../../ui/theme";
import { FixedPanel } from "../../../components/FixedPanel";
import LoadingScreen from "../../../components/LoadingScreen";
import { IconTypes } from "../../../components/icons.types";

import { trackEvent } from "../../../services/analytics";
import { enumIndexName } from "../../../features/data/data-arrays";

const tabsWidth = 100;

interface Props {
  panel: React.ReactNode | React.ReactNode[];
  tabs: React.ReactNode[];
  panelWidth: number;
  //close: () => void;
  isDark?: boolean;
  containerId?: string;
  onTogglePanel?: (v: DesignerTabIndex) => void;
  tab: DesignerTabIndex;
  tabEnum: object;
  setTab: (v: DesignerTabIndex) => void;
  name: string;
}

export interface DesignerSidebarRef {
  isOpen: boolean;
  toggle: (open: boolean) => void;
}

export const DesignerSidebar = forwardRef<DesignerSidebarRef, Props>(
  (
    {
      panel,
      tabs,
      panelWidth,
      isDark,
      containerId,
      onTogglePanel,
      tab,
      setTab,
      tabEnum,
      name,
    }: Props,
    ref
  ) => {
    const [isOpen, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      isOpen,
      toggle: (open: boolean) => {
        setOpen(open);
      },
    }));

    const handleClose = useCallback(() => {
      //setOpen(false);
      setTab(-1);
      onTogglePanel?.(-1);
    }, [onTogglePanel, setTab]);

    useEffect(() => {
      setOpen(tab !== -1);
    }, [tab]);

    return (
      <ThemeProvider theme={isDark ? darkTheme : appTheme}>
        <FixedPanel
          //overflow="auto"
          className={"shadow-panel sidebar " + (isDark ? "dark" : "")}
          container={containerId}
          sx={{
            minWidth: {
              xs: (isOpen ? panelWidth / 1.5 : 0) + tabsWidth,
              lg: (isOpen ? panelWidth : 0) + tabsWidth,
            },
            maxWidth: {
              xs: (isOpen ? panelWidth / 1.5 : 0) + tabsWidth,
              lg: (isOpen ? panelWidth : 0) + tabsWidth,
            },
            // overflowY: "auto",
            overflow: "hidden",
          }}
          breakpoint="lg"
        >
          <Box
            className="sidebar-panel"
            sx={{ maxWidth: isOpen ? `calc(100% - ${tabsWidth}px)` : 0 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                my: 0.2,
                ml: 1,
              }}
            >
              <IconButton onClick={handleClose}>
                <IconTypes.DoubleRightArrow
                  fontSize="small"
                  sx={{ color: "text.secondary" }}
                />
              </IconButton>
            </Box>
            <Suspense fallback={<LoadingScreen title="loading database" />}>
              {panel}
            </Suspense>
          </Box>
          <ThemeProvider theme={darkTheme}>
            <Box className="sidebar-tabs" sx={{ width: tabsWidth }}>
              <FixedPanel
                overflow="auto"
                sx={{ width: tabsWidth, paddingBottom: "60px" }}
                // margin={18}
              >
                <Tabs
                  value={tab === -1 ? false : tab}
                  onChange={(e, v) => {
                    setTab(v);
                    setOpen(true);
                    onTogglePanel?.(v);
                    e.stopPropagation();
                    trackEvent({
                      category: name,
                      action: "tab",
                      label: enumIndexName(tabEnum, v + 1),
                    });
                  }}
                  onClick={() => {
                    if (isOpen) handleClose();
                  }}
                  orientation="vertical"
                >
                  {tabs}
                </Tabs>
              </FixedPanel>
            </Box>
          </ThemeProvider>
        </FixedPanel>
      </ThemeProvider>
    );
  }
);

DesignerSidebar.displayName = "DesignerSidebar";
