import { useCallback, useEffect, useState } from "react";

const SITE_KEY = "6LfOL8wUAAAAAKE86ab48OtkoAxROTWwN53x7SUJ";
const RECAPTCHA_URL = `https://www.recaptcha.net/recaptcha/api.js?render=${SITE_KEY}`;
const TOKEN_REFRESH_INTERVAL = 110000;

export const useRecaptcha = (action: string) => {
  const [token, setToken] = useState<string | null>(null);
  const [isLoadingToken, setIsLoading] = useState(true);

  const generateToken = useCallback(async (): Promise<string | null> => {
    if (!window.grecaptcha) {
      return null;
    }

    try {
      const newToken = await window.grecaptcha.execute(SITE_KEY, { action });
      setToken(newToken);
      return newToken;
    } catch (error) {
      console.error("Error generating reCAPTCHA token:", error);
      setToken(null);
      return null;
    }
  }, [action]);

  useEffect(() => {
    let isMounted = true;
    const script = document.createElement("script");
    script.src = RECAPTCHA_URL;
    script.async = true;
    script.defer = true;

    const handleLoad = () => {
      window.grecaptcha.ready(async () => {
        if (isMounted) {
          await generateToken();
          setIsLoading(false);
        }
      });
    };

    script.addEventListener("load", handleLoad);
    document.body.appendChild(script);

    return () => {
      isMounted = false;
      document.body.removeChild(script);
      script.removeEventListener("load", handleLoad);
    };
  }, [generateToken]);

  useEffect(() => {
    const interval = setInterval(generateToken, TOKEN_REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [generateToken]);

  return { token, isLoadingToken, regenerateToken: generateToken };
};
