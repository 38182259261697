import { trackEvent } from "../../services/analytics";
import apiClient, {
  BatchOperationResult,
  OperationResult,
  BaseOperationResult,
} from "../../services/apiClient";
import { onlyUnique } from "../../services/util";
import { appState } from "../app/app.state";
import { EMPTY_GUID } from "../data/data-types";
import {
  Category,
  CategoryRef,
  Collection,
  CollectionRef,
  DataChange,
  mapCategory,
  mapCollection,
  mapProduct,
  Product,
  ProductDb,
  ProductDbRaw,
  ProductFieldUpdate,
  ProductOption,
  refreshCategoryMap,
  refreshCollectionMap,
  VariantFieldUpdate,
} from "./product";

export const getProductDb = async (storageUrl: string): Promise<ProductDb> => {
  const response = await apiClient.get<ProductDbRaw>("/products/db");
  return transformProductDb(response.data, storageUrl);
};

export const getDataChange = async (): Promise<DataChange> => {
  const response = await apiClient.get<DataChange>("/products/data-change");
  return response.data;
};

const transformProductDb = (
  dbRaw: ProductDbRaw,
  storageUrl: string
): ProductDb => {
  const opts = dbRaw.options.map((r): [number, ProductOption] => [
    r.optionId,
    { ...r, count: 0 },
  ]);
  const optionMap = new Map(opts);

  const categories = dbRaw.categories.map((c) =>
    mapCategory(c, optionMap, storageUrl)
  );
  const categoryMap = new Map(
    categories.map((r): [string, CategoryRef] => [r.group.id, r])
  );

  refreshCategoryMap(categoryMap);

  const products = dbRaw.products.map((p) => {
    return mapProduct(p, categoryMap, optionMap, storageUrl);
  });

  const productMap = new Map(products.map((r) => [r.product.id, r]));

  //refreshProductOptions(productMap, optionMap);

  const collections = dbRaw.collections.map((c) =>
    mapCollection(c, storageUrl)
  );
  const collectionMap = new Map(
    collections.map((r): [string, CollectionRef] => [r.group.id, r])
  );

  refreshCollectionMap(collectionMap, productMap);

  appState.data_change = dbRaw.dataChange;

  return {
    categoryMap,
    productMap,
    optionMap,
    collectionMap,
    storageUrl,
    version: 1,
  };
};

export const updateProductField = async (
  updates: ProductFieldUpdate[]
): Promise<BatchOperationResult<Product>> => {
  const fields = updates
    .flatMap((u) => u.fields)
    .map((f) => f.field)
    .filter(onlyUnique)
    .reduce((a, f) => a + f + "; ", "");
  trackEvent({
    category: "product",
    action: "update-field",
    value: updates.length,
    label: fields,
  });
  const response = await apiClient.post<BatchOperationResult<Product>>(
    "/products/field",
    updates
  );
  return response.data;
};

export const updateVariantField = async (
  updates: VariantFieldUpdate[]
): Promise<BatchOperationResult<number>> => {
  const fields = updates
    .map((f) => f.field)
    .filter(onlyUnique)
    .reduce((a, f) => a + f + "; ", "");
  trackEvent({
    category: "product",
    action: "update-variant-field",
    value: updates.length,
    label: fields,
  });
  const response = await apiClient.post<BatchOperationResult<number>>(
    "/products/variant",
    updates
  );
  return response.data;
};

export const deleteDbProducts = async (
  type: "products" | "variants",
  ids: unknown[]
): Promise<BatchOperationResult<string>> => {
  trackEvent({
    category: "product",
    action: "delete-" + type,
    value: ids.length,
  });
  const response = await apiClient.post<BatchOperationResult<string>>(
    "/products/delete-" + type,
    ids
  );
  return response.data;
};

export const deleteCategory = async (
  categoryId: string
): Promise<OperationResult<string>> => {
  trackEvent({ category: "category", action: "delete", label: categoryId });
  const response = await apiClient.post<OperationResult<string>>(
    "/products/delete-category?categoryId=" + categoryId
  );
  return response.data;
};

export const deleteOption = async (
  optionId: number
): Promise<OperationResult<string>> => {
  trackEvent({ category: "option", action: "delete", label: "" + optionId });
  const response = await apiClient.post<OperationResult<string>>(
    "/products/delete-option?optionId=" + optionId
  );
  return response.data;
};

export const saveCategory = async (
  model: Category
): Promise<OperationResult<Category>> => {
  trackEvent({
    category: "category",
    action: model.id === EMPTY_GUID ? "add" : "update",
    label: (model.id === EMPTY_GUID ? "new" : model.id) + ":" + model.name,
    feature: true,
  });
  const response = await apiClient.post<OperationResult<Category>>(
    "/products/category",
    model
  );
  return response.data;
};

export const saveProduct = async (
  model: Product
): Promise<OperationResult<Product>> => {
  trackEvent({
    category: "product",
    action: model.id === EMPTY_GUID ? "add" : "update",
    label: (model.id === EMPTY_GUID ? "new" : model.id) + ":" + model.name,
    feature: true,
  });
  const response = await apiClient.post<OperationResult<Product>>(
    "/products/product",
    model
  );
  return response.data;
};

export const saveOption = async (
  model: ProductOption
): Promise<OperationResult<ProductOption>> => {
  trackEvent({
    category: "option",
    action: "save",
    label: model.optionId + ":" + model.name,
    feature: true,
  });
  const response = await apiClient.post<OperationResult<ProductOption>>(
    "/products/option",
    model
  );
  return response.data;
};

export const saveCollection = async (
  model: Collection,
  withItems: boolean
): Promise<OperationResult<Collection>> => {
  trackEvent({
    category: "collection",
    action: model.id === EMPTY_GUID ? "add" : "update",
    label:
      (model.id === EMPTY_GUID ? "new" : model.id) +
      " : " +
      model.name +
      (withItems ? " items:" + (model.items?.length || 0) : ""),
    feature: true,
  });
  const response = await apiClient.post<OperationResult<Collection>>(
    "/products/collection?withItems=" + withItems,
    model
  );
  return response.data;
};

export const deleteCollection = async (
  collectionId: string
): Promise<OperationResult<string>> => {
  trackEvent({ category: "collection", action: "delete", label: collectionId });
  const response = await apiClient.post<OperationResult<string>>(
    "/products/delete-collection?collectionId=" + collectionId
  );
  return response.data;
};

export const modifyCollectionItems = async (
  id: string,
  items: string[],
  remove: boolean
) => {
  await apiClient.post<BaseOperationResult>(
    "/products/collection-modify-items",
    {
      id,
      items,
      remove,
    }
  );
};

export const modifyProductCollections = async (id: string, items: string[]) => {
  await apiClient.post<BaseOperationResult>(
    "/products/product-modify-collections",
    {
      id,
      items,
    }
  );
};
