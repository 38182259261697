import { useLocation, matchPath, Link as RouterLink } from "react-router-dom";

import {
  Box,
  Divider,
  Link,
  List,
  ListSubheader,
  Typography,
  IconButton,
  Button,
} from "@mui/material";

import NavItem from "./NavItem";
import Profile from "./Profile";
import { uiFrame } from "../../ui/constants";
import { MAIN_SITE_URL, HELP_CENTER } from "../../services/site.config";
import { Permission } from "../../features/accounts/account";
import { sessionAtom } from "../../features/accounts/account.state";
import { useAtom } from "jotai";
import { isSuperuser } from "../../features/accounts/auth.service";
import { IconTypes } from "../../components/icons.types";
import { FixedPanel } from "../../components/FixedPanel";
import { RouteTable } from "../../ui/routes";
import { isIntercomActive, showIntercom } from "../../services/intercom";
import { useCallback, useMemo } from "react";

interface Item {
  href?: string;
  icon?;
  items?: Item[];
  title: string;
  permission?: Permission;
  iconHref?: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sections: Section[] = [
  {
    subheader: "",
    items: [
      {
        title: "Dashboard",
        icon: IconTypes.Dashboard,
        href: "/",
      },
      {
        title: "Catalogs",
        icon: IconTypes.Catalog,
        href: "/catalogs",
        items: [
          {
            title: "Product Cards",
            href: RouteTable.cards,
            permission: "catalogs",
          },
          {
            title: "Layouts",
            href: RouteTable.layouts,
            permission: "catalogs",
          },
        ],
      },
      {
        title: "Products",
        icon: IconTypes.Product,
        href: "/products",
        items: [
          {
            title: "Categories",
            href: "/products/categories",
            permission: "products",
          },
          {
            title: "Collections",
            href: "/products/collections",
            permission: "products",
          },
          {
            title: "Import",
            href: "/import",
            permission: "products",
          },
        ],
      },
      {
        title: "Images",
        icon: IconTypes.Image,
        href: "/images",
      },
      {
        title: "Advanced",
        icon: IconTypes.Settings,
        iconHref: "/parameters",
        items: [
          {
            title: "Parameters",
            href: "/parameters",
            permission: "catalogs",
          },
        ],
      },
    ],
  },
];

const adminSections: Section[] = [
  {
    subheader: "Lab",
    items: [
      {
        title: "Playground",
        icon: IconTypes.Playground,
        href: "/pg",
      },
    ],
  },
];

interface NavBarProps {
  onNavBarChange: () => void;
  openNavBar: boolean;
}

const NavBar = ({ onNavBarChange, openNavBar }: NavBarProps) => {
  const location = useLocation();
  const [session] = useAtom(sessionAtom);

  const drawerWidth = openNavBar
    ? uiFrame.navBarWidth
    : uiFrame.navBarClosedWidth;
  const drawerHeight = `calc(100% - ${uiFrame.top}px)`;

  const renderUpgradeButton = () => {
    if (session?.account?.planName !== "Free") return null;
    return (
      <Box p={2} mt={2} mb={2} bgcolor="background.paper" borderRadius={1}>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Unlock more features
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          component={RouterLink}
          to="/account/billing/plan"
        >
          Upgrade Now
        </Button>
      </Box>
    );
  };

  const renderNavItems = useCallback(
    ({ items, depth = 0 }: { items: Item[]; depth?: number }) => {
      if (!session && !session?.account) return;

      return (
        <List disablePadding>
          {items.map((item) => {
            const open =
              item.href && matchPath(item.href + "/*", location.pathname);
            const selected =
              item.href && matchPath(item.href, location.pathname);
            const items = item.items?.filter(
              (i) =>
                !i.permission ||
                !session.account.permissions ||
                session.account.permissions[i.permission]
            );
            return (
              <NavItem
                depth={depth}
                icon={item.icon}
                href={item.href}
                key={item.title + depth}
                open={!!open}
                selected={!!selected}
                title={item.title}
                isOpenNav={openNavBar}
                iconHref={item.iconHref}
              >
                {items?.length > 0 &&
                  renderNavItems({
                    items,
                    depth: depth + 1,
                  })}
              </NavItem>
            );
          })}
        </List>
      );
    },
    [location.pathname, openNavBar, session]
  );

  const barItems = useMemo(() => {
    const s = isSuperuser() ? [...sections, ...adminSections] : sections;
    return s.map((section) => (
      <List
        key={section.subheader}
        subheader={
          <ListSubheader disableGutters disableSticky>
            {section.subheader}
          </ListSubheader>
        }
      >
        {renderNavItems({
          items: section.items,
        })}
      </List>
    ));
  }, [renderNavItems]);

  return (
    <Box
      sx={{
        width: drawerWidth,
        height: drawerHeight,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          height: drawerHeight,
          boxSizing: "border-box",
          whiteSpace: "nowrap",
          overflowX: "hidden",
        },
      }}
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        sx={{ borderRight: "#eee solid 1px" }}
      >
        <Box pl={1}>
          <IconButton color="inherit" onClick={onNavBarChange} size="large">
            <IconTypes.Menu />
          </IconButton>
        </Box>
        <Box px={2} pb={2} sx={{ borderBottom: "#eee solid 1px" }}>
          <Profile openNavBar={openNavBar} />
        </Box>
        <FixedPanel margin={36} overflow="auto">
          <Box p={2}>{barItems}</Box>
          {openNavBar && (
            <>
              {renderUpgradeButton()}
              <Divider />
              <Box p={2}>
                <Box
                  p={2}
                  borderRadius={1}
                  bgcolor="darkBackground"
                  sx={{ textAlign: "center" }}
                >
                  <Typography variant="h6" color="textPrimary">
                    Need Help?
                  </Typography>
                  <Link
                    variant="subtitle1"
                    color="secondary"
                    component="a"
                    href={HELP_CENTER}
                    target="_blank"
                  >
                    Check our guides
                  </Link>
                  {isIntercomActive() && (
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ mt: 1 }}
                      onClick={() => showIntercom()}
                      startIcon={<IconTypes.Chat />}
                    >
                      Chat with us
                    </Button>
                  )}
                </Box>

                <Box mt={3}>
                  <Link
                    variant="caption"
                    color="textSecondary"
                    component="a"
                    href={MAIN_SITE_URL + "/app"}
                    target="_blank"
                    sx={{
                      mr: 1,
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    Previous app version
                    <IconTypes.OpenInNew
                      sx={{ fontSize: "small", ml: 0.5, mt: 0.5 }}
                    />
                  </Link>
                </Box>
              </Box>
            </>
          )}
          {!openNavBar && (
            <Box p={2}>
              <Link
                variant="subtitle1"
                color="secondary"
                component="a"
                href={HELP_CENTER}
                target="_blank"
                style={{
                  paddingLeft: "4px",
                }}
              >
                <IconTypes.Help color="action" />
              </Link>
            </Box>
          )}
        </FixedPanel>
        <Box
          sx={{
            bgcolor: "secondary.main",
            px: 1,
            pt: 1,
            height: 38,
          }}
        >
          <RouterLink to="/">
            <img
              alt="Logo"
              src="/images/app-logo.png"
              style={{ maxHeight: 24 }}
            />
          </RouterLink>
        </Box>
      </Box>
    </Box>
  );
};

export default NavBar;
