import { useAtom } from "jotai";
import { useState } from "react";
import { SelectionDialog } from "../../../components/dialogs/SelectionDialog";
import { useDialogs } from "../../../contexts/useDialogs";
import { useProducts } from "../../../features/products/product-func";
import { UpdateProductOptions } from "../../../features/products/product-modal-state";
import { productDbAtom } from "../../../features/products/product.state";

type Props = UpdateProductOptions & {
  onClose: () => void;
};

export const UpdateProductModal = (props: Props) => {
  const { categoryNames, collectionNames } = useProducts();
  const [collectionIds, setCollectionIds] = useState<string[]>(
    props.target === "collection" ? props.collectionIds ?? [] : []
  );
  const { productModalApi } = useDialogs();
  const [productDb] = useAtom(productDbAtom);

  // const openCollectionEdit = useCallback(
  //   (id: string) => {
  //     productModalApi.openCollectionEditor({
  //       collectionId: id,
  //       onChange: (id) => {
  //         if (id) setCollectionIds([...collectionIds, id]);
  //       },
  //     });
  //   },
  //   [collectionIds, productModalApi]
  // );

  if (!props) return null;

  if (props.target === "category") {
    return (
      <SelectionDialog
        open={true}
        title="Change Category"
        values={[props.categoryId]}
        entries={categoryNames.map((c) => ({ ...c, name: c.fullName }))}
        onClose={props.onClose}
        setValues={(values) => {
          props.onChange(values?.[0]);
          props.onClose();
        }}
      />
    );
  }
  if (props.target === "collection") {
    return (
      <SelectionDialog
        open={true}
        title="Update Collections"
        values={collectionIds}
        entries={collectionNames.map((c) => ({
          value: c.value,
          name: `${c.fullName} (${c.count})`,
        }))}
        multiple
        onClose={props.onClose}
        setValues={(collectionIds) => {
          setTimeout(() => setCollectionIds(collectionIds));
        }}
        onSave={() => {
          props.onChange(collectionIds, productDb);
          props.onClose();
        }}
        onEdit={(id) =>
          productModalApi.openCollectionEditor({
            collectionId: id,
            onChange: (id) => {
              if (id) setCollectionIds([...collectionIds, id]);
            },
          })
        }
        onNew={() =>
          productModalApi.openCollectionAttrs({
            withItems: true,
            onChange: (id) => {
              setCollectionIds([...collectionIds, id]);
            },
          })
        }
      />
    );
  }
};
