import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { ReactNode } from "react";
import { ProcessButton } from "../buttons/ProcessButton";
import { UiColors } from "../../ui/styles";

interface WarningDialogParams {
  open: boolean;
  processing?: boolean;
  title: string;
  action?: string;
  cancelLabel?: string;
  secondaryAction?: string;
  onConfirm: (secondary?: boolean) => void;
  onClose: () => void;
  color?: UiColors;
  children: ReactNode;
}

export const WarningDialog = ({
  onConfirm,
  onClose,
  title,
  action,
  cancelLabel,
  secondaryAction,
  open,
  processing,
  color,
  children,
}: WarningDialogParams) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelLabel ?? "Cancel"}</Button>
        {secondaryAction && (
          <ProcessButton
            processing={processing}
            color={"primary"}
            onClick={() => onConfirm(true)}
            label={secondaryAction}
            variant="outlined"
          />
        )}
        {action && (
          <ProcessButton
            processing={processing}
            color={color ?? "primary"}
            onClick={() => onConfirm(false)}
            label={action}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};
