import { ReactNode } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  SxProps,
} from "@mui/material";
import { UiColors, UiSize, UiButtonVariant } from "../../ui/styles";
interface ProcessButtonParams {
  processing: boolean;
  disabled?: boolean;
  label?: ReactNode | string;
  color?: UiColors;
  size?: UiSize;
  variant?: UiButtonVariant;
  onClick: () => void;
  sx?: SxProps;
  startIcon?: React.ReactNode;
  title?: string;
  fullWidth?: boolean;
}

export const ProcessButton = ({
  processing,
  disabled,
  label,
  color,
  size,
  variant,
  onClick,
  sx,
  startIcon,
  title,
  fullWidth,
}: ProcessButtonParams) => {
  const isIcon = !label && startIcon;
  return (
    <Box
      sx={{
        m: fullWidth ? 0 : 1,
        position: "relative",
        display: "inline-flex",
        width: fullWidth ? "100%" : "auto",
        ...sx,
      }}
    >
      {isIcon && (
        <IconButton
          color={color}
          size={size}
          disabled={processing || disabled}
          onClick={onClick}
          title={title}
        >
          {startIcon}
        </IconButton>
      )}
      {!isIcon && (
        <Button
          variant={variant ?? "contained"}
          color={color}
          size={size}
          disabled={processing || disabled}
          onClick={onClick}
          startIcon={startIcon}
          title={title}
          fullWidth={fullWidth}
        >
          {label}
        </Button>
      )}
      {processing && (
        <CircularProgress
          size={24}
          color={color}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Box>
  );
};
