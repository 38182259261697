import { useCallback, useMemo, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  DataGridPro,
  GridRowOrderChangeParams,
} from "@mui/x-data-grid-pro";
import { useAtom } from "jotai";
import { FixedPanel } from "../../../components/FixedPanel";
import { SelectFromList } from "../../../components/SelectFromList";
import { IconTypes } from "../../../components/icons.types";
import { ProductListExtOptions } from "../../../features/catalogs/product-list.ext";
import {
  DataType,
  DataTypeName,
  ExtendedDataType,
  moveEntry,
} from "../../../features/data/data-types";
import {
  VariantPrefix,
  ImageFieldParams,
  parseImageField,
  imagePositions,
  ImagePosition,
} from "../../../features/design/design.types";
import { productDbAtom } from "../../../features/products/product.state";
import { Transition } from "../../../components/Transitions";

type FieldRow = { name: string; dataType?: DataType };

const presetFields: FieldRow[] = [
  { name: "Name" },
  { name: "Code" },
  { name: "Price", dataType: DataTypeName.Money },
  { name: "Description" },
  { name: ExtendedDataType.Category },
  { name: "Image", dataType: DataTypeName.Image },
  { name: "Product Page Link" },
  { name: "Product Page Link:Name" },
  { name: "Product Page Link:Code" },
  { name: "Product Order Link" },
  { name: "Quantity:Empty" },
];

const variantFields: FieldRow[] = [
  { name: "Option" },
  { name: "Price", dataType: DataTypeName.Money },
  { name: "SKU" },
  { name: "Image", dataType: DataTypeName.Image },
  { name: "Description" },
  { name: "Quantity", dataType: DataTypeName.Number },
  { name: "Barcode" },
  { name: "BarcodeImage" },
  { name: "BarcodeImageExt" },
].map((f) => ({ name: VariantPrefix + ":" + f.name, dataType: f.dataType }));

const dataTypeCell: GridColDef = {
  field: "type",
  headerName: "",
  width: 25,
  disableColumnMenu: true,
  sortable: false,
  renderCell: (params: GridRenderCellParams) => {
    return (
      <Box className="data-type-col" title={params.row.dataType}>
        {params.row.dataType === DataTypeName.Image && <IconTypes.Image />}
        {params.row.dataType === DataTypeName.Money && <IconTypes.Money />}
        {params.row.dataType === DataTypeName.Number && <IconTypes.Number />}
        {params.row.dataType === DataTypeName.RichText && <IconTypes.Text />}
        {params.row.dataType === DataTypeName.Date && <IconTypes.Date />}
      </Box>
    );
  },
};

export const ProductListExtFields = ({
  extension,
  onChange,
  categoryId,
}: ProductListExtOptions & { categoryId: string }) => {
  const [productDb] = useAtom(productDbAtom);
  const [editField, setEditField] = useState<ImageFieldParams>();

  const getDataType = useCallback(
    (name: string) => {
      if (name === DataTypeName.Image) return DataTypeName.Image;
      if (name.startsWith(VariantPrefix + ":"))
        return variantFields.find((f) => f.name === name)?.dataType;
      const c = Array.from(
        productDb.categoryMap.get(categoryId).fieldMap.values()
      ).find((f) => f.name === name);
      return c?.dataType;
    },
    [categoryId, productDb.categoryMap]
  );

  const categoryFieldColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "add",
        headerName: "",
        width: 50,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box>
              <IconButton
                className="action"
                size="small"
                onClick={() => {
                  let name = params.row.name as string;
                  if (params.row.dataType === DataTypeName.Image)
                    name += ":Fit:40:40";
                  onChange?.({
                    ...extension,
                    fields: [...extension.fields, name],
                  });
                }}
              >
                <IconTypes.AddAction />
              </IconButton>
            </Box>
          );
        },
      },
      dataTypeCell,
      {
        field: "name",
        headerName: "Add category field to the list",
        flex: 1,
      },
    ],
    [extension, onChange]
  );

  const extFieldColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "remove",
        headerName: "",
        width: 50,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box>
              <IconButton
                className="action"
                size="small"
                onClick={() =>
                  onChange?.({
                    ...extension,
                    fields: extension.fields.filter(
                      (f) => f !== params.row.name
                    ),
                  })
                }
              >
                <IconTypes.RemoveAction />
              </IconButton>
            </Box>
          );
        },
      },
      dataTypeCell,
      {
        field: "name",
        headerName: "Product List fields",
        flex: 1,
      },
      {
        field: "edit",
        headerName: "",
        width: 50,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          if (!params.row.args) return null;
          return (
            <Box>
              <IconButton
                className="action"
                size="small"
                onClick={() =>
                  setEditField({ ...params.row.args, field: params.row.name })
                }
              >
                <IconTypes.Settings />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [extension, onChange]
  );

  const extRows = useMemo(() => {
    return extension.fields.map((f) => {
      const args = parseImageField(f);
      const dataType = getDataType(args.name);
      return {
        id: f,
        name: f,
        args: dataType === DataTypeName.Image ? args : null,
        dataType,
      };
    });
  }, [extension.fields, getDataType]);

  const fieldRows = useMemo(() => {
    const category = productDb.categoryMap.get(categoryId);
    const categoryFields = category.group.fields.map((f) => ({
      name: f.name,
      dataType: f.dataType,
    }));

    const optionFields = category.options
      .map((o) => [
        VariantPrefix + ":" + o.name,
        VariantPrefix + ":" + o.name + ":Unique",
      ])
      .flatMap((f) => f);

    const fields: FieldRow[] = [
      ...presetFields,
      ...categoryFields,
      ...optionFields.map((f) => ({ name: f })),
      ...variantFields,
    ].filter(
      (f) => !extRows.some((e) => e.name === f.name || e.args?.name === f.name)
    );

    const uniqueNames = new Set();

    const uniqueFields = fields.filter((f) => {
      if (uniqueNames.has(f.name)) {
        return false;
      }
      uniqueNames.add(f.name);
      return true;
    });

    return uniqueFields.map((f) => ({
      id: f.name,
      name: f.name,
      dataType: f.dataType,
    }));
  }, [categoryId, extRows, productDb.categoryMap]);

  const handleOrderChange = useCallback(
    (params: GridRowOrderChangeParams) => {
      const fields = moveEntry(
        extension.fields,
        params.oldIndex,
        params.targetIndex
      );
      onChange?.({ ...extension, fields });
    },
    [extension, onChange]
  );

  return (
    <Box
      sx={{
        "& .data-type-col": {
          display: "flex",
          alignItems: "center",
          height: "100%",
          "& svg": { width: 16, height: 16 },
        },
      }}
    >
      <FixedPanel overflow="auto" container="#plist-container" breakpoint="lg">
        <Box sx={{ height: "100%", display: "flex", gap: 2, p: 2 }}>
          <Box
            sx={{
              height: "100%",
              minHeight: 100,
              minWidth: 250,
              display: "flex",
              flex: 1,
            }}
          >
            <DataGridPro
              rows={extRows}
              columns={extFieldColumns}
              hideFooter
              rowReordering
              onRowOrderChange={handleOrderChange}
            />
          </Box>
          <Box
            sx={{
              height: "100%",
              minHeight: 100,
              minWidth: 250,
              display: "flex",
              flex: 1,
            }}
          >
            <DataGridPro
              rows={fieldRows}
              columns={categoryFieldColumns}
              hideFooter
            />
          </Box>
        </Box>
      </FixedPanel>
      <Dialog
        open={!!editField}
        onClose={() => setEditField(null)}
        TransitionComponent={Transition}
      >
        {editField && (
          <>
            <DialogTitle>Edit {editField.name} field</DialogTitle>
            <DialogContent>
              <Box sx={{ py: 2, display: "flex", gap: 1 }}>
                <SelectFromList
                  entries={imagePositions.slice() as string[]}
                  value={editField.pos}
                  setValue={(v) =>
                    setEditField({ ...editField, pos: v as ImagePosition })
                  }
                  label="Position"
                  sx={{ minWidth: 150 }}
                />
                <TextField
                  label="Width"
                  sx={{ width: 70 }}
                  value={editField.w}
                  onChange={(e) =>
                    setEditField({ ...editField, w: +e.target.value })
                  }
                  type="number"
                />
                <TextField
                  label="Height"
                  sx={{ width: 70 }}
                  value={editField.h}
                  onChange={(e) =>
                    setEditField({ ...editField, h: +e.target.value })
                  }
                  type="number"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setEditField(null)}>Cancel</Button>
              <Button
                onClick={() => {
                  const fields = extension.fields.map((f) => {
                    if (f === editField.field) {
                      return `${editField.name}:${editField.pos}:${editField.w}:${editField.h}`;
                    }
                    return f;
                  });
                  onChange?.({ ...extension, fields });
                  setEditField(null);
                }}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};
