import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { processDesignImages } from "../designer.func";
import { useDesignerBoardApi } from "../designer.board.api";
import { SelectFromList } from "../../../components/SelectFromList";
import { IconTypes } from "../../../components/icons.types";
import { CatalogBuilder } from "../../../features/catalogs/catalog.builder";
import { pageSizes } from "../../../features/catalogs/catalog.defs";
import { updatePage } from "../../../features/catalogs/catalog.page";
import {
  resizeToGrid,
  updateTemplateItemsStyle,
  createTemplateItem,
  updateTemplateItemContent,
  initTemplateItem,
} from "../../../features/catalogs/catalog.template";
import { CatalogItemRef } from "../../../features/catalogs/catalogs";
import { DataTypeName } from "../../../features/data/data-types";
import {
  TemplateItemRef,
  getItemDescription,
} from "../../../features/design/design.types";
import { BoardViewProps, gridSizes } from "../../../features/design/designer";
import {
  ExternalDesignBoardProps,
  DesignBoardRef,
  ToolbarMode,
  DesignBoard,
} from "../components/DesignerBoard";
import { PageZoom } from "../components/PageZoom";
import { renderTemplateItem } from "./template.render";

export type TemplateViewMode = "preview" | "source";

const previewPageSizes = pageSizes
  .filter((s) => s.name !== "Custom")
  .map((s) => s.name);

type TemplateBoardProps = {
  parentItem: CatalogItemRef;
  setBuilder: (builder: CatalogBuilder) => void;
  viewMode: TemplateViewMode;
  setViewMode: (v: TemplateViewMode) => void;
  isNavOpen: boolean;
  onNavToggle: (isOpen: boolean) => void;
} & ExternalDesignBoardProps;

export const TemplateBoard = forwardRef<DesignBoardRef, TemplateBoardProps>(
  (
    {
      parentItem,
      onChange,
      onSelect,
      addHistory,
      builder,
      setBuilder,
      viewMode,
      setViewMode,
      isNavOpen,
      onNavToggle,
    }: TemplateBoardProps,
    designBoardRef: React.MutableRefObject<DesignBoardRef>
  ) => {
    const [zoom, setZoom] = useState(100);

    const api = useDesignerBoardApi({
      meta: "template",
      builder,
      onChange,
      onSelect,
      boardRef: designBoardRef.current,
      getItemById: (id) =>
        parentItem.info.templateItems.find((i) => i.id === id),
    });

    const templateItem = api.selectedItem as TemplateItemRef;

    const updateBoard = useCallback(
      (view: BoardViewProps) => {
        parentItem.version++;
        builder.setBoard(view);
        resizeToGrid(parentItem, view);
        if (view.pageSize && builder?.pages.length) {
          updatePage(builder, builder.getActivePage());
          updatePage(builder, builder.getViewPage());
        }
        builder.initItem(parentItem);
        updateTemplateItemsStyle(builder, {
          parent: parentItem,
        });
        setBuilder(builder);
        processDesignImages();
        designBoardRef.current?.clearTargets();
      },
      [builder, designBoardRef, parentItem, setBuilder]
    );

    useEffect(() => {
      updateBoard(builder.board.view);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentItem?.id]);

    const toolbarMode: ToolbarMode = useMemo(() => {
      if (api.editedHtml != null) return "html";
      if (templateItem?.di.imgMode === "field") return "product-image";
      if (templateItem?.di.imgMode === "image") return "image";
      return templateItem !== null ? "item" : "page";
    }, [api.editedHtml, templateItem]);

    const imageFields = useMemo(() => {
      return (
        parentItem?.info?.product?.category.group.fields
          .filter((f) => f.dataType === DataTypeName.Image)
          .map((f) => ({
            value: f.name,
            name: f.name,
          })) ?? []
      );
    }, [parentItem?.info?.product?.category.group.fields]);

    return (
      <Box id="template-board-container" sx={{ height: "100%" }}>
        <DesignBoard
          ref={designBoardRef}
          boardId="template"
          boardContainer="#template-board-container"
          designerBoardApi={api}
          zoom={zoom}
          renderItem={(i) =>
            renderTemplateItem(
              i as TemplateItemRef,
              viewMode === "preview",
              false
            )
          }
          addHistory={addHistory}
          onChange={onChange}
          onSelect={onSelect}
          pageItems={parentItem?.info.templateItems ?? []}
          isNavOpen={isNavOpen}
          onNavToggle={onNavToggle}
          onItemDrop={(attrs) => {
            if (attrs.isNew) {
              return createTemplateItem(builder, {
                ...attrs,
                parent: parentItem,
              });
            } else if (attrs.isNew === false) {
              const item = attrs.item as TemplateItemRef;
              updateTemplateItemContent(item.di, attrs);
              initTemplateItem(builder, item, {
                parent: parentItem,
              });
              return item;
            }
          }}
          boardToolbar={
            <Box
              sx={{
                display: "flex",
                gap: 1,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ minWidth: 100 }}>
                <Typography variant="caption">
                  <b>{parentItem?.info.template.template.name}</b>
                </Typography>
              </Box>
              <PageZoom
                zoom={zoom}
                setZoom={setZoom}
                builder={builder}
                boardId="template"
              />
            </Box>
          }
          toolbar={
            <Box
              sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}
            >
              <Box></Box>
              {toolbarMode === "page" && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexGrow: 1,
                      }}
                    >
                      <Typography variant="caption">Page Size</Typography>
                      <SelectFromList
                        entries={previewPageSizes}
                        value={builder.board.view?.pageSize}
                        setValue={(v) => {
                          updateBoard({ ...builder.board.view, pageSize: v });
                          setTimeout(() =>
                            designBoardRef.current?.adjustBoard()
                          );
                        }}
                        size="mini"
                        sx={{ mx: 1 }}
                      />
                      <Typography variant="caption" sx={{ ml: 2 }}>
                        Grid
                      </Typography>

                      <SelectFromList
                        entries={gridSizes}
                        value={builder.board.view?.productsHor ?? 1}
                        setValue={(value) => {
                          updateBoard({
                            ...builder.board.view,
                            productsHor: +value,
                          });
                        }}
                        size="mini"
                        sx={{ mx: 1 }}
                      />
                      <span>X</span>
                      <SelectFromList
                        entries={gridSizes}
                        value={builder.board.view?.productsVert ?? 1}
                        setValue={(value) => {
                          updateBoard({
                            ...builder.board.view,
                            productsVert: +value,
                          });
                        }}
                        size="mini"
                        sx={{ mx: 1 }}
                      />
                      <Box sx={{ ml: 2 }}>
                        <ToggleButtonGroup
                          color="primary"
                          value={viewMode}
                          exclusive
                          onChange={(_, v) =>
                            setViewMode((v ?? viewMode) as TemplateViewMode)
                          }
                          aria-label="View"
                          size="small"
                          className="toggle-buttons"
                          // sx={{
                          //   justifySelf: "end",
                          //   button: {
                          //     fontSize: 10,
                          //     py: 0.5,
                          //     px: 2,
                          //     "&.Mui-selected": {
                          //       backgroundColor: "#111",
                          //       color: "white",
                          //       "&:hover": {
                          //         backgroundColor: "#333",
                          //       },
                          //     },
                          //   },
                          // }}
                        >
                          <ToggleButton value="preview">Preview</ToggleButton>
                          <ToggleButton value="source">Source</ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </Box>
                  </Box>
                  <Box></Box>
                </>
              )}
              {toolbarMode === "product-image" && imageFields.length > 1 && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* <Typography variant="caption">Image Field</Typography> */}

                  <SelectFromList
                    entries={imageFields}
                    value={templateItem.di.src}
                    label="Image Field"
                    setValue={(src) => {
                      onChange({
                        type: "update",
                        item: templateItem,
                        data: { src },
                        action: "change image field",
                      });
                    }}
                    size="mini"
                    sx={{ mx: 1, minWidth: 100 }}
                    checkIncluded={viewMode === "preview"}
                  />
                </Box>
              )}

              {templateItem && (
                <Box sx={{ display: "flex", alignItems: "center", px: 1 }}>
                  <Typography variant="caption">
                    {getItemDescription(templateItem, true)}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      api.selectItem();
                      api.boardRef?.clearTargets();
                    }}
                    size="small"
                    sx={{ mx: 1, mb: "2px" }}
                  >
                    <IconTypes.Close
                      fontSize="small"
                      sx={{ height: 16, width: 16 }}
                    />
                  </IconButton>
                </Box>
              )}
            </Box>
          }
        />
      </Box>
    );
  }
);

TemplateBoard.displayName = "TemplateBoard";
