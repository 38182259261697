import { useState } from "react";
import { Avatar, SxProps } from "@mui/material";
import { Link, To } from "react-router-dom";

interface SmartImageProps {
  src: string;
  alt: string;
  to?: To;
  imageClassName?: string;
  avatarSx?: SxProps;
  imageProps?;
  title?: string;
}

const SmartImage = ({
  src,
  alt,
  to,
  imageClassName,
  avatarSx,
  imageProps,
  title,
}: SmartImageProps) => {
  const [hasError, setHasError] = useState(false);

  const content =
    !src || hasError ? (
      <Avatar
        alt={alt}
        title={title}
        sx={{ cursor: "pointer", textDecoration: "none", ...avatarSx }}
      >
        {alt.charAt(0).toUpperCase()}
      </Avatar>
    ) : (
      <img
        className={imageClassName}
        alt={alt}
        src={src}
        onError={() => setHasError(true)}
        title={title}
        {...imageProps}
      />
    );

  return to ? (
    <Link style={{ textDecoration: "none" }} to={to}>
      {content}
    </Link>
  ) : (
    content
  );
};

export default SmartImage;
