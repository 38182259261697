export const uiFrame = {
  top: 0,
  navBarWidth: 256,
  navBarClosedWidth: 64,
  scrollerHeight: 17,
};

export const colorPalette = [
  { n: "Lime", h: "#b8d557" },
  { n: "Madang", h: "#a2f8d3" },
  { n: "Jordy Blue", h: "#89c4f4" },
  { n: "Medium Purple", h: "#bf55ec" },
  { n: "Hoki", h: "#67809f" },
  { n: "Crowd", h: "#66ce5f" },
  { n: "Turquoise", h: "#36d7b7" },
  { n: "Peter River", h: "#3498db" },
  { n: "Amethyst", h: "#9b59b6" },
  { n: "Wet Asphalt", h: "#34495e" },
  { n: "Nephritis", h: "#27ae60" },
  { n: "Green Sea", h: "#16a085" },
  { n: "Belize Hole", h: "#2980b9" },
  { n: "Wisteria", h: "#8e44ad" },
  { n: "Midnight Blue", h: "#2c3e50" },
  { n: "Cream Can", h: "#f7d769" },
  { n: "Carrot", h: "#e67e22" },
  { n: "Tomato", h: "#ff6347" },
  { n: "Pink", h: "#ff9cd4" },
  { n: "Silver", h: "#bdc3c7" },
  { n: "Ripe Lemon", h: "#f7ca18" },
  { n: "Pumpkin", h: "#d35400" },
  { n: "Alizarin", h: "#e74c3c" },
  { n: "Peony", h: "#e85893" },
  { n: "Concrete", h: "#95a5a6" },
  { n: "Orange", h: "#f39c12" },
  { n: "Saddle Brown", h: "#8b4513" },
  { n: "Pomegranate", h: "#c0392b" },
  { n: "Fuchsia", h: "#c0117a" },
  { n: "Asbestos", h: "#7f8c8d" },
];

export const colorSwatches = [
  "#61BD6D",
  "#1ABC9C",
  "#54ACD2",
  "#2C82C9",
  "#9365B8",
  "#475577",
  "#BBBBBB", // '#999999',
  "#41A85F",
  "#00A885",
  "#3D8EB9",
  "#2969B0",
  "#553982",
  "#28324E",
  "#555555", // '#000000',
  "#F7DA64",
  "#FBA026",
  "#EB6B56",
  "#E25041",
  "#A38F84",
  "#EFEFEF",
  "#FFFFFF", // '#555555',
  "#FAC51C",
  "#F37934",
  "#D14841",
  "#B8312F",
  "#7C706B",
  "#D1D5D8",
  "#000000", //, 'REMOVE'
];
