import {
  Box,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import { SelectFromList } from "./SelectFromList";
import { useAtom } from "jotai";
import { sessionAtom } from "../features/accounts/account.state";
import {
  uiConfigAtom,
  getFonts,
  FONT_SIZES,
} from "../features/design/ui.config";
import { useMemo } from "react";

type FontPickerProps = {
  label: string;
  fontFamily: string;
  fontSize?: string;
  withFontSize?: boolean;
  onChange: (fontFamily: string, fontSize: string) => void;
};

export const FontPicker = ({
  label,
  fontFamily,
  onChange,
  fontSize,
  withFontSize,
}: FontPickerProps) => {
  const [session] = useAtom(sessionAtom);
  const [uiConfig] = useAtom(uiConfigAtom);

  const fonts = useMemo(
    () => getFonts(session.account, uiConfig),
    [session.account, uiConfig]
  );

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <FormControl fullWidth sx={{ my: 2 }} size="small">
        <InputLabel>{label}</InputLabel>
        <Select
          value={fontFamily || ""}
          label={label}
          onChange={(e) => {
            onChange(e.target.value as string, fontSize);
          }}
          sx={{
            "& .MuiSelect-select": { fontFamily },
          }}
        >
          <MenuItem value="">Default</MenuItem>
          {fonts.map((f) => {
            return f.IsGroup ? (
              <ListSubheader key={f.Name}>{f.Description}</ListSubheader>
            ) : (
              <MenuItem key={f.Name} value={f.Name} sx={{ fontFamily: f.Name }}>
                {f.Description}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {withFontSize && (
        <SelectFromList
          label="Font Size"
          entries={FONT_SIZES}
          value={fontSize}
          setValue={(value) => {
            onChange(fontFamily, value);
          }}
          size="small"
          sx={{ mx: 1, minWidth: 80 }}
        />
      )}
    </Box>
  );
};
