import { useCallback } from "react";
import { DesignerChange } from "./designer.board.api";
import { useDialogs } from "../../contexts/useDialogs";
import { CatalogBuilder } from "../../features/catalogs/catalog.builder";
import { getProductListExtChange } from "../../features/catalogs/product-list.ext";
import { DesignItemRef } from "../../features/design/design.types";

export const useEditors = () => {
  const { openProductListExt, productModalApi } = useDialogs();

  const openProductListExtEditor = useCallback(
    (
      item: DesignItemRef,
      builder: CatalogBuilder,
      onChange: (change: DesignerChange) => void,
      isNew?: boolean
    ) => {
      openProductListExt({
        item,
        builder,
        extension: item.info.extension,
        onChange: (ext, productsOnly) => {
          onChange({
            type: "update",
            item,
            data: getProductListExtChange(
              ext,
              item.meta,
              productsOnly && !isNew
            ),
            action: "product list",
          });
        },
        onClose: () => {},
        onCancel: () => {
          if (isNew)
            onChange({
              type: "undo",
              index: -1,
            });
        },
        isNew,
      });
    },
    [openProductListExt]
  );

  const openItemProductEdit = useCallback(
    (item: DesignItemRef, onChange: (change: DesignerChange) => void) => {
      productModalApi.openEditProduct({
        productId: item.info.product.id,
        onChange: (cr) => {
          item.info.product = cr;
          onChange({ type: "update", item, source: "product" });
        },
      });
    },
    [productModalApi]
  );

  return { openProductListExtEditor, openItemProductEdit };
};
