import { useAtom } from "jotai";
import { authenticate, logout, signup } from "./auth.service";
import { Permission } from "./account";
import { sessionAtom } from "./account.state";

export const useAuth = () => {
  const [session, setSession] = useAtom(sessionAtom);

  const doLogin = async (request, admin = false) => {
    const response = await authenticate(request, admin);
    if (response.succeeded) {
      setSession(response);
    }
    return response;
  };

  const doSignup = async (request) => {
    const response = await signup(request);
    if (response.succeeded) {
      setSession(response);
    }
    return response;
  };

  const doLogout = () => {
    logout();
    setSession(null);
  };

  const hasPermission = (permission: Permission) => {
    return !permission || session?.account?.permissions[permission];
  };

  return {
    doLogin,
    doLogout,
    doSignup,
    hasPermission,
    isAdmin: hasPermission("admin"),
  };
};
