import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { ResponsiveColorPicker } from "../../../components/ColorPicker";
import { SelectFromList } from "../../../components/SelectFromList";
import { DesignItemRef } from "../../../features/design/design.types";
import { DesignerBoardApi } from "../designer.board.api";

type BackgroundToolbarProps = {
  item?: DesignItemRef;
  api: DesignerBoardApi;
};

const colorSrc = [
  { name: "custom", value: "" },
  { name: "prime", value: "prime" },
  { name: "category", value: "{{Category.Color}}" },
  { name: "collection", value: "{{Collection.Color}}" },
];

const borderStyles = ["none", "solid", "dotted", "dashed"];

export const BackgroundToolbar = ({ item, api }: BackgroundToolbarProps) => {
  const { onChange } = api;

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", pt: 0.5 }}
    >
      <Typography variant="caption" sx={{ mr: 1 }}>
        <b>Background</b>
      </Typography>
      {item.meta === "catalog" && (
        <FormControlLabel
          control={
            <Checkbox
              checked={item.di.Prime}
              onChange={(e) => {
                onChange({
                  type: "update",
                  item,
                  data: { Prime: e.target.checked },
                  action: "bg color",
                });
              }}
              size="small"
              sx={{ mx: 0.5, p: "6px" }}
            />
          }
          label="prime color"
        />
      )}

      {item.meta === "template" && (
        <SelectFromList
          label="Color Source"
          entries={colorSrc}
          value={item.di.ColorSrc}
          displayEmpty
          setValue={(c) =>
            onChange({
              type: "update",
              item,
              data: { ColorSrc: c },
              action: "bg color",
            })
          }
          size="mini"
          sx={{ mr: 1 }}
        />
      )}
      {!item.di.Prime && item.di.ColorSrc !== "prime" && (
        <ResponsiveColorPicker
          breakpoint="md"
          color={item.di.Color ?? ""}
          size="small"
          setColor={(c) =>
            onChange({
              type: "update",
              item,
              data: { Color: c },
              action: "bg color",
            })
          }
        />
      )}
      <Typography variant="caption" sx={{ ml: 1 }}>
        <b>Border</b> radius
      </Typography>
      <TextField
        type="number"
        size="small"
        variant="standard"
        inputProps={{ pattern: "[0-9]*" }}
        sx={{ width: 30, mx: 1, "& input": { fontSize: 12 } }}
        value={item.di.BorderRadius}
        onChange={(e) => {
          if (isNaN(+e.target.value)) return;
          onChange({
            type: "update",
            item,
            data: { BorderRadius: +e.target.value },
            action: "border",
          });
        }}
      />
      <SelectFromList
        entries={borderStyles}
        value={item.di.BorderStyle}
        setValue={(c) =>
          onChange({
            type: "update",
            item,
            data: { BorderStyle: c },
            action: "border",
          })
        }
        size="mini"
        sx={{ mx: 0.5 }}
      />
      <TextField
        type="number"
        size="small"
        variant="standard"
        inputProps={{ pattern: "[0-9]*" }}
        sx={{ width: 30, mx: 1, "& input": { fontSize: 12 } }}
        value={item.di.BorderWidth}
        onChange={(e) => {
          if (isNaN(+e.target.value)) return;
          onChange({
            type: "update",
            item,
            data: { BorderWidth: +e.target.value },
            action: "border",
          });
        }}
      />

      <ResponsiveColorPicker
        breakpoint="md"
        color={item.di.BorderColor ?? ""}
        size="small"
        setColor={(c) =>
          onChange({
            type: "update",
            item,
            data: { BorderColor: c },
            action: "border",
          })
        }
      />
    </Box>
  );
};
