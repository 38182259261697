import { atom } from "jotai";
import { atomWithDefault } from "jotai/utils";
import { loadCatalogsDb } from "./catalog.service";
import { sessionAtom } from "../accounts/account.state";

export const baseCatalogDbAtom = atomWithDefault(async (get) => {
  const session = await get(sessionAtom);
  return session?.account?.config
    ? await loadCatalogsDb(session.account.config.imageStorageUrl)
    : null;
});

export const catalogDbAtom = atom(
  async (get) => await get(baseCatalogDbAtom),
  (_get, set, update) => {
    set(baseCatalogDbAtom, update);
  }
);

// const catalogDbQuery = selector({
//   key: "catalogDbQuery",
//   async get({ get }) {
//     return await loadCatalogsDb();
//   },
// });

// export const catalogDbAtom = atom({
//   key: "catalogDb",
//   default: catalogDbQuery,
// });
