import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { CloseButton } from "../../components/buttons/CloseButton";
import { CatalogRef } from "../../features/catalogs/catalogs";

export type CatalogSelectModalParams = {
  excludeCatalogId?: string;
  onChange: (catalogId: string) => void;
};

type CatalogSelectModalProps = {
  isOpen?: boolean;
  onClose: () => void;
  catalogs: CatalogRef[];
} & CatalogSelectModalParams;

export type OpenCatalogSelectModalFunc = (
  params: CatalogSelectModalParams
) => void;

export const CatalogSelectModal = ({
  excludeCatalogId,
  onClose,
  isOpen,
  onChange,
  catalogs,
}: CatalogSelectModalProps) => {
  return (
    <Dialog onClose={onClose} open={isOpen} maxWidth="sm" fullWidth>
      <DialogTitle>
        Select Catalog <CloseButton onClose={onClose} />
      </DialogTitle>
      <List sx={{ pt: 0 }}>
        {catalogs
          .filter((c) => c.catalog.id !== excludeCatalogId)
          .map((c) => (
            <ListItem disableGutters key={c.catalog.id}>
              <ListItemButton
                onClick={() => {
                  onChange(c.catalog.id);
                  onClose();
                }}
              >
                {c.catalog.name}
              </ListItemButton>
            </ListItem>
          ))}
        {catalogs.length < 2 && <ListItem>No other catalogs found</ListItem>}
      </List>
    </Dialog>
  );
};
