import { useCallback } from "react";
import { useAtom } from "jotai";
import {
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  Button,
  AccordionDetails,
} from "@mui/material";
import { FixedPanel } from "../../../components/FixedPanel";
import { IconTypes } from "../../../components/icons.types";
import { CatalogBuilder } from "../../../features/catalogs/catalog.builder";
import { CreateItemAttrs } from "../../../features/catalogs/catalog.design";
import { CatalogItemRef } from "../../../features/catalogs/catalogs";
import {
  clipboardAtom,
  getClipboardItems,
  removeClipboardItem,
} from "../../../features/design/clipboard";
import {
  DesignItemMeta,
  DesignItemRef,
  TemplateItemRef,
} from "../../../features/design/design.types";
import { LayoutItemRef } from "../../../features/layouts/layouts";
import { CatalogPagePreview } from "../catalogs/CatalogPagePreview";
import { renderCatalogItem } from "../catalogs/catalog.render";
import { DesignerChange } from "../designer.board.api";
import { startElementDrag, endElementDrag } from "../designer.func";
import { renderLayoutItem } from "../layouts/layout.render";
import { renderTemplateItem } from "../templates/template.render";

const thumbScale = 0.2;
const itemThumbHeight = 100;
const itemThumbWidth = 100;

type ClipboardPanelProps = {
  builder: CatalogBuilder;
  onChange: (change: DesignerChange) => void;
  meta: DesignItemMeta;
};

export const ClipboardPanel = ({
  builder,
  onChange,
  meta,
}: ClipboardPanelProps) => {
  const [clipboard, setClipboard] = useAtom(clipboardAtom);
  const items = getClipboardItems(clipboard, meta);

  const renderItem = useCallback((item: DesignItemRef) => {
    switch (item.meta) {
      case "catalog":
        return renderCatalogItem(item as CatalogItemRef);
      case "layout":
        return renderLayoutItem(item as LayoutItemRef, false);
      case "template":
        return renderTemplateItem(item as TemplateItemRef, false, false);
    }
    return null;
  }, []);

  return (
    <FixedPanel overflow="auto">
      <Box p={1} className="clipboard-panel">
        {meta === "catalog" && (
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<IconTypes.ExpandMore />}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexGrow: 1,
                  mr: 2,
                }}
              >
                <Typography>
                  Pages
                  {clipboard.pages.length
                    ? "(" + clipboard.pages.length + ")"
                    : ""}
                </Typography>
                <Button
                  onClick={() => setClipboard({ ...clipboard, pages: [] })}
                  disabled={clipboard.pages.length === 0}
                >
                  Clear
                </Button>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {clipboard.pages.length === 0 && (
                <Typography
                  sx={{ textAlign: "center", mb: 3 }}
                  variant="subtitle1"
                >
                  No pages in clipboard
                </Typography>
              )}
              {clipboard.pages.length > 0 && (
                <Box>
                  <Typography variant="caption">
                    Click on page to add to the catalog
                  </Typography>
                  <Box className="catalog-pages plain-list">
                    {clipboard.pages.map((p, index) => (
                      <Box
                        key={index}
                        className="page-thumb with-actions"
                        sx={{
                          height: builder?.board.h * thumbScale,
                          width: builder?.board.w * thumbScale,
                        }}
                      >
                        <Box
                          onClick={() => {
                            onChange({
                              type: "paste-page",
                              action: "paste page",
                              page: p.page,
                            });
                          }}
                        >
                          <CatalogPagePreview
                            builder={builder}
                            page={p.page}
                            scale={thumbScale}
                          />
                        </Box>
                        <Box className="page-actions">
                          <Box></Box>
                          <Button
                            size="small"
                            onClick={() => {
                              clipboard.pages.splice(index, 1);
                              setClipboard({
                                ...clipboard,
                              });
                            }}
                          >
                            Remove
                          </Button>
                          <Box></Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<IconTypes.ExpandMore />}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexGrow: 1,
                mr: 2,
              }}
            >
              <Typography>
                Elements {items.length ? "(" + items.length + ")" : ""}
              </Typography>
              <Button
                onClick={() => setClipboard({ ...clipboard, items: [] })}
                disabled={items.length === 0}
              >
                Clear
              </Button>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {items.length === 0 && (
              <Typography
                sx={{ textAlign: "center", mb: 3 }}
                variant="subtitle1"
              >
                No elements in clipboard
              </Typography>
            )}
            {items.length > 0 && (
              <Typography variant="caption">
                Drag and drop or click on the element to add to the page
              </Typography>
            )}
            <Box className="clipboard-items">
              {items.map((i, index) => {
                const scale = 1;
                return (
                  <Box key={index} className="clipboard-item-container">
                    <Box
                      className="clipboard-item element"
                      sx={{
                        width: itemThumbWidth,
                        height: itemThumbHeight,
                      }}
                      draggable="true"
                      onDragStart={(e) =>
                        startElementDrag(e, {
                          type: i.item.type,
                          clipboardIndex: "" + index,
                        })
                      }
                      onDragEnd={endElementDrag}
                      onClick={() => {
                        onChange({
                          type: "create-item",
                          action: "paste item",
                          attrs: {
                            type: i.item.type,
                            clone: i.item.di,
                            pos: "none",
                          } as CreateItemAttrs,
                        });
                      }}
                    >
                      <Box
                        style={i.item.style}
                        sx={{
                          transform: "scale(" + scale + ")",
                        }}
                      >
                        {renderItem(i.item)}
                      </Box>
                    </Box>
                    <Box className="item-actions">
                      <Button
                        size="small"
                        onClick={() => {
                          const c = removeClipboardItem(
                            clipboard,
                            i.item.meta,
                            index
                          );
                          setClipboard(c);
                        }}
                      >
                        Remove
                      </Button>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </FixedPanel>
  );
};
