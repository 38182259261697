//https://github.com/remirror/remirror/blob/b3c8b02f7562afb7de08b4308a3302a500386d33/packages/remirror__react-core/src/react-framework.tsx#L268

import { forwardRef, useImperativeHandle } from "react";
import { Box } from "@mui/material";
import "remirror/styles/all.css";
import "./remirror.scss";
import {
  OnChangeHTML,
  Remirror,
  useChainedCommands,
  useRemirror,
  useRemirrorContext,
} from "@remirror/react";
import { HtmlEditorMenu } from "./HtmlEditorMenu";
import { HtmlEditorProps, HtmlEditorProvider } from "./editor.context";
import { editorExtensions } from "./editor.extensions";
import { HtmlEditorRef } from "./editor-func";
import { useHtmlEditorContext } from "./useHtmlEditorContext";
import { HtmlEditorBox } from "./HtmlEditorBox";

export const InnerEditor = (props: HtmlEditorProps) => {
  const context = useHtmlEditorContext();
  return (
    <>
      <OnChangeHTML onChange={context.onChange} />
      {props.children}
    </>
  );
};

const ImperativeHandle = forwardRef(
  (_: unknown, ref: React.Ref<HtmlEditorRef>) => {
    const { setContent, manager, commands } = useRemirrorContext();

    const replaceContent = (content: string) => {
      manager.view.updateState(manager.createState({ content: content }));
    };

    const chain = useChainedCommands();

    useImperativeHandle(ref, () => ({
      setContent,
      replaceContent,
      focus: commands.focus,
      insertText: commands.insertText,
      insertParagraph: commands.insertParagraph,
      chain: chain,
    }));

    return <></>;
  }
);

export const HtmlEditorWrapperSwitch = forwardRef(
  (props: HtmlEditorProps, ref: React.Ref<HtmlEditorRef>) => {
    if (props.value === null || props.value === undefined) {
      return <>{props.children}</>;
    }
    return <HtmlEditorWrapper {...props} ref={ref} />;
  }
);

export const HtmlEditorWrapper = forwardRef(
  (props: HtmlEditorProps, ref: React.Ref<HtmlEditorRef>) => {
    const { manager, state } = useRemirror({
      extensions: editorExtensions,
      content: props.value,
      selection: "start",
      stringHandler: "cmCode",
      extraAttributes: [
        {
          identifiers: "nodes",
          attributes: {
            "data-expr-start": { default: null },
            "data-expr-end": { default: null },
          },
        },
        {
          identifiers: ["table", "tableRow", "tableCell", "tableHeaderCell"],
          attributes: { style: { default: null } },
        },
      ],
    });

    return (
      <Remirror manager={manager} initialContent={state}>
        <HtmlEditorProvider {...props}>
          <InnerEditor {...props} />
          <ImperativeHandle ref={ref} />
        </HtmlEditorProvider>
      </Remirror>
    );
  }
);

export const HtmlEditor = (props: HtmlEditorProps) => {
  return (
    <HtmlEditorWrapper {...props}>
      <Box pb={1}>
        <HtmlEditorMenu editorId={props.editorId} />
      </Box>

      <HtmlEditorBox onSourceEdit={props.onChange} editorId={props.editorId} />
    </HtmlEditorWrapper>
  );
};

ImperativeHandle.displayName = "ImperativeHandle";
HtmlEditorWrapper.displayName = "HtmlEditorWrapper";
HtmlEditorWrapperSwitch.displayName = "HtmlEditorWrapperSwitch";
