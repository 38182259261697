import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import { useAuth } from "../../features/accounts/useAuth";
import { apiUrl } from "../../services/apiClient";
import { useRecaptcha } from "../../components/forms/Recaptcha";
import { useCallback, useState } from "react";
import { dataPatterns } from "../../features/data/data-types";
import CookieConsentDialog from "../../components/dialogs/CookieConsentDialog";
import { ProcessButton } from "../../components/buttons/ProcessButton";

type LoginData = {
  email?: string;
  password?: string;
};

type LocationState = {
  from: {
    pathname: string;
  };
};

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as LocationState)?.from?.pathname || "/";
  const { doLogin } = useAuth();
  const externalUrl =
    apiUrl +
    `/auth/external-login?returnUrl=` +
    encodeURIComponent(window.location.origin + "/external-login");

  const { token, isLoadingToken, regenerateToken } = useRecaptcha("login");

  const [loginData, setLoginData] = useState<LoginData>({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState<LoginData>({});

  const [isProcessing, setIsProcessing] = useState(false);

  const validateForm = () => {
    let valid = true;
    const newErrors = { email: "", password: "" };

    if (!loginData.email || !dataPatterns.email.test(loginData.email)) {
      newErrors.email = "Please enter a valid email";
      valid = false;
    }

    if (!loginData.password) {
      newErrors.password = "Please enter a valid password";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async () => {
    //event.preventDefault();
    if (!validateForm()) return;

    setIsProcessing(true);

    let currentToken = token;
    if (isLoadingToken || !currentToken) {
      currentToken = await regenerateToken();
      if (!currentToken) {
        setErrors({
          ...errors,
          email: "Unable to verify reCAPTCHA. Please try again.",
        });
        setIsProcessing(false);
        return;
      }
    }

    try {
      const response = await doLogin({ ...loginData, token: currentToken });
      if (response.succeeded) {
        navigate(from, { replace: true });
      } else {
        setErrors({
          ...errors,
          email: response?.message || "Login failed. Please try again.",
        });
        await regenerateToken();
        setIsProcessing(false);
      }
    } catch {
      setErrors({
        ...errors,
        email: "An error occurred. Please try again.",
      });
      await regenerateToken();
      setIsProcessing(false);
    }
  };

  const handleInputChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setLoginData({ ...loginData, [name]: value });
    },
    [loginData]
  );

  return (
    <Container sx={{ pt: 5, pb: 5 }} maxWidth="sm">
      <Box mb={8} display="flex" justifyContent="center">
        <RouterLink to="/">
          <img alt="Logo" src="/images/logo.png" />
        </RouterLink>
      </Box>
      <Card>
        <CardContent
          sx={{
            padding: 4,
            display: "flex",
            flexDirection: "column",
            minHeight: 400,
          }}
        >
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={2}
          >
            <div>
              <Typography color="textPrimary" gutterBottom variant="h2">
                Sign in
              </Typography>
            </div>
          </Box>
          <Grid container spacing={3}>
            <Grid size={{ md: 12, xl: 7 }}>
              <Button
                size="large"
                variant="outlined"
                href={externalUrl + "&provider=Google"}
              >
                <img
                  alt="Google"
                  style={{
                    marginRight: "16px",
                  }}
                  src="/images/google.svg"
                />
                Sign in with Google
              </Button>
            </Grid>
            <Grid size={{ md: 12, xl: 5 }} sx={{ textAlign: "right" }}>
              <Button
                size="large"
                variant="outlined"
                href={externalUrl + "&provider=Facebook"}
              >
                <img
                  alt="Facebook"
                  style={{
                    marginRight: "16px",
                    width: 24,
                    height: 24,
                  }}
                  src="/images/facebook.svg"
                />
                Facebook
              </Button>
            </Grid>
          </Grid>
          <Box mt={4}>
            <Typography color="textPrimary">
              or use your email to sign in:
            </Typography>
          </Box>
          <Box>
            <form>
              <TextField
                sx={{ mt: 2 }}
                error={Boolean(errors.email)}
                fullWidth
                helperText={errors.email}
                label="Email"
                name="email"
                onBlur={validateForm}
                onChange={handleInputChange}
                type="email"
                value={loginData.email}
                variant="outlined"
                slotProps={{
                  inputLabel: { shrink: true },
                }}
                placeholder="Enter your email"
                autoComplete="email"
              />

              <TextField
                sx={{ mt: 2 }}
                error={Boolean(errors.password)}
                fullWidth
                helperText={errors.password}
                label="Password"
                name="password"
                onBlur={validateForm}
                onChange={handleInputChange}
                type="password"
                value={loginData.password}
                variant="outlined"
                placeholder="Enter your password"
                slotProps={{
                  inputLabel: { shrink: true },
                }}
              />

              <Box mt={2}>
                <ProcessButton
                  onClick={handleSubmit}
                  size="large"
                  disabled={isProcessing || isLoadingToken}
                  processing={isProcessing}
                  label={"Log In"}
                  color={"primary"}
                  variant="contained"
                  fullWidth
                />
              </Box>
            </form>
          </Box>
          <Box mt={3}>
            <Link
              component={RouterLink}
              to="/forgot-password"
              variant="body2"
              color="textSecondary"
            >
              Forgot password?
            </Link>
          </Box>
          <Box my={3}>
            <Divider />
          </Box>
          <Box>
            <Typography variant="body2" color="textSecondary" align="center">
              Don&apos;t have an account?{" "}
              <Link
                component={RouterLink}
                to={"/signup" + (from ? "?from=" + from : "")}
                variant="body2"
                color="secondary"
              >
                Sign up
              </Link>
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <Typography
        component="div"
        sx={{
          textAlign: "center",
          mt: 3,
          color: "#999",
          fontSize: 10,
          a: { color: "#999" },
        }}
      >
        This site is protected by reCAPTCHA and the Google{" "}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </Typography>
      <CookieConsentDialog />
    </Container>
  );
};

export default Login;
