import { useState, useCallback } from "react";
import { ProductListExtOptions } from "../../../features/catalogs/product-list.ext";
import { ProductListExtModal } from "./ProductListExtModal";

export const useProductListExtModal = () => {
  const [params, setParams] = useState<ProductListExtOptions>();

  const openProductListExt = (p: ProductListExtOptions) => setParams(p);
  const renderProductListExtModal = useCallback(() => {
    if (!params) return null;

    return (
      <ProductListExtModal
        {...params}
        isOpen={!!params}
        onClose={() => setParams(null)}
      />
    );
  }, [params]);

  return { renderProductListExtModal, openProductListExt };
};
