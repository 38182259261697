import { ProductContainer } from "../catalogs/catalog.products";
import { makeBrief } from "../data/data-types";
import { LayoutItemRef } from "../layouts/layouts";
import { ProductRef } from "../products/product";
import { TemplateRef } from "../templates/templates";

export type BoundRect = {
  top: number;
  right: number;
  bottom: number;
  left: number;
  zoom: number;
};

export type DesignItemType =
  | "text"
  | "image"
  | "product"
  | "template"
  | "page"
  | "icon"
  | "bg"
  | "product-image"
  | "product-list";

export type DesignSubtype = "product";

export const imagePositions = ["Fit", "Fill", "Stretch", "Center"] as const;

export type ImagePosition = (typeof imagePositions)[number];

export type CatalogExtension = {
  type: "productlist";
  version?: string;
  fields: string[];
  orderBy?: string;
  orderDesc?: boolean;
  grouping?: string;
  groupOrder?: string;
  HeaderColor?: string;
  HeaderBkgColor?: string;
  HeaderPadding?: string;
  ProductPadding?: string;
  GroupPadding?: string;
  Border?: string;
  BorderColor?: string;
  HeaderFontSize?: string;
  HeaderFont?: string;
  ProductBkgColor?: string;
  AltRowBkgColor?: string;
  ProductColor?: string;
  ProductFontSize?: string;
  ProductFont?: string;
  VariantRow?: boolean;
  GroupBy?: string;
  UseGroupColor?: boolean;
  GroupColor?: string;
  GroupBkgColor?: string;
  GroupFontSize?: string;
  GroupFont?: string;
  GroupOrder?: string;
  GroupThumb?: boolean;
  GroupDesc?: boolean;
  HideHeader?: boolean;
} & ProductContainer;

export type ItemStyle = {
  left?: number;
  top?: number;
  width?: number;
  height?: number;
  zIndex?: number;
  fontSize?: string;
  color?: string;
  backgroundColor?: string;
  transform?: string;
};

export type DesignItem = {
  w: number;
  h: number;
  x: number;
  y: number;
  z?: number;
  r?: number;

  Color?: string;
  ColorSrc?: string;
  BorderColor?: string;
  BorderWidth?: number;
  BorderRadius?: number;
  BorderStyle?: string;
  Prime?: boolean;

  exp?: boolean;
  fix?: boolean;
  scale?: boolean;
  subtype?: DesignSubtype;
};

export type DesignItemInfo = {
  product?: ProductRef;
  template?: TemplateRef;
  content?: string;
  src?: string;
  path?: string;
  title?: string;
  extension?: CatalogExtension;
  templateItems?: TemplateItemRef[];
  imageField?: string;
};

export type DesignItemMeta = "template" | "catalog" | "layout";

export type DesignItemRef = {
  id: string;
  type?: DesignItemType;
  content?: string;
  imgPosition?: ImagePosition;
  version: number;
  di: DesignItem;
  style?: ItemStyle;
  info?: DesignItemInfo;
  meta?: DesignItemMeta;
};

export type TemplateItem = DesignItem & {
  type: DesignItemType;
  text?: string;
  src?: string;
  imgMode?: "field" | "image";
  position?: ImagePosition;
};

export type TemplateItemRef = {
  di: TemplateItem;
  meta: "template";
} & DesignItemRef;

export const orderDesignItem = <T extends DesignItemRef>(
  items: T[],
  item: T,
  isFront: boolean,
  withStyle: boolean
) => {
  if (!item) return items;

  items.sort((a, b) => (a.di.z < b.di.z ? -1 : 1));
  let z = isFront ? 1 : 2;
  items
    .filter((i) => i !== item)
    .forEach((i) => {
      i.di.z = z++;
      if (withStyle) i.style = { ...i.style, zIndex: i.di.z };
    });
  item.di.z = isFront ? z : 1;
  if (withStyle) item.style = { ...item.style, zIndex: isFront ? z : 1 };
  return items;
};

export const removeDesignItem = <T extends DesignItemRef>(
  items: T[],
  item: T
) => {
  if (!item) return;
  return items.filter((i) => i.id !== item.id);
};

export const getItemDescription = (item: DesignItemRef, short: boolean) => {
  if (!item.type) return "empty";

  if (item.info.extension) {
    return short
      ? ""
      : item.info.extension.type === "productlist"
      ? "Product List"
      : "Extension";
  }

  const p = item.meta === "catalog" ? item.info.product : null;

  const product = p ? " P: <b>" + p.product.name + "</b> " : "";

  if (item.info.template) {
    return (
      product +
      (item.info.template
        ? " Card: " +
          (item.info.template.template.name || (item as LayoutItemRef).di.text)
        : "")
    );
  }

  if (item.meta === "template") {
    const ti = item as TemplateItemRef;
    if (ti.type === "image")
      return ti.di.imgMode === "field" && !short
        ? "Field: " + ti.di.src
        : ti.info.path ?? ti.type;
  }

  if (item.type === "image" && !p) {
    return item.info.title ?? item.type;
  }

  switch (item.type) {
    case "bg":
      return short ? "" : "rectangle";
    case "text":
      return product + (short ? "text" : makeBrief(item.content, 100));
    case "template":
      return "<b>Card</b> " + ((item as LayoutItemRef).di.text || "");
    default:
      return product + (short ? "" : item.info.title ?? item.type);
  }
};

export const VariantPrefix = "Variant";

export type ImageFieldParams = {
  name: string;
  pos: ImagePosition;
  w: number;
  h: number;
  field?: string;
};

export const parseImageField = (expr: string): ImageFieldParams => {
  return parseImageFieldArgs(expr.split(":"), expr);
};

export const parseImageFieldArgs = (
  fieldArgs: string[],
  field?: string
): ImageFieldParams => {
  let prefix = "";
  if (fieldArgs[0] === VariantPrefix) {
    prefix = VariantPrefix + ":";
    fieldArgs.shift();
  }
  const result = {
    name: prefix + fieldArgs[0],
    pos: "Fit" as ImagePosition,
    w: 0,
    h: 0,
    field,
  };
  let ph = 3;
  if (fieldArgs.length > 1) {
    if (!isNaN(+fieldArgs[1])) {
      result.w = +fieldArgs[1];
      ph = 2;
    } else {
      result.pos = fieldArgs[1] as ImagePosition;
      result.w = fieldArgs.length > 2 ? +fieldArgs[2] : 0;
    }
    if (fieldArgs.length > ph && !isNaN(+fieldArgs[ph])) {
      result.h = +fieldArgs[ph];
    }
  }
  return result;
};

export const getPositonAttr = (meta: DesignItemMeta) =>
  meta === "catalog" ? "Position" : "z";

export const setRotationStyle = (item: DesignItemRef) => {
  if (item.di.r)
    item.style = { ...item.style, transform: `rotate(${item.di.r}deg)` };
};
