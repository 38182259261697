import { EMPTY_GUID } from "../features/data/data-types";

export type ListValueType = string | number;

export type NameValueType<T extends ListValueType> = {
  value: T;
  name: string;
  level?: number;
};

//export type SelectValueList<T extends ListValueType> = SelectValueType<T>[];

export type SelectEntryType<T extends ListValueType> = NameValueType<T>[] | T[];

export const emptySelectValue = "<empty>";
export const isEmptySelectValue = (value) =>
  value === "" || value === emptySelectValue || value === EMPTY_GUID;

export const getSelectionEntry = <T extends ListValueType>(
  item: SelectEntryType<T>,
  defaultLabel: string
): NameValueType<T> => {
  return (
    typeof item === "string" || typeof item === "number"
      ? { value: item, name: isEmptySelectValue(item) ? defaultLabel : item }
      : { ...item }
  ) as NameValueType<T>;
};
