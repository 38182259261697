import { Box, SxProps, debounce } from "@mui/material";
import { ReactNode, useEffect, useRef } from "react";

type Props = {
  children?: ReactNode;
  className?: string;
  sx?: SxProps;
};
export const RightPanel = ({ children, className, sx }: Props) => {
  const containerRef = useRef(null);

  const handleResize = () => {
    if (containerRef.current) {
      const viewportWidth = window.innerWidth;
      const elementLeftPosition =
        containerRef.current.getBoundingClientRect().left;
      const remainingWidth = viewportWidth - elementLeftPosition;
      containerRef.current.style.width = `${remainingWidth}px`;
    }
  };

  const resize = debounce(handleResize, 200);

  useEffect(() => {
    handleResize();

    const resizeObserver = new ResizeObserver(resize);
    const mutationObserver = new MutationObserver(resize);

    resizeObserver.observe(containerRef.current);
    mutationObserver.observe(containerRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [resize]);

  return (
    <Box
      className={"right-panel " + (className || "")}
      ref={containerRef}
      sx={{
        overflowX: "auto",
        height: "100%",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
