import { ReactNode } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Typography, Button } from "@mui/material";
import { UiSize } from "../ui/styles";

type EmptyStateProps = {
  label?: string;
  btnLabel?: string;
  imageSrc?: string;
  onAdd?: () => void;
  children?: ReactNode;
  size?: UiSize;
  startIcon?: ReactNode;
};

export const EmptyState: React.FC<EmptyStateProps> = ({
  label,
  btnLabel,
  imageSrc,
  onAdd,
  children,
  size,
  startIcon,
}: EmptyStateProps) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        {imageSrc && (
          <img
            src={imageSrc}
            alt={label}
            style={{ maxWidth: 64, opacity: 0.7 }}
          />
        )}
        <Typography variant="h6" sx={{ my: 3, p: 1 }}>
          {label}
        </Typography>
        {onAdd && (
          <Button
            color="primary"
            size={size ?? "small"}
            startIcon={startIcon ?? <AddIcon />}
            onClick={onAdd}
            variant="contained"
          >
            {btnLabel}
          </Button>
        )}
        {children}
      </Box>
    </Box>
  );
};
