import { useMemo, useState } from "react";
import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import structuredClone from "@ungap/structured-clone";

import { prepareLayoutRender } from "../layouts/layout.render";
import { PageZoom } from "../components/PageZoom";
import { OutlinedColorPicker } from "../../../components/ColorPicker";
import { FixedPanel } from "../../../components/FixedPanel";
import { SelectFromList } from "../../../components/SelectFromList";
import {
  CatalogPageRef,
  CatalogItemRef,
} from "../../../features/catalogs/catalogs";
import {
  ProductListExtOptions,
  getProductListExtChange,
} from "../../../features/catalogs/product-list.ext";
import { CatalogPagePreview } from "../catalogs/CatalogPagePreview";
import { ProductListExtDesignGroup } from "./ProductListExtDesignGroup";

const grouping = ["Categories", "Collections"];
const borderStyle = ["None", "Grid", "Bottom"];
const groupOrder = ["Name", "Rank"];

export const ProductListExtDesign = ({
  builder,
  extension,
  item,
  onChange,
}: ProductListExtOptions) => {
  const [zoom, setZoom] = useState(100);

  const page = useMemo(() => {
    let p: CatalogPageRef = null;
    if (item.meta === "catalog") {
      p =
        builder?.findPage((item as CatalogItemRef).di.PageId) ??
        builder?.getActivePage();
      p = structuredClone(p);
      const i = p?.items.find((i) => i.id === item.id);
      if (i) {
        i.di = {
          ...i.di,
          ...getProductListExtChange(extension, i.meta, false),
        };
        builder.initItem(i);
      }
    }
    if (item.meta === "layout") {
      const l = structuredClone(builder.layout);
      const i = l.items.find((i) => i.id === item.id);
      if (i) {
        i.di = {
          ...i.di,
          ...getProductListExtChange(extension, i.meta, false),
        };
      }
      prepareLayoutRender(builder, l);
      p = builder?.getActivePage();
    }
    return p;
  }, [builder, extension, item]);

  return (
    <Box className="design-preview-panel" sx={{ display: "flex" }}>
      <Box sx={{ minWidth: "50%", flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 1,
            gap: 2,
          }}
        >
          <PageZoom
            zoom={zoom}
            setZoom={setZoom}
            builder={builder}
            boardId="plist"
          />
        </Box>

        <FixedPanel
          overflow="auto"
          className="design-preview"
          container="#plist-container"
          breakpoint="lg"
        >
          <Box
            className="page-preview-wrapper"
            id="plist-board-panel"
            sx={{
              height: builder.board.h * (zoom / 100),
              minWidth: builder.board.w * (zoom / 100),
              maxWidth: builder.board.w * (zoom / 100),
            }}
          >
            <CatalogPagePreview
              builder={builder}
              page={page}
              scale={zoom / 100}
            />
          </Box>
        </FixedPanel>
      </Box>
      <Box sx={{ width: 550 }} className="design-tools-panel shadow-panel">
        <FixedPanel
          overflow="auto"
          container="#plist-container"
          breakpoint="lg"
        >
          <Box sx={{ m: 2 }}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">List settings</Typography>
              <Divider sx={{ my: 1 }} />
              <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                <SelectFromList
                  label="Sort by Field"
                  defaultLabel="No Sorting"
                  entries={extension.fields ?? []}
                  value={extension.orderBy ?? ""}
                  setValue={(value) => {
                    onChange?.({ ...extension, orderBy: value });
                  }}
                  size="small"
                  sx={{ mr: 2, mt: 1, minWidth: 100 }}
                />
                {extension.orderBy && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={extension.orderDesc}
                        onChange={(e) =>
                          onChange({
                            ...extension,
                            orderDesc: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Descending order"
                  />
                )}
              </Box>
              <Box sx={{ mb: 2 }}>
                <SelectFromList
                  label="Group Products By"
                  defaultLabel="No Grouping"
                  entries={grouping}
                  value={extension.GroupBy}
                  setValue={(value) => {
                    onChange?.({ ...extension, GroupBy: value });
                  }}
                  size="small"
                  sx={{ mr: 1, minWidth: 100 }}
                />

                {extension.GroupBy && (
                  <>
                    <SelectFromList
                      label="Order Group By"
                      defaultLabel="Default Order"
                      entries={groupOrder}
                      value={extension.GroupOrder}
                      setValue={(value) => {
                        onChange?.({ ...extension, GroupOrder: value });
                      }}
                      size="small"
                      sx={{ mx: 1, minWidth: 100 }}
                      helpText={
                        extension.GroupOrder === "Rank"
                          ? "Configure rank by editing individual category / collection."
                          : ""
                      }
                    />
                  </>
                )}
              </Box>
              <FormGroup sx={{ ml: 1, mt: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={extension.inStock}
                      onChange={(e) =>
                        onChange({ ...extension, inStock: e.target.checked })
                      }
                    />
                  }
                  label="In Stock products only"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={extension.HideHeader}
                      onChange={(e) =>
                        onChange({
                          ...extension,
                          HideHeader: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Hide List Header"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={extension.VariantRow}
                      onChange={(e) =>
                        onChange({
                          ...extension,
                          VariantRow: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Separate Variant Rows"
                />
              </FormGroup>
            </Paper>
          </Box>
          <Box>
            <ProductListExtDesignGroup
              name="Product"
              extension={extension}
              onChange={onChange}
            />
            {!extension.HideHeader && (
              <ProductListExtDesignGroup
                name="Header"
                extension={extension}
                onChange={onChange}
              />
            )}
            {extension.GroupBy && (
              <ProductListExtDesignGroup
                name="Group"
                extension={extension}
                onChange={onChange}
              />
            )}
          </Box>
          <Box sx={{ m: 2 }}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">Cell border</Typography>
              <Divider sx={{ my: 1 }} />
              <Box>
                <SelectFromList
                  label="Style"
                  entries={borderStyle}
                  value={extension.Border}
                  setValue={(value) => {
                    onChange?.({ ...extension, Border: value });
                  }}
                  size="small"
                  sx={{ mx: 1, minWidth: 100 }}
                />

                {extension.Border !== "None" && (
                  <OutlinedColorPicker
                    size="small"
                    color={extension.BorderColor}
                    setColor={(color) =>
                      onChange({ ...extension, BorderColor: color })
                    }
                  />
                )}
              </Box>
            </Paper>
          </Box>
        </FixedPanel>
      </Box>
    </Box>
  );
};
