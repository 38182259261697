import { useCallback, useState } from "react";

import { useAtom } from "jotai";
import { useDialogs } from "../../contexts/useDialogs";
import { CatalogBuilder } from "../../features/catalogs/catalog.builder";
import {
  CreateItemAttrs,
  UpdateItemAtrs,
} from "../../features/catalogs/catalog.design";
import {
  CatalogItem,
  CatalogPageType,
  PageItemConfig,
  CatalogPageRef,
  Catalog,
} from "../../features/catalogs/catalogs";
import { DataTypeName } from "../../features/data/data-types";
import { EditorField } from "../../features/data/editor";
import {
  clipboardAtom,
  addClipboardElement,
} from "../../features/design/clipboard";
import {
  DesignItemRef,
  TemplateItem,
  DesignItemMeta,
} from "../../features/design/design.types";
import { TargetElement } from "../../features/design/designer";
import { LayoutItem } from "../../features/layouts/layouts";
import { useProducts } from "../../features/products/product-func";
import { randomNum } from "../../services/util";
import { DesignBoardRef } from "./components/DesignerBoard";
import { trackEvent } from "../../services/analytics";

export type BoardInteractionMode = "drag" | "resize" | "rotate";

export type BoardState = {
  mode?: BoardInteractionMode;
  version: number;
};

export type CatalogNavChange = {
  pageId?: number;
  action?: string;
  gridId?: number;
  isView?: boolean;
  delta?: number;
};

// export type PageChangeArgs = {
//   delta?: number;
//   pageId?: number;
//   isView?: boolean;
// };

type DesignerChangeCommand =
  | {
      type: "delete-page";
    }
  | {
      type: "undo" | "redo";
      index: number;
    }
  | {
      type: "remove" | "order-up" | "order-down" | "img-position";
      item: DesignItemRef;
    }
  | {
      type: "update";
      content?: string;
      soft?: boolean;
      item: DesignItemRef;
      data?: Partial<TemplateItem | CatalogItem | LayoutItem>;
    }
  | {
      type: "page-config";
      pageConfig?: {
        type?: CatalogPageType;
        config?: Partial<PageItemConfig>;
      };
      pageId: number;
      multi?: boolean;
    }
  | {
      type: "add-page";
      layoutId?: string;
      pageType?: CatalogPageType;
      config?: PageItemConfig;
    }
  | {
      type: "paste-page";
      page: CatalogPageRef;
    }
  | {
      type: "catalog-props";
      catalog: Partial<Catalog>;
    }
  | {
      type: "refresh";
      nav?: CatalogNavChange;
    }
  | {
      type: "create-item";
      attrs: CreateItemAttrs;
    };

export type DesignerChange = DesignerChangeCommand & {
  item?: DesignItemRef;
  source?: "product" | "template" | "collection";
  refreshBoard?: boolean;
  clear?: boolean;
  action?: string;
  force?: boolean;
};

export type ItemSelectionMode = "click" | "drop" | "create";

export type DesignBoardActionsProps = {
  designerBoardApi: DesignerBoardApi;
  pageItems: DesignItemRef[];
  boardContainer: string;
  onChange: (change: DesignerChange) => void;
  onItemDrop: (attrs: CreateItemAttrs | UpdateItemAtrs) => DesignItemRef;
  readOnly?: boolean;
};

type DesignBoardApiProps = {
  meta: DesignItemMeta;
  builder: CatalogBuilder;
  onChange: (change: DesignerChange) => void;
  onSelect: (item?: DesignItemRef, mode?: ItemSelectionMode) => void;
  boardRef: DesignBoardRef;
  getItemById(id: string): DesignItemRef;
};

export interface DesignerBoardApi {
  builder: CatalogBuilder;
  boardRef: DesignBoardRef;
  onChange: (change: DesignerChange) => void;
  selectedItem: DesignItemRef;
  selectItem: (
    id?: string,
    clicked?: boolean,
    updated?: boolean,
    mode?: ItemSelectionMode
  ) => void;
  editedHtml?: string;
  setEditedHtml: (html: string) => void;
  editedId: string;
  editItem: (id?: string) => void;
  removeItem: (id?: string) => void;
  targets: TargetElement[];
  setTargets: (targets: TargetElement[]) => void;
  boardState: BoardState;
  setBoardState: (update: (state: BoardState) => BoardState) => void;
  insertText: (text: string) => void;
  insertParagraph: (text: string) => void;
  getItemById(id: string): DesignItemRef;
  meta: DesignItemMeta;
  getItemFields(item: DesignItemRef, productOnly?: boolean): EditorField[];
  copyItems: (ids?: string[]) => void;
  pasteItem: () => void;
  deleteItems: (ids?: string[]) => void;
}

export const canEditItem = (item: DesignItemRef) => {
  return (
    item.type !== "bg" &&
    !item.info?.imageField &&
    (item.type !== "image" || !item.info.product)
  );
};

export const extractAndLogAction = (name: string, change: DesignerChange) => {
  if (change.action || change.type === "create-item") {
    const action =
      change.type === "create-item"
        ? "create " + change.attrs.type
        : change.action;

    trackEvent({
      category: name,
      action: "designer",
      label: action,
      internal: true,
    });

    return action;
  }
  return null;
};

export const useDesignerBoardApi = ({
  meta,
  builder,
  boardRef,
  onChange,
  onSelect,
  getItemById,
}: DesignBoardApiProps): DesignerBoardApi => {
  const { openHtmlEditorModal, openImageModal, openTemplateDesigner } =
    useDialogs();

  const [targets, setTargets] = useState<TargetElement[]>([]);
  const [editedHtml, setEditedHtml] = useState<string>(null);
  const [editedId, setEditedId] = useState<string>();
  const [selectedItem, setSelectedItem] = useState<DesignItemRef>();
  const [boardState, setBoardState] = useState<BoardState>({
    version: 0,
  });
  const [waitClick, setWaitClick] = useState<boolean>();
  const { defaultCategory } = useProducts();
  const [clipboard, setClipboard] = useAtom(clipboardAtom);

  const updateText = useCallback(
    (item: DesignItemRef, content: string) => {
      onChange({ type: "update", action: "edit text", item, content });
    },
    [onChange]
  );

  const updateSelectedItem = useCallback(() => {
    if (!selectedItem) return;

    if (selectedItem?.type === "text" && editedHtml !== null) {
      updateText(selectedItem, editedHtml);
    }
  }, [editedHtml, selectedItem, updateText]);

  const getItemFields = useCallback(
    (item: DesignItemRef, productOnly?: boolean): EditorField[] => {
      if (!item) return [];

      const isProductSink =
        item.di.subtype === "product" && !item.info?.product;

      const category = isProductSink
        ? defaultCategory
        : item.info?.product?.category;

      const categoryFields = [
        { name: "Name" },
        ...(category?.group.fields ?? []),
      ];

      const fields =
        categoryFields.map((f) => {
          const isImage = f.dataType === DataTypeName.Image;
          return {
            key: f.name,
            name: f.name,
            content: `{{${f.name}${isImage ? ":Fit:50:50" : ""}}}`,
          };
        }) ?? [];

      if (item.info.product?.category.options.length) {
        fields.push(
          { key: "1", name: null, content: null },
          {
            key: "$variants",
            name: "Variants",
            content: "$variants",
          }
        );
      }

      if (!item.info.product && !productOnly) {
        if (isProductSink) {
          fields.push({ key: "2", name: null, content: null });
        }
        fields.push(
          {
            key: "$product-list",
            name: "Product List",
            content: "$product-list",
          },
          {
            key: "$category-list",
            name: "Category List",
            content: "$category-list",
          }
        );
      }

      return fields;
    },
    [defaultCategory]
  );

  const editItem = useCallback(
    (id?: string) => {
      const item = getItemById(id);
      if (!item) return;

      if (item.type === "text") {
        openHtmlEditorModal({
          value: editedHtml != null ? editedHtml : item.content,
          onChange: (value) => {
            updateText(item, value);
            setEditedHtml(null);
            setEditedId(null);
          },
          fields: getItemFields(item, true),
          fieldArgs: { product: item.info?.product },
          editorId: "item-text-" + id,
        });
      }
      if (item.type === "image") {
        openImageModal(
          {
            path: item.info.src ? item.content : undefined,
            canChange: true,
            onSelect(path) {
              item.version = randomNum();
              onChange({
                type: "update",
                action: "image",
                content: path,
                item,
              });
            },
          },
          item.info.src ? "view" : "select"
        );
      }
      if (item.type === "template") {
        openTemplateDesigner({
          templateId: item.info?.template?.template.id,
          productId: item.info?.product?.product.id,
          onChange: (templateId) => {
            onChange({
              type: "update",
              action: "template",
              item: item,
              data: { TemplateId: templateId },
              source: "template",
            });
          },
          pageSize: builder.catalog.catalogPage?.pageSize,
          canSelect: true,
        });
      }
    },
    [
      builder.catalog.catalogPage?.pageSize,
      editedHtml,
      getItemById,
      getItemFields,
      onChange,
      openHtmlEditorModal,
      openImageModal,
      openTemplateDesigner,
      updateText,
    ]
  );

  const selectItem = useCallback(
    (
      id?: string,
      clicked?: boolean,
      updated?: boolean,
      mode?: ItemSelectionMode
    ) => {
      if (clicked && waitClick) {
        setWaitClick(false);
        return;
      }
      setBoardState((s) => ({ ...s, mode: null }));
      if (id) {
        if (selectedItem?.id === id) {
          if (editedId) {
            return;
          }
          if (selectedItem?.type === "text") {
            setEditedId(id);
            const content = selectedItem?.content;
            boardRef?.editorRef?.replaceContent(content);
            setEditedHtml(content);
          }
          if (selectedItem?.type === "image" && !selectedItem?.info.src) {
            editItem(id);
          }
          return;
        }

        updateSelectedItem();

        const item = getItemById(id);
        setSelectedItem(item);
        onSelect(item, mode);
        setWaitClick(clicked);
      } else {
        updateSelectedItem();
        if (!updated) {
          setSelectedItem(null);
          onSelect(null);
        }
      }
      setEditedHtml(null);
      setEditedId(null);
    },
    [
      boardRef?.editorRef,
      editItem,
      editedId,
      getItemById,
      onSelect,
      selectedItem?.content,
      selectedItem?.id,
      selectedItem?.info.src,
      selectedItem?.type,
      updateSelectedItem,
      waitClick,
    ]
  );

  const removeItem = useCallback(
    (id?: string) => {
      const item = getItemById(id);
      if (!item) return;
      onChange({ action: "remove", type: "remove", item });
      selectItem();
      setTargets([]);
    },
    [getItemById, onChange, selectItem]
  );

  const insertText = useCallback(
    (text: string) => {
      if (boardRef?.editorRef && selectedItem?.type === "text") {
        boardRef?.editorRef.insertText(text);
        boardRef?.editorRef.focus();
      }
    },
    [boardRef?.editorRef, selectedItem?.type]
  );

  const insertParagraph = useCallback(
    (text: string) => {
      if (boardRef?.editorRef && selectedItem?.type === "text") {
        boardRef?.editorRef.insertParagraph(text);
        boardRef?.editorRef.focus();
      }
    },
    [boardRef?.editorRef, selectedItem?.type]
  );

  const copyItems = useCallback(
    (ids?: string[]) => {
      ids = ids || targets.map((t) => t.id);
      let c = clipboard;
      ids.forEach((id) => {
        const item = getItemById(id);
        if (item) c = addClipboardElement(c, item);
      });
      setClipboard(c);
    },
    [clipboard, getItemById, setClipboard, targets]
  );

  const pasteItem = useCallback(() => {
    if (!clipboard?.items?.length) return;
    const i = clipboard.items[0];
    onChange({
      type: "create-item",
      action: "paste item",
      attrs: {
        type: i.item.type,
        clone: i.item.di,
        pos: "none",
      } as CreateItemAttrs,
    });
  }, [clipboard, onChange]);

  const deleteItems = useCallback(
    (ids?: string[]) => {
      ids = ids || targets.map((t) => t.id);
      ids.forEach((id) => {
        removeItem(id);
      });
      setTargets([]);
    },
    [removeItem, setTargets, targets]
  );

  return {
    getItemFields,
    meta,
    builder,
    selectItem,
    selectedItem,
    editedHtml,
    setEditedHtml,
    editedId,
    editItem,
    removeItem,
    onChange,
    targets,
    setTargets,
    boardState,
    setBoardState,
    insertText,
    insertParagraph,
    boardRef,
    getItemById,
    copyItems,
    pasteItem,
    deleteItems,
  };
};
