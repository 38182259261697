import { Box, SxProps, Typography } from "@mui/material";

type Props = {
  title: string;
  children: React.ReactNode;
  sx?: SxProps;
};

export const Page = ({ title, children, sx }: Props) => {
  return (
    <Box
      sx={{
        overflow: "auto",
        p: 3,
        backgroundColor: "darkBackground",
        height: "100%",
      }}
    >
      <Box sx={{ ...sx }}>
        <Typography
          variant="h3"
          sx={{
            color: "textPrimary",
            mb: 3,
            mt: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {title}
        </Typography>
        {children}
      </Box>
    </Box>
  );
};
