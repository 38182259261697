import { trackEvent } from "../../services/analytics";
import apiClient, { OperationResult } from "../../services/apiClient";
import { EMPTY_GUID } from "../data/data-types";
import { ParameterRef, Parameter } from "./parameters";

export const getParametersDb = async (): Promise<ParameterRef[]> => {
  const response = await apiClient.get<Parameter[]>("/catalogs/parameters");
  return response.data.map((parameter) => ({ parameter }));
};

export const saveParameter = async (
  model: Parameter
): Promise<OperationResult<Parameter>> => {
  trackEvent({
    category: "parameter",
    action: model.id != EMPTY_GUID ? "update" : "add",
    label: model.id + ":" + model.name,
    feature: true,
  });
  const response = await apiClient.post<OperationResult<Parameter>>(
    "/catalogs/parameter",
    model
  );
  return response.data;
};

export const deleteParameter = async (
  parameterId: string
): Promise<OperationResult<string>> => {
  trackEvent({ category: "parameter", action: "delete", label: parameterId });
  const response = await apiClient.post<OperationResult<string>>(
    "/catalogs/delete-parameter?paramId=" + parameterId
  );
  return response.data;
};
