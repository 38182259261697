import { CatalogPage, CatalogRef } from "./catalogs";
import { pageSizeRange, pageSizes, PageSize } from "./catalog.defs";
import { AccountConfig } from "../accounts/account";
import { EMPTY_GUID } from "../data/data-types";
import { TemplateItemRef } from "../design/design.types";
import { ParameterRef } from "../eval/parameters";
import { LayoutDb } from "../layouts/layouts";
import { ProductRef, ProductDb } from "../products/product";
import { TemplateRef } from "../templates/templates";
export const getImageSrc = (
  storageUrl: string,
  src: string,
  isThumb = false,
  version?: number
) => {
  if (!src) return "";
  if (isThumb) {
    const i = src.lastIndexOf("/");
    if (i < 0) {
      src = "_thumb/" + src;
    } else {
      src = src.substring(0, i) + "/_thumb/" + src.substring(i + 1);
    }
  }
  return storageUrl + "/" + src + "?v=" + version;
};

export const getProductField = (product: ProductRef, name: string) => {
  if (!name) return null;
  name = name.toLowerCase();
  return Array.from(product.fieldMap.entries()).find(
    (k) => k[0].toLowerCase() === name
  )?.[1];
};

export const getDefaultProductTemplateRoot = (
  templates: TemplateRef[],
  isDetails = false,
  isList = false
): TemplateRef => {
  if (templates.length === 0) return null;

  let template = null;
  if (isDetails) template = templates.find((t) => t.template.isDefaultDetails);
  if (isList) template = templates.find((t) => t.template.isDefaultList);

  if (!template) template = templates.find((t) => t.template.isDefault);

  return template ?? templates[0];
};

export const getProductTemplate = (
  templates: TemplateRef[],
  pr: ProductRef,
  templateId?: string
) => {
  if ((!templateId || templateId === EMPTY_GUID) && pr) {
    templateId =
      pr.product.itemDetailsTemplateId &&
      pr.product.itemDetailsTemplateId !== EMPTY_GUID
        ? pr.product.itemDetailsTemplateId
        : pr.category.group.detailsTemplateId;
  }
  let template =
    templateId && templateId !== EMPTY_GUID
      ? templates.find((t) => t.template.id === templateId)
      : null;
  if (!template) template = getDefaultProductTemplateRoot(templates);
  return { ...template };
};

export const getTemplateImageSrc = (item: TemplateItemRef, p: ProductRef) => {
  return item.di.imgMode === "field"
    ? p
      ? getProductField(p, item.di.src)?.value
      : null
    : item.di.src;
};

export const getTemplateImage = (
  storageUrl: string,
  item: TemplateItemRef,
  p: ProductRef,
  isThumb = false,
  version?: number
) => {
  const src = getTemplateImageSrc(item, p);
  return getImageSrc(storageUrl, src, isThumb, version);
};

export const setCustomSize = (page: CatalogPage) => {
  const ws = pageSizeRange.find((s) => s.size === page.pageWidthInches);
  const hs = pageSizeRange.find((s) => s.size === page.pageHeightInches);
  if (!ws || !hs) return;
  page.pageWidth = ws.val;
  page.pageHeight = hs.val;
  const ps = pageSizes[pageSizes.length - 1];
  ps.w = ws.val;
  ps.h = hs.val;
  if (ws.val === hs.val) page.orientation = "Square";
  else if (ws.val > hs.val) page.orientation = "Landscape";
  else page.orientation = "Portrait";
};

export const setPageSize = (page: CatalogPage, pageSize?: PageSize) => {
  if (!page) return;

  const readOnly = !pageSize;

  if (readOnly) {
    pageSize = pageSizes.find((s) => page.pageSize === s.name);
    if (!pageSize) {
      pageSize = pageSizes[0];
    }
  }
  page.pageSize = pageSize.name;
  page.orientation = pageSize.orientation;
  if (pageSize.sizes) {
    if (pageSize.sizes.indexOf(page.pdfSize) < 0)
      page.pdfSize = pageSize.sizes[0];
  } else {
    page.pdfSize = null;
  }
  if (pageSize.name !== "Custom" || !page.pageWidth || !page.pageHeight) {
    page.pageWidth = pageSize.w;
    page.pageHeight = pageSize.h;
  }
  if (!readOnly && pageSize.name === "Custom") {
    if (!page.pageWidthInches) page.pageWidthInches = "8.5";
    if (!page.pageHeightInches) page.pageHeightInches = "11";
    setCustomSize(page);
  }
  return page;
};

export type CatalogData = {
  productDb: ProductDb;
  templates: TemplateRef[];
  catalogs: CatalogRef[];
  layoutDb: LayoutDb;
  config: AccountConfig;
  parameters: ParameterRef[];
  version?: number;
};
