export const barcodeFont = "Libre Barcode 39";
export const barcodeFontExt = "Libre Barcode 39 Extended Text";

const pageSizeSm = 400;
const pageSizeMd = 600;
const pageSizeLg = 800;

export type PageSizeName =
  | "Portrait"
  | "Landscape"
  | "Half Portrait"
  | "Half Landscape"
  | "Small Square"
  | "Square"
  | "Large Square"
  | "Custom";

export type PageSize = {
  name: PageSizeName;
  w: number;
  h: number;
  orientation?: string;
  sizes?: string[];
};

export const pageSizes: PageSize[] = [
  {
    name: "Portrait",
    w: pageSizeMd,
    h: pageSizeLg,
    orientation: "Portrait",
    sizes: ["Letter", "A4"],
  },
  {
    name: "Landscape",
    w: pageSizeLg,
    h: pageSizeMd,
    orientation: "Landscape",
    sizes: ["Letter", "A4"],
  },
  {
    name: "Half Portrait",
    w: pageSizeSm,
    h: pageSizeMd,
    orientation: "Portrait",
    sizes: ["Half Letter", "A5"],
  },
  {
    name: "Half Landscape",
    w: pageSizeMd,
    h: pageSizeSm,
    orientation: "Landscape",
    sizes: ["Half Letter", "A5"],
  },
  { name: "Small Square", w: pageSizeSm, h: pageSizeSm, orientation: "Square" },
  { name: "Square", w: pageSizeMd, h: pageSizeMd, orientation: "Square" },
  { name: "Large Square", w: pageSizeLg, h: pageSizeLg, orientation: "Square" },
  { name: "Custom", w: pageSizeMd, h: pageSizeLg },
];

export const pageSizeRange = [];
for (let i = 4; i <= 11; i = i + 0.5) {
  pageSizeRange.push({
    size: "" + i,
    name: i + '"',
    val: Math.floor((pageSizeLg * i) / 11),
  });
}

export const CATALOG_PLACEHOLDER_THUMB =
  "/images/catalog-placeholder-white.png";

export const CatalogPrivacyLevels = [
  {
    name: "Public",
    value: 0,
    d: "Anyone can search for and view. Submitted to search engines as Google or Bing.",
    i: "fa-globe",
    l: "",
  },
  {
    name: "Direct",
    value: 1,
    d: "Anyone with the link can view. Listed only under your namespace.",
    i: "fa-eye",
    l: "",
  },
  {
    name: "Hidden",
    value: 2,
    d: "Anyone with the link can view. Not listed anywhere.",
    i: "fa-shield",
    l: "",
  },
  {
    name: "Protected",
    value: 3,
    d: "Only people with access code can view.",
    i: "fa-lock",
    l: "(Pro+ accounts only)",
  },
  {
    name: "Email",
    value: 4,
    d: "Anyone who provides email can view.",
    i: "fa fa-envelope-o",
    l: "(Pro+ accounts only)",
  },
];

export const ShowroomPrivacyLevels = [
  {
    name: "Public",
    value: 0,
    d: "Anyone can search for and view. Submitted to search engines as Google or Bing.",
    l: "",
    c: "success",
  },
  {
    name: "Direct",
    value: 1,
    d: "Anyone with the direct link can view.",
    l: "",
    c: "info",
  },
  {
    name: "Closed",
    value: 2,
    d: "Showroom is unavailable for anyone except you.",
    l: "",
    c: "default",
  },
  {
    name: "Protected",
    value: 3,
    d: "Only people with access code can view.",
    l: "(Pro+ accounts only)",
    c: "danger",
  },
  {
    name: "Email",
    value: 4,
    d: "Anyone who provides email can view.",
    l: "(Pro+ accounts only)",
    c: "warning",
  },
];

export type CatalogNavigationType =
  | "none"
  | "bullets"
  | "small"
  | "medium"
  | "large";

export const catalogNavigation: {
  name: string;
  value: CatalogNavigationType;
}[] = [
  { name: "None (fast)", value: "none" },
  { name: "Bullets (fast)", value: "bullets" },
  { name: "Small Thumbnails", value: "small" },
  { name: "Medium Thumbnails", value: "medium" },
  { name: "Large Thumbnails", value: "large" },
];

export const catalogCompression = [
  { name: "Uncompressed", value: 0 },
  { name: "25% compression", value: 25 },
  { name: "50% compression", value: 50 },
  { name: "75% compression", value: 75 },
  { name: "Best compression", value: 100 },
];
export const catalogHeadFootSizes = [
  { name: "none", value: 0 },
  { name: "small", value: 80 },
  { name: "medium", value: 120 },
  { name: "large", value: 180 },
  { name: "x-large", value: 240 },
];

export const catalogHeadFootMargin = [
  { name: "default", value: "" },
  { name: "none", value: "0" },
];

export const catalogHeadFootColorSrc = [
  { name: "None", value: "" },
  { name: "Category Color", value: "{{Category.Color}}" },
  { name: "Collection Color", value: "{{Collection.Color}}" },
];

export type CatalogViewerModeType = "slider" | "flow";

export const catalogViewerMode: {
  name: string;
  value: CatalogViewerModeType;
}[] = [
  { name: "Slider with thumbnails", value: "slider" },
  { name: "Flow (horizontal scroll)", value: "flow" },
];
