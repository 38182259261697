import { useCallback, useEffect, useRef } from "react";
import { debounce } from "../ui/useDebounceEffect";

type Props = {
  content: string;
  scalable?: boolean;
  scroll?: boolean;
  blockContent?: boolean;
};

const computeScale = (container) => {
  const containerWidth = container.offsetWidth;
  const containerHeight = container.offsetHeight;
  const contentWidth = container.scrollWidth;
  const contentHeight = container.scrollHeight;

  const scaleX = containerWidth / contentWidth;
  const scaleY = containerHeight / contentHeight;
  return Math.min(scaleX, scaleY);
};

const setStyle = (container, scale) => {
  container.style.transform = `scale(${scale})`;
  container.style.width = `${100 / scale}%`;
  container.style.height = `${100 / scale}%`;
};

export const HtmlText = ({
  content,
  scalable,
  scroll,
  blockContent,
}: Props) => {
  const contentRef = useRef(null);

  const adjustScale = useCallback(() => {
    const container = contentRef.current;

    if (!container || !content) return;

    setStyle(container, 1);

    if (!scalable) return;

    let iterations = 0;
    let adjustedScale = computeScale(container);
    if (adjustedScale >= 1) return;

    let lowerBound = adjustedScale;
    let upperBound = 1;

    while (iterations < 10 && Math.abs(upperBound - lowerBound) > 0.02) {
      setStyle(container, adjustedScale);
      if (computeScale(container) < 1) {
        upperBound = adjustedScale;
      } else {
        lowerBound = adjustedScale;
      }
      adjustedScale = (upperBound + lowerBound) / 2;
      iterations++;
    }
  }, [content, scalable]);

  useEffect(() => {
    adjustScale();

    const debouncedAdjustScale = debounce(adjustScale, 50);

    const resizeObserver = new ResizeObserver(debouncedAdjustScale);
    if (contentRef.current && contentRef.current.parentNode) {
      resizeObserver.observe(contentRef.current.parentNode);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [scalable, content, adjustScale]);

  return (
    <div
      ref={contentRef}
      className={`unstyled-text content-view ${scroll ? "text-y-scroll" : ""} ${
        scalable ? "scalable" : ""
      }`}
      dangerouslySetInnerHTML={{
        __html: content ?? "",
      }}
      style={{ pointerEvents: blockContent ? "none" : "auto" }}
    />
  );
};
