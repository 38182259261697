import { Typography, Button } from "@mui/material";

const NotFound = () => {
  return (
    <>
      <h1>Page Not Found</h1>
      <Typography sx={{ my: 4 }}>
        Sorry, but we can&apos;t find the page you are looking for :(
      </Typography>
      <Button variant="outlined" href="/">
        Back to home
      </Button>
    </>
  );
};

export default NotFound;
