import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu } from "@mui/material";
import { UiColors } from "../../ui/styles";
import { useState } from "react";

export type MenuFunc = (
  onClose: () => void
) => React.ReactNode | React.ReactNode[];

type MoreButtonProps = {
  color?: UiColors;
  menu: MenuFunc;
  cssClass?: string;
};

export const MoreButton = ({ menu, color, cssClass }: MoreButtonProps) => {
  const [moreAnchor, setMoreAnchor] = useState<HTMLElement>(null);

  return (
    <>
      <IconButton
        size="small"
        color={color ?? "primary"}
        onClick={(e) => setMoreAnchor(e.currentTarget)}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        open={!!moreAnchor}
        anchorEl={moreAnchor}
        onClose={() => setMoreAnchor(null)}
        className={cssClass}
      >
        {menu(() => setMoreAnchor(null))}
      </Menu>
    </>
  );
};
