import { Dialog } from "@mui/material";
import { Transition } from "../../../components/Transitions";
import { ProductListExt } from "./ProductListExt";
import { ProductListExtOptions } from "../../../features/catalogs/product-list.ext";

export type ProductListExtModalProps = {
  isOpen?: boolean;
} & ProductListExtOptions;

export type OpenProductListExtModalFunc = (
  params: ProductListExtOptions
) => void;

export const ProductListExtModal = (props: ProductListExtModalProps) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      fullScreen
      TransitionComponent={Transition}
    >
      <ProductListExt {...props} />
    </Dialog>
  );
};
