import { FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { logSignIn } from "../../services/logging";
import {
  getDecodedToken,
  isLoggedIn,
  setAuthToken,
} from "../../features/accounts/auth.service";
import LoadingScreen from "../../components/LoadingScreen";
import { Button } from "@mui/material";

const ExternaLogin: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const error = searchParams.get("error");

  let errorMessage = error ? "Sign in Errror" : null;
  switch (error) {
    case "externalsigninerror":
      errorMessage = "External sign in error";
      break;
    case "notregistered":
      errorMessage = "User is not registered.";
      break;
    case "access_denied":
      errorMessage = "Access denied.";
      break;
  }

  useEffect(() => {
    if (error === "expired") {
      navigate("/login");
    }
    if (error) return;

    const token = searchParams.get("token");

    if (token) {
      setAuthToken(token);
      if (isLoggedIn()) {
        logSignIn(getDecodedToken()?.provider);
        const returnUrl = searchParams.get("path") ?? "/";
        window.location.href = returnUrl;
        return;
      }
    }
    navigate("/login");
  }, [error, navigate, searchParams]);

  return (
    <>
      {!error && <LoadingScreen title="signing in..." fullScreen />}
      {error && (
        <>
          <h1>{errorMessage}</h1>
          <Button variant="outlined" href="/login" sx={{ mt: 2 }}>
            Back to sign in
          </Button>
        </>
      )}
    </>
  );
};

export default ExternaLogin;
