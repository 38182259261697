import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DialogTitle,
  Box,
  Typography,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  TextField,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { grey } from "@mui/material/colors";
import { ImageModal, ImageModalParams, ImageModalType } from "./ImageModal";
import { useAtom } from "jotai";
import { fetchFileType, downloadFile } from "../../../features/data/file-util";
import { formatDateStr } from "../../../features/data/time";
import {
  combinePath,
  getImageUrl,
  ImageSelectOptions,
} from "../../../features/images/image";
import { useImages } from "../../../features/images/image-func";
import { imageDbAtom } from "../../../features/images/image.state";
import { useSnackbar } from "notistack";
import { renameImageDb } from "../../../features/images/image.service";
import { ProcessButton } from "../../../components/buttons/ProcessButton";
import { dataPatterns } from "../../../features/data/data-types";
import { sessionAtom } from "../../../features/accounts/account.state";

export const ImageViewModal = (params: ImageModalParams) => {
  return (
    <ImageModal
      open={!!params.options}
      title={params.options?.path}
      onClose={params.onClose}
    >
      <ImageViewContent {...params} />
    </ImageModal>
  );
};

export const ImageViewContent = ({
  options,
  onClose,
  openModal,
}: ImageModalParams) => {
  const [imgDimensions, setImageDimensions] = useState<{
    w: number;
    h: number;
  }>();
  const [imageDb, setImageDb] = useAtom(imageDbAtom);
  const [session] = useAtom(sessionAtom);
  const [imgType, setImgType] = useState<string>();
  const [renameOpen, setRenameOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const [processing, setProcessing] = useState(false);
  const [invalidName, setInvalidName] = useState(false);

  const { openImageEditor } = useImages();
  const { enqueueSnackbar } = useSnackbar();

  const onCloseView = useCallback(() => {
    setImageDimensions(null);
    onClose();
  }, [onClose]);

  const path = options?.path;
  const image = path ? imageDb.imageMap.get(path) : null;

  const imgUrl = useMemo(
    () => getImageUrl(imageDb.storageUrl, path),
    [imageDb.storageUrl, path]
  );

  const openImageModal = useCallback(
    (type: ImageModalType, override?: ImageSelectOptions) => {
      onCloseView();
      const opts = override ? { ...options, ...override } : options;
      openModal(opts, type);
    },
    [onCloseView, openModal, options]
  );

  useEffect(() => {
    const getType = async () => {
      const type = await fetchFileType(imgUrl, path);
      setImgType(type);
    };
    if (!path) {
      openImageModal("upload");
    } else {
      getType();
    }
  }, [imgUrl, openImageModal, path]);

  const renameImage = useCallback(async () => {
    if (!newName || !image || invalidName || newName === image.name) {
      setRenameOpen(false);
      return;
    }

    const newPath = combinePath(image.folder, newName);
    if (imageDb.imageMap.has(newPath)) {
      enqueueSnackbar("Image name already exists", {
        variant: "error",
      });
      return;
    }

    try {
      setProcessing(true);
      const db = await renameImageDb(imageDb, image.path, newName);
      setImageDb(db);
      enqueueSnackbar(`Image renamed to ${newName}`, {
        variant: "success",
      });
      onClose();
      options.onSelect?.(
        newPath,
        getImageUrl(session.account?.config.imageStorageUrl, newPath)
      );
    } catch {
      enqueueSnackbar("Error renaming image", {
        variant: "error",
      });
    } finally {
      setRenameOpen(false);
      setProcessing(false);
    }
  }, [
    enqueueSnackbar,
    image,
    imageDb,
    invalidName,
    newName,
    onClose,
    options,
    session.account?.config.imageStorageUrl,
    setImageDb,
  ]);

  const setImageName = useCallback((name: string) => {
    setNewName(name);
    setInvalidName(!dataPatterns.path.test(name));
  }, []);

  return (
    <>
      {options && (
        <>
          <DialogTitle>
            <Box sx={{ mr: "30px" }}>
              {image?.path ?? path}
              <Typography variant="caption" component="div">
                <Box
                  sx={{
                    display: "flex",
                    "& .date": { ml: 2 },
                    "& .size": { mr: 1, fontWeight: "bold" },
                  }}
                >
                  <div className="info">
                    <span className="size">
                      {image?.size > 0
                        ? (image.size / 1024).toFixed(0) + " KB"
                        : "N/A "}
                    </span>
                    <span className="ext">{imgType}</span>
                    <span className="date">
                      {formatDateStr(image?.modified)}
                    </span>
                  </div>

                  {imgDimensions && (
                    <Box sx={{ ml: "auto" }}>
                      dimensions:{" "}
                      <b>
                        {imgDimensions.w} x {imgDimensions.h}
                      </b>
                    </Box>
                  )}
                </Box>
              </Typography>
            </Box>
            <IconButton
              aria-label="close"
              onClick={onCloseView}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                "& img": { maxWidth: "100%", maxHeight: "70vh", m: "auto" },
              }}
            >
              <img
                src={imgUrl}
                alt=""
                onLoad={(e) => {
                  const img = e.target as HTMLImageElement;
                  if (img) {
                    setImageDimensions({
                      w: img.naturalWidth ?? img.width,
                      h: img.naturalHeight ?? img.height,
                    });
                  }
                }}
                onError={(e) => {
                  const img = e.target as HTMLImageElement;
                  img.alt = " Load error";
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            {options.canChange && (
              <Button onClick={() => openImageModal("select")}>
                Select another
              </Button>
            )}
            <Button
              onClick={() =>
                openImageModal("upload", { canSelectFolder: false })
              }
            >
              Re-upload
            </Button>
            <Button
              onClick={() => {
                onCloseView();
                openImageEditor(path, options.onSelect);
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                setImageName(image.name);
                setRenameOpen(true);
              }}
            >
              Rename
            </Button>
            <Button
              onClick={() => {
                downloadFile(image.url, image.path);
              }}
            >
              Download
            </Button>
          </DialogActions>
          <Dialog
            open={renameOpen}
            onClose={() => setRenameOpen(false)}
            maxWidth="xs"
          >
            <DialogTitle>Rename Image</DialogTitle>
            <DialogContent>
              <Alert severity="warning">
                Renaming an image will break any links to the image in products
                and catalogs
              </Alert>
              <Box>
                <TextField
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  required
                  margin="dense"
                  label="Image Name"
                  fullWidth
                  variant="standard"
                  value={newName}
                  onChange={(e) => {
                    setImageName(e.target.value);
                  }}
                  helperText={
                    invalidName
                      ? "Invalid image name. Use only letters and -"
                      : ""
                  }
                  error={invalidName}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setRenameOpen(false);
                }}
              >
                Cancel
              </Button>
              <ProcessButton
                label="Rename"
                disabled={!newName || invalidName}
                processing={processing}
                onClick={renameImage}
                color="error"
                variant="outlined"
              />
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};
