import { Box } from "@mui/material";
import { HtmlText } from "../../../components/HtmlText";
import { IconTypes } from "../../../components/icons.types";
import { CatalogBuilder } from "../../../features/catalogs/catalog.builder";
import { getPageItems } from "../../../features/catalogs/catalog.page";
import { CatalogPageRef } from "../../../features/catalogs/catalogs";
import { renderPageBackground, renderCatalogItem } from "./catalog.render";

type CatalogPagePreviewProps = {
  builder: CatalogBuilder;
  page: CatalogPageRef;
  scale?: number;
  isQuickPreview?: boolean;
  blockContent?: boolean;
};

export const CatalogPagePreview = ({
  builder,
  page,
  scale = 1,
  blockContent,
}: CatalogPagePreviewProps) => {
  if (!page) return null;

  const pageItems = getPageItems(builder, page);

  return (
    <Box
      className="page-preview"
      sx={{
        height: builder.board.h,
        width: builder.board.w,
        transform: "scale(" + scale + ")",
        transformOrigin: "0 0",
      }}
    >
      {renderPageBackground(page)}
      {page.headerHeight > 0 && (
        <Box
          style={{
            height: page.headerHeight,
            backgroundColor: page.headerBkgColor,
          }}
          className="page-part page-header"
        >
          <HtmlText content={page.headerContent} />
          {/* <div
            className="unstyled-text  content-view"
            dangerouslySetInnerHTML={{
              __html: page.headerContent,
            }}
          /> */}
        </Box>
      )}
      {page.pageItem?.di.PageType === "toc" && (
        <Box
          className="page-part"
          sx={{
            height: page.pageHeight,
            display: "flex",
            justifyContent: "center",
            svg: {
              mt: page.pageHeight / 8 + "px",
              fontSize: page.pageHeight / 2,
              color: "#aaa",
            },
          }}
        >
          <IconTypes.Toc />
        </Box>
      )}
      <Box
        style={{
          height: page.pageHeight,
        }}
        className="page-part page-body"
      >
        {builder?.context.activePageId >= 0 &&
          pageItems
            .filter((i) => i.di.Type !== "page")
            .map((item) => (
              <Box
                key={item.id}
                id={item.id}
                style={item.style}
                className="page-thumb-item"
              >
                {renderCatalogItem(item, false, blockContent)}
              </Box>
            ))}
      </Box>
      {page.footerHeight > 0 && (
        <Box
          style={{
            height: page.footerHeight,
            backgroundColor: page.footerBkgColor,
          }}
          className="page-part page-footer"
        >
          <HtmlText content={page.footerContent} />
          {/* <div
            className="unstyled-text  content-view"
            dangerouslySetInnerHTML={{
              __html: page.footerContent,
            }}
          /> */}
        </Box>
      )}
    </Box>
  );
};
