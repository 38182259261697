import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  Box,
  Tab,
  DialogContent,
  Typography,
  Chip,
  Button,
  FormControl,
  TextField,
  FormLabel,
  DialogActions,
} from "@mui/material";
import { useAtom } from "jotai";
import { useSearchParams } from "react-router-dom";
import { ImagePicker } from "../../../components/ImagePicker";
import { SelectFromList } from "../../../components/SelectFromList";
import { CloseButton } from "../../../components/buttons/CloseButton";
import { ProcessButton } from "../../../components/buttons/ProcessButton";
import { HtmlEditor } from "../../../components/remirror/RemirrorEditor";
import { useDialogs } from "../../../contexts/useDialogs";
import {
  EMPTY_GUID,
  validateValue,
  DataTypeName,
} from "../../../features/data/data-types";
import { formatDateStr } from "../../../features/data/time";
import {
  Product,
  createProduct,
  mapProduct,
  ProductField,
  refreshVariantField,
  changeCategory,
} from "../../../features/products/product";
import { useProducts } from "../../../features/products/product-func";
import {
  ProductEditOptions,
  OpenDeleteProductFunc,
} from "../../../features/products/product-modal-state";
import { saveProduct } from "../../../features/products/product.service";
import { productDbAtom } from "../../../features/products/product.state";
import { templateDbAtom } from "../../../features/templates/template.state";
import { useDbOperations } from "../../data-func";
import { VariantGrid } from "../grid/VariantGrid";
import { useVariantGrid } from "../grid/grid-variants";
import { useProductOps } from "../product-ops";
import { IconTypes } from "../../../components/icons.types";
import LoadingScreen from "../../../components/LoadingScreen";

interface ProductEditModalProps extends ProductEditOptions {
  onClose: () => void;
  openDeleteProducts: OpenDeleteProductFunc;
}

export const ProductEditModal = (props: ProductEditModalProps) => {
  if (!props) {
    return null;
  }
  return (
    <Suspense fallback={<LoadingScreen title="loading database" />}>
      <ProductEditModalInner {...props} />
    </Suspense>
  );
};

const ProductEditModalInner = ({
  productId,
  categoryId,
  collectionId,
  onClose,
  onChange,
  openDeleteProducts,
  shouldUpdateQueryString,
}: ProductEditModalProps) => {
  const [productDb, setProductDb] = useAtom(productDbAtom);
  const [templates] = useAtom(templateDbAtom);
  const [processingSave, setSaveProcessing] = useState(false);
  const [edit, setEdit] = useState<Product>();
  const [collectionIds, setCollectionIds] = useState<string[]>([]);
  const [tab, setTab] = useState("fields");
  const nameRef = useRef<HTMLInputElement>();
  const { processDb } = useDbOperations();
  const [errors, setErrors] = useState<Map<number, string>>(new Map());
  const { getVariantUpdates, addVariant } = useVariantGrid();
  const { isReadOnly, defaultCategory } = useProducts();
  const [searchParams, setSearchParams] = useSearchParams();
  const { productModalApi } = useDialogs();
  const { updateProductCollections } = useProductOps();

  useEffect(() => {
    const cr =
      productDb.categoryMap.get(categoryId) ??
      (!productId ? defaultCategory : null);
    const pr =
      !productId && cr
        ? createProduct(cr, EMPTY_GUID)
        : productDb.productMap.get(productId);
    setEdit(pr ? { ...pr.product } : null);
    setCollectionIds(
      pr?.collections?.map((c) => c.group.id) ??
        (collectionId ? [collectionId] : [])
    );
    setTab("fields");
    if (!productId)
      setTimeout(() => {
        nameRef.current?.focus();
      });

    if (shouldUpdateQueryString) {
      setSearchParams((s) => {
        if (productId && pr) {
          s.set("product", pr.product.code);
        } else {
          s.delete("product");
        }
        return s;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, productId]);

  const handleClose = useCallback(() => {
    searchParams.delete("product");
    setSearchParams(searchParams);
    onClose();
  }, [onClose, searchParams, setSearchParams]);

  const collections = useMemo(() => {
    return collectionIds?.map((id) => productDb.collectionMap.get(id));
    // const pr = productDb.productMap.get(productId);
    // return pr?.collections;
  }, [collectionIds, productDb.collectionMap]);

  const processSaveProduct = useCallback(async () => {
    processDb(
      async () => {
        return await saveProduct(edit);
      },
      (result) => {
        const productMap = new Map(productDb.productMap);
        const r = mapProduct(
          result.value,
          productDb.categoryMap,
          productDb.optionMap,
          productDb.storageUrl
        );
        productMap.set(r.product.id, r);

        setProductDb({
          ...productDb,
          productMap,
          version: productDb.version + 1,
        });

        setTimeout(() => {
          updateProductCollections(r.product.id, collectionIds, productDb);
        });

        onChange?.(r);
        handleClose();
      },
      setSaveProcessing,
      "Saved product " + edit.name,
      "Error saving product.",
      "products"
    );
  }, [
    collectionIds,
    edit,
    handleClose,
    onChange,
    processDb,
    productDb,
    setProductDb,
    updateProductCollections,
  ]);

  const validateField = useCallback(
    (field: ProductField, value) => {
      const error = validateValue(value, field.dataType);
      const map = new Map(errors);
      if (error) map.set(field.fieldId, error);
      else map.delete(field.fieldId);
      setErrors(map);
      return !error;
    },
    [errors]
  );

  const updateField = useCallback(
    (field: ProductField, value) => {
      const fields = [...edit.fields];
      const pf = fields.find((f) => f.fieldId === field.fieldId);
      if (pf) pf.value = value;
      else fields.push({ ...field, value });
      setEdit({ ...edit, fields });
    },
    [edit]
  );

  const updateVariant = useCallback(
    (newRow, oldRow) => {
      const updates = getVariantUpdates(newRow, oldRow);
      if (!updates.length) return newRow;

      updates.forEach((u) => {
        refreshVariantField(
          edit,
          +u.id,
          u.field,
          u.optionId,
          u.value,
          productDb.storageUrl
        );
      });

      setEdit({ ...edit });

      return newRow;
    },
    [edit, getVariantUpdates, productDb.storageUrl]
  );

  const deleteVariant = useCallback(
    (id: number) => {
      const variants = edit.variants.filter((v) => v.id !== id);
      setEdit({ ...edit, variants });
    },

    [edit]
  );

  const handleRevert = useCallback(() => {
    const pr = productDb.productMap.get(productId);
    if (pr) setEdit({ ...pr.product });
    setCollectionIds(pr?.collections?.map((c) => c.group.id) ?? []);
  }, [productDb.productMap, productId]);

  const handleCategoryClick = useCallback(() => {
    if (isReadOnly) return;
    productModalApi.openUpdateProduct({
      categoryId: edit.categoryId,
      target: "category",
      onChange: (categoryId) => {
        const pr = changeCategory(productDb, edit, categoryId);
        setEdit(pr.product);
      },
    });
  }, [edit, isReadOnly, productDb, productModalApi]);

  const handleCollectionClick = useCallback(() => {
    if (isReadOnly) return;
    productModalApi.openUpdateProduct({
      collectionIds,
      target: "collection",
      onChange: (ids) => setCollectionIds(ids),
    });
  }, [collectionIds, isReadOnly, productModalApi]);

  if (!edit) return null;

  const categoryRef = productDb.categoryMap.get(edit.categoryId);

  return (
    <Dialog
      open={!!edit}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="md"
      keepMounted
    >
      <DialogTitle>
        Edit product {edit?.name} <CloseButton onClose={handleClose} />
      </DialogTitle>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(_, newValue: string) => {
              setTab(newValue);
            }}
            sx={{
              mx: 2,
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Product Fields" value="fields" />
            <Tab label="Product Variants" value="variants" />
            <Tab label="Showroom & Cards" value="templates" />
          </TabList>
        </Box>{" "}
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            "& .MuiFormControl-root": { my: 1 },
          }}
        >
          <DialogContent sx={{ height: "70vh" }}>
            <TabPanel value="fields" sx={{ pt: 0 }}>
              <Box
                mb={1}
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Typography variant="caption" component="span">
                  <b>Modified:</b> {formatDateStr(edit.modified, true)}
                </Typography>
                <Typography variant="caption" component="span">
                  <b>Rank:</b> {edit.rank}
                </Typography>
                <Typography variant="caption" component="span">
                  <b>ID:</b> {productId ? edit.id : "NEW"}
                </Typography>
                {edit.syncWith && (
                  <Typography variant="caption" component="span">
                    <b>{edit.syncWith}:</b> {formatDateStr(edit.syncAt, true)}
                  </Typography>
                )}

                {edit.inStock && <Chip label="In Stock" size="small" />}
              </Box>
              <Box
                mb={1}
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Chip
                  label={categoryRef.fullName}
                  variant="outlined"
                  sx={{
                    borderColor: categoryRef.group.color,
                    borderRadius: 1,
                    mr: 2,
                  }}
                  size="small"
                  title="category"
                  onClick={handleCategoryClick}
                />
                <Typography variant="caption" component="span">
                  <b>Collections:</b>
                </Typography>
                {collections?.map((c) => (
                  <Chip
                    key={c.handle}
                    label={c.fullName}
                    size="small"
                    title="collection"
                    variant="outlined"
                    sx={{ borderColor: c.group.color }}
                    onClick={handleCollectionClick}
                  />
                ))}
                {!collections?.length && (
                  <Button onClick={handleCollectionClick} size="small">
                    + add
                  </Button>
                )}
              </Box>
              <div>
                <FormControl fullWidth>
                  <TextField
                    label="Product Code"
                    value={edit.code ?? ""}
                    onChange={(e) => setEdit({ ...edit, code: e.target.value })}
                    helperText="unique product code"
                  />
                </FormControl>
              </div>
              <div>
                <FormControl fullWidth>
                  <TextField
                    label="Product Name"
                    value={edit.name ?? ""}
                    required
                    onChange={(e) => setEdit({ ...edit, name: e.target.value })}
                    inputRef={nameRef}
                  />
                </FormControl>
              </div>

              {categoryRef.group.fields.map((cf) => {
                const pf = edit.fields.find(
                  (f) => f.fieldId === cf.fieldId
                ) ?? { ...cf, value: "" };
                switch (cf.dataType) {
                  case DataTypeName.Image:
                    return (
                      <Box my={2} key={cf.fieldId}>
                        <ImagePicker
                          label={cf.name}
                          path={pf.value}
                          setPath={(thumbnail) => updateField(pf, thumbnail)}
                        />
                      </Box>
                    );
                  case DataTypeName.RichText:
                    return (
                      <FormControl fullWidth key={cf.fieldId}>
                        <FormLabel component="legend">{cf.name}</FormLabel>
                        <HtmlEditor
                          value={pf.value}
                          onChange={(html) => updateField(pf, html)}
                          editorId={"product-" + cf.name + "-editor"}
                        />
                      </FormControl>
                    );
                }
                return (
                  <FormControl fullWidth key={cf.fieldId}>
                    <TextField
                      label={cf.name}
                      value={pf.value}
                      onChange={(e) => updateField(pf, e.target.value)}
                      onBlur={(event) => validateField(pf, event.target.value)}
                      helperText={errors.get(cf.fieldId)}
                      error={errors.has(cf.fieldId)}
                    />
                  </FormControl>
                );
              })}
            </TabPanel>
            <TabPanel
              value="variants"
              sx={{ pt: 0, height: "100%", minHeight: 100 }}
            >
              <VariantGrid
                product={edit}
                onUpdate={(newRow, oldRow) => updateVariant(newRow, oldRow)}
                onDelete={(row) => deleteVariant(+row.id)}
                onAdd={() => {
                  addVariant(edit);
                  setEdit({ ...edit });
                }}
                onReorder={(variants) => setEdit({ ...edit, variants })}
                toolbar={true}
                reorder={true}
                isReadOnly={isReadOnly}
              />
            </TabPanel>
            <TabPanel value="templates">
              <div>
                <FormControl fullWidth>
                  <TextField
                    label="Rank"
                    value={edit.rank ?? 0}
                    type="number"
                    onChange={(e) =>
                      setEdit({ ...edit, rank: +e.target.value })
                    }
                    inputProps={{ pattern: "[0-9]*" }}
                  />
                </FormControl>
              </div>
              <div>
                <SelectFromList
                  entries={templates.map((t) => ({
                    value: t.template.id,
                    name: t.template.name,
                  }))}
                  value={edit.itemDetailsTemplateId}
                  setValue={(itemDetailsTemplateId) =>
                    setEdit({ ...edit, itemDetailsTemplateId })
                  }
                  label="Detail Card"
                  defaultLabel="-- Default Card --"
                />
              </div>

              <div>
                <SelectFromList
                  entries={templates.map((t) => ({
                    value: t.template.id,
                    name: t.template.name,
                  }))}
                  value={edit.itemListTemplateId}
                  setValue={(itemListTemplateId) =>
                    setEdit({ ...edit, itemListTemplateId })
                  }
                  label="List Card"
                  defaultLabel="-- Default Card --"
                />
              </div>
            </TabPanel>
          </DialogContent>
        </Box>
      </TabContext>
      {!isReadOnly && (
        <DialogActions>
          <Box sx={{ display: "flex", mx: 2, width: "100%" }}>
            {productId && (
              <Box>
                <Button
                  onClick={() =>
                    openDeleteProducts({
                      ids: [edit.id],
                      title: edit.name,
                    })
                  }
                  color="error"
                  variant="outlined"
                  disabled={processingSave}
                >
                  <IconTypes.DeleteAction />
                </Button>
              </Box>
            )}
            <Box flexGrow={1} />

            {edit.id !== EMPTY_GUID && (
              <Button onClick={handleRevert} sx={{ mr: 1 }}>
                Revert
              </Button>
            )}
            <Button onClick={handleClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <ProcessButton
              processing={processingSave}
              onClick={processSaveProduct}
              label="Save"
              disabled={!edit?.name || errors.size > 0}
            />
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};
