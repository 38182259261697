import { Divider } from "@mui/material";
import { useActive, useCommands } from "@remirror/react";

import { CmTableExtension } from "../extensions/table-extension";

import BorderClearIcon from "@mui/icons-material/BorderClear";
import {
  CommandButton,
  DropdownButton,
  CommandMenuItem,
} from "@remirror/react-ui";

export const TableButtons = () => {
  const activeTable = useActive<CmTableExtension>().table();
  const commands = useCommands<CmTableExtension>();
  return (
    <>
      {!activeTable && (
        <CommandButton
          commandName="createTable"
          label="Create table"
          active={activeTable}
          enabled={true}
          icon="table2"
          onSelect={() =>
            commands.createTable({
              rowsCount: 2,
              columnsCount: 2,
            })
          }
        />
      )}
      {activeTable && (
        <DropdownButton
          aria-label="Modify table"
          icon="table2"
          color="secondary"
        >
          <CommandMenuItem
            commandName="addTableColumnAfter"
            label="Add column"
            enabled={true}
            icon="insertColumnRight"
            onSelect={commands.addTableColumnAfter}
          />

          <CommandMenuItem
            commandName="addTableRowAfter"
            label="Add row"
            enabled={true}
            icon="insertRowBottom"
            onSelect={commands.addTableRowAfter}
            onClick={() => {}}
          />
          <CommandMenuItem
            commandName="deleteTableColumn"
            label="Delete column"
            enabled={true}
            icon="deleteColumn"
            onSelect={commands.deleteTableColumn}
          />
          <CommandMenuItem
            commandName="deleteTableRow"
            label="Delete row"
            enabled={true}
            icon="deleteRow"
            onSelect={commands.deleteTableRow}
          />
          <CommandMenuItem
            commandName="deleteTable"
            label="Delete table"
            enabled={true}
            icon="deleteBinLine"
            onSelect={commands.deleteTable}
          />
          <Divider />
          <CommandMenuItem
            commandName="toggleTableBorder"
            label="Toggle border"
            enabled={true}
            icon={<BorderClearIcon fontSize="small" />}
            onSelect={commands.toggleTableBorder}
          />
          <Divider />
          <CommandMenuItem
            commandName="createTable"
            label="Add nested table"
            enabled={true}
            icon="table2"
            onSelect={() =>
              commands.createTable({
                rowsCount: 2,
                columnsCount: 2,
              })
            }
            displayDescription={false}
          />
        </DropdownButton>
      )}
    </>
  );
};
