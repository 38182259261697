import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { FixedPanel } from "../../../components/FixedPanel";
import { CreateItemAttrs } from "../../../features/catalogs/catalog.design";
import { ROOT_FOLDER, BlobImage } from "../../../features/images/image";
import { ImageScroll } from "../../images/ImageScroll";
import {
  ImageViewOptions,
  FolderSelect,
  ImageViewControls,
} from "../../images/image-components";
import { DesignerChange } from "../designer.board.api";
import { startElementDrag, endElementDrag } from "../designer.func";
import { IconTypes } from "../../../components/icons.types";
import { useDialogs } from "../../../contexts/useDialogs";
import { imageSortBy } from "../../../features/images/image-func";

export const ImageBar = ({
  onChange,
}: {
  onChange: (change: DesignerChange) => void;
}) => {
  const { openImageModal } = useDialogs();

  const [view, setView] = useState<ImageViewOptions>({
    filter: "",
    sort: imageSortBy[0].v,
    folder: ROOT_FOLDER,
  });

  const changeView = (v: ImageViewOptions) => {
    setView({ ...view, ...v });
  };

  const renderImages = (images: BlobImage[]) => {
    return images.map((i) => (
      <Box
        key={i.path}
        className="img-item element"
        draggable="true"
        onDragStart={(e) =>
          startElementDrag(e, { type: "image", content: i.path })
        }
        onDragEnd={endElementDrag}
        onClick={() => {
          onChange({
            type: "create-item",
            attrs: {
              type: "image",
              content: i.path,
              pos: "none",
            } as CreateItemAttrs,
          });
        }}
      >
        <Box className="img-area">
          <img
            src={i.thumb}
            alt=""
            onError={(e) => {
              const img = e.target as HTMLImageElement;
              img.alt = " Load error";
            }}
          />
        </Box>
        <Box className="bar">
          <div className="caption" title={i.name}>
            <div className="title">{i.name}</div>
          </div>
        </Box>
      </Box>
    ));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          py: 1,
          px: 2,
          //flexDirection: "column",
          gap: 1,
          justifyContent: "space-between",
        }}
      >
        <FolderSelect
          folder={view.folder}
          onChange={(f) => changeView({ folder: f })}
          label="Folder"
          sx={{ width: 100 }}
          //showAll={true}
        />
        <ImageViewControls view={view} onChange={changeView} align="right" />
        <Button
          color="primary"
          onClick={() => {
            openImageModal({ multi: true, folder: view.folder }, "upload");
          }}
          variant="outlined"
          title="Upload image"
          sx={{ minWidth: 40 }}
        >
          <IconTypes.Upload fontSize="small" />
        </Button>
      </Box>
      <Typography variant="caption" sx={{ px: 1, mb: 1 }}>
        Drag & drop image to a page
      </Typography>
      <FixedPanel>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
            display: "flex",
            flexWrap: "wrap",
            p: 1,
            "& .img-item": {
              width: 100,
              m: 0.5,
              position: "relative",
              "& .img-area": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: "4px",
                height: 120,
                "& img": {
                  maxWidth: "100%",
                  maxHeight: "100%",
                },
                cursor: "pointer",
              },
              "& .bar": {
                position: "absolute",
                bottom: 0,
                display: "none",
                alignItems: "center",
                width: "100%",
                "& .caption": {
                  pl: 1,
                  width: 100,
                  "& .title": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: 12,
                  },
                },
              },
              "&:hover": {
                "& .img-area": {
                  "& img": {
                    maxHeight: 80,
                    maxWidth: "90%",
                  },
                },
                "& .bar": {
                  display: "flex",
                },
              },
            },
          }}
        >
          <ImageScroll view={view} renderImages={renderImages} />
        </Box>
      </FixedPanel>
    </Box>
  );
};
