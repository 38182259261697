import { useCallback, useMemo, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Transition } from "../../components/Transitions";
import { Catalog } from "../../features/catalogs/catalogs";
import { CloseButton } from "../../components/buttons/CloseButton";
import { IconTypes } from "../../components/icons.types";
import { sessionAtom } from "../../features/accounts/account.state";
import { useAtom } from "jotai";
import { ShareLinkField } from "./ShareLinkField";
import { SelectFromList } from "../../components/SelectFromList";
import {
  catalogNavigation,
  CatalogNavigationType,
} from "../../features/catalogs/catalog.defs";

export type CatalogShareModalProps = {
  isOpen?: boolean;
  catalog: Catalog;
  onClose: () => void;
};

export type OpenCatalogShareModalFunc = (catalog: Catalog) => void;

export const CatalogShareModal = ({
  catalog,
  onClose,
  isOpen,
}: CatalogShareModalProps) => {
  const [session] = useAtom(sessionAtom);

  const [embedNav, setEmbedNav] = useState<CatalogNavigationType>("bullets");
  const [pages, setPages] = useState<string>();

  const c = catalog;

  const getLinkParams = useCallback(
    (partial = false) => {
      if (!pages) return "";
      else return (partial ? "&" : "?") + "pages=" + pages;
    },
    [pages]
  );

  const embedText = useMemo(
    () =>
      `<iframe src="${session.account.config.catalogPath}/embed/catalogs/${
        c.permalink
      }.html?nav=${embedNav}${getLinkParams(
        true
      )}" width="800px" height="1000px" frameborder="0" allowfullscreen></iframe>`,
    [c.permalink, embedNav, getLinkParams, session.account.config.catalogPath]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      TransitionComponent={Transition}
    >
      <DialogTitle>
        Share {c.name} <CloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        {c.status !== "Published" && (
          <Alert severity="error" sx={{ mb: 2 }}>
            A catalog is in a <b>Draft</b>. Publish a catalog before sharing
          </Alert>
        )}
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            "& .MuiFormControl-root": { my: 1 },
          }}
        >
          <ShareLinkField
            id="html-link"
            label="HTML"
            path={c.catalogPath + ".html" + getLinkParams()}
          />
          <ShareLinkField
            id="pdf-link"
            label="PDF"
            path={c.catalogPath + ".pdf" + getLinkParams()}
          />

          <ShareLinkField
            id="download-link"
            label="Download"
            path={c.catalogPath + ".pdf?download=true" + getLinkParams(true)}
            openIcon={<IconTypes.Download />}
            openTitle="Download PDF"
          />

          <Box>
            <Alert severity="info" sx={{ mb: 2 }}>
              Embed this catalog into <b>your website</b>{" "}
              <i>(paid plans only)</i>. Copy and paste the code into the HTML of
              your site.
            </Alert>
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                id="embed-html"
                label="EmbedH HTML"
                multiline
                rows={3}
                value={embedText}
                onFocus={(e) => e.target.select()}
                onMouseUp={() => false}
                sx={{ mb: 2, flexGrow: 1 }}
              />
              <div>
                <SelectFromList
                  label="Catalog Navigation"
                  entries={catalogNavigation}
                  value={embedNav}
                  setValue={(v) => setEmbedNav(v as CatalogNavigationType)}
                />
              </div>
            </Box>
          </Box>
          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary expandIcon={<IconTypes.ExpandMore />}>
              <Typography>Advanced Settings</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl variant="standard" fullWidth>
                <FormLabel component="legend">
                  Limit pages{" "}
                  <Link
                    href="https://help.catalogmachine.com/getting-started/share-your-catalog"
                    target="help"
                  >
                    Learn more
                  </Link>
                </FormLabel>
                <TextField
                  label="Pages"
                  size="small"
                  value={pages}
                  onChange={(e) => setPages(e.target.value)}
                  helperText="e.g. 5 or 1,2 or 4-6 or 1,6-10,2,3-5 (any page order and ranges work)"
                />
              </FormControl>
              <ShareLinkField
                id="rss-link"
                label="RSS"
                path={session.account.config.catalogPath + "/catalogs/rss"}
                helperText="RSS feed for public catalogs to provide update for your customers"
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
