export const copyTextToClipboard = (text: string, elemId?: string) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(
      function () {},
      function (err) {
        console.error("Could not copy text: ", err);
      }
    );
  }
  if (elemId) {
    const e = window.document.getElementById(elemId) as HTMLInputElement;
    if (e && e.select) {
      e.select();
      e.setSelectionRange(0, 99999);
    }
  }
};

const siteName = "Catalog Machine";
const maxTitleLength = 60 - siteName.length - 3;
export const changePageTitle = (title: string, section?: string) => {
  let fullTitle = "";

  if (section) {
    fullTitle += `${section} - `;
  }

  fullTitle += title;

  if (fullTitle.length > maxTitleLength) {
    fullTitle = fullTitle.substring(0, maxTitleLength - 1) + "…";
  }

  fullTitle += " | Catalog Machine";

  document.title = fullTitle;
};
