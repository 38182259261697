import { useCallback, useEffect, useState } from "react";

export const debounce = (func, wait: number) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const useDebouncedEffect = (
  effect,
  delay,
  deps,
  skipFirstLoad = false
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, deps);
  const [firstLoad, setFirstLoad] = useState(skipFirstLoad);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, delay]);
};

export default useDebouncedEffect;
