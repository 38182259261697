export const NEED_UPGRADE_ERROR = "need_upgrade";

export class AppError extends Error {
  displayed: boolean;
  constructor(message: string, displayed = false) {
    super(message);
    this.name = "AppError";
    this.displayed = displayed;
  }
}
