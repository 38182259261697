import {
  CatalogData,
  getDefaultProductTemplateRoot,
} from "../catalogs/catalog.func";
import { ALL_PRODUCTS } from "../catalogs/catalog.products";
import { CatalogItem, CatalogItemRef } from "../catalogs/catalogs";
import { initProductListExt } from "../catalogs/product-list.ext";
import { LayoutItem } from "./layouts";

interface ILayoutConverter {
  convert: (
    item: LayoutItem,
    props: CatalogItem,
    data: CatalogData
  ) => CatalogItem;
  toLayout: (item: CatalogItemRef, li: LayoutItem) => LayoutItem;
}

const LayoutTextConverter = (): ILayoutConverter => {
  return {
    convert: function (item, props) {
      props.Body = item.text;
      props.Extension = item.Extension;
      if (item.subtype === "product" && !props.ItemId) {
        props.Sink = "text";
      }
      if (props.Extension) {
        const ext = initProductListExt(props.Extension);
        if (ext?.productSource === ALL_PRODUCTS) {
          ext.productSource = null;
          props.Extension = JSON.stringify(ext);
        }
      }
      return props;
    },
    toLayout: function (item, li) {
      li.text = item.di.Body;
      li.Extension = item.di.Extension;
      if (item.di.ItemId) {
        li.subtype = "product";
      }
      return li;
    },
  };
};

const LayoutImageConverter = (): ILayoutConverter => {
  return {
    convert: function (item, props) {
      props.ImgPosition = item.position;
      if (!item.src && !props.ItemId) props.Sink = "image";
      props.Body = item.field || item.text || item.src;
      return props;
    },
    toLayout: function (item, li) {
      li.position = item.di.ImgPosition;
      if (item.di.ItemId) {
        li.field = item.di.Body;
      } else {
        li.src = item.di.Sink ? undefined : item.di.Body;
        li.text = item.di.Sink ? item.di.Body : undefined;
      }
      return li;
    },
  };
};

const LayoutTemplateConverter = (): ILayoutConverter => {
  return {
    convert: function (item, props, data) {
      let template = data.templates.find(
        (t) => t.template.id === item.templateId
      );
      if (!template) {
        if (item.th && item.tw)
          template = data.templates.find((t) => {
            return (
              t.template.width === item.tw && t.template.height === item.th
            );
          });
        else template = getDefaultProductTemplateRoot(data.templates);
      }
      if (template) props.TemplateId = template.template.id;
      if (!props.ItemId) props.Sink = "template";
      return props;
    },
    toLayout: function (item, li) {
      li.templateId = item.di.TemplateId;
      if (item.info.template) {
        li.tw = item.info.template.template.width;
        li.th = item.info.template.template.height;
      }
      return li;
    },
  };
};

const convertBg = (item: LayoutItem, props: CatalogItem) => {
  props.Prime = item.Prime;
  props.Color = item.Color;
  props.BorderColor = item.BorderColor;
  props.BorderRadius = item.BorderRadius || 0;
  props.BorderWidth = item.BorderWidth;
  props.BorderStyle = item.BorderStyle;
  return props;
};

const LayoutBgConverter = (): ILayoutConverter => {
  return {
    convert: convertBg,
    toLayout: function (item, li) {
      convertBg(item.di, li as CatalogItem);
      return li;
    },
  };
};

const LayoutIconConverter = (): ILayoutConverter => {
  return {
    convert: function (item, props) {
      props.Body = item.text;
      return props;
    },
    toLayout: function (item, li) {
      li.text = item.di.Body;
      return li;
    },
  };
};

export const LayoutConvertors: { [key: string]: ILayoutConverter } = {
  text: LayoutTextConverter(),
  bg: LayoutBgConverter(),
  icon: LayoutIconConverter(),
  image: LayoutImageConverter(),
  template: LayoutTemplateConverter(),
};
