import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { CloseButton } from "../buttons/CloseButton";
import { IconTypes } from "../icons.types";
import {
  getSelectionEntry,
  ListValueType,
  SelectEntryType,
} from "../selection";

interface Props<T extends ListValueType> {
  open: boolean;
  title: string;
  entries: SelectEntryType<T>;
  values: T[];
  setValues: (values: T[]) => void;
  multiple?: boolean;
  defaultLabel?: string;
  onClose: () => void;
  onSave?: () => void;
  onEdit?: (value: T) => void;
  currentLabel?: string;
  availableLabel?: string;
  onNew?: () => void;
}

export const SelectionDialog = <T extends ListValueType>({
  open,
  title,
  entries,
  values,
  setValues,
  multiple,
  onClose,
  defaultLabel,
  currentLabel,
  availableLabel,
  onSave,
  onNew,
  onEdit,
}: Props<T>) => {
  const list = entries.map((e) => getSelectionEntry(e, defaultLabel));
  const currentSelection = list.filter((e) => values.includes(e.value as T));
  const availableSelection = list.filter((e) => !values.includes(e.value as T));
  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        {title}
        {onNew && (
          <Button
            color="primary"
            onClick={onNew}
            variant="outlined"
            size="small"
            sx={{ ml: 3 }}
          >
            + New
          </Button>
        )}
        <CloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent
        sx={{
          "& .MuiListItem-root": {
            py: 0,
            "&:hover": {
              "& button": { visibility: "visible" },
              "& .action": { visibility: "visible" },
            },
            "& button": { visibility: "hidden" },
            "& .action": {
              visibility: "hidden",
              color: "text.secondary",
              mr: 2,
            },
          },
        }}
      >
        {currentSelection.length > 0 && (
          <Typography variant="caption">{currentLabel ?? "Current"}</Typography>
        )}
        <List sx={{ maxHeight: "30vh", overflow: "auto", mb: 1 }}>
          {currentSelection.map((item) => {
            return (
              <ListItem
                key={item.value}
                secondaryAction={
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {onEdit && (
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => onEdit(item.value as T)}
                      >
                        <IconTypes.Edit />
                      </IconButton>
                    )}
                  </Box>
                }
              >
                <ListItemButton
                  onClick={() => {
                    if (multiple)
                      setValues(values.filter((v) => v !== item.value) as T[]);
                  }}
                >
                  {multiple && <IconTypes.RemoveAction className="action" />}
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        {availableSelection.length > 0 && (
          <Typography variant="caption">
            {availableLabel ?? "Available"}
          </Typography>
        )}
        <List sx={{ maxHeight: "40vh", overflow: "auto" }}>
          {availableSelection.map((item) => {
            return (
              <ListItem
                key={item.value}
                secondaryAction={
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {onEdit && (
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => onEdit(item.value as T)}
                      >
                        <IconTypes.Edit />
                      </IconButton>
                    )}
                  </Box>
                }
              >
                <ListItemButton
                  onClick={() =>
                    setValues(
                      (multiple ? [...values, item.value] : [item.value]) as T[]
                    )
                  }
                >
                  {multiple && <IconTypes.AddAction className="action" />}
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      {onSave && (
        <DialogActions>
          <Button onClick={onSave}>Save</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
