import { useSnackbar, SnackbarMessage } from "notistack";
import { useDialogs } from "../contexts/useDialogs";
import { EntityType } from "../features/data/data-types";
import {
  OperationResult,
  reportApiError,
  reportApiWarnings,
} from "../services/apiClient";
import { NEED_UPGRADE_ERROR } from "../services/errors";
import { logError } from "../services/logging";

export type DbOperation<T> = () => Promise<OperationResult<T>>;

export const useDbOperations = () => {
  const { enqueueSnackbar } = useSnackbar();
  const d = useDialogs();

  const processDb = async <T>(
    operation: DbOperation<T>,
    onSuccess: (result: OperationResult<T>) => void,
    setProcessing: (on: boolean) => void,
    successMessage?: SnackbarMessage,
    errorMesssage?: SnackbarMessage,
    type?: EntityType,
    onError?: (error: string, result: OperationResult<T>) => void
  ) => {
    let result: OperationResult<T> = null;
    try {
      setProcessing(true);
      result = await operation();

      if (result.success) {
        if (successMessage) {
          enqueueSnackbar(successMessage, {
            variant: "success",
          });
        }

        const apiWarning = reportApiWarnings(result);
        if (apiWarning) {
          enqueueSnackbar(apiWarning, {
            variant: "warning",
          });
        }

        onSuccess(result);
      }
      if (!result.success) {
        if (result.errorCode === NEED_UPGRADE_ERROR) {
          d?.openUpgradeModal(type);
        } else {
          const apiError = reportApiError(result);
          if (errorMesssage) {
            enqueueSnackbar(errorMesssage + " " + apiError, {
              variant: "error",
            });
          }
          onError?.(apiError, result);
        }
      }
    } catch (error) {
      if (errorMesssage) {
        enqueueSnackbar(errorMesssage, {
          variant: "error",
        });
      }
      logError(error);
      throw error;
    } finally {
      setProcessing(false);
    }
    return result;
  };
  return { processDb };
};
