import { isValidElement } from "react";
import { Box, IconButton, Link } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  useGridApiContext,
  GridRenderCellParams,
  useGridSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
} from "@mui/x-data-grid-pro";
import { ImageModalType } from "../../images/modals/ImageModal";

export const NoProductsOverlay = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div>No products found</div>
    </Box>
  );
};

export const CustomDetailPanelToggle = (params) => {
  const apiRef = useGridApiContext();
  const { id }: Pick<GridRenderCellParams, "id"> = params;
  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector
  );

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = isValidElement(contentCache[id]);
  const isExpanded =
    hasDetail && apiRef.current.getExpandedDetailPanels().some((r) => r === id);
  return hasDetail ? (
    <IconButton
      size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? "Close" : "Open"}
    >
      <ExpandMoreIcon
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        fontSize="inherit"
      />
    </IconButton>
  ) : null;
};

interface ImageCellProps {
  className: string;
  thumb: string;
  onClick?: (type: ImageModalType) => void;
  hideSelect?: boolean;
}

export const ImageCell = ({
  thumb,
  onClick,
  className,
  hideSelect,
}: ImageCellProps) => {
  return (
    <>
      {thumb && (
        <Box className={className} onClick={() => onClick?.("view")}>
          <img
            alt=""
            src={thumb}
            onError={(e) => {
              const img = e.target as HTMLImageElement;
              img.alt = "error";
            }}
          />
        </Box>
      )}
      {!thumb && !hideSelect && (
        <Link
          component="button"
          variant="caption"
          onClick={(e) => {
            e.preventDefault();
            onClick?.("select");
          }}
        >
          Select
        </Link>
      )}
    </>
  );
};
