import { forwardRef, useImperativeHandle, useState } from "react";
import { Box } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageEditor from "filepond-plugin-image-editor";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css";

import "filepond/dist/filepond.min.css";
import "./filepond.scss";

import { ProcessServerConfigFunction } from "filepond";
import {
  legacyDataToImageState,
  openEditor,
  createDefaultImageReader,
  createDefaultImageWriter,
  processImage,
  getEditorDefaults,
} from "@pqina/pintura";
import "@pqina/pintura/pintura.css";

import { useDialogs } from "../contexts/useDialogs";
import { ImageSelectOptions, combinePath } from "../features/images/image";
import { NEED_UPGRADE_ERROR } from "../services/errors";
import { uploadFile } from "../features/data/file-util";

registerPlugin(
  //FilePondPluginImageExifOrientation,
  //FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  //FilePondPluginImageCrop,
  //FilePondPluginImageResize,
  //FilePondPluginImageTransform,
  FilePondPluginImageEditor,
  FilePondPluginFilePoster
);

interface FileUploadParams {
  options: ImageSelectOptions;
  onChange: (count: number) => void;
  onUploadFinish: (success: boolean) => void;
  onFileUpload: (path: string, fileSize: number, error) => void;
  minHeight?: string;
}

export type FileUploadRef = {
  upload: () => void;
  reset: () => void;
};

export const FileUpload = forwardRef<FileUploadRef, FileUploadParams>(
  (
    {
      onChange,
      onUploadFinish,
      onFileUpload,
      options,
      minHeight,
    }: FileUploadParams,
    ref: React.MutableRefObject<FileUploadRef>
  ) => {
    const [pond, setPond] = useState<FilePond | null>();

    const { openUpgradeModal } = useDialogs();
    useImperativeHandle(ref, () => ({
      upload: () => {
        pond?.processFiles();
      },
      reset: () => {
        pond?.removeFiles();
      },
    }));

    const processHandler: ProcessServerConfigFunction = async (
      _fieldName,
      file,
      _metadata,
      load,
      error,
      progress,
      abort
    ) => {
      const fileName = options.path
        ? options.path
        : combinePath(options.folder, file.name);
      const onload = (request) => {
        if (request.status >= 200 && request.status < 300) {
          load(request.responseText);
        } else {
          if (request.responseText === NEED_UPGRADE_ERROR) {
            openUpgradeModal("images");
          }
          error("upload error");
          onUploadFinish(false);
        }
      };
      const onprogress = (e) => {
        progress(e.lengthComputable, e.loaded, e.total);
      };
      await uploadFile(
        "image",
        file,
        fileName,
        null,
        onload,
        onprogress,
        abort
      );
    };

    return (
      <Box
        sx={{
          "& .filepond--wrapper .filepond--drop-label": {
            minHeight: pond?.getFiles()?.length > 0 ? 150 : minHeight || 150,
          },
        }}
        className={options.multi ? "multi-upload" : null}
      >
        <FilePond
          ref={(ref) => setPond(ref)}
          name="file"
          allowMultiple={true}
          allowImageEditor={true}
          maxFiles={options.multi ? 50 : 1}
          instantUpload={false}
          onupdatefiles={(fileItems) => {
            onChange(fileItems.length);
          }}
          onprocessfiles={() => onUploadFinish(true)}
          onprocessfile={(error, file) => {
            onFileUpload(file.serverId, file.fileSize, error);
          }}
          server={{
            process: processHandler,
          }}
          acceptedFileTypes={["image/*"]}
          //filePosterMaxHeight={256}
          // FilePond Image Editor plugin properties
          imageEditor={{
            // Maps legacy data objects to new imageState objects (optional)
            legacyDataToImageState: legacyDataToImageState,

            // Used to create the editor (required)
            createEditor: openEditor,

            // Used for reading the image data. See JavaScript installation for details on the `imageReader` property (required)
            imageReader: [
              createDefaultImageReader,
              {
                // createDefaultImageReader options here
              },
            ],

            // Can leave out when not generating a preview thumbnail and/or output image (required)
            imageWriter: [
              createDefaultImageWriter,
              {
                // We'll resize images to fit a 512 × 512 square
                //   targetSize: {
                //     width: 512,
                //     height: 512,
                //   },
              },
            ],

            // Used to poster and output images, runs an invisible "headless" editor instance.
            imageProcessor: processImage,

            // Pintura Image Editor options
            editorOptions: {
              // Pass the editor default configuration options
              ...getEditorDefaults(),

              // This will set a square crop aspect ratio
              //imageCropAspectRatio: 1,
            },
          }}
        />
      </Box>
    );
  }
);

FileUpload.displayName = "FileUpload";
