import { useAtom } from "jotai";
import { useCallback, useState } from "react";
import { catalogDbAtom } from "../../features/catalogs/catalog.state";
import { Catalog } from "../../features/catalogs/catalogs";
import { storeDbAtom } from "../../features/stores/store.state";
import { CatalogPublishModal } from "./CatalogPublishDialog";
import {
  CatalogSelectModalParams,
  OpenCatalogSelectModalFunc,
  CatalogSelectModal,
} from "./CatalogSelectDialog";
import { CatalogShareModal } from "./CatalogShareDialog";

export const useCatalogShareModal = () => {
  const [catalog, setCatalog] = useState<Catalog>();

  const openCatalogShareModal = (c: Catalog) => setCatalog(c);
  const renderCatalogShareModal = useCallback(() => {
    if (!catalog) return null;

    return (
      <CatalogShareModal
        catalog={catalog}
        isOpen={!!catalog}
        onClose={() => setCatalog(null)}
      />
    );
  }, [catalog]);

  return { renderCatalogShareModal, openCatalogShareModal };
};

export const useCatalogSelectModal = () => {
  const [selectParams, setSelectParams] = useState<CatalogSelectModalParams>();
  const [catalogs] = useAtom(catalogDbAtom);

  const openCatalogSelectModal: OpenCatalogSelectModalFunc = (params) => {
    setSelectParams(params);
  };

  const renderCatalogSelectModal = useCallback(() => {
    if (!selectParams) return null;

    return (
      <CatalogSelectModal
        isOpen={!!selectParams}
        onClose={() => setSelectParams(null)}
        catalogs={catalogs}
        {...selectParams}
      />
    );
  }, [catalogs, selectParams]);

  return { renderCatalogSelectModal, openCatalogSelectModal };
};

export const useCatalogPublishModal = (
  onChange: (catalog: Catalog) => void
) => {
  const [catalog, setCatalog] = useState<Catalog>();
  const [stores] = useAtom(storeDbAtom);

  const openCatalogPublishModal = (c: Catalog) => setCatalog(c);

  const renderCatalogPublishModal = useCallback(() => {
    if (!catalog) return null;

    return (
      <CatalogPublishModal
        catalog={catalog}
        isOpen={!!catalog}
        onClose={() => setCatalog(null)}
        onChange={onChange}
        stores={stores}
      />
    );
  }, [catalog, onChange, stores]);

  return { renderCatalogPublishModal, openCatalogPublishModal };
};
