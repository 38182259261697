import { jwtDecode } from "jwt-decode";
import { AppSession, DecodedToken, ExternalUser } from "./account";
import apiClient, {
  BaseOperationResult,
  OperationResult,
} from "../../services/apiClient";
import {
  clearUserAuthLogging,
  logSignIn,
  setUserAuthLogging,
} from "../../services/logging";
import { ResetPasswordData } from "./account.data";

export const logout = () => {
  setAuthToken();
  window.location.href = "/login";
};

export const getAuthToken = () => {
  return localStorage.getItem("access_token");
};

export const setAuthToken = (token?: string) => {
  token
    ? localStorage.setItem("access_token", token)
    : localStorage.removeItem("access_token");
};

export const getDecodedToken = (token?: string): DecodedToken => {
  const t = token ?? getAuthToken();
  return t ? jwtDecode(t) : null;
};

export const authenticate = async (request, admin) => {
  const op = admin ? "auth/admin-login" : "auth/authenticate";
  const res = await apiClient.post<AppSession>(op, request);
  if (res.data.succeeded) {
    setAuthToken(res.data.token);
    logSignIn(res.data.provider);
    setUserAuthLogging(res.data);
  }
  return res.data;
};

export const signup = async (request) => {
  const res = await apiClient.post<AppSession>("auth/signup", request);
  if (res.data.succeeded) {
    setAuthToken(res.data.token);
    logSignIn(res.data.provider);
  }
  return res.data;
};

export const isLoggedIn = () => {
  const token = getAuthToken();
  if (token) {
    const currentTime = new Date().getTime() / 1000;
    if (currentTime < getDecodedToken()?.exp) {
      return true;
    }
  }
  return false;
};

export const isSuperuser = () => {
  const token = getAuthToken();
  if (token) {
    if (getDecodedToken()?.su === "True") {
      return true;
    }
  }
  return false;
};

export const loadAuthSession = async () => {
  if (isLoggedIn()) {
    try {
      const res = await apiClient.get<AppSession>("auth/extend-session");
      if (res.data?.succeeded) {
        setAuthToken(res.data.token);
        setUserAuthLogging(res.data);
        return res.data;
      }
      logout();
    } catch {
      logout();
      clearUserAuthLogging();
      return null;
    }
  }
  return null;
};

export const loadExtUser = async (token: string) => {
  const res = await apiClient.get<ExternalUser>(
    "auth/external-user?token=" + token
  );
  return res.data as ExternalUser;
};

export const switchAccount = async (id: number) => {
  const res = await apiClient.post<AppSession>("auth/switch?id=" + id);
  if (res.data.succeeded) {
    setAuthToken(res.data.token);
    return true;
  }
  return false;
};

export const forgotPassword = async (
  email: string
): Promise<OperationResult<boolean>> => {
  const response = await apiClient.post<BaseOperationResult>(
    "/auth/forgot-password",
    { email }
  );
  return response.data;
};

export const resetPassword = async (
  data: ResetPasswordData
): Promise<OperationResult<boolean>> => {
  const response = await apiClient.post<BaseOperationResult>(
    "/auth/reset-password",
    data
  );
  return response.data;
};

export const joinAccount = async (
  email: string,
  code: string
): Promise<AppSession> => {
  const res = await apiClient.post<AppSession>("/auth/join", { email, code });
  if (res.data.succeeded && !res.data.requiresSignup) {
    setAuthToken(res.data.token);
    logSignIn(res.data.provider);
  }
  return res.data;
};

export const createAccount = async (name: string) => {
  const res = await apiClient.post<AppSession>("account/create-account", {
    name,
  });
  if (res.data.succeeded) {
    setAuthToken(res.data.token);
    return null;
  }
  return res.data.message;
};
