import axios from "axios";
import { getAuthToken, logout } from "../features/accounts/auth.service";
import { logError } from "./logging";
import { NEED_UPGRADE_ERROR } from "./errors";

export const apiUrl = import.meta.env.VITE_API_URL + "/api/v1";

export type ErrorCodeType = typeof NEED_UPGRADE_ERROR;

export type BaseOperationResult = {
  success: boolean;
  message?: string;
  errors?: string[];
  warnings?: string[];
  errorCode?: ErrorCodeType;
};

export type OperationResult<T> = {
  value?: T;
} & BaseOperationResult;

export type BatchOperationResult<T> = {
  failed: OperationResult<T>[];
  processed: string[];
  inserted: { [key: string]: T };
} & OperationResult<T>;

export const reportApiError = <T>(result: OperationResult<T>) => {
  const h = result.message ?? "";
  let e = "";
  if (Array.isArray(result.errors) && result.errors.length > 0) {
    e = result.errors.join(" ");
  }
  return h + (h && e ? ": " : "") + e;
};

export const reportApiWarnings = <T>(result: OperationResult<T>) => {
  let e = "";
  if (Array.isArray(result.warnings) && result.warnings.length > 0) {
    e = result.warnings.join(" ");
  }
  return e;
};

const instance = axios.create({
  baseURL: apiUrl,
});

instance.interceptors.request.use(
  async (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    logError(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      logout();
    } else {
      logError(error);
    }
    return Promise.reject(error);
  }
);

export default instance;
