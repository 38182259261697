import { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import {
  TextElement,
  ProductImageElement,
  BackgroundElement,
} from "../components/Elements";
import { ImageBar } from "../components/ImageBar";
import { DesignerChange } from "../designer.board.api";
import { CreateItemAttrs } from "../../../features/catalogs/catalog.design";
import { FixedPanel } from "../../../components/FixedPanel";

enum TabIndex {
  Elements,
  Images,
}

type Props = {
  onChange: (change: DesignerChange) => void;
};

export const TemplateSidebarElements = ({ onChange }: Props) => {
  const [tab, setTab] = useState(TabIndex.Elements);

  const handleClick = (attrs: CreateItemAttrs) =>
    onChange({ type: "create-item", attrs });

  return (
    <FixedPanel overflow="auto">
      <Box sx={{ mb: 3 }}>
        <Tabs value={tab} onChange={(_e, v) => setTab(v)} sx={{ px: 1, mb: 2 }}>
          <Tab label="Elements" />
          <Tab label="Images" />
        </Tabs>
        {tab === TabIndex.Elements && (
          <Box sx={{ display: "flex", mt: 3, gap: 2, px: 2, flexWrap: "wrap" }}>
            <TextElement content="<div>{{Name}}</div>" onClick={handleClick} />
            <ProductImageElement onClick={handleClick} />
            <BackgroundElement onClick={handleClick} />
          </Box>
        )}
        {tab === TabIndex.Images && <ImageBar onChange={onChange} />}
      </Box>
    </FixedPanel>
  );
};
