import { useCallback, useMemo, useState } from "react";
import { Box, Button } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridRowOrderChangeParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAtom } from "jotai";
import { WarningDialog } from "../../../components/dialogs/WarningDialog";
import { swapEntries } from "../../../features/data/data-types";
import { Category, ProductOption } from "../../../features/products/product";
import { productDbAtom } from "../../../features/products/product.state";
import { OptionAddDialog } from "./OptionModal";

interface CategoryOptionsProps {
  category: Category;
  onChange: (category: Category) => void;
}

export const CategoryOptions = ({
  category,
  onChange,
}: CategoryOptionsProps) => {
  const [optionAddShow, setOptionAdd] = useState(false);

  const [toDeleteOption, setDeleteOption] = useState<ProductOption>();

  const [productDb] = useAtom(productDbAtom);

  const apiOptionRef = useGridApiRef();

  const deleteOption = useCallback(
    (id) => {
      const options = category.options.filter((o) => o !== id);
      onChange({ ...category, options });
      setDeleteOption(null);
    },
    [category, onChange]
  );

  const optionColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Option",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "actions",
        type: "actions",
        width: 35,
        getActions: (params) => {
          const opt = productDb.optionMap.get(+params.id);
          return opt
            ? [
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete option"
                  key="1"
                  onClick={() => {
                    setDeleteOption(opt);
                  }}
                />,
              ]
            : [];
        },
      },
    ],
    [productDb.optionMap]
  );

  const optionToRow = useCallback(
    (optionId: number) => {
      const opt = productDb.optionMap.get(optionId);
      return { id: optionId, __reorder__: opt.name, ...opt };
    },
    [productDb.optionMap]
  );

  const optionRows = useMemo(() => {
    return category?.options.map((id) => optionToRow(id)) ?? [];
  }, [category?.options, optionToRow]);

  const handleOptionOrderChange = useCallback(
    (params: GridRowOrderChangeParams) => {
      const options = swapEntries(
        category.options,
        params.oldIndex,
        params.targetIndex
      );
      onChange({ ...category, options });
    },
    [category, onChange]
  );

  return (
    <Box>
      <Box sx={{ mb: 1, minHeight: 100 }}>
        <Button onClick={() => setOptionAdd(true)} size="small">
          + Add option
        </Button>
      </Box>
      {category.options.length > 0 && (
        <DataGridPro
          apiRef={apiOptionRef}
          rows={optionRows}
          columns={optionColumns}
          rowReordering
          onRowOrderChange={handleOptionOrderChange}
          autoHeight
          hideFooter
          //experimentalFeatures={{ newEditingApi: true }}
        />
      )}
      <WarningDialog
        open={!!toDeleteOption}
        onClose={() => {
          setDeleteOption(null);
        }}
        onConfirm={() => deleteOption(toDeleteOption.optionId)}
        title={`Remove option ${toDeleteOption?.name ?? ""}?`}
        action="Remove"
        color="error"
      >
        The option will be removed from all product variants in this category
      </WarningDialog>
      <OptionAddDialog
        categoryName={category.name}
        open={optionAddShow}
        options={category.options}
        onClose={() => setOptionAdd(false)}
        onToggle={(optionId) => {
          const i = category.options.indexOf(optionId);
          const options =
            i >= 0
              ? category.options.filter((o) => o !== optionId)
              : [...category.options, optionId];
          onChange({ ...category, options });
        }}
      />
    </Box>
  );
};
