import { useCallback, useMemo, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
  Typography,
  Paper,
} from "@mui/material";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { FilePondFile } from "filepond";
import { useSnackbar } from "notistack";
import { CloseButton } from "../../../../components/buttons/CloseButton";
import { ProcessButton } from "../../../../components/buttons/ProcessButton";
import {
  CsvResult,
  CsvImportOptions,
  CsvJobId,
  syncMeta,
} from "../../../../features/import/csv";
import { useImportJob } from "../../../../features/import/import.service";
import { useProducts } from "../../../../features/products/product-func";
import { useDbOperations } from "../../../data-func";
import { CategorySelect } from "../../categories/CategorySelect";
import { CollectionSelect } from "../../collections/CollectionSelect";
import { ImportCsvAnalysis } from "./import-csv-analysis";
import { ImportCsvPreview } from "./import-csv-preview";
import { ImportStatusLine, ImportProgress } from "./import-progress";
import { uploadFile } from "../../../../features/data/file-util";

registerPlugin(FilePondPluginFileValidateType);

interface ImportCsvModalProps {
  categoryId?: string;
  collectionId?: string;
  isOpen: boolean;
  onClose: () => void;
  onSample: () => void;
  onImport?: () => void;
}

export const ImportCsvModal = (props: ImportCsvModalProps) => {
  if (!props?.isOpen) {
    return null;
  }
  return <ImportCsvModalInner {...props} />;
};

type ImportStep = "analyze" | "preview" | "import" | "done" | "failed";

const ImportCsvModalInner = ({
  isOpen,
  onClose,
  onSample,
  onImport,
  collectionId,
  categoryId,
}: ImportCsvModalProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { categoryNames } = useProducts();
  const { processDb } = useDbOperations();

  const [csvFile, setCsvFile] = useState<FilePondFile>(null);
  const [uploading, setUploading] = useState(false);
  const [result, setResult] = useState<CsvResult>();

  const [step, setStep] = useState<ImportStep>();

  const initialOptions: CsvImportOptions = useMemo(() => {
    return {
      collectionId: collectionId ?? "",
      categoryId: categoryId ?? "",
    };
  }, [categoryId, collectionId]);

  const [options, setOptions] = useState<CsvImportOptions>({
    ...initialOptions,
  });

  const { startJob, jobStatus, resetJob, lastJobStatus } = useImportJob({
    serviceJobId: CsvJobId,
    onFinish: () => {
      setUploading(false);
      setStep("done");
      onImport?.();
      enqueueSnackbar("Imported CSV " + csvFile?.filename, {
        variant: "success",
      });
    },
    onContinue: () => {
      setStep("import");
      setUploading(true);
    },
  });

  const upload = useCallback(
    async (uploadOptions: CsvImportOptions) => {
      setUploading(true);
      setOptions(uploadOptions);

      await uploadFile(
        "csv",
        csvFile.file,
        csvFile.filename,
        uploadOptions,
        async (request) => {
          const r = JSON.parse(request.response) as CsvResult;
          setResult(r);
          const needResolution =
            !r?.analysis?.resolved &&
            !r?.records?.length &&
            uploadOptions.importMode !== "direct";
          if (needResolution) {
            setStep("analyze");
            if (r.analysis?.missedCategories > 0 && categoryNames.length) {
              setOptions({
                ...uploadOptions,
                categoryId: categoryNames[0].value,
              });
            }
          } else if (r.records?.length > 0 && step !== "import") {
            setStep("preview");
          }
          if (r.jobId) {
            setStep("import");
            startJob(r.jobId, r.fileName, r.count);
          } else {
            setUploading(false);
          }
        }
      );
    },
    [categoryNames, csvFile, startJob, step]
  );

  const reImport = useCallback(async () => {
    processDb(
      async () => {
        return await syncMeta(result.analysis);
      },
      async () => {
        await upload({ ...options, importMode: "direct" });
      },
      setUploading,
      null,
      "Error uploading CSV.",
      "products"
    );
  }, [options, processDb, result?.analysis, upload]);

  const process = useCallback(
    async (isPreview?: boolean) => {
      if (step === "analyze") {
        await reImport();
      } else {
        const opts = {
          ...options,
          preview: isPreview || (step !== "preview" && !!options.preview),
        };
        upload(opts);
      }
    },
    [options, reImport, step, upload]
  );

  const resetImport = useCallback(() => {
    setCsvFile(null);
    setResult(null);
    setStep(null);
    setOptions({ ...initialOptions });
    resetJob();
  }, [initialOptions, resetJob]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <div>Import CSV</div>
        <CloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ my: 1 }}>
          {!lastJobStatus.checked && (
            <Typography variant="caption">Preparing job...</Typography>
          )}
          {!csvFile && lastJobStatus.checked && step !== "import" && (
            <Box>
              <Box>
                Download
                <Button
                  variant="text"
                  sx={{ textTransform: "none", textDecoration: "underline" }}
                  onClick={onSample}
                >
                  a sample CSV template
                </Button>
                to see an example of the format required.
              </Box>
              <FilePond
                name="file"
                allowMultiple={false}
                instantUpload={false}
                onupdatefiles={(fileItems) => {
                  setCsvFile(fileItems[0]);
                }}
                acceptedFileTypes={["text/csv", "text/plain"]}
              />
              <ImportStatusLine jobStatus={lastJobStatus?.status} />
            </Box>
          )}
          {csvFile && (
            <Box>
              File: <strong>{csvFile.filename}</strong>{" "}
              <small>
                ({Math.round((csvFile.fileSize / 1024) * 10) / 10} KB{" "}
                {result?.analysis?.recordsCount > 0 ? (
                  <span>
                    {" "}
                    - <b>{result.analysis.recordsCount}</b> records
                  </span>
                ) : null}
                )
              </small>
              {step !== "import" && (
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 2 }}
                  onClick={resetImport}
                >
                  Replace file
                </Button>
              )}
              {result?.criticalStop && (
                <Box sx={{ my: 2 }}>
                  <Typography variant="subtitle2" color="error">
                    Import File Error
                  </Typography>

                  {result.errors?.map((e, i) => (
                    <Typography variant="body2" color="error" key={i}>
                      - {e.index ? e.index + ": " : ""} {e.message}
                    </Typography>
                  ))}
                </Box>
              )}
              {!step && (
                <Paper
                  variant="outlined"
                  sx={{ p: 2, my: 2, display: "flex", gap: 1 }}
                >
                  <CategorySelect
                    categoryId={options.categoryId}
                    onChange={(id) =>
                      setOptions({ ...options, categoryId: id })
                    }
                    defaultLabel="-- Select default category --"
                  />
                  <CollectionSelect
                    collectionId={options.collectionId}
                    onChange={(id) =>
                      setOptions({ ...options, collectionId: id })
                    }
                    defaultLabel="-- Import to collection (optional) --"
                  />
                </Paper>
              )}
            </Box>
          )}
          {step === "analyze" && (
            <ImportCsvAnalysis
              options={options}
              setOptions={setOptions}
              result={result}
              setResult={setResult}
            />
          )}
          {step === "preview" && <ImportCsvPreview records={result.records} />}
          {(step === "import" || step === "done") && (
            <ImportProgress jobStatus={jobStatus} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {csvFile && (
          <>
            {!step && !uploading && (
              <ProcessButton
                onClick={() => process(true)}
                processing={uploading}
                label="Preview &raquo;"
                color="primary"
              />
            )}
            <ProcessButton
              onClick={() => process()}
              processing={uploading}
              label={
                step === "analyze"
                  ? "Update & " + (options.preview ? "Preview" : "Import")
                  : "Import"
              }
              variant={step ? "contained" : "outlined"}
              disabled={step === "done"}
            />
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
