import { createContext } from "react";
import { OpenHtmlEditorModalFunc } from "../components/dialogs/HtmlEditorDialog";
import { OpenUpgradeModalFunc } from "../components/dialogs/UpgradeDialog";
import { OpenCatalogSelectModalFunc } from "../containers/catalogs/CatalogSelectDialog";
import { OpenCatalogShareModalFunc } from "../containers/catalogs/CatalogShareDialog";
import { OpenProductListExtModalFunc } from "../containers/designer/product-list/ProductListExtModal";
import { OpenTemplateDesignerModalFunc } from "../containers/designer/templates/TemplateDesignerModal";
import { OpenImageModalFunc } from "../containers/images/modals/ImageModal";
import { ProductModalApi } from "../features/products/product-modals";

export interface DialogContextProps {
  openImageModal: OpenImageModalFunc;
  openUpgradeModal: OpenUpgradeModalFunc;
  openHtmlEditorModal: OpenHtmlEditorModalFunc;
  openTemplateDesigner: OpenTemplateDesignerModalFunc;
  openProductListExt: OpenProductListExtModalFunc;
  openCatalogShareModal: OpenCatalogShareModalFunc;
  openCatalogSelectModal: OpenCatalogSelectModalFunc;
  productModalApi: ProductModalApi;
  isHtmlEditorOpened: () => boolean;
}

// const defaultContextValues = {
//   openImageModal: defaultNoOp,
//   openUpgradeModal: defaultNoOp,
//   openHtmlEditorModal: defaultNoOp,
//   openTemplateDesigner: defaultNoOp,
//   openProductListExt: defaultNoOp,
//   openCatalogShareModal: defaultNoOp,
//   openCatalogSelectModal: defaultNoOp,
//   productModalApi: null,
// };

export const DialogContext = createContext<DialogContextProps>(null);
