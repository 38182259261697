import { atomWithDefault } from "jotai/utils";
import { getLayoutDb } from "./layout.service";
import { atom } from "jotai";

export const baseLayoutDbAtom = atomWithDefault(
  async () => await getLayoutDb()
);

export const layoutDbAtom = atom(
  async (get) => await get(baseLayoutDbAtom),
  (_get, set, update) => {
    set(baseLayoutDbAtom, update);
  }
);
