import { DataChange } from "../products/product";

export type AppStateProps = {
  data_dirty?: boolean;
  data_change?: DataChange;
  info?: ServerInfo;
};

export type ServerInfo = {
  version: string;
  machine: string;
  time: string;
};

export const appState: AppStateProps = {};
