import { useMemo } from "react";

import {
  Card,
  CardContent,
  Typography,
  LinearProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  SxProps,
} from "@mui/material";
import { formatDateStr } from "../../../../features/data/time";
import { ImportJobStatus } from "../../../../features/import/import";

export const ImportProgress = ({
  jobStatus,
}: {
  jobStatus: ImportJobStatus;
}) => {
  const importProgress = useMemo(() => {
    if (!jobStatus?.counters) return 0;
    const total =
      (jobStatus.counters["products-total"] || 0) +
      (jobStatus.counters["images-total"] || 0);
    const done =
      (jobStatus.counters["products"] || 0) +
      (jobStatus.counters["images"] || 0);
    return total > 0 ? Math.round((done / total) * 100) : 0;
  }, [jobStatus]);

  if (!jobStatus) return null;

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Typography variant="h6" my={1}>
          {jobStatus.job.finished ? "Finished" : "Importing"}
        </Typography>
        <Typography variant="caption" my={1} sx={{ display: "flex", gap: 4 }}>
          {!jobStatus.job.finished && (
            <>
              <span>
                {jobStatus.counters["products"] || 0} /{" "}
                {jobStatus.counters["products-total"] || 0} products
              </span>
              {jobStatus.counters["images-total"] > 0 && (
                <span>
                  {jobStatus.counters["images"] || 0} /{" "}
                  {jobStatus.counters["images-total"] || 0} images
                </span>
              )}{" "}
              {jobStatus.counters["error-count"] > 0 && (
                <span>{jobStatus.counters["error-count"] || 0} errors</span>
              )}
            </>
          )}
        </Typography>
        <LinearProgress
          variant="determinate"
          color={importProgress < 100 ? "primary" : "success"}
          value={importProgress}
        />
        <Typography
          component="div"
          variant="caption"
          sx={{ mt: 1, minHeight: 30 }}
        >
          {jobStatus.duration} {jobStatus.status}
        </Typography>
        {jobStatus.errors.length > 0 && (
          <>
            <Typography variant="h6" my={1}>
              Import Errors
            </Typography>
            <TableContainer sx={{ maxHeight: 250, mt: 1 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Code</TableCell>
                    <TableCell>Index</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobStatus.errors.map((e, i) => (
                    <TableRow key={i}>
                      <TableCell>{e.code}</TableCell>
                      <TableCell>{e.index}</TableCell>
                      <TableCell
                        sx={{
                          color: (theme) =>
                            e.isWarning ? null : theme.palette.error.main,
                        }}
                      >
                        {e.message}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export const ImportStatusLine = ({
  jobStatus,
  sx,
}: {
  jobStatus?: ImportJobStatus;
  sx?: SxProps;
}) => {
  if (!jobStatus?.job) return null;
  return (
    <Typography
      component="div"
      variant="caption"
      sx={{ display: "flex", gap: 2, ...sx }}
    >
      <span>Last import: {formatDateStr(jobStatus.updated, true)}</span>
      <span>{jobStatus.job.name}</span>
      <span>{jobStatus.status}</span>
    </Typography>
  );
};
