import {
  PlainExtension,
  FragmentStringHandlerOptions,
  NodeStringHandlerOptions,
  ProsemirrorNode,
  Static,
  StringHandlerOptions,
  getDocument,
  isDocNode,
} from "remirror";

import {
  DOMParser as PMDomParser,
  DOMSerializer,
  Fragment,
} from "@remirror/pm/model";

const defaultParseOptions = { preserveWhitespace: true } as const;

const startExprRe = /^\{\{(?!\s*\/)([^}]+)\}\}/i;
const endExprRe = /^\{\{\s*\/([^}]+)\}\}/i;

const exprDelimiter = "_EXPR_";

function elementFromString(html: string, document?: Document): HTMLElement {
  const parser = new (
    (document || getDocument())?.defaultView ?? window
  ).DOMParser();
  return parser.parseFromString(`<body>${html}</body>`, "text/html").body;
}

// const replaceComments = (el: HTMLElement) => {
//   for (let i = 0; i < el.childNodes.length; i++) {
//     const node = el.childNodes[i] as HTMLElement;
//     if (node.nodeType === 8) {
//       const expr = node.textContent?.trim();
//       if (expr) {
//         if (startExprRe.test(expr)) {
//           (node.nextElementSibling as HTMLElement)?.setAttribute?.(
//             "data-expr-start",
//             expr
//           );
//         } else if (endExprRe.test(expr)) {
//           (node.previousElementSibling as HTMLElement)?.setAttribute?.(
//             "data-expr-end",
//             expr
//           );
//         }
//       }
//       el.removeChild(node);
//       i--;
//     } else {
//       replaceComments(node);
//     }
//   }
// };

const replaceComments = (el) => {
  let i = 0;
  while (i < el.childNodes.length) {
    const node = el.childNodes[i];
    if (node.nodeType === 8) {
      const expr = node.textContent?.trim();
      if (expr) {
        if (startExprRe.test(expr)) {
          const existingStartExpr =
            node.nextElementSibling?.getAttribute("data-expr-start");
          const newStartExpr = existingStartExpr
            ? `${existingStartExpr} ${exprDelimiter} ${expr}`
            : expr;
          node.nextElementSibling?.setAttribute(
            "data-expr-start",
            newStartExpr
          );
        } else if (endExprRe.test(expr)) {
          const existingEndExpr =
            node.previousElementSibling?.getAttribute("data-expr-end");
          const newEndExpr = existingEndExpr
            ? `${existingEndExpr} ${exprDelimiter} ${expr}`
            : expr;
          node.previousElementSibling?.setAttribute(
            "data-expr-end",
            newEndExpr
          );
        }
      }
      el.removeChild(node);
    } else {
      replaceComments(node);
      i++;
    }
  }
};

// const replaceExpressions = (el: HTMLElement) => {
//   for (let i = 0; i < el.childNodes.length; i++) {
//     const node = el.childNodes[i] as HTMLElement;
//     if (node.hasAttribute?.("data-expr-start")) {
//       const comment = document.createComment(
//         node.getAttribute("data-expr-start")
//       );
//       el.insertBefore(comment, node);
//       node.removeAttribute("data-expr-start");
//       i++;
//     }
//     if (node.hasAttribute?.("data-expr-end")) {
//       const comment = document.createComment(
//         node.getAttribute("data-expr-end")
//       );
//       node.after(comment);
//       node.removeAttribute("data-expr-end");
//       i++;
//     }
//     replaceExpressions(node);
//   }
// };

const replaceExpressions = (el) => {
  for (let i = 0; i < el.childNodes.length; i++) {
    const node = el.childNodes[i];
    if (node.nodeType === 1) {
      if (node.hasAttribute("data-expr-start")) {
        const startExprs = node
          .getAttribute("data-expr-start")
          .split(exprDelimiter);
        startExprs.forEach((expr) => {
          const comment = document.createComment(expr.trim());
          el.insertBefore(comment, node);
        });
        node.removeAttribute("data-expr-start");
      }
      if (node.hasAttribute("data-expr-end")) {
        const endExprs = node
          .getAttribute("data-expr-end")
          .split(exprDelimiter);
        endExprs.reverse().forEach((expr) => {
          const comment = document.createComment(expr.trim());
          node.after(comment);
        });
        node.removeAttribute("data-expr-end");
      }
      replaceExpressions(node);
    }
  }
};

const removeDataAttributes = (element) => {
  if (element.attributes) {
    const attributesToRemove = [];
    for (const attr of element.attributes) {
      if (attr.name.startsWith("data-")) {
        attributesToRemove.push(attr.name);
      }
    }
    for (const attrName of attributesToRemove) {
      element.removeAttribute(attrName);
    }
  }

  for (const child of element.children) {
    removeDataAttributes(child);
  }
};

export function cmCodeToProsemirrorNode(
  props: FragmentStringHandlerOptions
): Fragment;
export function cmCodeToProsemirrorNode(
  props: NodeStringHandlerOptions
): ProsemirrorNode;
export function cmCodeToProsemirrorNode(
  props: StringHandlerOptions
): ProsemirrorNode | Fragment {
  const {
    content,
    schema,
    document,
    fragment = false,
    ...parseOptions
  } = props;
  const element = elementFromString(content, document);

  replaceComments(element);
  // for (let i = 0; i < comments.length; i++) {
  //   const comment = comments[i];
  //   const cmItem = document.createElement("cm");
  //   cmItem.innerHTML = comment.textContent;
  //   comment.replaceWith(cmItem);
  // }

  const parser = PMDomParser.fromSchema(schema);

  const dom = fragment
    ? parser.parseSlice(element, { ...defaultParseOptions, ...parseOptions })
        .content
    : parser.parse(element, { ...defaultParseOptions, ...parseOptions });

  return dom;
}

export function prosemirrorCmCodeToHtml(
  node: ProsemirrorNode,
  document = getDocument()
): string {
  const element = document.createElement("div");
  element.append(prosemirrorCmCodeToDom(node, document));

  return element.innerHTML;
}

export function prosemirrorCmCodeToDom(
  node: ProsemirrorNode,
  document = getDocument()
): DocumentFragment | HTMLElement {
  const fragment = isDocNode(node, node.type.schema)
    ? node.content
    : Fragment.from(node);
  const dom = DOMSerializer.fromSchema(node.type.schema).serializeFragment(
    fragment,
    { document }
  );

  replaceExpressions(dom as HTMLElement);

  removeDataAttributes(dom as HTMLElement);

  return dom;
}

export interface CmCodeOptions {
  htmlToCmCode?: Static<(html: string) => string>;
  cmCodeToHtml?: Static<
    (markdown: string, sanitizer?: (html: string) => string) => string
  >;
  htmlSanitizer?: Static<(html: string) => string>;
}

export class CmCodeExtension extends PlainExtension {
  get name() {
    return "cmCode" as const;
  }

  onCreate(): void {
    this.store.setStringHandler(
      "cmCode",
      this.cmCodeToProsemirrorNode.bind(this)
    );
  }

  private cmCodeToProsemirrorNode(
    options: FragmentStringHandlerOptions
  ): Fragment;
  private cmCodeToProsemirrorNode(
    options: NodeStringHandlerOptions
  ): ProsemirrorNode;
  private cmCodeToProsemirrorNode(
    options: StringHandlerOptions
  ): ProsemirrorNode | Fragment {
    return cmCodeToProsemirrorNode(options as NodeStringHandlerOptions);
    // return this.store.stringHandlers.html({
    //   ...(options as NodeStringHandlerOptions),
    //   content: this.options.cmCodeToHtml(
    //     options.content,
    //     this.options.htmlSanitizer
    //   ),
    // });
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface StringHandlers {
      cmCode: CmCodeExtension;
    }

    interface AllExtensions {
      cmCode: CmCodeExtension;
    }
  }
}
