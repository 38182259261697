import { atomWithDefault } from "jotai/utils";
import { loadAuthSession } from "./auth.service";
import { atom } from "jotai";

export const baseSessionAtom = atomWithDefault(
  async () => await loadAuthSession()
);

export const sessionAtom = atom(
  async (get) => await get(baseSessionAtom),
  (_get, set, update) => {
    set(baseSessionAtom, update);
  }
);
