import { ReactNode, Suspense } from "react";
import { Breakpoint, Dialog, DialogTitle } from "@mui/material";
import LoadingScreen from "../../../components/LoadingScreen";
import { ImageSelectOptions } from "../../../features/images/image";

interface ImageModalWrapperParams {
  title: string;
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  width?: Breakpoint;
}

export type ImageModalType = "upload" | "view" | "select";

export type OpenImageModalFunc = (
  opts: ImageSelectOptions,
  type: ImageModalType
) => void;

export interface ImageModalParams {
  options?: ImageSelectOptions;
  onClose: () => void;
  openModal: OpenImageModalFunc;
}

export const ImageModal = ({
  title,
  open,
  onClose,
  children,
  width,
}: ImageModalWrapperParams) => {
  return (
    <Dialog onClose={onClose} open={open} maxWidth={width} fullWidth={true}>
      <Suspense
        fallback={
          <>
            <DialogTitle>{title}</DialogTitle>
            <LoadingScreen title="preparing image database..." />
          </>
        }
      >
        {children}
      </Suspense>
    </Dialog>
  );
};
