//https://github.com/microsoft/ApplicationInsights-JS/blob/master/API-reference.md
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      "InstrumentationKey=27cf03f7-75df-4fb3-9015-ec5bc7e668de;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/",
  },
});

appInsights.loadAppInsights();

export default appInsights;
