import { DesignItem, DesignItemRef } from "../design/design.types";
import { CatalogBuilder } from "./catalog.builder";
import { BgStyle } from "./catalogs";

export const initBg = (bg: DesignItem) => {
  bg.Prime = false;
  bg.Color = "#cccccc";
  bg.BorderColor = "#000000";
  bg.BorderRadius = 0;
  bg.BorderWidth = 1;
  bg.BorderStyle = "solid";
};

export const getBgStyle = (
  item: DesignItemRef,
  d: number,
  builder: CatalogBuilder
): BgStyle => {
  let r = item.di.BorderRadius,
    w = item.di.BorderWidth;
  if (d > 0) {
    r = r > 0 ? Math.max(r / d, 1) : 0;
    w = w > 0 ? Math.max(w / d, 1) : 0;
  }
  let bg =
    (item.di.ColorSrc === "prime" || item.di.Prime) &&
    builder.catalog.primeColor
      ? builder.catalog.primeColor
      : item.di.Color;

  if (item.di.ColorSrc?.indexOf("{{") === 0) {
    bg =
      builder.evaluator.getContent(item.di.ColorSrc, item.info.product) || bg;
  }

  return {
    backgroundColor: bg,
    borderStyle: item.di.BorderStyle,
    borderColor: item.di.BorderColor,
    borderRadius: r,
    borderWidth: w,
  };
};

// export const setTextStyle = (item: DesignItemRef, builder: CatalogBuilder) => {
//   if (item.type === "text" && builder.catalog.fontColor) {
//     item.style["color"] = builder.catalog.fontColor;
//   }
// };
