import { useState } from "react";
import { Box } from "@mui/material";
import { TemplateList, TemplateViewOptions } from "./TemplateList";
import { TemplateListFilter } from "./TemplateListFilter";
import { EMPTY_GUID } from "../../../features/data/data-types";
import { templateSortBy } from "./template.render";
import { FixedPanel } from "../../../components/FixedPanel";

type Props = {
  onTemplateChange: (templateId: string) => void;
  productId?: string;
  templateId?: string;
};

export const TemplateNavBar = ({
  productId,
  templateId,
  onTemplateChange,
}: Props) => {
  const [view, setView] = useState<TemplateViewOptions>({
    sort: templateSortBy[0].value,
  });

  return (
    <Box className="catalog-nav">
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
        }}
      >
        <TemplateListFilter
          view={view}
          setView={setView}
          templateId={templateId}
          onAddTemplate={() => onTemplateChange(EMPTY_GUID)}
        />
        <FixedPanel
          overflow="auto"
          container="#template-designer-container"
          breakpoint="lg"
          sx={{ pt: 1 }}
        >
          <TemplateList
            productId={productId}
            selectedId={templateId}
            onClick={(t) => onTemplateChange(t)}
            view={view}
            showDefault
          />
        </FixedPanel>
      </Box>
    </Box>
  );
};
