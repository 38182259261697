import {
  CategoryEditOptions,
  CollectionAttrsOptions,
  CollectionDeleteOptions,
  CollectionEditorOptions,
  OpenCollectionAttrsFunc,
  OpenCollectionDeleteFunc,
  OpenCollectionEditorFunc,
  OpenDeleteProductFunc,
  OpenEditCategoryFunc,
  OpenEditProductFunc,
  OpenUpdateProductFunc,
  ProductDeleteOptions,
  ProductEditOptions,
  UpdateProductOptions,
  useProductModalState,
} from "./product-modal-state";
import { CategoryModal } from "../../containers/products/categories/CategoryModal";
import { CollectionDeleteModal } from "../../containers/products/collections/CollectionDeleteModal";
import {
  CollectionEditorModal,
  CollectionAttrsModal,
} from "../../containers/products/collections/CollectionModal";
import { ProductDeleteModal } from "../../containers/products/modals/ProductDeleteModal";
import { ProductEditModal } from "../../containers/products/modals/ProductEditModal";
import { UpdateProductModal } from "../../containers/products/modals/ProductUpdateModal";
import { useCallback } from "react";

export interface ProductModalApi {
  openEditProduct: OpenEditProductFunc;
  openDeleteProducts: OpenDeleteProductFunc;
  openCollectionEditor: OpenCollectionEditorFunc;
  openCollectionAttrs: OpenCollectionAttrsFunc;
  openCollectionDelete: OpenCollectionDeleteFunc;
  openEditCategory: OpenEditCategoryFunc;
  openUpdateProduct: OpenUpdateProductFunc;
  productId?: string;
}

export const useProductModals = () => {
  const state = useProductModalState();

  const openDeleteProducts: OpenDeleteProductFunc = useCallback(
    (opts: ProductDeleteOptions) => {
      state.setDeleteOptions(opts);
    },
    [state]
  );

  const openEditProduct: OpenEditProductFunc = useCallback(
    (opts: ProductEditOptions) => {
      state.setEditOptions(opts);
    },
    [state]
  );

  const renderDeleteProductModal = useCallback(() => {
    if (!state.deleteOptions) return null;
    return (
      <ProductDeleteModal
        {...state.deleteOptions}
        onClose={() => state.setDeleteOptions(null)}
        onDelete={() => {
          state.setEditOptions(null);
          state.deleteOptions?.onDelete?.();
        }}
      />
    );
  }, [state]);

  const renderEditProductModal = useCallback(() => {
    if (!state.editOptions) return null;

    return (
      <ProductEditModal
        {...state.editOptions}
        openDeleteProducts={openDeleteProducts}
        onClose={() => state.setEditOptions(null)}
      />
    );
  }, [openDeleteProducts, state]);

  const openCollectionEditor: OpenCollectionEditorFunc = useCallback(
    (opts: CollectionEditorOptions) => {
      state.setCollectionEditorOptions(opts);
    },
    [state]
  );

  const renderCollectionEditorModal = useCallback(() => {
    const opts = state.collectionEditorOptions;
    if (!opts) return null;

    return (
      <CollectionEditorModal
        {...state.collectionEditorOptions}
        onClose={() => {
          state.setCollectionEditorOptions(null);
        }}
      />
    );
  }, [state]);

  const openCollectionAttrs: OpenCollectionAttrsFunc = useCallback(
    (opts: CollectionAttrsOptions) => {
      state.setCollectionAttrsOptions(opts);
    },
    [state]
  );

  const renderCollectionAttrsModal = useCallback(() => {
    const opts = state.collectionAttrsOptions;
    if (!opts) return null;

    return (
      <CollectionAttrsModal
        {...state.collectionAttrsOptions}
        onClose={() => {
          state.setCollectionAttrsOptions(null);
        }}
      />
    );
  }, [state]);

  const openCollectionDelete: OpenCollectionDeleteFunc = useCallback(
    (opts: CollectionDeleteOptions) => {
      state.setCollectionDeleteOptions(opts);
    },
    [state]
  );

  const renderCollectionDeleteModal = useCallback(() => {
    const opts = state.collectionDeleteOptions;
    if (!opts) return null;

    return (
      <CollectionDeleteModal
        {...state.collectionDeleteOptions}
        onClose={() => state.setCollectionDeleteOptions(null)}
      />
    );
  }, [state]);

  const openEditCategory: OpenEditCategoryFunc = useCallback(
    (opts: CategoryEditOptions) => {
      state.setCategoryOptions(opts);
    },
    [state]
  );
  const renderEditCategoryModal = useCallback(() => {
    if (!state.categoryOptions) return null;

    return (
      <CategoryModal
        {...state.categoryOptions}
        onClose={() => state.setCategoryOptions(null)}
      />
    );
  }, [state]);

  const openUpdateProduct: OpenUpdateProductFunc = useCallback(
    (opts: UpdateProductOptions) => {
      state.setUpdateOptions(opts);
    },
    [state]
  );
  const renderUpdateProductModal = useCallback(() => {
    if (!state.updateOptions) return null;

    return (
      <UpdateProductModal
        {...state.updateOptions}
        onClose={() => state.setUpdateOptions(null)}
      />
    );
  }, [state]);

  const renderProductModals = useCallback(() => {
    return (
      <>
        {renderDeleteProductModal()}
        {renderEditProductModal()}
        {renderCollectionAttrsModal()}
        {renderCollectionEditorModal()}
        {renderCollectionDeleteModal()}
        {renderEditCategoryModal()}
        {renderUpdateProductModal()}
      </>
    );
  }, [
    renderCollectionAttrsModal,
    renderCollectionDeleteModal,
    renderCollectionEditorModal,
    renderDeleteProductModal,
    renderEditCategoryModal,
    renderEditProductModal,
    renderUpdateProductModal,
  ]);

  return {
    productModalApi: {
      openEditProduct,
      openDeleteProducts,
      openEditCategory,
      openUpdateProduct,
      openCollectionAttrs,
      openCollectionDelete,
      openCollectionEditor,
      productId: state?.editOptions?.productId,
    } as ProductModalApi,
    renderProductModals,
  };
};
