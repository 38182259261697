//https://github.com/remirror/remirror/tree/main/packages/remirror__extension-tables/src
//https://github.com/ProseMirror/prosemirror-tables

import {
  TableCellExtension,
  TableControllerCellExtension,
  TableExtension,
  TableHeaderCellExtension,
  TableSchemaSpec,
} from "remirror/extensions";

import {
  extension,
  ExtensionPriority,
  NodeExtension,
  ApplySchemaAttributes,
  ExtensionTag,
  NodeSpecOverride,
  ProsemirrorNode,
  ProsemirrorPlugin,
  isElementDomNode,
  command,
  findParentNodeOfType,
} from "remirror";

import { removeStyleAttribute } from "./extension-utils";

import type { CommandFunction, EditorState } from "remirror";

import { Decoration, DecorationSet } from "@remirror/pm/view";

function getCellAttrs(dom: HTMLElement) {
  return {
    colspan: Number(dom.getAttribute("colspan") ?? 1),
    rowspan: Number(dom.getAttribute("rowspan") ?? 1),
  };
}

function setCellAttrs(node: ProsemirrorNode) {
  const attrs: Record<string, string> = {};

  if (node.attrs.colspan !== 1) {
    attrs.colspan = node.attrs.colspan;
  }

  if (node.attrs.rowspan !== 1) {
    attrs.rowspan = node.attrs.rowspan;
  }
  if (node.attrs.colwidth) {
    const width = node.attrs.colwidth;
    attrs.style = `width: ${width}px;${removeStyleAttribute(
      node.attrs.style,
      "width"
    )}`;
  }

  return attrs;
}

export function createTableNodeSchema(
  extra: ApplySchemaAttributes,
  override: NodeSpecOverride
): Record<
  "table" | "tableRow" | "tableCell" | "tableHeaderCell",
  TableSchemaSpec
> {
  const cellAttrs = {
    ...extra.defaults(),
    colspan: { default: 1 },
    rowspan: { default: 1 },
    colwidth: { default: null },
  };

  return {
    table: {
      isolating: true,
      ...override,
      attrs: {
        ...extra.defaults(),
        border: { default: null },
      },
      content: "tableRow+",
      tableRole: "table",
      parseDOM: [
        {
          tag: "table",
          getAttrs: (dom) => {
            if (!isElementDomNode(dom)) {
              return false;
            }
            const attrs = {
              ...extra.parse(dom),
              border: dom.getAttribute("border"),
            };
            return attrs;
          },
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM(node) {
        const attrs = extra.dom(node);
        if (node.attrs.border) {
          attrs.border = node.attrs.border;
          attrs.style = `border: ${
            node.attrs.border === "1" ? "1px solid black" : "none"
          };`;
        }
        return ["table", attrs, ["tbody", 0]];
      },
    },

    tableRow: {
      ...override,
      attrs: extra.defaults(),
      content: "(tableCell | tableHeaderCell)*",
      tableRole: "row",
      parseDOM: [
        {
          tag: "tr",
          getAttrs: (dom) => ({
            ...extra.parse(dom),
          }),
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM(node) {
        return ["tr", extra.dom(node), 0];
      },
    },

    tableCell: {
      isolating: true,
      content: `${ExtensionTag.Block}+`,
      ...override,
      attrs: cellAttrs,
      tableRole: "cell",
      parseDOM: [
        {
          tag: "td",
          getAttrs: (dom) => ({
            ...extra.parse(dom),
            ...getCellAttrs(dom as HTMLElement),
          }),
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM(node) {
        return ["td", { ...extra.dom(node), ...setCellAttrs(node) }, 0];
      },
    },

    tableHeaderCell: {
      isolating: true,
      content: `${ExtensionTag.Block}+`,
      ...override,
      attrs: cellAttrs,
      tableRole: "header_cell",
      parseDOM: [
        {
          tag: "th",
          getAttrs: (dom) => ({
            ...extra.parse(dom),
            ...getCellAttrs(dom as HTMLElement),
          }),
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM(node) {
        return ["th", { ...extra.dom(node), ...setCellAttrs(node) }, 0];
      },
    },
  };
}

@extension({ defaultOptions: {}, defaultPriority: ExtensionPriority.Low })
export class CmTableRowExtension extends NodeExtension {
  get name() {
    return "tableRow" as const;
  }

  createExtensions(): Array<
    TableCellExtension | TableHeaderCellExtension | TableControllerCellExtension
  > {
    return [
      new CmTableCellExtension({ priority: ExtensionPriority.Low }),
      new CmTableHeaderCellExtension({ priority: ExtensionPriority.Low }),
    ];
  }

  createNodeSpec(
    extra: ApplySchemaAttributes,
    override: NodeSpecOverride
  ): TableSchemaSpec {
    return createTableNodeSchema(extra, override).tableRow;
  }

  createExternalPlugins(): ProsemirrorPlugin[] {
    const plugins: ProsemirrorPlugin[] = [];

    return plugins;
  }
}

@extension({ defaultOptions: {}, defaultPriority: ExtensionPriority.Low })
export class CmTableCellExtension extends NodeExtension {
  get name() {
    return "tableCell" as const;
  }

  createNodeSpec(
    extra: ApplySchemaAttributes,
    override: NodeSpecOverride
  ): TableSchemaSpec {
    return createTableNodeSchema(extra, override).tableCell;
  }
}

@extension({ defaultOptions: {}, defaultPriority: ExtensionPriority.Low })
export class CmTableHeaderCellExtension extends NodeExtension {
  get name() {
    return "tableHeaderCell" as const;
  }

  createNodeSpec(
    extra: ApplySchemaAttributes,
    override: NodeSpecOverride
  ): TableSchemaSpec {
    return createTableNodeSchema(extra, override).tableHeaderCell;
  }
}

export class CmTableExtension extends TableExtension {
  createNodeSpec(extra, override) {
    return createTableNodeSchema(extra, override).table;
  }

  createExtensions() {
    return [new CmTableRowExtension({ priority: ExtensionPriority.Low })];
  }

  createPlugin() {
    return {
      props: {
        decorations: (state: EditorState) => {
          const decorations: Decoration[] = [];

          state.doc.descendants((node, pos) => {
            if (node.type.name === "table" && node.attrs.border === "0") {
              const decoration = Decoration.node(pos, pos + node.nodeSize, {
                class: "table-no-border",
              });

              decorations.push(decoration);
            }
          });

          // Create and return the DecorationSet
          return DecorationSet.create(state.doc, decorations);
        },
      },
    };
  }

  @command()
  toggleTableBorder(): CommandFunction {
    return ({ tr, dispatch }) => {
      const tableNode = findParentNodeOfType({
        types: this.type,
        selection: tr.selection,
      });

      if (!tableNode) return false;

      const currentBorder = tableNode.node.attrs.border;
      const newAttrs = {
        ...tableNode.node.attrs,
        border: currentBorder !== "0" ? "0" : "1",
      };

      if (dispatch) {
        dispatch(tr.setNodeMarkup(tableNode.pos, undefined, newAttrs));
        return true;
      }
      return false;
    };
  }
}
