import { atom } from "jotai";
import { atomWithDefault } from "jotai/utils";
import { sessionAtom } from "../accounts/account.state";
import { appState } from "../app/app.state";
import { getProductDb } from "./product.service";

const baseProductDbAtom = atomWithDefault(async (get) => {
  const session = await get(sessionAtom);
  return session?.account?.config
    ? await getProductDb(session.account.config.imageStorageUrl)
    : null;
});

export const productDbAtom = atom(
  async (get) => {
    const db = await get(baseProductDbAtom);
    return db;
  },
  (_get, set, update) => {
    set(baseProductDbAtom, update);
    appState.data_dirty = true;
  }
);

// export const productDbQuery = selector({
//   key: "productDbQuery",
//   async get({ get }) {
//     const session = get(sessionAtom);
//     return session?.account
//       ? await getProductDb(session.account.config.imageStorageUrl)
//       : null;
//   },
// });

// export const productDbAtom = atom({
//   key: "productDb",
//   default: productDbQuery,
//   effects: [
//     ({ onSet }) => {
//       onSet(() => {
//         appState.data_dirty = true;
//       });
//     },
//   ],
// });
