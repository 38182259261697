import { atomWithDefault } from "jotai/utils";
import { getTemplatesDb } from "./template.service";
import { atom } from "jotai";

export const baseTemplateDbAtom = atomWithDefault(
  async () => await getTemplatesDb()
);

export const templateDbAtom = atom(
  async (get) => await get(baseTemplateDbAtom),
  (_get, set, update) => {
    set(baseTemplateDbAtom, update);
  }
);
