import { SxProps } from "@mui/material";
import { SelectFromList } from "../../../components/SelectFromList";
import { useProducts } from "../../../features/products/product-func";
export const CategorySelect = ({
  categoryId,
  onChange,
  defaultLabel,
  sx,
  error,
  startMenu,
}: {
  categoryId?: string;
  onChange: (categoryId: string) => void;
  defaultLabel?: string;
  sx?: SxProps;
  error?: string;
  startMenu?: { value: string; name: string }[];
}) => {
  const { categoryNames } = useProducts();

  return (
    <SelectFromList
      entries={categoryNames.map((c) => ({
        value: c.value,
        name: c.name,
        level: c.level,
      }))}
      value={categoryId ?? ""}
      setValue={(v) => onChange(v ?? "")}
      defaultLabel={defaultLabel}
      label={categoryId ? "Category" : ""}
      size="small"
      sx={sx}
      error={error}
      startMenu={startMenu}
    />
  );
};
