import { atom } from "jotai";
import { atomWithDefault } from "jotai/utils";
import { sessionAtom } from "../accounts/account.state";
import { appState } from "../app/app.state";
import { getImageDb } from "./image.service";

const baseImageDbAtom = atomWithDefault(async (get) => {
  const session = await get(sessionAtom);
  return session?.account?.config
    ? await getImageDb(session.account.config.imageStorageUrl)
    : null;
});

export const imageDbAtom = atom(
  async (get) => await get(baseImageDbAtom),
  (_get, set, update) => {
    set(baseImageDbAtom, update);
    appState.data_dirty = true;
  }
);
