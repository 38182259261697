import { CatalogBuilder } from "../catalogs/catalog.builder";
import { barcodeFontExt, barcodeFont } from "../catalogs/catalog.defs";
import { stripScript } from "../catalogs/catalog.util";
import { makeBrief, parseMoney, DataTypeName } from "../data/data-types";
import { parseImageFieldArgs } from "../design/design.types";
import { parseImagePath } from "../images/image";
import {
  ProductRef,
  ProductVariant,
  ProductOption,
  CategoryRef,
  ProductGroupRef,
  ProductField,
} from "../products/product";
import { ContentEvaluator } from "./content.eval";
import {
  EvalContentArgs,
  EvalOptions,
  ValueProviderType,
  ValueProvider,
  ArgInfo,
} from "./content.eval.types";

export class ContentQuery {
  builder: CatalogBuilder;
  evaluator: ContentEvaluator;

  constructor(builder: CatalogBuilder, evaluator: ContentEvaluator) {
    this.builder = builder;
    this.evaluator = evaluator;
  }

  extendContext(args: EvalContentArgs, opts: EvalOptions) {
    args.context = { ...args.context, ...opts };
    return args;
  }

  getProvider<T>(
    type: ValueProviderType,
    args: EvalContentArgs,
    exact?: boolean
  ) {
    if (args.providers) {
      if (type === "product" && args.alias && !exact) {
        type += args.alias;
      }
      const p = args.providers.find((p) => p.type === type);
      return p?.source as T;
    }
    return null;
  }

  setProvider(
    args: EvalContentArgs,
    type: ValueProviderType,
    provider: ValueProvider | null,
    exact?: boolean
  ): ValueProvider {
    args.providers = args.providers || [];
    if (type === "product" && args.alias && !exact) {
      type += args.alias;
    }
    const i = args.providers.findIndex((p) => p.type === type);
    if (provider === null) {
      if (i >= 0) args.providers.splice(i, 1);
      return null;
    }
    if (i >= 0) {
      args.providers[i] = provider;
    } else {
      args.providers.push(provider);
    }
    return provider;
  }

  normalizeName(name: string) {
    if (!name) return "";
    return name.replace("%20", " ").replace("&apos;", "'").toLowerCase().trim();
  }

  findField(product: ProductRef, name: string) {
    name = name.toLowerCase();
    return Array.from(product.fieldMap.entries()).find(
      (e) => e[0].toLowerCase() === name
    )?.[1];
  }

  imageField(path, fieldArgs) {
    if (!path) return "";

    // let ph = 3;
    // let w = 0,
    //   h = 0;
    // let pos = "Fit";
    // if (fieldArgs.length > 1) {
    //   if (!isNaN(+fieldArgs[1])) {
    //     w = fieldArgs[1];
    //     ph = 2;
    //   } else {
    //     pos = fieldArgs[1];
    //     w = fieldArgs.length > 2 ? fieldArgs[2] : 0;
    //   }
    //   if (fieldArgs.length > ph) {
    //     h = fieldArgs[ph];
    //   }
    // }

    const args = parseImageFieldArgs(fieldArgs);

    const p = parseImagePath(
      this.builder.data.config.imageStorageUrl,
      path
    )?.thumb;

    return (
      "<img class='inline-img-field' src='" +
      p +
      "' data-pos='" +
      args.pos +
      "' data-width='" +
      args.w +
      "' data-height='" +
      args.h +
      "' onload='processImage(this,\".board-item\")'/>"
    );
  }

  textField(text: string, fieldArgs: string[], args: EvalContentArgs) {
    if (!text) return "";
    let result = stripScript(text);
    if (fieldArgs.length >= 2 && !isNaN(+fieldArgs[1]))
      result = makeBrief(result, +fieldArgs[1]);
    const content = this.evaluator.evalTextContent(result, args);
    return content;
  }

  numField(text: string, mask?: string) {
    if (!text || !mask) return text ?? "";

    const result = parseMoney(text, this.builder.data.config.culture);

    if (!result.matched || isNaN(result.value)) return text;

    const m = /^\s*(C\s*)?#(([^#0]+)(#+))?(([^#0]+)(0+))?(\s*C)?\s*$/gi.exec(
      mask
    );
    if (!m) return text;

    const c1 = result.prefix ? result.prefix : result.suffix;
    const c2 = result.suffix ? result.suffix : result.prefix;
    const parts = result.value.toString().split(".");

    let r = "";

    if (m[1] && c1) {
      r += m[1].replace("C", c1);
    }

    r += m[3] ? parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, m[3]) : parts[0];

    if (m[6] && m[7]) {
      const l = m[7].length;
      let f = parts.length > 1 ? parts[1] : "0";
      f = f.length >= l ? f.substring(0, l) : f.padEnd(l, "0");
      r += m[6] + f;
    }
    if (m[8] && c2) {
      r += m[8].replace("C", c2);
    }
    return r;
  }

  formatPriceField(
    p: string,
    fieldArgs: string[],
    args: EvalContentArgs,
    typed: boolean
  ) {
    if (p && (typed || args.isNumericValue)) {
      const m = parseMoney(p, args);
      return m?.value;
    }
    return this.numField(p, fieldArgs.length > 1 ? fieldArgs[1] : null);
  }

  static ops = ["+", "-", "*", "/"];
  calc(expr: string, args: EvalContentArgs, opts?: EvalOptions) {
    if (!expr) return 0;

    expr = expr.trim();

    let i1;
    while ((i1 = expr.indexOf("(")) >= 0) {
      const i2 = expr.indexOf(")", i1);
      if (i2 < 0) return 0;
      let r = this.calc(expr.substring(i1 + 1, i2), args, opts);
      if (i1 > 0) r = expr.substring(0, i1) + r;
      if (i2 < expr.length - 1) r = r + expr.substring(i2 + 1);
      expr = r;
    }

    for (let o = 0; o < ContentQuery.ops.length; o++) {
      const i = expr.indexOf(ContentQuery.ops[o]);
      if (i >= 0) {
        if (i === 0 || i === expr.length - 1) return 0;

        const a1 = this.calc(expr.substring(0, i), args, opts);
        const a2 = this.calc(expr.substring(i + 1), args, opts);

        switch (ContentQuery.ops[o]) {
          case "*":
            return a1 * a2;
          case "/":
            return a2 !== 0 ? a1 / a2 : 0;
          case "+":
            return a1 + a2;
          case "-":
            return a1 - a2;
        }
      }
    }

    if (!isNaN(+expr)) return +expr;

    const f = this.getFieldContent(expr, args, opts, "");
    if (f) {
      return parseMoney(f, this.builder.data.config.culture).value;
    }

    return 0;
  }

  private invalidField(name: string, args: EvalContentArgs) {
    if (!args || !args.assert) return "";
    const invalidChars = !/^(\w|\d|\s|[_'-])+$/gi.test(name);
    return (
      "<span class='invalid-field " +
      (invalidChars ? "format" : "") +
      "' " +
      "data-name='" +
      name +
      "' title='" +
      (invalidChars
        ? "invalid field formatting - only letters, digits, underscore or dash are allowed"
        : "unknown field name") +
      "'>{{" +
      name +
      "}}</span>"
    );
  }

  getParameterValue(name: string, args: EvalContentArgs, fieldArgs: string[]) {
    const opts: EvalOptions = args.context || {};
    const n = this.normalizeName(name);
    const pm = this.builder.data.parameters.find(
      (p) => p.parameter.name.toLowerCase() === n
    );
    if (pm) {
      const type = pm.parameter.data.valueType;
      let value = pm.parameter.data.value;

      const builder = this.builder;
      if (pm.parameter.data.overrides) {
        const o = pm.parameter.data.overrides.find((o) => {
          if (o.target === "Catalog" && o.targetId === builder.catalog.id)
            return true;
          if (o.target === "Showroom" && o.targetId === "" + args.storeId)
            return true;
          if (
            o.target === "Category" &&
            opts &&
            (o.targetId === opts.categoryId ||
              (opts.product && o.targetId === opts.product.product.categoryId))
          )
            return true;
          if (
            o.target === "Collection" &&
            opts &&
            (o.targetId === opts.collectionId ||
              (opts.product &&
                opts.product.collections?.some(
                  (c) =>
                    c.group.id === o.targetId ||
                    c.parents.some((p) => p.group.id === o.targetId)
                )))
          )
            return true;
          return false;
        });
        if (o) {
          value = o.value;
        }
      }

      if (type === DataTypeName.Image) return this.imageField(value, fieldArgs);

      if (type === "Number" || type === "Money")
        return this.numField(value, fieldArgs.length > 1 ? fieldArgs[1] : null);

      return this.textField(value, fieldArgs, args);
    }
    return null;
  }

  getVariantValue(
    v: ProductVariant,
    name: string,
    args: EvalContentArgs,
    typed?: boolean,
    skipAssert?: boolean
  ) {
    const fieldArgs = name.split(":");
    let n = this.normalizeName(fieldArgs[0]);
    if (n.indexOf("product.") === 0) {
      return this.getProductValue(
        this.getProvider("product", args),
        name.substring("product.".length),
        args
      );
    }

    const cf = this.getCommonFieldContent(n, args, fieldArgs, { variant: v });
    if (cf !== null) return cf;

    if (n === "price") {
      return this.formatPriceField(v.price || "", fieldArgs, args, typed);
    }
    if (n === "sku") return v.sku || "";
    if (n === "barcode") return v.barcode || "";
    if (n === "quantity") return v.quantity || "";
    if (n === "description")
      return this.textField(v.description, fieldArgs, args);
    if (n === "image") return this.imageField(v.image, fieldArgs);

    if (n === "optiontitle") {
      const po = this.getProvider<ProductOption>("option", args);
      return this.textField(po?.name || "", fieldArgs, args);
    }
    if (n === "optionvalue") {
      const po = this.getProvider<ProductOption>("option", args);
      n = po?.name.toLowerCase() || "";
    }
    const opt = v.options.find((o) => o.name.toLowerCase() === n);
    if (opt) {
      if (opt.value && n.indexOf("price") >= 0) {
        return this.formatPriceField(opt.value, fieldArgs, args, typed);
      }
      return opt.value || "";
    }
    return this.getProductValue(
      this.getProvider("product", args),
      name,
      args,
      null,
      skipAssert
    );
  }

  getOptionValue(
    option: ProductOption,
    name: string,
    args: EvalContentArgs,
    typed?: boolean,
    skipAssert?: boolean
  ) {
    const fieldArgs = name.split(":");
    const n = this.normalizeName(fieldArgs[0]);
    const cf = this.getCommonFieldContent(n, args, fieldArgs);
    if (cf !== null) return cf;
    switch (n) {
      case "optiontitle":
        return this.textField(option?.name, fieldArgs, args);
      case "optionvalue":
        return this.textField(option?.value, fieldArgs, args);
    }
    return this.getProvider("variant", args)
      ? this.getVariantValue(
          this.getProvider("variant", args),
          name,
          args,
          typed,
          skipAssert
        )
      : skipAssert
      ? ""
      : this.invalidField(name, args);
  }

  getFieldContent(
    field: string,
    args: EvalContentArgs,
    opts?: EvalOptions,
    defaultContent?: string
  ) {
    let r = defaultContent;
    if (opts?.product) {
      r = this.getProductValue(opts.product, field, args, null, true);
    }
    if (opts?.variant) {
      r = this.getVariantValue(opts.variant, field, args, false, true);
    }
    if (!r) {
      const a = opts
        ? this.extendContext(args, {
            product: opts.product,
            provider: opts.variant
              ? {
                  type: "variant",
                  source: opts.variant,
                  id: "" + opts.variant.id,
                }
              : {
                  type: "product",
                  source: opts.product,
                  id: opts.product?.product.id,
                },
          })
        : args;
      r = this.getParameterValue(field, a, []);
    }
    return r || defaultContent;
  }

  getFieldValue(product: ProductRef, name: string, args: EvalContentArgs) {
    const addInfo: ArgInfo = {};
    const v = this.getProductValue(product, name, args, addInfo);
    return addInfo.type ? addInfo.value : v;
  }

  private getCommonFieldContent(
    name: string,
    args: EvalContentArgs,
    fieldArgs: string[],
    opts?: EvalOptions
  ) {
    if (name === "current date")
      return (
        "[date:" + (fieldArgs.length > 1 ? fieldArgs[1] : "yyyy-MM-dd") + "]"
      );
    if (name === "page number") return args?.pageNumber || 1;
    if (name === "section name")
      return this.textField(
        args ? args.sectionName || "" : "Section",
        fieldArgs,
        args
      );
    if (name === "altrow") return args.altRow ? "yes" : "";
    if (name === "islast") return args.isLast ? "yes" : "";
    if (name === "isfirst") return args.isFirst ? "yes" : "";
    if (name === "ispdf") return "";
    if (name === "separator") {
      return args.isLast ? "" : fieldArgs.length > 1 ? fieldArgs[1] : "/";
    }
    if (name.indexOf("barcodeimage") === 0) {
      const isExt = name !== "barcodeimage";
      const font = isExt ? barcodeFontExt : barcodeFont;
      const field = fieldArgs.length > 1 ? fieldArgs[1] : "barcode";
      const content = this.getFieldContent(
        field,
        args,
        opts,
        fieldArgs.length > 1 ? fieldArgs[1] : ""
      );
      return content
        ? "<span style='font-family:\"" + font + "\";'>*" + content + "*</span>"
        : "";
    }
    if (name === "calc") {
      if (fieldArgs.length > 1) {
        const v = this.calc(fieldArgs[1], args, opts);
        return fieldArgs.length > 2 ? this.numField(v, fieldArgs[2]) : v;
      }
      return 0;
    }
    if (name === "link" && fieldArgs.length > 1) {
      const l = this.getFieldContent(fieldArgs[1], args, opts, fieldArgs[1]);
      const rl = l.indexOf("http") >= 0 ? l : "http://" + l;
      const n =
        fieldArgs.length > 2
          ? this.getFieldContent(fieldArgs[2], args, opts, fieldArgs[2])
          : l;
      return '<a href="' + rl + '" target="_blank">' + n + "</a>";
    }

    if (name === "option") {
      let options = this.getProvider<ProductVariant>("variant", args)?.options;
      if (!options) {
        options = this.getProvider<ProductRef>("product", args)?.category
          .options;
      }
      if (!options) {
        options = this.getProvider<CategoryRef>("category", args)?.options;
      }
      let index = fieldArgs.length > 1 ? +fieldArgs[1] : 0;
      if (index <= 0) index = 1;
      const o = options && index <= options.length ? options[index - 1] : null;
      if (fieldArgs.length > 2 && fieldArgs[2].toLowerCase() === "title") {
        return o?.name || "";
      }
      return o?.value || "";
    }

    return null;
  }

  getGroupValue(g: ProductGroupRef, name: string, args: EvalContentArgs) {
    const fieldArgs = name.split(":");
    const n = this.normalizeName(fieldArgs[0]);
    const cf = this.getCommonFieldContent(n, args, fieldArgs);
    if (cf !== null) return cf;
    switch (n) {
      case "name":
        return this.textField(g.group.name, fieldArgs, args);
      case "fullname":
        return this.textField(g.fullName, fieldArgs, args);
      case "description":
        return this.textField(g.group.description, fieldArgs, args);
      case "parent":
        return this.textField(g.parent?.group.name || "", fieldArgs, args);
      case "productcount":
        return g.extProductCount >= 0 ? g.extProductCount : g.productCount;
      case "fullproductcount":
        return g.totalProductCount;
      case "thumbnail":
        return this.imageField(g.thumb, fieldArgs);
      case "color":
        return g.group.color || "";
      case "rank":
        return g.group.rank || 0;
      case "level":
        return g.parents?.length || 0;
    }
    const opts: EvalOptions =
      g.type === "collection"
        ? { collectionId: g.group.id }
        : { categoryId: g.group.id };
    opts.provider =
      g.type === "collection"
        ? { type: g.type, source: g, id: g.group.id }
        : { type: "category", source: g, id: g.group.id };
    const pv = this.getParameterValue(
      n,
      this.extendContext(args, opts),
      fieldArgs
    );
    if (pv !== null) return pv;
    return this.invalidField(name, args);
  }

  getProductValue(
    product: ProductRef,
    name: string,
    args: EvalContentArgs,
    addInfo?: ArgInfo,
    skipAssert?: boolean
  ) {
    const fieldArgs = name.split(":");
    let n = this.normalizeName(fieldArgs[0]);
    let alias: ValueProviderType;
    if (n.indexOf("product1.") === 0 || n.indexOf("product2.") === 0) {
      const ln = "product1".length;
      alias = n.substring(0, ln) as ValueProviderType;
      n = n.substring(ln + 1);
    }

    if ((args.alias || alias) && "product" + args.alias !== alias) {
      product = this.getProvider(alias || "product", args) || product;
    }

    const cf = this.getCommonFieldContent(n, args, fieldArgs, {
      product: product,
    });
    if (cf !== null) return cf;
    if (product) {
      if (n === "name")
        return this.textField(product.product.name, fieldArgs, args);
      if (n === "code")
        return this.textField(product.product.code, fieldArgs, args);
      if (n === "modified") return product.fieldMap["Modified"];
      if (n === "rank") return product.product.rank;
      if (n === "instock") return product.product.inStock ? "yes" : "";
      if (n === "variantcount") return product.product.variants?.length || 0;

      if (n === "product page link") {
        const innerText =
          fieldArgs.length > 1
            ? this.getProductValue(
                product,
                fieldArgs[1].trim(),
                args,
                addInfo,
                skipAssert
              )
            : "##";
        return "<span class='cm-page-link'>" + innerText + "</span>";
      }

      if (n === "product order link") {
        return "<span class='cm-order-link'>Order</span>";
      }

      if (n === "category")
        return this.textField(product.category.group.name, fieldArgs, args);
      if (n.indexOf("category.") === 0) {
        return this.getGroupValue(
          product.category,
          n.substring("category.".length),
          args
        );
      }

      if (n === "collection" || n.indexOf("collection.") === 0) {
        const col = product.collections?.[0];
        const colField =
          n === "collection" ? "name" : n.substring("collection.".length);
        return col ? this.getGroupValue(col, colField, args) : "";
      }

      let field: ProductField = null;
      if (n === "price" && args.priceField) {
        field = this.findField(product, args.priceField);
      }
      if (!field) {
        field = this.findField(product, n);
      }

      if (field) {
        if (field.dataType === DataTypeName.Image)
          return this.imageField(field.value, fieldArgs);

        if (args.isNumericValue || (addInfo && field.dataType === "Money")) {
          const value = parseMoney(field.value, args);
          if (addInfo) {
            addInfo.value = +value;
            addInfo.type = "numeric";
          }
        }
        if (field.dataType === "Number" && addInfo) {
          addInfo.value = field.value ? +field.value : 0;
          addInfo.type = "numeric";
        }

        if (field.dataType === "Number" || field.dataType === "Money") {
          return this.numField(
            field.value,
            fieldArgs.length > 1 ? fieldArgs[1] : null
          );
        }

        if (
          !field.dataType ||
          field.dataType === DataTypeName.Text ||
          field.dataType === DataTypeName.RichText
        ) {
          return this.textField(field.value, fieldArgs, args);
        }

        return "" + (field.value ?? "");
      }
    }
    const pv = this.getParameterValue(
      n,
      this.extendContext(args, {
        product: product,
        provider: product
          ? { type: "product", source: product, id: product.product.id }
          : null,
      }),
      fieldArgs
    );
    if (pv !== null) return pv;
    return skipAssert ? "" : this.invalidField(name, args);
  }

  extractCompareInfo(field: string): ArgInfo {
    const ni = field.indexOf("!") === 0;
    return {
      negative: ni,
      field: ni ? field.substring(1) : field,
    };
  }

  compareValues(
    v: string | number,
    op: string,
    value: string | number,
    addInfo?: ArgInfo,
    addInfo2?: ArgInfo
  ) {
    let v1: string | number = ("" + v).trim().toLocaleLowerCase();
    if (addInfo?.type === "numeric") {
      v1 = addInfo.value !== undefined ? addInfo.value : +v1;
    }
    let success = false;
    if (!op) {
      success = addInfo.type === "numeric" ? +v1 > 0 : !!v1;
    } else {
      let v2: string | number = ("" + value).trim().toLocaleLowerCase();
      if (addInfo2?.type === "numeric") {
        v2 = addInfo.value !== undefined ? addInfo2.value : +v2;
      } else if (addInfo.type === "numeric") {
        v2 = +v2;
      }
      switch (op) {
        case "~":
          success = ("" + v1).indexOf("" + v2) >= 0;
          break;
        case "^": {
          const a2 = "" + v2;
          success =
            v1 &&
            a2 &&
            a2.split(",").findIndex((a1) => {
              return a1.trim().indexOf("" + v1) >= 0;
            }) >= 0;
          break;
        }
        case "=":
          success = v1 === v2;
          break;
        case "<>":
          success = v1 !== v2;
          break;
        case ">":
          success = v1 > v2;
          break;
        case ">=":
          success = v1 >= v2;
          break;
        case "<":
          success = v1 < v2;
          break;
        case "<=":
          success = v1 <= v2;
          break;
      }
    }
    return addInfo?.negative ? !success : success;
  }

  compareProduct(
    product: ProductRef,
    field: string,
    op: string,
    value: string,
    args: EvalContentArgs
  ) {
    if (args && args.alias) {
      this.setProvider(args, "product", {
        type: "product",
        source: product,
        id: product.product.id,
      });
    }
    const addInfo = this.extractCompareInfo(field);
    const addInfo2 = {};
    const v = this.getProductValue(product, addInfo.field, args, addInfo, true);
    const v2 = this.getProductValue(product, value, args, addInfo2, true);
    return this.compareValues(v, op, v2 || value, addInfo, addInfo2);
  }

  compareGroup(
    group: ProductGroupRef,
    field: string,
    op: string,
    value: string,
    args: EvalContentArgs
  ) {
    const addInfo = this.extractCompareInfo(field);
    const v = this.getGroupValue(group, addInfo.field, args);
    addInfo.type =
      addInfo.field.indexOf("productcount") >= 0 ? "numeric" : undefined;
    return this.compareValues(v, op, value, addInfo);
  }

  compareVariant(
    variant: ProductVariant,
    field: string,
    op: string,
    value: string,
    args: EvalContentArgs
  ) {
    const addInfo = this.extractCompareInfo(field);
    const v = this.getVariantValue(variant, addInfo.field, args, true, true);
    addInfo.type = addInfo.field.indexOf("price") >= 0 ? "numeric" : undefined;
    return this.compareValues(v, op, value, addInfo);
  }

  compareOption(
    option: ProductOption,
    field: string,
    op: string,
    value: string,
    args: EvalContentArgs
  ) {
    const addInfo = this.extractCompareInfo(field);
    const v = this.getOptionValue(option, addInfo.field, args, true);
    return this.compareValues(v, op, value, addInfo);
  }
}
