export const findEntityByName = <T>(
  list: T[],
  name: string,
  getName: (entity: T) => string
) => {
  return list.find((e) => getName(e) === name);
};

export const generateNewEntityName = <T>(
  list: T[],
  name: string,
  getName: (entity: T) => string
) => {
  name = name.replace(/[-\d\s]+$/, "");
  let i = 1;
  while (findEntityByName(list, `${name} - ${i}`, getName)) i++;
  return `${name} - ${i}`;
};
