import { useCallback, useMemo, useState } from "react";
import { validateAccountField } from "../../features/accounts/account.data";
import { reportApiError } from "../../services/apiClient";

interface AccountForm {
  fields: string[];
  validExtUser?: boolean;
  required?: string[];
}

export const useAccountForm = <T>({
  fields,
  required,
  validExtUser,
}: AccountForm) => {
  const [data, setData] = useState<T>(
    fields.reduce((obj, key) => {
      obj[key] = "";
      return obj;
    }, {}) as T
  );

  const [errors, setErrors] = useState<T>({} as T);
  const [isProcessing, setIsProcessing] = useState(false);
  const [serverError, setServerError] = useState<string | null>(null);

  const invalidField = useCallback(
    (fieldName) =>
      validateAccountField(
        data,
        fieldName,
        !required || required.includes(fieldName),
        validExtUser
      ),
    [data, required, validExtUser]
  );

  const validateField = useCallback(
    (fieldName) => {
      const errorMsg = invalidField(fieldName);
      setErrors({ ...errors, [fieldName]: errorMsg });
      return !errorMsg;
    },
    [invalidField, errors]
  );

  const validateForm = useCallback(() => {
    let valid = true;

    fields.forEach((fieldName) => {
      if (!validateField(fieldName)) valid = false;
    });

    return valid;
  }, [fields, validateField]);

  const isValid = useMemo(
    () =>
      fields.every((fieldName) => {
        return !invalidField(fieldName);
      }),
    [fields, invalidField]
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (
    name: string,
    submit: () => Promise<{ success: boolean; message?: string }>,
    onError?: () => void
  ) => {
    //event.preventDefault();
    if (!validateForm()) return;

    setIsProcessing(true);
    setServerError(null);

    try {
      const response = await submit();

      if (!response?.success) {
        const error = reportApiError(response);
        setServerError(error || name + " failed. Please try again.");
      }
    } catch (err) {
      setServerError(name + " failed. Please review and try again.");
      if (err.response?.data?.errors) {
        setErrors(err.response.data.errors);
      }
      onError?.();
    } finally {
      setIsProcessing(false);
    }
  };

  return {
    data,
    setData,
    errors,
    setErrors,
    isValid,
    validateField,
    handleInputChange,
    handleSubmit,
    isProcessing,
    serverError,
  };
};
