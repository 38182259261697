export type UiColors =
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | "inherit";

export type UiSize = "small" | "medium" | "large";

export type UiButtonVariant = "text" | "contained" | "outlined";

export const listCountStyles = {
  "& .count-text, .count-text-all, .edit-btn": {
    justifyContent: "flex-end",
    display: "flex",
  },
  "& .edit-btn": {
    display: "none",
  },
  "&:hover": {
    "& .edit-btn": {
      display: "flex",
    },
    "& .count-text": {
      display: "none",
    },
  },
};
