import { useCallback, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  MenuItem,
  Select,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useAtom } from "jotai";
import { CloseButton } from "../../../../components/buttons/CloseButton";
import { sessionAtom } from "../../../../features/accounts/account.state";
import { getTimeStamp } from "../../../../features/data/time";
import { productDbAtom } from "../../../../features/products/product.state";
import { apiUrl } from "../../../../services/apiClient";
import { onlyUnique } from "../../../../services/util";

const specialFields = [
  ["IncludeVariants", "Include variants and options"],
  ["Collections", "List collections"],
  ["AsPlainText", "Export rich text fields as plain text"],
];

type FieldCheck = {
  [key: string]: { checked: boolean; label: string; level?: number };
};

export const ExportCsvModal = ({
  productIds,
  categoryId,
  collectionId,
  onClose,
}: {
  productIds?: string[];
  categoryId?: string;
  collectionId?: string;
  onClose: () => void;
}) => {
  const [session] = useAtom(sessionAtom);
  const [productDb] = useAtom(productDbAtom);

  const [fields, setFileds] = useState<FieldCheck>({});
  const [imgPath, setImgPath] = useState<string>("");
  const [selectAll, setSelectAll] = useState(false);

  const formEl = useRef<HTMLFormElement>();
  const timestampEl = useRef<HTMLInputElement>();
  const fieldsEl = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!productIds) return;

    const categories =
      productIds.length > 0
        ? productIds
            .map((id) => productDb.productMap.get(id).product.categoryId)
            .filter(onlyUnique)
            .map((id) => productDb.categoryMap.get(id))
        : categoryId
        ? [productDb.categoryMap.get(categoryId)]
        : Array.from(productDb.categoryMap.values());

    const f = categories
      .flatMap((c) => c.group.fields)
      .map((f) => f.name)
      .filter(onlyUnique);

    const cf = ["Name", "Rank", ...f].reduce((acc, cur) => {
      acc[cur] = { checked: false, label: cur, level: 0 };
      return acc;
    }, {});

    const sf = specialFields.reduce((acc, cur) => {
      acc[cur[0]] = {
        checked: false,
        label: cur[1],
        level: 1,
      };
      return acc;
    }, {});

    const newFields = { ...cf, ...sf } as FieldCheck;

    setFileds((f) => {
      Object.entries(newFields).forEach(([key, value]) => {
        value.checked = f[key]?.checked || false;
      });
      return newFields;
    });
  }, [productIds, productDb, categoryId]);

  const download = useCallback(() => {
    timestampEl.current.value =
      productIds.length > 0 ? getTimeStamp() : "sample";
    fieldsEl.current.value = Object.keys(fields)
      .filter((k) => fields[k].checked)
      .join(",");
    formEl.current.submit();
    onClose();
    // downloadFile(apiUrl + "/data/csv-export2", "test.csv", {
    //   productIds,
    //   fields,
    // });
  }, [fields, onClose, productIds?.length]);

  if (!productIds) return null;

  return (
    <Dialog open={!!productIds} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <div>
          {productIds.length > 0 && (
            <span>Export CSV - {productIds.length} products</span>
          )}
          {!productIds.length && <span>Sample CSV Card</span>}
        </div>
        <CloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ my: 1 }}>
          <Typography variant="caption" component="div">
            Fields
          </Typography>
          <FormControlLabel
            sx={{ color: "primary" }}
            control={
              <Checkbox
                checked={selectAll}
                onChange={(event) => {
                  const c = event.target.checked;
                  setSelectAll(c);
                  setFileds((v) => {
                    Object.keys(v)
                      .filter((k) => v[k].level === 0)
                      .forEach((k) => {
                        v[k].checked = c;
                      });
                    return { ...v };
                  });
                }}
              />
            }
            label={selectAll ? "Deselect All" : "Select All"}
          />
          {[0, 1].map((level) => {
            return (
              <Box key={level}>
                {level === 1 && (
                  <Typography variant="caption" component="div" sx={{ mt: 2 }}>
                    Export Options
                  </Typography>
                )}
                {Object.entries(fields)
                  .filter((f) => f[1].level === level)
                  .map((f) => (
                    <FormControlLabel
                      key={f[0]}
                      control={
                        <Checkbox
                          checked={f[1].checked}
                          onChange={(event) => {
                            setFileds((v) => {
                              v[f[0]].checked = event.target.checked;
                              return { ...v };
                            });
                          }}
                        />
                      }
                      label={f[1].label}
                    />
                  ))}
              </Box>
            );
          })}
          <FormControl sx={{ my: 2 }}>
            <Typography variant="caption" component="div">
              Image format
            </Typography>
            <Select
              sx={{ minWidth: 150 }}
              value={imgPath}
              onChange={(event) => {
                setImgPath(event.target.value);
              }}
              labelId="code-select"
              size="small"
              displayEmpty
            >
              <MenuItem value="">Relative path</MenuItem>
              <MenuItem value="abs">Absolute url</MenuItem>
              <MenuItem value="data">Data url (embedded)</MenuItem>
            </Select>
            <FormHelperText>
              {!imgPath && <span>an image path inside your storage</span>}
              {imgPath === "abs" && <span>full web address of the image</span>}
              {imgPath === "data" && <span>embed image content into CSV</span>}
            </FormHelperText>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>

        <Button onClick={() => download()} variant="contained">
          Export
        </Button>
      </DialogActions>
      <form action={apiUrl + "/data/csv-export"} method="post" ref={formEl}>
        <input
          type="hidden"
          id="apiKey"
          name="apiKey"
          value={session.account.apiKey}
        />
        <input
          type="hidden"
          id="productIds"
          name="productIds"
          value={productIds.join(",")}
        />
        <input type="hidden" id="fields" name="fields" ref={fieldsEl} />
        <input
          type="hidden"
          id="categoryId"
          name="categoryId"
          value={categoryId}
        />
        <input
          type="hidden"
          id="collectionId"
          name="collectionId"
          value={collectionId}
        />
        <input
          type="hidden"
          id="timestamp"
          name="timestamp"
          ref={timestampEl}
        />
        <input
          type="hidden"
          id="imgPathFormat"
          name="imgPathFormat"
          value={imgPath}
        />
      </form>
    </Dialog>
  );
};
