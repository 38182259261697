import { Alert, Box, TextField } from "@mui/material";
import { EditorComponent, ThemeProvider } from "@remirror/react";
import { useLayoutEffect, useRef, useState } from "react";
import { useHtmlEditorContext } from "./useHtmlEditorContext";
import ErrorBoundary from "../../contexts/ErrorBoundary";
import { logError } from "../../services/logging";

const RootEditorComponent = ({ editorId }) => {
  return (
    <ErrorBoundary
      errorContent={<Alert severity="error">Editor error</Alert>}
      onError={(error) => {
        logError(error, "RootEditorComponent: " + editorId);
      }}
    >
      <EditorComponent />
    </ErrorBoundary>
  );
};

export const HtmlEditorBox = ({
  onSourceEdit,
  editorId,
}: {
  onSourceEdit?: (html: string) => void;
  editorId: string;
}) => {
  const { isSourceEdit, editedHtml, darkBkg, setEditedHtml, fullScreen } =
    useHtmlEditorContext();

  const [, setEditorWidth] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  //   const count = useRef(0);
  //   if (count.current === 0) {
  //     trackEvent({
  //       category: "text-editor",
  //       action: "init",
  //       label: editorId,
  //       internal: true,
  //     });
  //   }
  //   count.current++;

  useLayoutEffect(() => {
    if (!isSourceEdit) {
      setEditorWidth(ref.current?.offsetWidth ?? 0);
    }
  }, [isSourceEdit, ref.current?.offsetWidth, setEditorWidth]);

  return (
    <Box
      ref={ref}
      sx={{
        "& .remirror-editor-wrapper": {
          display: isSourceEdit ? "none" : "block",
          "& > div": {
            backgroundColor: darkBkg && !isSourceEdit ? "#333" : null,
          },
          "& .remirror-editor": {
            height: fullScreen ? "calc(100vh - 220px);" : "auto",
          },
        },
      }}
    >
      <ThemeProvider>
        <RootEditorComponent editorId={editorId} />
      </ThemeProvider>

      {isSourceEdit && (
        <Box>
          <TextField
            sx={{
              mt: 1,
              width: "100%",
              height: fullScreen ? "calc(100vh - 250px);" : 400,
              "& .MuiInputBase-root": {
                bgcolor: "text.primary",
                color: "background.paper",
                p: 0,
                height: "100%",
                minHeight: 100,
              },
              "& textarea": {
                height: "90% !important",
                overflowY: "auto !important",
                p: 2,
              },
            }}
            fullWidth
            multiline
            value={editedHtml ?? ""}
            onChange={(e) => {
              setEditedHtml(e.target.value);
              onSourceEdit?.(e.target.value);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
