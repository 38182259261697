import { createTheme } from "@mui/material";
import typography from "./typography";

declare module "@mui/material/styles" {
  interface Palette {
    darkBackground: string;
    mute: Palette["primary"];
  }

  interface PaletteOptions {
    darkBackground: string;
    mute?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    mute: true;
  }
  interface ButtonGroupPropsColorOverrides {
    mute: true;
  }
}

// export const createAppTheme = (): Theme => {
//   const theme = createTheme({
//     palette: {
//       darkBackground: "#f4f6f8",
//       primary: {
//         main: "#1b67b4",
//       },
//       secondary: {
//         main: "#fa7e17",
//         contrastText: "#fff",
//       },
//       mute: {
//         main: "#777",
//         contrastText: "#fff",
//       },
//     },
//     typography,
//   });

//   return theme;
// };

export const appTheme = createTheme({
  palette: {
    darkBackground: "#f4f6f8",
    primary: {
      main: "#1b67b4",
    },
    secondary: {
      main: "#fa7e17",
      contrastText: "#fff",
    },
    mute: {
      main: "#777",
      contrastText: "#fff",
    },
  },
  typography,
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    darkBackground: "#f4f6f8",
  },
});
