import { Box, Chip, Typography } from "@mui/material";

import PublicIcon from "@mui/icons-material/Public";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SecurityIcon from "@mui/icons-material/Security";
import LockIcon from "@mui/icons-material/Lock";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { processImage } from "../../../features/catalogs/catalog.util";
import { HtmlText } from "../../../components/HtmlText";
import { TextPlaceholder } from "../../../components/TextPlaceholder";
import { IconTypes } from "../../../components/icons.types";
import { CatalogPrivacyLevels } from "../../../features/catalogs/catalog.defs";
import {
  CatalogPageRef,
  CatalogItemRef,
  Catalog,
} from "../../../features/catalogs/catalogs";
import { renderTemplateItem } from "../templates/template.render";

export const renderPageBackground = (page: CatalogPageRef) => {
  return (
    <Box
      className="page-bkg"
      sx={{
        backgroundColor: page?.pageItem?.style?.backgroundColor || "#fff",
      }}
    >
      {page?.pageItem?.info.url && (
        <img
          src={page.pageItem.info.url}
          alt=""
          data-pos={page?.pageItem?.di.ImgPosition}
          onLoad={(e) =>
            processImage(e.target as HTMLImageElement, ".page-bkg")
          }
        />
      )}
    </Box>
  );
};

export const renderCatalogItem = (
  item: CatalogItemRef,
  isThumb?: boolean,
  blockContent?: boolean
) => {
  if (item.di.Sink && item.di.Type !== "template") {
    return (
      <div className={"sink-block sink-block-" + item.di.Type}>
        <div className="field-icon">
          {item.di.Type === "image" && <IconTypes.ImagePlaceholder />}
        </div>
        <div className="sink-desc">
          <div className="title">
            {item.di.Type === "text" && (
              <IconTypes.TextPlaceholder fontSize="small" />
            )}
            {item.info.title}
          </div>
          <div className="hint">
            drop product {item.di.Type === "image" && <span>or image</span>}{" "}
            here...
          </div>
        </div>
      </div>
    );
  }
  switch (item.di.Type) {
    case "image":
      return (
        <div className="image-item">
          <img
            className="content-image"
            src={item.info.src}
            alt={item.info.path}
            title={item.info.path}
            data-pos={item.di.ImgPosition}
            onLoad={(e) => processImage(e.target as HTMLImageElement)}
            draggable={false}
          />
        </div>
      );
    case "template":
      return (
        <div className={item.info.product ? "" : "template-field"}>
          {item.info.templateItems?.map((ti, i) => (
            <div key={i} className="template-item board-item" style={ti.style}>
              {renderTemplateItem(
                ti,
                !!item.info.product,
                isThumb,
                blockContent
              )}
            </div>
          ))}
          {!item.info.product && !isThumb && (
            <div className="field-name">
              <div>{item.info.title}</div>
              <div className="hint">drop product here...</div>
            </div>
          )}
        </div>
      );
    default:
      return isThumb ? (
        <TextPlaceholder ext={item.info.extension} />
      ) : (
        <HtmlText
          content={item.info?.content ?? item.di.Body}
          scalable={item.di.scale}
          scroll={item.di.exp}
          blockContent={blockContent}
        />
        // <div
        //   className={`unstyled-text  content-view  ${
        //     item.di.exp ? "text-y-scroll" : ""
        //   } ${item.di.scale ? "scalable" : ""}`}
        //   dangerouslySetInnerHTML={{
        //     __html: item.info?.content ?? item.di.Body,
        //   }}
        // />
      );
  }
};

export const getPrivacyIcon = (privacy: number) => {
  if (privacy === 1) return <VisibilityIcon fontSize="small" />;
  if (privacy === 2) return <SecurityIcon fontSize="small" />;
  if (privacy === 3) return <LockIcon fontSize="small" />;
  if (privacy === 4) return <MailOutlineIcon fontSize="small" />;

  return <PublicIcon fontSize="small" />;
};

export const getCatalogPrivacyName = (catalog: Catalog) =>
  CatalogPrivacyLevels[catalog.privacy ?? 0]?.name;

export const getCatalogPrivacyLabel = (
  catalog: Catalog,
  withText?: boolean
) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        svg: {
          color: "text.secondary",
          fontSize: 16,
        },
      }}
      title={getCatalogPrivacyName(catalog) + " privacy"}
    >
      {getPrivacyIcon(catalog.privacy)}
      {withText && (
        <Typography variant="caption">
          {getCatalogPrivacyName(catalog)}{" "}
        </Typography>
      )}
    </Box>
  );
};

export const getCatalogPublishLabel = (
  catalog: Catalog,
  onClick?: () => void
) => {
  return (
    <Chip
      label={catalog.status ?? "Draft"}
      color={catalog.status === "Published" ? "success" : "default"}
      size="small"
      sx={{ fontSize: 11, borderRadius: 1 }}
      onClick={onClick}
    />
  );
};
