import { GlobalStyles } from "@mui/material";
import type { FC } from "react";
import typography from "../ui/typography";
const AppGlobalStyles: FC = () => (
  <GlobalStyles
    styles={{
      html: {
        // WebkitFontSmoothing: 'antialiased',
        // MozOsxFontSmoothing: 'grayscale',
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        width: "100%",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
      ".grecaptcha-badge": { visibility: "hidden" },
      ...typography,
    }}
  />
);

export default AppGlobalStyles;
