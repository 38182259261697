import {
  DesignItemRef,
  DesignItemType,
  DesignSubtype,
  DesignItem,
  TemplateItemRef,
} from "../design/design.types";
import { LayoutItemRef } from "../layouts/layouts";
import { CatalogPageRef, CatalogItemRef } from "./catalogs";

export type PageDimensions = {
  w: number;
  h: number;
  scaleX: number;
  scaleY: number;
  header?: number;
  footer?: number;
  //opts?: PageOptions;
};

export type PageOptions = {
  includeHeaderFooter?: boolean;
  page?: CatalogPageRef;
};

export type ItemAttrs = {
  content: string;
  productId?: string;
  templateId?: string;
};

export type UpdateItemAtrs = ItemAttrs & {
  isNew: false;
  item?: DesignItemRef;
  type: DesignItemType;
};

export type BaseCreateItemAttrs = {
  type: DesignItemType;
  subtype?: DesignSubtype;
  contentType?: DesignItemType;
  content?: string;
  productId?: string;
  templateId?: string;
};

export type CreateItemAttrs = ItemAttrs &
  BaseCreateItemAttrs & {
    isNew: true;
    type: DesignItemType;
    subtype?: DesignSubtype;
    x: number;
    y: number;
    w?: number;
    h?: number;
    color?: string;
    ext?: string;
    exp?: boolean;
    scale?: boolean;
    clone?: DesignItem;
    pos?: "none";
  };

export type InitItemArgs = {
  soft?: boolean;
  force?: boolean;
  useOriginal?: boolean;
  page?: CatalogPageRef;
  initial?: boolean;
  //dimensions?: PageDimensions;
  //raw?: boolean;
};

export type InitTemplateItemArgs = {
  parent: DesignItemRef;
} & InitItemArgs;

export const syncItemAttrs = <
  T extends TemplateItemRef | CatalogItemRef | LayoutItemRef
>(
  item: T
): T => {
  if (item.meta === "template") {
    const ti = item as TemplateItemRef;
    item.type = ti.di.type;
    item.content = ti.di.type === "image" ? ti.di.src : ti.di.text;
    item.imgPosition = ti.di.position;
  } else if (item.meta === "catalog") {
    const ci = item as CatalogItemRef;
    item.type = ci.di.Type;
    item.content = ci.di.Body;
    item.imgPosition = ci.di.ImgPosition;
  } else if (item.meta === "layout") {
    const ti = item as LayoutItemRef;
    item.type = ti.di.type;
    item.content = ti.di.type === "image" ? ti.di.src : ti.di.text;
    item.imgPosition = ti.di.position;
  }

  return item;
};
