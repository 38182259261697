import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import { OutlinedColorPicker } from "../../../components/ColorPicker";
import { FontPicker } from "../../../components/FontPicker";
import { SelectFromList } from "../../../components/SelectFromList";
import { CatalogExtension } from "../../../features/design/design.types";

type ProductListExtDesignGroupProps = {
  name: string;
  extension: CatalogExtension;
  onChange: (extension: CatalogExtension) => void;
};

const padding = ["", "2", "4", "6", "8", "10"];

export const ProductListExtDesignGroup = ({
  name,
  extension,
  onChange,
}: ProductListExtDesignGroupProps) => {
  return (
    <Box sx={{ m: 2 }}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6">{name} row design</Typography>
        <Divider sx={{ my: 1 }} />

        <Box sx={{ display: "flex", gap: 1, mt: 1, pt: 1 }}>
          <OutlinedColorPicker
            label="Text Color"
            size="small"
            color={extension[name + "Color"]}
            setColor={(color) =>
              onChange({ ...extension, [name + "Color"]: color })
            }
          />
          <OutlinedColorPicker
            label="Background"
            size="small"
            color={extension[name + "BkgColor"]}
            setColor={(color) =>
              onChange({ ...extension, [name + "BkgColor"]: color })
            }
          />

          {name === "Product" && (
            <OutlinedColorPicker
              label="Alt Background"
              size="small"
              color={extension.AltRowBkgColor}
              setColor={(color) =>
                onChange({ ...extension, AltRowBkgColor: color })
              }
            />
          )}

          <SelectFromList
            label="Padding"
            defaultLabel="None"
            entries={padding}
            value={extension[name + "Padding"]}
            setValue={(value) => {
              onChange({ ...extension, [name + "Padding"]: value });
            }}
            size="small"
            sx={{ ml: 2, minWidth: 80 }}
          />
        </Box>
        {name === "Group" && (
          <FormGroup sx={{ ml: 1 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={extension.UseGroupColor}
                  onChange={(e) =>
                    onChange({
                      ...extension,
                      UseGroupColor: e.target.checked,
                    })
                  }
                />
              }
              label={`Use ${extension.GroupBy} background color`}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={extension.GroupThumb}
                  onChange={(e) =>
                    onChange({
                      ...extension,
                      GroupThumb: e.target.checked,
                    })
                  }
                />
              }
              label="Show Group Thumbnail"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={extension.GroupDesc}
                  onChange={(e) =>
                    onChange({
                      ...extension,
                      GroupDesc: e.target.checked,
                    })
                  }
                />
              }
              label="Show Group Description"
            />
          </FormGroup>
        )}

        <FontPicker
          label="Font"
          fontFamily={extension[name + "Font"]}
          fontSize={extension[name + "FontSize"]}
          onChange={(fontFamily, fontSize) =>
            onChange({
              ...extension,
              [name + "Font"]: fontFamily,
              [name + "FontSize"]: fontSize,
            })
          }
          withFontSize
        />
      </Paper>
    </Box>
  );
};
