import { useCallback, useMemo, useState } from "react";
import { useAtom } from "jotai";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  DialogActions,
  IconButton,
} from "@mui/material";
import { SelectFromList } from "../../components/SelectFromList";
import { CloseButton } from "../../components/buttons/CloseButton";
import { ProcessButton } from "../../components/buttons/ProcessButton";
import { IconTypes } from "../../components/icons.types";
import { sessionAtom } from "../../features/accounts/account.state";
import { CatalogPrivacyLevels } from "../../features/catalogs/catalog.defs";
import {
  Catalog,
  CatalogStatus,
  PRIVACY_PROTECTED,
} from "../../features/catalogs/catalogs";
import { validatePermalink } from "../../features/data/data-types";
import { StoreRef } from "../../features/stores/store";
import { Transition } from "../../components/Transitions";
import { trackEvent } from "../../services/analytics";
import { useDialogs } from "../../contexts/useDialogs";

type CatalogPublishModalProps = {
  isOpen?: boolean;
  catalog: Catalog;
  onClose: () => void;
  onChange: (catalog: Catalog) => void;
  stores: StoreRef[];
};

export const CatalogPublishModal = ({
  catalog,
  onClose,
  isOpen,
  onChange,
  stores,
}: CatalogPublishModalProps) => {
  const [session] = useAtom(sessionAtom);
  const [edited, setEdited] = useState({ ...catalog });
  const [permalink, setPermalink] = useState<string>();
  const [isProcessing, setIsProcessing] = useState(false);

  const { openUpgradeModal } = useDialogs();

  const permalinkError = useMemo(() => {
    return validatePermalink(permalink);
  }, [permalink]);

  const handlePublish = useCallback(
    async (status: CatalogStatus) => {
      setIsProcessing(true);
      edited.status = status;
      await onChange(edited);
      setIsProcessing(false);

      trackEvent({
        category: "catalog",
        action: status === "Published" ? "publish" : "unpublish",
        tag: true,
      });

      onClose();
    },
    [edited, onChange, onClose]
  );

  // useEffect(() => {
  //   const getStores = async () => {
  //     const s = await getStoreDb();
  //     setStores(s);
  //   };
  //   getStores();
  // }, []);

  const storeList = useMemo(() => {
    return stores?.map((s) => ({
      name:
        s.store.name +
        " (" +
        (s.store.productCount ?? 0) +
        ") - " +
        s.store.privacyLevel,
      value: s.store.id,
    }));
  }, [stores]);

  const path = session.account.config.catalogPath + "/catalogs/";
  const isPublished = edited.status === "Published";

  const setPrivacy = (privacy: number) => {
    if (privacy >= PRIVACY_PROTECTED && !session.account.isEntPlusPlan) {
      openUpgradeModal({
        title: "Protected catalogs are available for Pro accounts only.",
      });
      return;
    }
    setEdited({ ...edited, privacy });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      TransitionComponent={Transition}
    >
      <DialogTitle>
        Publish {catalog.name} <CloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>
            <b>Permalink: </b>
            {permalink ? "" : path + edited.permalink}
          </Typography>
          {!permalink && (
            <>
              <Button
                size="small"
                onClick={() => setPermalink(catalog.permalink)}
              >
                Edit
              </Button>
              {edited.permalink === catalog.permalink && (
                <IconButton
                  size="small"
                  title={"Open the catalog site in the new tab"}
                  href={path + edited.permalink + ".html"}
                  target="_blank"
                >
                  <IconTypes.OpenInNew />
                </IconButton>
              )}
            </>
          )}
          {permalink && (
            <>
              <TextField
                size="small"
                sx={{ mx: 2 }}
                required
                value={permalink}
                onChange={(e) => setPermalink(e.target.value)}
                error={!!permalinkError}
                helperText={permalinkError}
              />
              <Button
                size="small"
                onClick={() => {
                  setEdited({ ...edited, permalink });
                  trackEvent({
                    category: "catalog",
                    action: "permalink",
                    label: permalink,
                  });
                  setPermalink(null);
                }}
                disabled={!!permalinkError}
              >
                Apply
              </Button>
              <Button
                size="small"
                onClick={() => {
                  setPermalink(null);
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </Box>
        <Box>
          <Typography variant="h6" sx={{ my: 2 }}>
            Settings
          </Typography>
          <Box sx={{ mb: 2 }}>
            <SelectFromList
              label="Privacy"
              size="small"
              entries={CatalogPrivacyLevels}
              value={edited.privacy ?? 0}
              setValue={(c) => {
                setPrivacy(c);
              }}
              helpText={CatalogPrivacyLevels[edited.privacy ?? 0].d}
            />
            {edited.privacy === PRIVACY_PROTECTED && (
              <TextField
                sx={{ ml: 2 }}
                label="Access code"
                size="small"
                value={edited.accessCode ?? ""}
                onChange={(e) =>
                  setEdited({ ...edited, accessCode: e.target.value })
                }
              />
            )}
          </Box>

          <FormGroup sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={edited.pdf}
                  onChange={(e) => {
                    setEdited({ ...edited, pdf: e.target.checked });
                  }}
                />
              }
              label="Enable PDF"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={edited.html}
                  onChange={(e) => {
                    setEdited({ ...edited, html: e.target.checked });
                  }}
                />
              }
              label="Enable HTML"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={edited.social}
                  onChange={(e) => {
                    setEdited({ ...edited, social: e.target.checked });
                  }}
                />
              }
              label="Show social buttons"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={edited.hideOther}
                  onChange={(e) => {
                    setEdited({ ...edited, hideOther: e.target.checked });
                  }}
                />
              }
              label="Hide other public catalogs"
            />
          </FormGroup>
          <FormGroup
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: "row",
              mt: 3,
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={edited.storeLinked}
                  onChange={(e) => {
                    setEdited({ ...edited, storeLinked: e.target.checked });
                  }}
                />
              }
              label="Link Showroom"
            />
            {edited.storeLinked && (
              <>
                <SelectFromList
                  label="Showroom"
                  size="small"
                  entries={storeList}
                  value={edited.storeId ?? 0}
                  setValue={(storeId) => {
                    setEdited({ ...edited, storeId });
                  }}
                />
                <Box>
                  {" "}
                  <TextField
                    label="Button text"
                    size="small"
                    value={edited.storeButton ?? ""}
                    onChange={(e) =>
                      setEdited({ ...edited, storeButton: e.target.value })
                    }
                  />
                </Box>
              </>
            )}
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ProcessButton
          label={isPublished ? "Update" : "Publish"}
          color={isPublished ? "primary" : "success"}
          processing={isProcessing}
          disabled={!!permalink}
          onClick={() => handlePublish("Published")}
          startIcon={isPublished ? null : <IconTypes.Play />}
        />
        {edited.status === "Published" && (
          <ProcessButton
            label="Unpublish"
            variant="outlined"
            color="primary"
            processing={isProcessing}
            disabled={!!permalink}
            onClick={() => handlePublish("Draft")}
            startIcon={<IconTypes.Pause />}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};
