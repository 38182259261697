import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { SelectFromList } from "../../../components/SelectFromList";
import { EMPTY_GUID } from "../../../features/data/data-types";
import { Template } from "../../../features/templates/templates";
import { FixedPanel } from "../../../components/FixedPanel";

const defaultTemplateSizes = [
  { n: "full", v: 12 },
  { n: "5/6", v: 10 },
  { n: "3/4", v: 9 },
  { n: "2/3", v: 8 },
  { n: "1/2", v: 6 },
  { n: "1/3", v: 4 },
  { n: "1/4", v: 3 },
  { n: "1/6", v: 2 },
  { n: "1/12", v: 1 },
];

const sizes = defaultTemplateSizes.map((s) => ({ name: s.n, value: "" + s.v }));

type TemplateSettingsProps = {
  template: Template;
  onChange: (template: Template, action: string) => void;
};

export const TemplateSettings = ({
  template,
  onChange,
}: TemplateSettingsProps) => {
  return (
    <FixedPanel overflow="auto">
      <Box sx={{ px: 3, mt: 2 }}>
        <div>
          <FormControl fullWidth>
            <TextField
              label="Card Name"
              value={template.name ?? ""}
              required
              onChange={(e) =>
                e.target.value &&
                onChange({ ...template, name: e.target.value }, "name")
              }
            />
          </FormControl>
        </div>
        <Typography variant="caption" component={"div"} my={2}>
          Card ID: {template.id === EMPTY_GUID ? "NEW" : template.id}
        </Typography>
        <FormControl component="fieldset" variant="standard" sx={{ my: 2 }}>
          <FormLabel component="legend">Default Page Size</FormLabel>

          <Box sx={{ display: "flex", mt: 2, gap: 2 }}>
            <SelectFromList
              entries={sizes}
              label="Width"
              value={"" + template.width}
              setValue={(s) => {
                onChange({ ...template, width: +s }, "width");
              }}
              size="small"
              sx={{ width: "100%" }}
            />
            <SelectFromList
              entries={sizes}
              label="Height"
              value={"" + template.height}
              setValue={(s) => {
                onChange({ ...template, height: +s }, "height");
              }}
              size="small"
              sx={{ width: "100%" }}
            />
          </Box>
          <FormHelperText>in Showroom, overlay, catalog pages</FormHelperText>
        </FormControl>

        <Box my={2}>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Default Card for</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={template.isDefault ?? false}
                    onChange={(e) =>
                      onChange(
                        { ...template, isDefault: e.target.checked },
                        "default"
                      )
                    }
                  />
                }
                label="Catalog Product Grid"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={template.isDefaultDetails ?? false}
                    onChange={(e) =>
                      onChange(
                        { ...template, isDefaultDetails: e.target.checked },
                        "default"
                      )
                    }
                  />
                }
                label="Showroom Product Details"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={template.isDefaultList ?? false}
                    onChange={(e) =>
                      onChange(
                        { ...template, isDefaultList: e.target.checked },
                        "default"
                      )
                    }
                  />
                }
                label="Showroom Product List"
              />
            </FormGroup>
          </FormControl>
        </Box>
      </Box>
    </FixedPanel>
  );
};
