import { useCallback } from "react";
import {
  Box,
  List,
  Button,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import { FixedPanel } from "../../../components/FixedPanel";
import { IconTypes } from "../../../components/icons.types";
import { EMPTY_GUID } from "../../../features/data/data-types";
import { CollectionRef } from "../../../features/products/product";
import { useProducts } from "../../../features/products/product-func";

type Props = {
  collectionId: string;
  onSelect: (collectionId: string) => void;
  onOpenEdit: (collectionId: string, parentId?: string) => void;
};

export const CollectionNav = ({
  collectionId,
  onSelect,
  onOpenEdit,
}: Props) => {
  const { topCollections } = useProducts();

  const getCollectionList = useCallback(
    (r: CollectionRef, level: number) => {
      const children = r.children
        ? r.children
            .sortList((c) => c.group.name.toLowerCase())
            .map((c) => getCollectionList(c, level + 1))
        : [];
      return [
        <ListItemButton
          key={r.group.id}
          onClick={() => {
            onSelect(r.group.id);
          }}
          selected={collectionId === r.group.id}
          className="collection"
        >
          <ListItemIcon className="dot-item">
            <div
              className="dot"
              style={{ backgroundColor: r.group.color }}
            ></div>
          </ListItemIcon>
          <ListItemIcon className="image-item">
            {r.thumb && <img src={r.thumb} alt={r.group.thumbnail} />}
          </ListItemIcon>
          <ListItemIcon className="rank-item">
            {r.group.rank > 0 && <div>{r.group.rank}</div>}
          </ListItemIcon>
          <ListItemText
            primary={r.group.name}
            sx={{
              pl: level * 2 + 1,
              "& .MuiTypography-root": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "160px",
              },
            }}
          />
          <ListItemText
            secondary={r.group.items.length + "/" + r.totalProductCount}
            className="count-text"
          />
          <ListItemIcon className="edit-btn">
            <div>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onOpenEdit(r.group.id);
                }}
                title="Edit collection"
              >
                <IconTypes.Edit />
              </IconButton>
            </div>
            <div>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onOpenEdit(EMPTY_GUID, r.group.id);
                }}
                title="Add sub-collection"
              >
                <IconTypes.Add />
              </IconButton>
            </div>
          </ListItemIcon>
        </ListItemButton>,
        ...children,
      ];
    },
    [collectionId, onOpenEdit, onSelect]
  );

  return (
    <FixedPanel overflow="auto" breakpoint="md">
      <Box sx={{ p: 1 }}>
        <List
          dense={true}
          sx={{
            "& .dot-item": {
              minWidth: 14,
              "& .dot": {
                height: 8,
                width: 8,
                borderRadius: 10,
              },
            },
            "& .image-item": {
              minWidth: 25,
              "& img": {
                maxHeight: 30,
                maxWidth: 25,
              },
            },
            "& .rank-item": {
              minWidth: 5,
              div: {
                ml: 0.5,
                fontSize: 9,
                color: "#999",
                backgroundColor: "#eee",
                borderRadius: "4px",
                padding: "1px 4px",
              },
            },
            "& .edit-btn": {
              "& svg": {
                fontSize: 16,
              },
            },
          }}
        >
          {topCollections.map((r) => getCollectionList(r, 0))}
        </List>
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            color="primary"
            startIcon={<IconTypes.Add />}
            onClick={() => onOpenEdit(EMPTY_GUID)}
            variant="text"
            size="small"
          >
            Add collection
          </Button>
        </Box>
      </Box>
    </FixedPanel>
  );
};
