import { Box } from "@mui/material";

import {
  startElementDrag,
  endElementDrag,
  ElementDragProps,
} from "../designer.func";
import { IconTypes } from "../../../components/icons.types";
import { CreateItemAttrs } from "../../../features/catalogs/catalog.design";
import { DesignSubtype } from "../../../features/design/design.types";
import { DefaultImageField } from "../../../features/data/data-types";

export type ElementType = {
  onClick: (attrs: CreateItemAttrs) => void;
};

export const TextElement = ({
  content,
  title,
  subtype,
  onClick,
  productId,
}: {
  content: string;
  title?: string;
  subtype?: DesignSubtype;
  productId?: string;
} & ElementType) => {
  const attrs = { type: "text", content, subtype, productId };
  return (
    <Box onClick={() => onClick({ ...attrs, pos: "none" } as CreateItemAttrs)}>
      <div
        className="element sidebar-element"
        draggable="true"
        onDragStart={(e) => startElementDrag(e, attrs as ElementDragProps)}
        onDragEnd={endElementDrag}
      >
        {!subtype && <IconTypes.Text className="icon" />}
        {subtype && <IconTypes.TextPlaceholder className="icon" />}
        <div className="title">{title ?? "Text"}</div>
      </div>
    </Box>
  );
};

export const BackgroundElement = ({ onClick }: ElementType) => {
  const attrs = { type: "bg", content: "" };
  return (
    <Box onClick={() => onClick({ ...attrs, pos: "none" } as CreateItemAttrs)}>
      <div
        className="element sidebar-element"
        draggable="true"
        onDragStart={(e) => startElementDrag(e, attrs as ElementDragProps)}
        onDragEnd={endElementDrag}
      >
        <IconTypes.Background className="icon" />

        <div className="title">Rectangle</div>
      </div>
    </Box>
  );
};

export const ImageElement = ({
  title,
  onDrop,
  onClick,
  content,
}: {
  title?: string;
  onDrop?: () => void;
  content?: string;
} & ElementType) => {
  const attrs = {
    type: "image",
    content: content ?? "",
  };
  return (
    <Box onClick={() => onClick({ ...attrs, pos: "none" } as CreateItemAttrs)}>
      <div
        className="element sidebar-element"
        draggable="true"
        onDragStart={(e) =>
          startElementDrag(e, attrs as ElementDragProps, onDrop)
        }
        onDragEnd={endElementDrag}
      >
        <IconTypes.Image className="icon" />

        <div className="title">{title ?? "Image"}</div>
      </div>
    </Box>
  );
};

export const ProductImageElement = ({ onClick }: ElementType) => {
  const attrs = {
    type: "product-image",
    content: DefaultImageField,
  };
  return (
    <Box onClick={() => onClick({ ...attrs, pos: "none" } as CreateItemAttrs)}>
      <div
        className="element sidebar-element"
        draggable="true"
        onDragStart={(e) => startElementDrag(e, attrs as ElementDragProps)}
        onDragEnd={endElementDrag}
      >
        <IconTypes.Image className="icon" />

        <div className="title">Product Image</div>
      </div>
    </Box>
  );
};

export const ProductListElement = ({ onClick }: ElementType) => {
  const attrs = {
    type: "product-list",
    contentType: "text",
    content: "",
  };
  return (
    <Box onClick={() => onClick({ ...attrs, pos: "none" } as CreateItemAttrs)}>
      <div
        className="element sidebar-element"
        draggable="true"
        onDragStart={(e) => startElementDrag(e, attrs as ElementDragProps)}
        onDragEnd={endElementDrag}
      >
        <IconTypes.List className="icon" />

        <div className="title">Product List</div>
      </div>
    </Box>
  );
};

export const TemplateElement = ({
  onDrop,
  onClick,
}: ElementType & { onDrop?: () => void }) => {
  const attrs = {
    type: "template",
    content: "",
  };
  return (
    <Box onClick={() => onClick({ ...attrs, pos: "none" } as CreateItemAttrs)}>
      <div
        className="element sidebar-element"
        draggable="true"
        onDragStart={(e) =>
          startElementDrag(e, attrs as ElementDragProps, onDrop)
        }
        onDragEnd={endElementDrag}
      >
        <IconTypes.TemplatePlaceholder className="icon" />

        <div className="title">Card</div>
      </div>
    </Box>
  );
};
