import type { FC } from "react";
import { Box, Card, LinearProgress, Typography } from "@mui/material";

interface ProgressMetricProps {
  title: string;
  info?: string;
  unit?: string;
  value: number;
  maxValue: number;
}

const ProgressMetric: FC<ProgressMetricProps> = ({
  title,
  info,
  unit,
  value,
  maxValue,
}: ProgressMetricProps) => {
  const progress =
    maxValue > 0 ? Math.min(Math.floor((value / maxValue) * 100), 100) : 0;
  return (
    <Card
      sx={{
        padding: 3,
      }}
    >
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Typography
          component="h3"
          gutterBottom
          variant="overline"
          color="textSecondary"
        >
          {title}
        </Typography>{" "}
        <Typography variant="caption" color="textSecondary" mt={1}>
          {info}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Typography variant="h6" color="textPrimary">
          {value}
        </Typography>
        <LinearProgress
          sx={{
            margin: "0px 8px",
            flexGrow: 1,
          }}
          value={progress}
          color={progress < 90 ? "primary" : "error"}
          variant="determinate"
        />{" "}
        <Typography variant="body2" color="textSecondary">
          {maxValue} {unit}
        </Typography>
      </Box>
    </Card>
  );
};

export default ProgressMetric;
