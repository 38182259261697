import { Navigate } from "react-router-dom";
import { isLoggedIn } from "../features/accounts/auth.service";

interface GuestGuardProps {
  children?: React.ReactNode;
}

const GuestGuard: React.FC<GuestGuardProps> = ({
  children,
}: GuestGuardProps) => {
  if (isLoggedIn()) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};

export default GuestGuard;
