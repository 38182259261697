import { useCallback, useState } from "react";
import { HtmlEditorProps } from "../remirror/editor.context";
import { HtmlEditorDialog } from "./HtmlEditorDialog";

export const useHtmlEditorDialog = () => {
  const [params, setParams] = useState<HtmlEditorProps>();

  const openHtmlEditorModal = (p: HtmlEditorProps) => setParams(p);
  const renderHtmlEditorModal = useCallback(() => {
    if (!params) return null;

    return (
      <HtmlEditorDialog
        {...params}
        isOpen={!!params}
        onClose={() => setParams(null)}
      />
    );
  }, [params]);

  return {
    renderHtmlEditorModal,
    openHtmlEditorModal,
    isHtmlEditorOpened: () => !!params,
  };
};
