export const processImage = (img: HTMLImageElement, container?: string) => {
  if (!img) return;

  const block = (img.closest(container) as HTMLElement) || img.parentElement;

  let cw = block?.offsetWidth ?? 0;
  let ch = block?.offsetHeight ?? 0;
  const cwd = +(img.getAttribute("data-width") ?? 0);
  const chd = +(img.getAttribute("data-height") ?? 0);
  if (cwd > 0) cw = cwd;
  if (chd > 0) ch = chd;

  if (cw === 0 || ch === 0) return;

  const pos = img.getAttribute("data-pos");
  //var size = imgNaturalSize(img);
  const iw = img.naturalWidth || img.width || 1;
  const ih = img.naturalHeight || img.height || 1;

  const css = {
    top: "0px",
    left: "0px",
    "max-width": "none",
    "max-height": "none",
    width: "auto",
    height: "auto",
  };
  switch (pos) {
    case "Fit":
    case "Fill": {
      const k =
        pos === "Fit" ? Math.min(cw / iw, ch / ih) : Math.max(cw / iw, ch / ih);
      css["width"] = Math.floor(iw * k) + "px";
      css["height"] = Math.floor(ih * k) + "px";
      css["top"] = Math.floor((ch - ih * k) / 2) + "px";
      css["left"] = Math.floor((cw - iw * k) / 2) + "px";
      break;
    }
    case "Stretch": {
      css["width"] = cw + "px";
      css["height"] = ch + "px";
      break;
    }
    case "Center":
      css["top"] = Math.floor((ch - ih) / 2) + "px";
      css["left"] = Math.floor((cw - iw) / 2) + "px";
      break;
  }

  Object.entries(css).forEach(([key, value]) => {
    img.style[key] = value;
  });

  const origSrc = img.getAttribute("data-orig-src");
  if (origSrc) {
    img.onerror = () => {
      if (img.getAttribute("src") !== origSrc) {
        img.setAttribute("src", origSrc);
      }
    };
  }
};

export const stripScript = (text: string) => {
  if (!text || (text.indexOf && text.indexOf("script") === -1))
    return text || "";

  const result = text.replace(
    /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
    ""
  );
  return result;
};

window["processImage"] = processImage;
