import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import ErrorBoundary from "../../contexts/ErrorBoundary";

export const EmptyLayout: React.FC = () => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        overflowY: "hidden",
        overflowX: "hidden",
      }}
    >
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </Box>
  );
};
