import { useCallback } from "react";

import { Box, IconButton, Theme, Typography } from "@mui/material";

import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import { DesignerHistoryApi } from "../useHistory";
import { MoreButton } from "../../../components/buttons/MoreButton";
import { ProcessButton } from "../../../components/buttons/ProcessButton";
import { IconTypes } from "../../../components/icons.types";
import ThemeWrapper from "../../../ui/ThemeWrapper";
import ErrorBoundary from "../../../contexts/ErrorBoundary";

type DesignerShellProps = {
  children?: React.ReactNode | React.ReactNode[];
  toolbar: React.ReactNode | React.ReactNode[];
  startToolbar: React.ReactNode | React.ReactNode[];
  endToolbar: React.ReactNode | React.ReactNode[];
  moreMenu: (onClose: () => void) => React.ReactNode | React.ReactNode[];
  isSaving: boolean;
  onSave: () => void;
  hadSave?: boolean;
  isDirty: boolean;
  historyApi: DesignerHistoryApi;
  modal?: boolean;
  readonly?: boolean;
  theme?: Theme;
  cssClass: string;
};

export const DesignerShell = ({
  children,
  toolbar,
  startToolbar,
  endToolbar,
  moreMenu,
  isDirty,
  historyApi,
  isSaving,
  onSave,
  hadSave,
  readonly,
  theme,
  cssClass,
}: DesignerShellProps) => {
  //const [undoAnchor, setUndoAnchor] = useState<null | HTMLElement>(null);

  const { history, undo, redo, getHistory } = historyApi;

  const undoAction = useCallback(
    (i: number) => {
      undo(i);
      //setUndoAnchor(null);
    },
    [undo]
  );

  //const [redoAnchor, setRedoAnchor] = useState<null | HTMLElement>(null);

  const redoAction = useCallback(
    (i: number) => {
      redo(i);
      //setRedoAnchor(null);
    },
    [redo]
  );

  return (
    <Box sx={{ height: "100%", width: "100%" }} className="designer-container">
      <ThemeWrapper theme={theme}>
        <Box
          className="designer-toolbar"
          sx={{
            display: "flex",
            alignContent: "center",
            gap: 1,
            p: 0.5,
            minHeight: 55,
          }}
        >
          {startToolbar}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              gap: 3,
            }}
          >
            <Box
              sx={{
                minWidth: 200,
                display: "flex",
                alignItems: "center",
              }}
            >
              {!readonly && (
                <>
                  <IconButton
                    color="inherit"
                    title={"Undo " + getHistory()?.[0]?.title}
                    onClick={() => undoAction(0)}
                    disabled={getHistory().length < 1}
                  >
                    <UndoIcon />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    title={"Redo " + history.redo?.[0]?.title}
                    onClick={() => redoAction(0)}
                    disabled={!history.redo.length}
                  >
                    <RedoIcon />
                  </IconButton>
                  {!isDirty && hadSave && (
                    <Typography
                      variant="caption"
                      sx={{ ml: 1, fontSize: 12, color: "#ccc" }}
                    >
                      All changes saved
                    </Typography>
                  )}
                </>
              )}
            </Box>
            {toolbar}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& .MuiButtonGroup-grouped.more": {
                minWidth: 20,
                p: 0,
              },
            }}
          >
            {!readonly && (
              <ProcessButton
                onClick={onSave}
                processing={isSaving}
                color="inherit"
                disabled={!isDirty}
                sx={{ m: 0.5, ml: 2 }}
                startIcon={<IconTypes.Save />}
              />
            )}
            {endToolbar}
            {!readonly && (
              <MoreButton
                menu={moreMenu}
                color="inherit"
                cssClass={cssClass + "-menu"}
              />
            )}
          </Box>
        </Box>
      </ThemeWrapper>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Box>
  );
};
