import apiClient, {
  BaseOperationResult,
  OperationResult,
} from "../../services/apiClient";
import { ServerInfo } from "../app/app.state";
import { AccountInfo, AccountUser } from "./account";
import {
  AccessLogRequest,
  AccessLogResponse,
  AccountSettings,
  AccountUsersResponse,
  BusinessData,
  CancelSurveyResponse,
  UserProfileData,
  UserSecurityData,
} from "./account.data";

export const getServerInfo = async (): Promise<ServerInfo> => {
  const response = await apiClient.get<ServerInfo>("/auth/info");
  return response.data;
};

export const loadAccountInfo = async (): Promise<AccountInfo> => {
  const response = await apiClient.get<AccountInfo>("/account/info");
  return response.data;
};

export const saveAccountProfile = async (dict): Promise<{ status: string }> => {
  const response = await apiClient.post<{ status: string }>(
    "/account/save-profile",
    dict
  );
  return response.data;
};

export const wipeAccountData = async (): Promise<BaseOperationResult> => {
  const response = await apiClient.post<BaseOperationResult>(
    "/account/wipe-data"
  );
  return response.data;
};

export const cancelAccount = async (
  data: CancelSurveyResponse
): Promise<BaseOperationResult> => {
  const response = await apiClient.post<BaseOperationResult>(
    "/account/delete-account",
    data
  );
  return response.data;
};

export const generateAPIKey = async (): Promise<OperationResult<string>> => {
  const response = await apiClient.post<OperationResult<string>>(
    "/account/generate-api-key"
  );
  return response.data;
};

export const saveUserProfile = async (
  data: UserProfileData
): Promise<OperationResult<boolean>> => {
  const response = await apiClient.post<OperationResult<boolean>>(
    "/account/user-profile",
    data
  );
  return response.data;
};

export const changeSecurity = async (
  data: UserSecurityData
): Promise<OperationResult<boolean>> => {
  const response = await apiClient.post<OperationResult<boolean>>(
    "/account/user-security",
    data
  );
  return response.data;
};

export const removeProviderLogin = async (
  provider: string
): Promise<OperationResult<string[]>> => {
  const response = await apiClient.post<OperationResult<string[]>>(
    "/auth/remove-login?provider=" + provider
  );
  return response.data;
};

export const getLoginProviders = async (): Promise<string[]> => {
  const response = await apiClient.get<string[]>("/auth/providers");
  return response.data;
};

export const getBusinessProfile = async (): Promise<BusinessData> => {
  const response = await apiClient.get<BusinessData>("/account/business");
  return response.data;
};

export const saveBusinessProfile = async (
  data: BusinessData
): Promise<OperationResult<boolean>> => {
  const response = await apiClient.post<OperationResult<boolean>>(
    "/account/business",
    data
  );
  return response.data;
};

export const getAccountSettings = async (): Promise<AccountSettings> => {
  const response = await apiClient.get<AccountSettings>("/account/settings");
  return response.data;
};

export const saveAccountSettings = async (
  data: AccountSettings
): Promise<OperationResult<boolean>> => {
  const response = await apiClient.post<OperationResult<boolean>>(
    "/account/settings",
    data
  );
  return response.data;
};

export const getAccessLog = async (
  request: AccessLogRequest
): Promise<AccessLogResponse> => {
  const response = await apiClient.get<AccessLogResponse>(
    "/account/access-log?pageNum=" +
      request.pageNum +
      "&pageSize=" +
      request.pageSize
  );
  return response.data;
};

export const getAccountUsers = async (): Promise<AccountUsersResponse> => {
  const response = await apiClient.get<AccountUsersResponse>("/account/users");
  return response.data;
};

export const saveAccountUser = async (
  user: AccountUser
): Promise<OperationResult<AccountUser[]>> => {
  const endpoint = user.id ? "/account/save-user" : "/account/invite-user";
  const response = await apiClient.post<OperationResult<AccountUser[]>>(
    endpoint,
    {
      id: user.id,
      email: user.email,
      role: user.role,
      userid: user.userId,
    }
  );
  return response.data;
};

export const deleteAccountUser = async (
  userId: number
): Promise<OperationResult<AccountUser[]>> => {
  const response = await apiClient.post<OperationResult<AccountUser[]>>(
    "/account/delete-user?userId=" + userId
  );
  return response.data;
};

export const resendAccountUserInvite = async (
  userId: number
): Promise<OperationResult<boolean>> => {
  const response = await apiClient.post<OperationResult<boolean>>(
    "/account/resend-invite?userId=" + userId
  );
  return response.data;
};
