import { useCallback, useState } from "react";
import { useAtom } from "jotai";
import { WarningDialog } from "../../../components/dialogs/WarningDialog";
import { deleteCategory } from "../../../features/products/product.service";
import { productDbAtom } from "../../../features/products/product.state";
import { useDbOperations } from "../../data-func";
import {
  Category,
  removeCollectionProducts,
} from "../../../features/products/product";

export const useCategories = (onDelete?: () => void) => {
  const [productDb, setProductDb] = useAtom(productDbAtom);

  const { processDb } = useDbOperations();

  const [categoryToDelete, setCategoryToDelete] = useState<Category>();
  const [processing, setProcessing] = useState(false);

  const processDeleteCategory = useCallback(async () => {
    processDb(
      async () => {
        return await deleteCategory(categoryToDelete.id);
      },
      () => {
        const categoryMap = new Map(productDb.categoryMap);
        categoryMap.delete(categoryToDelete.id);
        const products = Array.from(productDb.productMap.entries()).filter(
          (v) => v[1].product.categoryId !== categoryToDelete.id
        );
        const categoryProductIds = Array.from(productDb.productMap.entries())
          .filter((v) => v[1].product.categoryId === categoryToDelete.id)
          .map((v) => v[0]);
        const productMap = new Map(products);
        const collectionMap = new Map(productDb.collectionMap);

        removeCollectionProducts(collectionMap, categoryProductIds);
        setProductDb({
          ...productDb,
          categoryMap,
          productMap,
          collectionMap,
          version: productDb.version + 1,
        });
        setCategoryToDelete(null);
        onDelete?.();
      },
      setProcessing,
      "Deleted category " + categoryToDelete.name,
      "Error deleting category."
    );
  }, [
    categoryToDelete,
    onDelete,
    processDb,
    productDb,
    setProcessing,
    setProductDb,
  ]);

  const renderCategoryDeleteModal = () => {
    return (
      <WarningDialog
        open={!!categoryToDelete}
        onClose={() => {
          setCategoryToDelete(null);
        }}
        onConfirm={() => {
          processDeleteCategory();
        }}
        title={`Delete category ${categoryToDelete?.name ?? ""}?`}
        action="Delete"
        color="error"
        processing={processing}
      >
        All products in this category will be deleted everywhere including
        catalogs.
      </WarningDialog>
    );
  };

  return {
    renderCategoryDeleteModal,
    setCategoryToDelete,
  };
};
