import { useState } from "react";
import { ExportCsvModal } from "./ExportCsvModal";
import { ImportCsvModal } from "./ImportCsvModal";

type Props = {
  exportProducts?: string[];
  openCsvImport: boolean;
  onCloseCsvExport?: () => void;
  onCloseCsvImport?: () => void;
  onImport?: () => void;
  categoryId?: string;
  collectionId?: string;
};
export const ImportExportCsv = ({
  exportProducts,
  categoryId,
  collectionId,
  openCsvImport,
  onCloseCsvExport,
  onCloseCsvImport,
  onImport,
}: Props) => {
  const [sampleProducts, setSampleProducts] = useState<string[]>();

  return (
    <>
      <ImportCsvModal
        isOpen={openCsvImport}
        onClose={() => {
          onCloseCsvImport?.();
        }}
        onSample={() => setSampleProducts([])}
        categoryId={categoryId}
        collectionId={collectionId}
        onImport={onImport}
      />
      <ExportCsvModal
        productIds={exportProducts ?? sampleProducts}
        categoryId={categoryId}
        collectionId={collectionId}
        onClose={() => {
          setSampleProducts(null);
          onCloseCsvExport?.();
        }}
      />
    </>
  );
};
