//import Hotjar from "@hotjar/browser";
import * as Sentry from "@sentry/react";
import apiClient from "./apiClient";
import { isLoggedIn } from "../features/accounts/auth.service";
import { callGtag } from "./ga";

export const GA_TRACKING_ID = "G-893ZGSLH00";
export const GA_DEBUG_MODE = false;

type EventRecord = {
  category: string;
  action: string;
  value?: number;
  label?: string;
  ts?: string;
  feature?: boolean;
  internal?: boolean;
  tag?: boolean;
};

export const trackEvent = (event: EventRecord) => {
  if (!event.internal) {
    callGtag("event", event.category + "_" + event.action, {
      value: event.value ?? 1,
      debug_mode: GA_DEBUG_MODE,
    });
    Sentry.addBreadcrumb({
      category: event.category + "_" + event.action,
      message: `${event.value > 1 ? "[" + event.value + "] " : ""}${
        event.label
      }`,
      level: "info",
    });
  }
  if (event.tag || event.feature) {
    Sentry.setTag("feature." + event.category, event.action);
  }
  if (event.feature) {
    Intercom("trackEvent", event.action + " " + event.category, {
      label: event.label,
      value: event.value ?? 1,
    });
    //Hotjar.event("event:" + event.category + "_" + event.action);
  }
  trackInternal(event);
};

const _actionStack: EventRecord[] = [];

const pushEvent = (event: EventRecord) => {
  _actionStack.push({ ...event, ts: new Date().toLocaleTimeString() });
};

export const saveEventLog = (reason?: string) => {
  if (!isLoggedIn()) return;

  if ((!!reason && _actionStack.length > 1) || _actionStack.length > 50) {
    if (reason) pushEvent({ category: "app", action: "unload", label: reason });
    apiClient.post("/admin/events", {
      host: window.location.host,
      events: _actionStack,
    });
    _actionStack.length = 0;
  }
};

export const trackInternal = (event: EventRecord) => {
  pushEvent(event);
  saveEventLog();
  if (process.env.NODE_ENV?.toLowerCase() === "development")
    console.log(
      "trackEvent",
      event.category,
      event.action,
      event.label,
      (event.internal ? "i" : "") + (event.feature ? "f" : "")
    );
};
