import { SxProps, Box, Breakpoint } from "@mui/material";
import {
  useRef,
  useState,
  useEffect,
  useCallback,
  ReactNode,
  forwardRef,
  useImperativeHandle,
} from "react";
import useIsMobile from "../ui/useIsMobile";
import { uiFrame } from "../ui/constants";
//import { debounce } from "../ui/useDebounceEffect";

export type FixedPanelProps = {
  children?: ReactNode;
  margin?: number;
  overflow?: string;
  minWidth?: number;
  className?: string;
  container?: string;
  id?: string;
  onWheel?: (e: React.WheelEvent<HTMLDivElement>) => void;
  sx?: SxProps;
  breakpoint?: Breakpoint;
};

export type FixedPanelRef = {
  panelRef: React.MutableRefObject<HTMLDivElement>;
  resize: () => void;
};

export const FixedPanel = forwardRef<FixedPanelRef, FixedPanelProps>(
  (
    {
      children,
      margin,
      overflow = "auto",
      //minWidth,
      className,
      container,
      id,
      onWheel,
      sx,
      breakpoint,
    }: FixedPanelProps,
    panelRef: React.MutableRefObject<FixedPanelRef>
  ) => {
    const ref = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);
    const isMobile = useIsMobile(breakpoint);

    const adjustHeight = useCallback(() => {
      if (ref.current) {
        const r = ref.current.getBoundingClientRect();
        let top = r?.top || ref.current.offsetTop;
        if (container) {
          const c = document.querySelector(container) as HTMLDivElement;
          if (c) top = ref.current.offsetTop - c.offsetTop;
        }

        const rightPanel = ref.current.closest(
          ".right-panel"
        ) as HTMLDivElement;
        const isOverflowing =
          rightPanel && rightPanel.offsetWidth < rightPanel.scrollWidth;
        const mh =
          (isMobile && breakpoint) || isOverflowing
            ? uiFrame.scrollerHeight
            : 0;
        const h = Math.ceil(window.innerHeight - top - (margin || 0) - 1 - mh);
        setHeight(h);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current?.offsetTop, isMobile]);

    //const resize = debounce(adjustHeight, 100);

    useImperativeHandle(panelRef, () => ({
      panelRef: ref,
      resize: () => {
        adjustHeight();
      },
    }));

    useEffect(() => {
      adjustHeight();
    }, [adjustHeight]);

    useEffect(() => {
      window.addEventListener("resize", adjustHeight);
      return () => window.removeEventListener("resize", adjustHeight);
    }, [adjustHeight]);

    return (
      <Box
        ref={ref}
        //style={{ height, overflow, minWidth }}
        className={className}
        id={id}
        onWheel={onWheel}
        sx={{ overflow, minHeight: 100, ...sx, height }}
      >
        {children}
      </Box>
    );
  }
);

FixedPanel.displayName = "FixedPanel";

// export default FixedPanel;
