import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";

export const QuickAction = ({
  title,
  imageSrc,
  href,
}: {
  title: string;
  imageSrc: string;
  href: string;
}) => {
  return (
    <Card
      to={href}
      component={NavLink}
      sx={{
        textAlign: "center",
        minWidth: ["100%", 200],
        textDecoration: "none",
        zIndex: 1,
      }}
    >
      <CardActionArea>
        <CardContent sx={{ mt: 1 }}>
          <img
            src={imageSrc}
            alt={title}
            style={{ maxWidth: 64, opacity: 0.7 }}
          />
          <Box sx={{ mt: 2 }}>
            <Typography variant="button">{title}</Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export const ImportQuickActions = () => {
  return (
    <>
      <QuickAction
        title="Import from CSV / Excel"
        imageSrc="/images/excel.png"
        href="/products?import=csv"
      />
      <QuickAction
        title="Import from Shopify"
        imageSrc="/images/shopify.png"
        href="/import/shopify"
      />
    </>
  );
};
