import {
  FontFamilyExtension,
  ItalicExtension,
  BoldExtension,
  UnderlineExtension,
  FontSizeExtension,
  ImageExtension,
  HardBreakExtension,
  BulletListExtension,
  OrderedListExtension,
  TextColorExtension,
  StrikeExtension,
  SubExtension,
  SupExtension,
  HeadingExtension,
  IframeExtension,
} from "remirror/extensions";
import { ReactComponentExtension } from "@remirror/react";

import { TextBgColorExtension } from "./extensions/text-bg-extension";
import { CmCodeExtension } from "./extensions/cm-code-extension";
import { CmParagraphExtension } from "./extensions/paragraph-extension";
import { StyledLinkExtension } from "./extensions/link-extension";
import { CmTableExtension } from "./extensions/table-extension";
import { CmNodeFormattingExtension } from "./extensions/node-formatting-extension";

export const editorExtensions = () => [
  new CmParagraphExtension({}),
  new CmCodeExtension({}),
  new FontFamilyExtension(),
  new FontSizeExtension({ defaultSize: "16", unit: "px" }),
  new ItalicExtension(),
  new BoldExtension({}),
  new UnderlineExtension(),
  new StrikeExtension(),
  //new TableExtension({}),
  new CmTableExtension({}),
  new ReactComponentExtension({}),
  new ImageExtension({ enableResizing: true }),
  new HardBreakExtension(),
  new StyledLinkExtension(),
  //new LinkExtension({ autoLink: true, defaultTarget: "_blank" }),
  new CmNodeFormattingExtension({}),
  new OrderedListExtension(),
  new BulletListExtension({}),
  new TextColorExtension({}),
  new TextBgColorExtension({}),
  new SupExtension(),
  new SubExtension(),
  new HeadingExtension({}),
  new IframeExtension({ enableResizing: true }),
  //new CmExprExtension(),
];
