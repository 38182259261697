import { newItem } from "./catalog.item";
import { getDefaultProductTemplateRoot } from "./catalog.func";
import {
  ALL_PRODUCTS,
  INDIVIDUAL_PRODUCTS,
  initProductContainerCollection,
} from "./catalog.products";
import { getUnique } from "../../services/util";
import { gridSize, templateGridSize } from "../design/designer";
import {
  getAllLayouts,
  getLayoutItems,
  injectLayoutItems,
} from "../layouts/layouts";
import { ProductRef } from "../products/product";
import { TemplateRef } from "../templates/templates";
import { CatalogBuilder } from "./catalog.builder";
import {
  CatalogGrid,
  PageItemConfig,
  CatalogItemRef,
  LayoutSetItem,
} from "./catalogs";
import { trackEvent } from "../../services/analytics";

type GridItem = {
  product: ProductRef;
  template: TemplateRef;
  w: number;
  h: number;
};

export const newGrid = (): CatalogGrid => {
  return {
    title: "+ New Product Grid",
    id: 0,
    w: 2,
    h: 2,
    items: [],
    products: [],
    isNew: true,
  };
};

export const refreshGrids = (builder: CatalogBuilder) => {
  const grids: CatalogGrid[] = [];
  let g: CatalogGrid;
  let breakGrid = false;
  let index = 0;
  let gridId = 1;

  builder.pages.forEach((page) => {
    if (
      page.pageItem?.di.PageType === "products" ||
      page.pageItem?.di.PageType === "collection"
    ) {
      const isCollection = page.pageItem.di.PageType === "collection";
      const config: PageItemConfig = page.pageItem?.info?.config || {};
      let templateId = config.templateId;
      let items: CatalogItemRef[] = [];
      let item: CatalogItemRef;

      if (!isCollection) {
        items = getUnique(
          page.items.filter((e) => !!e.info.product),
          (i) => i.di.ItemId
        );
        item = items[0];
        if (item && !templateId && !config.gridId) {
          templateId = item.di.TemplateId;
        }
      }
      const w = config.useLayout
        ? 1
        : gridSize / config.w ||
          (item?.di.w > 0 ? Math.floor(templateGridSize / item.di.w) : null);
      const h = config.useLayout
        ? 1
        : gridSize / config.h ||
          (item?.di.h > 0 ? Math.floor(templateGridSize / item.di.h) : null);

      if (
        g &&
        (isCollection ||
          (!isCollection && g.collection) ||
          breakGrid ||
          (config.gridName && config.gridName !== g.name) ||
          (page.pageItem.di.Title &&
            page.pageItem.di.Title !== g.sectionTitle) ||
          g.templateId !== templateId ||
          (!g.useLayout && (g.w !== w || g.h !== h)) ||
          (g.useLayout && config.layoutSet !== g.layoutSet))
      ) {
        grids.push(g);
        g = null;
        breakGrid = false;
      }
      if (g) {
        g.end = page.pageId;
        g.items = getUnique(g.items.concat(items), (i) => i.di.ItemId);
      } else {
        const id = gridId++;
        const existingGrid = builder.grids.find((g) => g.id === id);

        g = {
          id,
          name: config.gridName || "",
          useLayout: config.useLayout,
          layoutSet: config.layoutSet,
          layouts: [],
          templateId: templateId,
          collectionId: config.collectionId ?? INDIVIDUAL_PRODUCTS,
          productSource: config.productSource,
          inStock: config.inStock,
          w: w || 2,
          h: h || 2,
          begin: page.pageId,
          end: page.pageId,
          type: page.pageItem.di.PageType,
          sectionTitle: page.pageItem.di.Title,
          items: items,
          index: index++,
          products: [],
          expanded: existingGrid?.expanded ?? false,
          pageItem: page.pageItem,
        };
        if (g.templateId) {
          g.template = builder.data.templates.find(
            (t) => t.template.id === g.templateId
          );
        }
        initProductContainerCollection(builder.data.productDb, g);
        initGridLayoutSet(builder, g);
      }
    } else if (g) {
      grids.push(g);
      g = null;
    }
  });

  if (g) {
    grids.push(g);
  }

  grids.push(newGrid());

  grids.forEach((g) => {
    g.products = g.items.map((i) => i.di.ItemId);

    if (g.title) return;
    g.title = g.name || g.sectionTitle;
    if (!g.title && g.collection) g.title = g.collection.group.name;
    if (!g.title && !g.useLayout)
      g.title =
        (g.template ? g.template.template.name : "Default") +
        " [" +
        g.w +
        "x" +
        g.h +
        "]";
    if (!g.title && g.useLayout && g.layouts?.length > 0)
      g.title = g.layouts[0].layout.name;
    g.title = g.title || "Grid " + g.id;
    //+ " [p. " + g.begin + (g.end > g.begin ? " - " + g.end : "") + "]";
  });

  return grids;
};

export const initGridLayout = (
  builder: CatalogBuilder,
  grid: CatalogGrid,
  layoutId: string,
  role?: string
) => {
  if (!grid.useLayout) return;
  grid.layouts = grid.layouts || [];
  const l = getAllLayouts(builder.data.layoutDb).find((l) => {
    return l.layout.id === layoutId;
  });
  if (l) {
    //initLayout(builder, l);
    const layout = grid.layouts.find((l) => l.role === role);
    if (layout) {
      layout.layout = l.layout;
      //layout.items = l.items;
    } else {
      grid.layouts.push({
        role: role,
        layout: l.layout,
        //items: l.items,
      });
    }
  }
};

const initGridLayoutSet = (builder: CatalogBuilder, g: CatalogGrid) => {
  if (!g.useLayout) return;
  g.layouts = [];
  if (g.layoutSet) {
    const layoutSet = JSON.parse(g.layoutSet) as LayoutSetItem[];
    layoutSet.forEach((ls) => {
      initGridLayout(builder, g, ls.layoutId, ls.role);
    });
  }
};

const getGridItem = (
  builder: CatalogBuilder,
  grid: CatalogGrid,
  productId?: string
): GridItem => {
  return {
    product: productId
      ? builder.data.productDb.productMap.get(productId)
      : null,
    template: grid.template,
    w: gridSize / grid.w,
    h: gridSize / grid.h,
  };
};

const addPagesWithTemplate = (
  builder: CatalogBuilder,
  grid: CatalogGrid,
  isPreview: boolean,
  pageId: number,
  config: PageItemConfig
) => {
  grid.preview = {
    pages: [],
    pageId,
  };

  if (grid.templateId) {
    if (grid.template?.template.id !== grid.templateId || !grid.template)
      grid.template = builder.data.templates.find(
        (t) => t.template.id === grid.templateId
      );
  } else {
    grid.template = getDefaultProductTemplateRoot(builder.data.templates);
    grid.templateId = grid.template?.template.id;
  }

  initProductContainerCollection(builder.data.productDb, grid);

  const items = grid.previewProductIds.map((id) =>
    getGridItem(builder, grid, id)
  );

  let x = 0,
    y = 0,
    cy = 0,
    pos = 0;
  const scale = templateGridSize / gridSize;

  let page = null;

  for (let i = 0; i < items.length; i++) {
    const p = items[i];
    const h = p.h || (p.template ? p.template.template.height : 2);
    const w = p.w || (p.template ? p.template.template.width : 2);
    if (x + w > gridSize) {
      x = 0;
      y = cy;
    }
    if (!page || y + h > gridSize) {
      if (page) {
        pageId++;
      }
      page = builder.addPage(
        pageId,
        isPreview,
        grid.collection ? "collection" : "products",
        config
      );
      if (!page) {
        return pageId;
      }
      grid.preview.pages.push(page);
      y = 0;
      x = 0;
      cy = 0;
      pos = 0;
    }

    const item: CatalogItemRef = newItem({
      PageId: pageId,
      Position: pos++,
      Type: "template",
      ItemId: p.product?.product.id,
      TemplateId: p.template?.template.id,
      x: x * scale,
      y: y * scale,
      w: w * scale,
      h: h * scale,
    });
    if (!isPreview) builder.items.push(item);

    item.info = { template: p.template, product: p.product };

    builder.initItem(item);
    page.items.push(item);

    if (x > 0 && x + w === gridSize && y + h < cy) {
      y = y + h;
    } else {
      x = x + w;
    }
    cy = Math.max(cy, y + h);
  }
  return pageId - 1;
};

const addPagesWithLayout = (
  builder: CatalogBuilder,
  grid: CatalogGrid,
  isPreview: boolean,
  pageId: number,
  config: PageItemConfig
) => {
  grid.preview = {
    pages: [],
    pageId,
  };

  if (!grid.layouts?.length) return pageId;

  initProductContainerCollection(builder.data.productDb, grid);
  const products = grid.previewProductIds.map((id) =>
    builder.data.productDb.productMap.get(id)
  );
  grid.layouts.forEach((l) => {
    l.items = getLayoutItems(l.layout);
  });
  let last = products.length + 1;
  let i = 0;
  while (products.length && products.length < last) {
    const page = builder.addPage(
      pageId,
      isPreview,
      grid.collection ? "collection" : "products",
      config
    );
    if (!page) return pageId;
    last = products.length;
    const l = grid.layouts[i++ % grid.layouts.length];
    injectLayoutItems(builder, page, {
      x: 0,
      y: 0,
      kw: 1,
      kh: 1,
      products,
      items: l.items,
      isPreview,
    });
    pageId++;
    grid.preview.pages.push(page);
  }
  return pageId - 1;
};

const createGridPages = (
  builder: CatalogBuilder,
  grid: CatalogGrid,
  isPreview: boolean,
  pageId = 1
) => {
  const layoutSet =
    grid.useLayout && grid.layouts?.length
      ? JSON.stringify(
          grid.layouts.map((l) => ({
            layoutId: l.layout?.id,
            role: l.role,
          })) as LayoutSetItem[]
        )
      : null;

  const config: PageItemConfig = {
    ...grid.pageItem?.info.config,
    templateId: grid.templateId,
    w: gridSize / grid.w,
    h: gridSize / grid.h,
    gridName: grid.name,
    gridId: grid.id,
    title: grid.sectionTitle,
    useLayout: grid.useLayout,
    layoutSet,
    inStock: grid.inStock,
    collectionId: grid.collectionId,
    imgPosition: grid.pageItem?.imgPosition,
  };

  if (config.collectionId === INDIVIDUAL_PRODUCTS) {
    config.collectionId = null;
  }
  if (
    config.collectionId === ALL_PRODUCTS ||
    grid.collectionId === ALL_PRODUCTS
  ) {
    config.collectionId = null;
    config.productSource = ALL_PRODUCTS;
  }

  if (
    !isPreview &&
    grid.collectionId &&
    grid.collectionId !== INDIVIDUAL_PRODUCTS
  ) {
    const page = builder.addPage(pageId, false, "collection", config);
    return page?.pageId;
  }
  if (grid.useLayout) {
    return addPagesWithLayout(builder, grid, isPreview, pageId, config);
  } else {
    return addPagesWithTemplate(builder, grid, isPreview, pageId, config);
  }
};

export const previewGrid = (builder: CatalogBuilder, grid: CatalogGrid) => {
  createGridPages(builder, grid, true);
};

export const deleteGrid = (builder: CatalogBuilder, grid: CatalogGrid) => {
  builder.deletePages(grid.begin, grid.end - grid.begin + 1);
};

export const generateGrid = (builder: CatalogBuilder, grid: CatalogGrid) => {
  if (!grid.isNew) {
    deleteGrid(builder, grid);
  }
  const pageId = createGridPages(builder, grid, false, grid.begin);
  trackEvent({
    category: "grid",
    action: "generate",
    label: `create pages: ${grid.begin} - ${grid.end ?? "?"}, page: ${pageId} ${
      grid.title
    } [${grid.useLayout ? "layout" : "template"}] collection: ${
      grid.collectionId
    }`,
    internal: true,
  });
  builder.refreshPages(true);
  builder.setActivePage(grid.begin);
};
