import {
  ApplySchemaAttributes,
  extension,
  ExtensionPriority,
  ExtensionTag,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
} from "remirror";
import { ParagraphExtension } from "remirror/extensions";

@extension({ defaultOptions: {}, defaultPriority: ExtensionPriority.Medium })
export class CmParagraphExtension extends ParagraphExtension {
  createExtensions() {
    //return [new DivExtension({})];
    return [new DivExtension({})];
  }
}

// class DivBlockExtension extends NodeExtension {
//   get name() {
//     return "divBlock" as const;
//   }

//   createNodeSpec(
//     extra: ApplySchemaAttributes,
//     override: NodeSpecOverride
//   ): NodeExtensionSpec {
//     return {
//       content: "block+",
//       group: "block",
//       ...override,
//       attrs: {
//         ...extra.defaults(),
//       },
//       parseDOM: [
//         {
//           tag: "div",
//           getAttrs: (node) => ({
//             ...extra.parse(node),
//           }),
//         },
//         ...(override.parseDOM ?? []),
//       ],

//       toDOM: (node) => {
//         return ["div", extra.dom(node), 0];
//       },
//     };
//   }
// }

// class DivInlineExtension extends NodeExtension {
//   get name() {
//     return "div" as const;
//   }

//   createTags() {
//     return [ExtensionTag.FormattingNode];
//   }

//   createNodeSpec(
//     extra: ApplySchemaAttributes,
//     override: NodeSpecOverride
//   ): NodeExtensionSpec {
//     return {
//       content: "inline*",
//       group: "block",
//       marks: "",
//       ...override,
//       attrs: {
//         ...extra.defaults(),
//       },
//       parseDOM: [
//         {
//           tag: "div",
//           getAttrs: (node) => ({
//             ...extra.parse(node),
//           }),
//         },
//         ...(override.parseDOM ?? []),
//       ],

//       toDOM: (node) => {
//         return ["div", extra.dom(node), 0];
//       },
//     };
//   }
// }

export class DivExtension extends NodeExtension {
  get name() {
    return "div" as const;
  }

  createTags() {
    return [
      ExtensionTag.LastNodeCompatible,
      ExtensionTag.TextBlock,
      ExtensionTag.Block,
      ExtensionTag.FormattingNode,
    ];
  }

  createNodeSpec(
    extra: ApplySchemaAttributes,
    override: NodeSpecOverride
  ): NodeExtensionSpec {
    return {
      content: "(block | div)+",
      group: "block",
      ...override,
      attrs: {
        ...extra.defaults(),
      },
      parseDOM: [
        {
          tag: "div",
          getAttrs: (node) => ({
            ...extra.parse(node),
          }),
        },
        ...(override.parseDOM ?? []),
      ],

      toDOM: (node) => {
        return ["div", extra.dom(node), 0];
      },
    };
  }
}
