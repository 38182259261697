import { trackEvent } from "../../services/analytics";
import apiClient, { BaseOperationResult } from "../../services/apiClient";
import { DataType } from "../data/data-types";

export const CsvJobId = "csv-import";

export type CsvField = {
  name: string;
  value: string;
  dataType?: DataType;
  ignore?: boolean;
  isVariant?: boolean;
};

export type CsvRecord = {
  fields: CsvField[];
  subRecords: CsvRecord[];
  collectionNames: string[];
  index: number;
  code: string;
  categoryName: string;
};

export type MetaRecord = {
  name: string;
  count: number;
  checked?: boolean;
};

export type FieldRecord = {
  dataType: string;
} & MetaRecord;

export type CategoryRecord = {
  fields: FieldRecord[];
  options: MetaRecord[];
  isNew: boolean;
} & MetaRecord;

export type CsvError = {
  message: string;
  code: string;
  index: number;
  isWarning?: boolean;
  count: number;
};

export type CsvAnalysys = {
  categories: CategoryRecord[];
  collections: MetaRecord[];
  recordsCount: number;
  headers: string[];
  categoryChanged: boolean;
  categoryAdded: number;
  resolved?: boolean;
  limitExceeded?: number;
  codeHeaderAbsent?: boolean;
  missedCode?: number;
  missedName?: number;
  missedCategories?: number;
  productMoved?: number;
};

export type CsvResult = {
  createLimit: number;
  maxLimit: number;
  fileName: string;
  analysis: CsvAnalysys;
  records: CsvRecord[];
  errors: CsvError[];
  jobId?: string;
  errorCount?: number;
  warningCount?: number;
  count?: number;
  criticalStop?: boolean;
};

export type CsvImportOptions = {
  categoryId?: string;
  importText?: string;
  importMode?: string;
  nameField?: string;
  codeField?: string;
  preview?: boolean;
  collectionId?: string;
};

export const syncMeta = async (
  analysis: CsvAnalysys
): Promise<BaseOperationResult> => {
  trackEvent({ category: "csv", action: "sync-meta" });
  const response = await apiClient.post<BaseOperationResult>(
    "/data/sync-meta",
    analysis
  );
  return response.data;
};
