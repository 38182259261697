import { useCallback, useState } from "react";
import {
  OpenUpgradeModalFunc,
  UpgradeAttrs,
  UpgradeDialog,
  UpgradeFuncAttrs,
} from "./UpgradeDialog";
import { useNavigate } from "react-router-dom";

export const useUpgradeDialog = () => {
  const [upgradeAttrs, setUpgradeAttrs] = useState<UpgradeAttrs>();

  const navigate = useNavigate();

  const openUpgradeModal: OpenUpgradeModalFunc = (
    funcAttrs: UpgradeFuncAttrs
  ) => {
    const attrs =
      typeof funcAttrs === "string" ? { type: funcAttrs } : funcAttrs;
    setUpgradeAttrs(attrs);
    if (attrs.type === "catalogs") {
      navigate("/catalogs");
    }
  };
  const renderUpgradeModal = useCallback(() => {
    if (!upgradeAttrs) return null;

    return (
      <UpgradeDialog
        {...upgradeAttrs}
        isOpen={!!upgradeAttrs}
        onClose={() => setUpgradeAttrs(null)}
      />
    );
  }, [upgradeAttrs]);

  return { renderUpgradeModal, openUpgradeModal };
};
