import { ReactNode } from "react";
import { useImageModals } from "../features/images/image-modals";
import { useProductModals } from "../features/products/product-modals";
import { useHtmlEditorDialog } from "../components/dialogs/useHtmlEditorDialog";
import { useUpgradeDialog } from "../components/dialogs/useUpgradeDialog";
import {
  useCatalogShareModal,
  useCatalogSelectModal,
} from "../containers/catalogs/useCatalogDialogs";
import { useTemplateDesignerModal } from "../containers/designer/templates/useTemplateDesignerModal";
import { useProductListExtModal } from "../containers/designer/product-list/useProductListExtModal";
import { DialogContext } from "./dialog-context-create";

// const defaultNoOp = () => {
//   console.warn("Function not initialized");
// };

interface DialogProviderProps {
  children?: ReactNode;
}

export const DialogProvider: React.FC<DialogProviderProps> = ({
  children,
}: DialogProviderProps) => {
  const { renderImageModals, openImageModal } = useImageModals();
  const { openUpgradeModal, renderUpgradeModal } = useUpgradeDialog();
  const { openHtmlEditorModal, renderHtmlEditorModal, isHtmlEditorOpened } =
    useHtmlEditorDialog();
  const { renderProductModals, productModalApi } = useProductModals();
  const { openTemplateDesigner, renderTemplateDesignerModal } =
    useTemplateDesignerModal();
  const { openProductListExt, renderProductListExtModal } =
    useProductListExtModal();
  const { openCatalogShareModal, renderCatalogShareModal } =
    useCatalogShareModal();

  const { renderCatalogSelectModal, openCatalogSelectModal } =
    useCatalogSelectModal();

  return (
    <DialogContext.Provider
      value={{
        openImageModal,
        openUpgradeModal,
        openHtmlEditorModal,
        openTemplateDesigner,
        productModalApi,
        openProductListExt,
        openCatalogShareModal,
        openCatalogSelectModal,
        isHtmlEditorOpened,
      }}
    >
      {children}
      {renderImageModals()}
      {renderUpgradeModal()}
      {renderHtmlEditorModal()}
      {renderProductModals()}
      {renderTemplateDesignerModal()}
      {renderProductListExtModal()}
      {renderCatalogShareModal()}
      {renderCatalogSelectModal()}
    </DialogContext.Provider>
  );
};
