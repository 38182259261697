import { Component } from "react";

interface State {
  hasError: boolean;
  error?: Error;
  info?: string;
}

const pingUrl = import.meta.env.VITE_API_URL + "/ping";
const nodeEnv = process.env.NODE_ENV;

class AppHealth extends Component<unknown, State> {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  public async componentDidMount() {
    const info = await fetch(pingUrl).then((res) => res.text());
    this.setState({ ...this.state, info });
  }

  render() {
    if (this.state.hasError) {
      return "Unhealthy - error";
    }
    if (!this.state.info) {
      return "Unhealthy - api";
    }

    return (
      "status: " +
      this.state.info +
      " api: " +
      import.meta.env.VITE_API_URL +
      " client v" +
      import.meta.env.VITE_VERSION +
      " [" +
      (nodeEnv ?? "n/a") +
      "]"
    );
  }
}

export default AppHealth;
