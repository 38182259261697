import { useCallback } from "react";
import { useAtom } from "jotai";
import CategoryIcon from "@mui/icons-material/Category";
import {
  Box,
  Typography,
  Alert,
  Button,
  FormControl,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Chip,
  ListItem,
  InputLabel,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useDialogs } from "../../../../contexts/useDialogs";
import { sessionAtom } from "../../../../features/accounts/account.state";
import { BaseDataTypeNames } from "../../../../features/data/data-types";
import {
  CsvResult,
  CsvImportOptions,
  MetaRecord,
  CategoryRecord,
  FieldRecord,
} from "../../../../features/import/csv";
import { CategorySelect } from "../../categories/CategorySelect";

export const ImportCsvAnalysis = ({
  result,
  setResult,
  options,
  setOptions,
}: {
  result: CsvResult;
  setResult: (r: CsvResult) => void;
  options: CsvImportOptions;
  setOptions: (o: CsvImportOptions) => void;
}) => {
  const { openUpgradeModal } = useDialogs();
  const [session] = useAtom(sessionAtom);

  const updateRecord = useCallback(
    (list: MetaRecord[], record: MetaRecord, checked?: boolean) => {
      const r = record ? { ...record } : null;
      for (let i = 0; i < list.length; i++) {
        list[i] =
          list[i].name === record?.name
            ? r
            : { ...list[i], checked: checked ? true : list[i].checked };
      }
      return r;
    },
    []
  );

  const updateCategory = useCallback(
    (
      cat: CategoryRecord,
      field?: FieldRecord,
      option?: MetaRecord,
      checked?: boolean
    ) => {
      const categories = [...result.analysis.categories];
      const c = updateRecord(categories, cat) as CategoryRecord;
      c.fields = [...c.fields];
      c.options = [...c.options];
      updateRecord(c.fields, field, checked);
      updateRecord(c.options, option, checked);
      setResult({ ...result, analysis: { ...result.analysis, categories } });
    },
    [result, setResult, updateRecord]
  );

  const updateCollection = useCallback(
    (col: MetaRecord) => {
      const collections = [...result.analysis.collections];
      updateRecord(collections, col);
      setResult({ ...result, analysis: { ...result.analysis, collections } });
    },
    [result, setResult, updateRecord]
  );

  const approveAll = useCallback(() => {
    const categories = result.analysis.categories.map((c) => ({
      ...c,
      checked: true,
      fields: c.fields.map((f) => ({ ...f, checked: true })),
      options: c.options.map((o) => ({ ...o, checked: true })),
    }));
    const collections = result.analysis.collections.map((c) => ({
      ...c,
      checked: true,
    }));
    setResult({
      ...result,
      analysis: { ...result.analysis, categories, collections },
    });
  }, [result, setResult]);

  return (
    <Box>
      <Box my={1}>Records: {result.analysis.recordsCount}</Box>{" "}
      <Typography
        variant="caption"
        component="div"
        sx={{ display: "flex", gap: 1, flexWrap: "wrap", mb: 1 }}
      >
        <span>Headers:</span>
        {result.analysis.headers.map((h, i) => [
          <i key={i + "-"}>|</i>,
          <i key={i}>{h}</i>,
        ])}
        <i>|</i>
      </Typography>
      {result.analysis.limitExceeded > 0 && (
        <Alert severity="warning">
          Your plan limit <strong>{session.account.limits.items}</strong>{" "}
          products is exceeded by{" "}
          <b className="error-text"> {result.analysis.limitExceeded}</b>. New
          items above this limit will be not imported.
          <Button
            variant="text"
            size="small"
            onClick={() => {
              openUpgradeModal("products");
            }}
          >
            Upgrade to increase
          </Button>
        </Alert>
      )}
      {result.analysis.codeHeaderAbsent && (
        <Alert severity="warning">
          <Box>
            The column header <strong>Code</strong> is not found. The system
            will automatically generate Code as a unique product id.
          </Box>
          <FormControl sx={{ my: 2 }}>
            <Select
              sx={{ minWidth: 150 }}
              value={options.codeField ?? ""}
              onChange={(event) => {
                setOptions({
                  ...options,
                  codeField: event.target.value,
                });
              }}
              labelId="code-select"
              size="small"
              displayEmpty
            >
              <MenuItem value="">
                -- Auto-generate code (or select field) --
              </MenuItem>
              {result.analysis.headers.map((h, i) => (
                <MenuItem key={i} value={h}>
                  {h}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Alert>
      )}
      {result.analysis.missedName > 0 && (
        <Alert severity="warning">
          <Box>
            Detected <strong>{result.analysis.missedName}</strong> record(s)
            with missing product <strong>Name</strong> field.
          </Box>

          <FormControl sx={{ my: 2 }}>
            <Select
              sx={{ minWidth: 150 }}
              value={options.nameField ?? ""}
              onChange={(event) => {
                setOptions({
                  ...options,
                  nameField: event.target.value,
                });
              }}
              labelId="name-select"
              size="small"
              displayEmpty
            >
              <MenuItem value="">
                -- Assign code as name (or select field) --
              </MenuItem>
              {result.analysis.headers.map((h, i) => (
                <MenuItem key={i} value={h}>
                  {h}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Alert>
      )}
      {result.analysis.missedCategories > 0 && (
        <Alert severity="warning">
          <Box mb={1}>
            Detected <strong>{result.analysis.missedCategories}</strong> new
            product(s) with missing category. Select a category for new products
          </Box>

          <CategorySelect
            categoryId={options.categoryId ?? ""}
            onChange={(id) => {
              setOptions({
                ...options,
                categoryId: id,
              });
            }}
          />
        </Alert>
      )}
      {result.analysis.missedCode > 0 && (
        <Alert severity="warning">
          Detected <strong>{result.analysis.missedCode}</strong> record(s) with
          missing Code field. The system will automatically generate Code for
          new products.
        </Alert>
      )}
      {result.analysis.productMoved > 0 && (
        <Alert severity="warning">
          Detected <strong>{result.analysis.productMoved}</strong> product(s)
          moved to another category.
        </Alert>
      )}
      {result.errors?.length > 0 && (
        <Box my={1}>
          <Alert severity="error">
            File contains{" "}
            {result.errorCount > 0 && <span>{result.errorCount} errors</span>}{" "}
            {result.warningCount > 0 && (
              <span>{result.warningCount} warnings</span>
            )}
          </Alert>
          <Table
            sx={{
              maxHeight: 100,
              my: 1,
              overflowY: "auto",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Line</TableCell>
                <TableCell>Code</TableCell>

                <TableCell>Error</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {result.errors.map((e, i) => (
                <TableRow key={i}>
                  <TableCell>{e.index}</TableCell>
                  <TableCell>{e.code}</TableCell>

                  <TableCell>
                    <Typography
                      color={e.isWarning ? "warning" : "error"}
                      component="span"
                    >
                      {e.message}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {e.count > 0 && <span>+ {e.count} record(s)</span>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
      {(result.analysis.categoryChanged ||
        result.analysis.collections.length > 0) && (
        <Alert severity="info">
          Detected new fields or collections in your CSV. Do you want to update
          your account for these changes?
          <Box mt={2}>
            <Button variant="outlined" size="small" onClick={approveAll}>
              Approve & select all updates
            </Button>
          </Box>
          {result.analysis.categoryAdded > 1 && (
            <Box mt={2}>
              You added <strong>{result.analysis.categoryAdded}</strong> new
              categories. Consider using <strong>collections</strong> to group
              your products.
            </Box>
          )}
        </Alert>
      )}
      {result.analysis.categoryChanged && (
        <Box>
          <List
            subheader={
              <ListSubheader>
                Select categories and fields for adding
              </ListSubheader>
            }
          >
            {result.analysis.categories.map((cat, i) => (
              <Box key={i}>
                <Grid container>
                  <Grid size={{ xs: 6 }}>
                    <ListItemButton key={cat.name}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          disableRipple
                          checked={cat.checked ?? false}
                          onChange={(e) =>
                            updateCategory(
                              {
                                ...cat,
                                checked: e.target.checked,
                              },
                              null,
                              null,
                              e.target.checked
                            )
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ my: 0 }}
                        primary={
                          <Box display="flex" alignItems="center" pb={1}>
                            <CategoryIcon fontSize="small" sx={{ mr: 1 }} />
                            <span>{cat.name}</span>
                            {cat.isNew && (
                              <Chip label="NEW" sx={{ ml: 1 }} size="small" />
                            )}
                          </Box>
                        }
                        secondary={cat.count + " products in the category"}
                      />
                    </ListItemButton>
                  </Grid>
                  <Grid size={{ xs: 6 }}>
                    {cat.checked && cat.fields.length > 0 && (
                      <List dense>
                        <Typography variant="caption">Add Fields</Typography>
                        {cat.fields.map((f, i) => (
                          <ListItem
                            key={i}
                            sx={{ my: 1 }}
                            secondaryAction={
                              f.checked && (
                                <FormControl sx={{ minWidth: 100 }}>
                                  <InputLabel>Data Type</InputLabel>
                                  <Select
                                    value={f.dataType}
                                    onChange={(e) =>
                                      updateCategory(cat, {
                                        ...f,
                                        dataType: e.target.value,
                                      })
                                    }
                                    label="Data Type"
                                    size="small"
                                  >
                                    {BaseDataTypeNames.map((t) => (
                                      <MenuItem key={t} value={t}>
                                        {t}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )
                            }
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  disableRipple
                                  checked={f.checked ?? false}
                                  onChange={(e) =>
                                    updateCategory(cat, {
                                      ...f,
                                      checked: e.target.checked,
                                    })
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText primary={f.name} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    )}
                    {cat.checked && cat.options.length > 0 && (
                      <List dense disablePadding>
                        <Typography variant="caption">
                          Add Variant Options
                        </Typography>
                        {cat.options.map((o, i) => (
                          <ListItem key={i} disablePadding>
                            <ListItemButton>
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  disableRipple
                                  checked={o.checked ?? false}
                                  onChange={(e) =>
                                    updateCategory(cat, null, {
                                      ...o,
                                      checked: e.target.checked,
                                    })
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText primary={o.name} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </Grid>
                </Grid>{" "}
                <Divider />
              </Box>
            ))}
          </List>
        </Box>
      )}
      {result.analysis.collections.length > 0 && (
        <Box>
          <List
            subheader={
              <ListSubheader>Select new collections for adding</ListSubheader>
            }
          >
            {result.analysis.collections.map((c, i) => (
              <Box key={i}>
                <ListItem key={c.name} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        disableRipple
                        checked={c.checked ?? false}
                        onChange={(e) =>
                          updateCollection({
                            ...c,
                            checked: e.target.checked,
                          })
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary={c.name} />
                  </ListItemButton>
                </ListItem>
              </Box>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};
