import { Suspense, useCallback, useEffect, useState } from "react";
import { Box, Tabs, Tab, Button, ThemeProvider } from "@mui/material";
import { FixedPanel } from "../../../components/FixedPanel";
import { CatalogExtension } from "../../../features/design/design.types";

import ListIcon from "@mui/icons-material/List";

import { IconTypes } from "../../../components/icons.types";
import {
  ProductListExtOptions,
  ProductListExtTabIndex,
} from "../../../features/catalogs/product-list.ext";
import LoadingScreen from "../../../components/LoadingScreen";
import {
  ALL_PRODUCTS,
  getProductContainerState,
  INDIVIDUAL_PRODUCTS,
  initProductContainerCollection,
} from "../../../features/catalogs/catalog.products";
import { ProductPicker } from "../../../containers/products/list/ProductPicker";
import { productDbAtom } from "../../../features/products/product.state";
import { useAtom } from "jotai";
import { CategorySelect } from "../../../containers/products/categories/CategorySelect";
import Typography from "@mui/material/Typography";
import { darkTheme } from "../../../ui/theme";
import { useBlockRoute } from "../../../ui/useBlockRoute";
import { ProductListExtDesign } from "./ProductListExtDesign";
import { ProductListExtFields } from "./ProductListExtFields";
import { trackEvent } from "../../../services/analytics";
import { enumIndexName } from "../../../features/data/data-arrays";

const minBarWidth = 320;
const tabsWidth = 100;

export const ProductListExt = (options: ProductListExtOptions) => {
  const [productDb] = useAtom(productDbAtom);

  const [tab, setTab] = useState(ProductListExtTabIndex.Products);
  const [isProductsDirty, setIsProductsDirty] = useState(false);
  const [isDesignDirty, setIsDesignDirty] = useState(false);

  const [edit, setEdit] = useState<CatalogExtension>();

  const [categoryId, setCategoryId] = useState<string>(
    productDb.categoryMap.keys().next().value
  );

  const setExtension = useCallback((extension: CatalogExtension) => {
    extension.version = new Date().toISOString();
    setEdit(extension);
  }, []);

  useEffect(() => {
    const ext = {
      ...options.extension,
      version: new Date().toISOString(),
    };
    initProductContainerCollection(productDb, ext, options.builder.items);
    setExtension(ext);
    // if (getProductContainerState(ext) === "collection") {
    //   setTab(ProductListExtTabIndex.Fields);
    // }
    setIsProductsDirty(options?.isNew);
    setIsDesignDirty(options?.isNew);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.extension]);

  const handleEdit = useCallback(
    (c: CatalogExtension, isDesignChange: boolean) => {
      setExtension(c);
      if (isDesignChange) setIsDesignDirty(true);
      else setIsProductsDirty(true);
    },
    [setExtension]
  );

  const { ExitWarningDialog, setIsExit } = useBlockRoute({
    isDirty: isProductsDirty || isDesignDirty,
    autoSave: false,
    onProceed(_c, cancelled) {
      if (cancelled) {
        options.onCancel?.();
      } else {
        if (isProductsDirty || isDesignDirty) {
          options.onChange(edit, !isDesignDirty);
          trackEvent({
            category: "product-list",
            action: options?.isNew ? "create" : "update",
            tag: true,
            label:
              getProductContainerState(edit) +
              " " +
              (edit.products?.length || ""),
          });
        }
      }
      options.onClose();
      setIsDesignDirty(false);
      setIsProductsDirty(false);
    },
    target: "a list",
  });

  if (!edit) return null;

  const state = getProductContainerState(edit);

  return (
    <Box id="plist-container" className="designer-page product-list-designer">
      <Box
        sx={{ height: "100%", width: "100%" }}
        className="designer-container"
      >
        <ThemeProvider theme={darkTheme}>
          <Box
            className="designer-toolbar"
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
              gap: 1,
              p: 0.5,
              minHeight: 55,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                minWidth: minBarWidth,
              }}
            >
              <Button
                variant="text"
                color="inherit"
                onClick={() =>
                  isProductsDirty || isDesignDirty
                    ? setIsExit(true)
                    : options.onClose()
                }
                startIcon={<IconTypes.ArrowBack />}
              >
                Back
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flex: 1,
              }}
            >
              <Typography variant="h6" component="div">
                Product List Designer
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              {tab === ProductListExtTabIndex.Fields && (
                <CategorySelect
                  sx={{ minWidth: 150, mx: 3 }}
                  categoryId={categoryId}
                  onChange={(c) => setCategoryId(c)}
                />
              )}

              {state !== "unset" && (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      if (edit.collectionId === INDIVIDUAL_PRODUCTS)
                        edit.collectionId = null;
                      if (edit.collectionId === ALL_PRODUCTS) {
                        edit.collectionId = null;
                        edit.productSource = ALL_PRODUCTS;
                      }
                      if (isProductsDirty || isDesignDirty)
                        options.onChange(edit, !isDesignDirty);
                      options.onClose();
                    }}
                    sx={{ mx: 1 }}
                  >
                    Save Product List
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setTab(ProductListExtTabIndex.Design)}
                    sx={{ mx: 1 }}
                  >
                    Preview
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </ThemeProvider>

        <Box sx={{ display: "flex" }}>
          <Box sx={{ flexGrow: 1, minWidth: 800 }}>
            <Suspense fallback={<LoadingScreen title="loading database" />}>
              {tab === ProductListExtTabIndex.Products && (
                <Box sx={{ px: 2, height: "100%" }}>
                  <ProductPicker
                    container={edit}
                    setContainer={(c) =>
                      handleEdit(c as CatalogExtension, false)
                    }
                    name="product list"
                    containerId="#plist-container"
                    catalogItems={options.builder?.items}
                    productDb={productDb}
                    breakpoint="lg"
                  />
                </Box>
              )}
              {tab === ProductListExtTabIndex.Fields && (
                <ProductListExtFields
                  extension={edit}
                  onChange={(c) => handleEdit(c, true)}
                  categoryId={categoryId}
                />
              )}
              {tab === ProductListExtTabIndex.Design && (
                <ProductListExtDesign
                  {...options}
                  extension={edit}
                  onChange={(c) => handleEdit(c, true)}
                />
              )}
            </Suspense>
          </Box>

          <Box
            className={
              tab !== ProductListExtTabIndex.Design ? "shadow-panel" : ""
            }
          >
            <FixedPanel
              overflow="auto"
              container="#plist-container"
              breakpoint="lg"
            >
              <ThemeProvider theme={darkTheme}>
                <Box
                  className="sidebar dark"
                  sx={{ width: tabsWidth, height: "100%" }}
                >
                  <Box className="sidebar-tabs" sx={{ width: tabsWidth }}>
                    <Tabs
                      value={tab}
                      onChange={(_e, v) => {
                        setTab(v);
                        trackEvent({
                          category: "product-list",
                          action: "tab",
                          label: enumIndexName(ProductListExtTabIndex, v),
                        });
                      }}
                      orientation="vertical"
                    >
                      <Tab icon={<IconTypes.Product />} label="Products" />
                      <Tab icon={<ListIcon />} label="Fields" />
                      <Tab icon={<IconTypes.Design />} label="Design" />
                    </Tabs>
                  </Box>
                </Box>
              </ThemeProvider>
            </FixedPanel>
          </Box>
        </Box>
      </Box>
      {ExitWarningDialog}
    </Box>
  );
};
