import { atomWithDefault } from "jotai/utils";
import { getParametersDb } from "./parameter.service";
import { atom } from "jotai";

export const baseParameterDbAtom = atomWithDefault(
  async () => await getParametersDb()
);

export const parameterDbAtom = atom(
  async (get) => await get(baseParameterDbAtom),
  (_get, set, update) => {
    set(baseParameterDbAtom, update);
  }
);
