import { useEffect, useState } from "react";
import { Box, DialogContent, DialogTitle, Tab, Tabs } from "@mui/material";

import { ImageModalParams, ImageModal } from "./ImageModal";
import { useAtom } from "jotai";
import { EmptyState } from "../../../components/EmptyState";
import { CloseButton } from "../../../components/buttons/CloseButton";
import { sessionAtom } from "../../../features/accounts/account.state";
import { getImageUrl } from "../../../features/images/image";
import { imageDbAtom } from "../../../features/images/image.state";
import { ImageBrowser } from "../ImageBrowser";
import {
  ImageViewOptions,
  FolderSelect,
  ImageViewControls,
} from "../image-components";
import { ImageUploadContent } from "./ImageUploadModal";
import { imageSortBy } from "../../../features/images/image-func";

export const ImageSelectModal = (params: ImageModalParams) => {
  const [tab, setTab] = useState("browse");

  useEffect(() => {
    if (params.options) {
      setTab("browse");
    }
  }, [params.options]);

  return (
    <ImageModal
      open={!!params.options}
      title="Select Image"
      onClose={params.onClose}
      width="lg"
    >
      <DialogTitle>
        <div>Select Image</div>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tab} onChange={(_e, v) => setTab(v)}>
            <Tab label="Browse" value="browse" />
            <Tab label="Upload" value="upload" />
          </Tabs>
        </Box>
        <CloseButton onClose={params.onClose} />
      </DialogTitle>
      {tab === "browse" && (
        <ImageBrowseContent
          {...params}
          onUpload={() => {
            setTab("upload");
          }}
        />
      )}
      {tab === "upload" && <ImageUploadContent {...params} />}
    </ImageModal>
  );
};

export const ImageBrowseContent = (
  params: ImageModalParams & { onUpload: () => void }
) => {
  //const ref = useRef<ImageBrowserRef>();
  const [view, setView] = useState<ImageViewOptions>({
    filter: "",
    sort: imageSortBy[0].v,
    folder: params.options.folder,
  });
  const [session] = useAtom(sessionAtom);
  const [imageDb] = useAtom(imageDbAtom);
  const isEmpty = imageDb.imageMap.size === 0;
  const changeView = (v: ImageViewOptions) => {
    setView({ ...view, ...v });
  };
  return (
    <DialogContent sx={{ p: 0 }}>
      {isEmpty && (
        <Box sx={{ height: "70vh", p: 1 }}>
          <EmptyState
            label="No images found"
            btnLabel="Upload images"
            imageSrc="/images/image-gallery.png"
            onAdd={params.onUpload}
          />
        </Box>
      )}
      {!isEmpty && (
        <>
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              py: 1,
              px: 3,
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1,
              },
            })}
          >
            <Box mr={1}>
              <FolderSelect
                folder={view.folder}
                onChange={(f) => changeView({ folder: f })}
                label="Filter by folder"
                showAll={true}
              />
            </Box>
            <ImageViewControls
              view={view}
              onChange={changeView}
              align="right"
            />
          </Box>
          <Box sx={{ height: "70vh" }}>
            <ImageBrowser
              view={view}
              openImageModal={params.openModal}
              selectOnly={true}
              onSelect={(i) => {
                params.onClose();
                params.options?.onSelect?.(
                  i.path,
                  getImageUrl(session.account?.config.imageStorageUrl, i.path)
                );
              }}
            />
          </Box>
        </>
      )}
    </DialogContent>
  );
};
