import Cookies from "js-cookie";

export interface CookiePreferences {
  essential: boolean;
  analytics: boolean;
}

export const CONSENT_COOKIE_NAME = "cm-app-cookie-consent";

export const getUserConsent = (): CookiePreferences => {
  const consent = Cookies.get(CONSENT_COOKIE_NAME);
  return consent ? JSON.parse(consent) : { analytics: true };
};
