import { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { ColorPicker } from "../../../components/ColorPicker";
import { FontPicker } from "../../../components/FontPicker";
import { CatalogBuilder } from "../../../features/catalogs/catalog.builder";
import { Catalog, CatalogTheme } from "../../../features/catalogs/catalogs";
import { ColorSchema, colorSchemas } from "../../../features/design/designer";

type DesignerTestProps = {
  catalog: Catalog;
  onChange: (theme: CatalogTheme) => void;
  builder?: CatalogBuilder;
};

const getCatalogTheme = (c: Catalog): CatalogTheme =>
  c
    ? {
        bgColor: c.bgColor,
        primeColor: c.primeColor,
        fontColor: c.fontColor,
        headerColor: c.headerColor,
        baseFont: c.baseFont,
        headerFont: c.headerFont,
      }
    : {};

export const DesignerTheme = ({ catalog, onChange }: DesignerTestProps) => {
  const [theme, setTheme] = useState<CatalogTheme>(getCatalogTheme(catalog));
  const [schema, setSchema] = useState<ColorSchema>();

  const handleChange = (theme: CatalogTheme) => {
    setTheme(theme);
    onChange(theme);
  };

  const colors = [
    { name: "Background", prop: "bgColor" },
    { name: "Prime", prop: "primeColor" },
    { name: "Font", prop: "fontColor" },
    { name: "Headings", prop: "headerColor" },
  ];

  const fonts = [
    { name: "Base", prop: "baseFont" },
    { name: "Heading", prop: "headerFont" },
  ];

  return (
    <Box>
      <Typography variant="subtitle1">Colors</Typography>

      <FormControl fullWidth sx={{ my: 2 }} size="small">
        <InputLabel>Schema</InputLabel>
        <Select
          value={schema?.n ?? ""}
          label="Schema"
          onChange={(e) => {
            const s = colorSchemas.find((s) => s.n === e.target.value);
            setSchema(s);
            handleChange({
              ...theme,
              bgColor: s?.a,
              primeColor: s?.b,
              fontColor: s?.c,
              headerColor: s?.d,
            });
          }}
          sx={{
            "& .MuiSelect-select": { display: "flex", alignItems: "center" },
          }}
        >
          {colorSchemas.map((s) => (
            <MenuItem key={s.n} value={s.n}>
              <ListItemIcon>
                <div className="schema-block">
                  <div
                    className="schema-color"
                    style={{ backgroundColor: s.a }}
                  ></div>
                  <div
                    className="schema-color"
                    style={{ backgroundColor: s.b }}
                  ></div>
                  <div
                    className="schema-color"
                    style={{ backgroundColor: s.c }}
                  ></div>
                  <div
                    className="schema-color"
                    style={{ backgroundColor: s.d }}
                  ></div>
                </div>
              </ListItemIcon>
              <ListItemText>{s.n}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, my: 2 }}>
        {colors.map((c) => (
          <Box key={c.prop}>
            <Typography variant="caption" component={"div"}>
              {c.name}
            </Typography>{" "}
            <ColorPicker
              color={theme[c.prop]}
              setColor={(value) =>
                handleChange({
                  ...theme,
                  [c.prop]: value,
                })
              }
            />
          </Box>
        ))}
      </Box>

      <Typography variant="subtitle1" mt={3}>
        Fonts
      </Typography>

      {fonts.map((f) => (
        <FontPicker
          key={f.prop}
          label={f.name}
          fontFamily={theme[f.prop]}
          onChange={(fontFamily) =>
            handleChange({
              ...theme,
              [f.prop]: fontFamily ? fontFamily : undefined,
            })
          }
        />
      ))}
    </Box>
  );
};
