import { useCallback, useState } from "react";
import { ImageSelectOptions } from "./image";
import {
  OpenImageModalFunc,
  ImageModalType,
} from "../../containers/images/modals/ImageModal";
import { ImageSelectModal } from "../../containers/images/modals/ImageSelectModal";
import { ImageUploadModal } from "../../containers/images/modals/ImageUploadModal";
import { ImageViewModal } from "../../containers/images/modals/ImageViewModal";

export const useImageModals = () => {
  const [uploadOptions, setUploadOptions] = useState<ImageSelectOptions>();
  const [viewOptions, setViewOptions] = useState<ImageSelectOptions>();
  const [selectOptions, setSelectOptions] = useState<ImageSelectOptions>();

  const openImageModal: OpenImageModalFunc = (
    opts: ImageSelectOptions,
    type: ImageModalType
  ) => {
    switch (type) {
      case "upload":
        setUploadOptions(opts);
        break;
      case "view":
        setViewOptions(opts);
        break;
      case "select":
        setSelectOptions(opts);
        break;
    }
  };

  const renderFileUpload = useCallback(() => {
    if (!uploadOptions) return null;

    return (
      <ImageUploadModal
        options={uploadOptions}
        onClose={() => {
          setUploadOptions(null);
        }}
        openModal={openImageModal}
      />
    );
  }, [uploadOptions]);

  const renderImageView = useCallback(() => {
    if (!viewOptions) return null;
    return (
      <ImageViewModal
        options={viewOptions}
        onClose={() => setViewOptions(null)}
        openModal={openImageModal}
      />
    );
  }, [viewOptions]);

  const renderImageSelect = useCallback(() => {
    if (!selectOptions) return null;

    return (
      <ImageSelectModal
        options={selectOptions}
        onClose={() => {
          setSelectOptions(null);
        }}
        openModal={openImageModal}
      />
    );
  }, [selectOptions]);

  const renderImageModals = () => (
    <>
      {renderImageView()}
      {renderFileUpload()}
      {renderImageSelect()}
    </>
  );

  return { openImageModal, renderImageModals };
};
