import { useCallback, useEffect, useState } from "react";
import { Box, LinearProgress } from "@mui/material";
import InfiniteScroll from "react-infinite-scroller";
import { useAtom } from "jotai";
import { ImageViewOptions } from "./image-components";
import { BlobImage } from "../../features/images/image";
import { imageDbAtom } from "../../features/images/image.state";
import { imageSortBy } from "../../features/images/image-func";

const imageListGrow = 50;

export const ImageScroll = ({
  view,
  renderImages,
}: {
  view: ImageViewOptions;
  renderImages: (images: BlobImage[]) => React.ReactNode;
}) => {
  const [imageDb] = useAtom(imageDbAtom);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState<BlobImage[]>([]);
  const [viewImages, setViewImages] = useState<BlobImage[]>([]);

  useEffect(() => {
    //const folder = view.folder ? imageDb.folderMap.get(view.folder) : null;

    const allImages = view.folder
      ? imageDb.folderMap.get(view.folder)?.files || []
      : Array.from(imageDb.imageMap.values());

    const vs = imageSortBy.find((s) => s.v === view.sort);
    const result = allImages
      .filterList(view.filter, (i) => i.path)
      .sortList((i) => i[vs.v], vs.o);

    setViewImages(result);
    setLoading(false);
    setImages([]);
  }, [imageDb.folderMap, imageDb.imageMap, view]);

  const loadMoreImages = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      setImages((i) => [
        ...i,
        ...viewImages.slice(i.length, i.length + imageListGrow),
      ]);
      setLoading(false);
    }, 200);
  }, [loading, viewImages]);

  if (viewImages.length === 0) {
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        No images found
      </Box>
    );
  }

  return (
    <InfiniteScroll
      loadMore={loadMoreImages}
      hasMore={images.length < viewImages.length}
      loader={<LinearProgress key="progress" />}
      useWindow={false}
    >
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {renderImages(images)}
      </Box>
    </InfiniteScroll>
  );
};
