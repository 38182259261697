const t = {
  // h1: {
  //   fontFamily: "Jost",
  // },
  // h2: {
  //   fontFamily: "Jost",
  // },
  // h3: {
  //   fontFamily: "Jost",
  // },
  // h4: {
  //   fontFamily: "Jost",
  // },
  // h5: {
  //   fontFamily: "Jost",
  // },
  // h6: {
  //   fontFamily: "Jost",
  // },
  h1: {
    fontFamily: "Jost",
    // fontWeight: 500,
    // fontSize: 35,
    // letterSpacing: "-0.24px",
  },
  h2: {
    fontFamily: "Jost",
    // fontWeight: 500,
    // fontSize: 29,
    // letterSpacing: "-0.24px",
  },
  h3: {
    fontFamily: "Jost",
    // fontWeight: 500,
    // fontSize: 24,
    // letterSpacing: "-0.06px",
  },
  h4: {
    fontFamily: "Jost",
    // fontWeight: 500,
    // fontSize: 20,
    // letterSpacing: "-0.06px",
  },
  h5: {
    fontFamily: "Jost",
    // fontWeight: 500,
    // fontSize: 16,
    // letterSpacing: "-0.05px",
  },
  h6: {
    fontFamily: "Jost",
    // fontWeight: 500,
    // fontSize: 14,
    // letterSpacing: "-0.05px",
  },
  // overline: {
  //   fontWeight: 500,
  // },
};
export default t;
