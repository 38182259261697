import { atomWithDefault } from "jotai/utils";
import apiClient from "../../services/apiClient";
import { logError } from "../../services/logging";
import { MAIN_SITE_URL } from "../../services/site.config";
import { AccountRef, FreePlan } from "../accounts/account";

const DEFAULT_FONTS: string[] = [
  "Arial",
  "Courier New",
  "Times New Roman",
  "Comic Sans Ms",
  "Fantasy",
];

export const FONT_SIZES = ["8", "10", "12", "14", "16", "18", "20", "24"];

export type FontFamily = {
  Name: string;
  Type: string;
  Description: string;
  IsPremium?: boolean;
  IsInternational?: boolean;
  IsGroup?: boolean;
};

export type UiConfig = {
  fonts: FontFamily[];
};

const getUiConfig = async (): Promise<UiConfig> => {
  try {
    const response = await apiClient.get<UiConfig>(
      MAIN_SITE_URL + "/bridge/uiconfig"
    );
    return response.data;
  } catch (e) {
    logError(e);
    return {
      fonts: DEFAULT_FONTS.map((f) => ({
        Name: f,
        Type: "basic",
        Description: f,
      })),
    };
  }
};

export const uiConfigAtom = atomWithDefault(async () => await getUiConfig());

export const getFontTypeName = (type: string) => {
  if (type === "basic" || type === "regular") return "";
  if (type === "intl") return "International";
  return type?.toUpperCase() ?? "";
};

export const getFonts = (
  account: AccountRef,
  config: UiConfig
): FontFamily[] => {
  const result = config.fonts
    .filter(
      (f) =>
        (account.enableIntlFonts || !f.IsInternational) &&
        (account.planCode !== FreePlan || !f.IsPremium)
    )
    .reduce(
      (acc, f) => {
        const type = getFontTypeName(f.Type);
        if (type && acc.type !== type) {
          acc.fonts.push({
            Name: f.Type,
            Description: type,
            Type: f.Type,
            IsGroup: true,
          });
          acc.type = type;
        }
        acc.fonts.push(f);
        return acc;
      },
      { fonts: [], type: "" } as { fonts: FontFamily[]; type: string }
    );

  return result.fonts;
};
